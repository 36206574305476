import React from 'react'
import { getOrganizationProductDescription, OrganizationBasic, OrganizationProduct, OrganizationSettings } from '../../models/organization'
import ErrorCmp from '../Error'
import Loading from '../Loading'
import { GET_MANAGERAGENT_ORGANIZATIONS } from 'queries/organization'
import i18n from 'components/i18n'
import { UserAuthenticated } from 'models/user'
import CreateFreeTierOrganization from 'components/CreateFreeTierOrganization'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { track } from 'segment'
import { userRoles } from 'lib/utils'


type OrgSelectPropsIn = {
  userAuthenticated: UserAuthenticated;
  selectedOrganizationId: string;
}

type QueryRes = {organizations: (OrganizationBasic&OrganizationSettings)[]}
type Variables = {userId: string}

const OrganizationSelect = (p: OrgSelectPropsIn) => {
  const location = useLocation()

  const {loading, data, error} = useQuery<QueryRes, Variables>(GET_MANAGERAGENT_ORGANIZATIONS, {
    variables: {userId: p.userAuthenticated.id},
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />

  const showCreateOwnOrg = !data?.organizations.some(o => p.userAuthenticated.billingManagerOrganizations.includes(o.id)
    && o.product === OrganizationProduct.FT)

  const organizations = [...(data?.organizations || [])]

  return <div className="my-3">
    {showCreateOwnOrg && <CreateFreeTierOrganization userId={p.userAuthenticated.id} onCompleted={(organizationId) => {
      window.location.replace(`/organization/${organizationId}`)
    }} fullWidth />}
    <div className={showCreateOwnOrg ? 'mt-1' : ''}>
      {organizations.map((o, index) => {
        return <div key={`menu-organization-${o.id}`}
          className="flex items-center cursor-pointer hover:bg-gray-100 rounded-lg py-1 px-2">
          <input id={o.id} name="notification-method" type="radio" checked={location.pathname.includes(o.id)}
            className="focus:ring-lake h-5 w-5 text-lake border-gray-300 cursor-pointer"
            onClick={(e) => {
              e.preventDefault()
              track({event: 'Workspace Changed', variables: {
                roles: userRoles(p.userAuthenticated, o.id),
                changedToWorkspaceId: o.id,
                workspaceProductId: o.product,
                workspaceProductName: getOrganizationProductDescription(o.product),
                workspacePlanId: o.billingService?.planId,
                workspacePlanName: o.billingService?.planName,
                platform: 'dashboard',
                workspaceName: o.name,
              }})
              window.location.replace(`/organization/${o.id}`)
            }}
            onChange={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          />
          <label htmlFor={o.id} className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer">
            {o.name}
            <div className="italic text-xs text-lightgray">
              {o.billingService?.planName || '-'}
            </div>
          </label>
        </div>
      }) || <div className="text-medgray w-full text-center text-sm px-4">
        {`${i18n.t('no_organization_available')}`}
      </div>}
    </div>
  </div>
}

export default OrganizationSelect
