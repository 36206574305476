import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { copyToClipboard } from 'lib/utils'
import CLIENT_SETTINGS from '../lib/client_settings'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import OpenInNewIcon from '@material-ui/icons/OpenInNewRounded'
import i18n from 'components/i18n'

type ContextMenuPropsIn = {
  cmp: any,
  route: string,
  disabled?: boolean,
}

export default function (props: ContextMenuPropsIn) {
  const [state, setState] = React.useState({
    mouseY: null,
    mouseX: null,
  })

  const handleClick = (event) => {
    event.preventDefault()
    if (props.disabled) {
      return
    }
    if (state.mouseY !== null && state.mouseX !== null) {
      setState({
        mouseX: null,
        mouseY: null,
      })
    } else {
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      })
    }
  }

  const handleClose = () => {
    setState({
      mouseX: null,
      mouseY: null,
    })
  }

  const handleOpenLinkInNewTab = () => {
    setState({
      mouseY: null,
      mouseX: null,
    })
    window.open(props.route, '_blank')

  }

  const handleCopyLink = (e) => {
    setState({
      mouseY: null,
      mouseX: null,
    })
    copyToClipboard(`${CLIENT_SETTINGS.public.gnowbeDashboardUrl}${props.route}`)
  }

  return <div onContextMenu={handleClick}>
    {props.cmp}
    <Menu
      elevation={2}
      keepMounted
      open={state.mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        state.mouseY !== null && state.mouseX !== null
          ? { top: state.mouseY, left: state.mouseX }
          : undefined
      }
    >
      <MenuItem onClick={handleOpenLinkInNewTab} className="text-sm">
        <OpenInNewIcon fontSize="small" className="text-medgray" />&nbsp;{i18n.t('open_in_new_tab')}
      </MenuItem>
      <MenuItem onClick={handleCopyLink} className="text-sm">
        <FileCopyIcon fontSize="small" className="text-medgray" />&nbsp;{i18n.t('copy_link')}
      </MenuItem>
    </Menu>
  </div>
}
