import * as React from 'react'
import IconType from './iconType'

const NoTravelIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 18 18"
      >
      <path d="M8.16654 5.80817L4.4082 2.04984C5.72487 1.17484 7.29987 0.666504 8.99987 0.666504C13.5999 0.666504 17.3332 4.39984 17.3332 8.99984C17.3332 10.6998 16.8249 12.2748 15.9499 13.5915L14.7332 12.3748C15.3249 11.3915 15.6665 10.2332 15.6665 8.99984C15.6665 6.20817 13.9415 3.8165 11.4999 2.82484V3.1665C11.4999 4.08317 10.7499 4.83317 9.8332 4.83317H8.16654V5.80817ZM16.6582 16.6582L15.4832 17.8332L13.5915 15.9415C12.2749 16.8248 10.6999 17.3332 8.99987 17.3332C4.39987 17.3332 0.666537 13.5998 0.666537 8.99984C0.666537 7.29984 1.17487 5.72484 2.04987 4.40817L0.158203 2.5165L1.3332 1.3415L16.6582 16.6582ZM8.16654 13.9998C7.24987 13.9998 6.49987 13.2498 6.49987 12.3332V11.4998L2.5082 7.50817C2.39987 7.9915 2.3332 8.48317 2.3332 8.99984C2.3332 12.3998 4.87487 15.1998 8.16654 15.6082V13.9998Z"
      />
    </svg>
  )
}

export default NoTravelIcon
