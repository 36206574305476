import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

type LoadingProps = {
  enabled: boolean;
  block?: boolean;
  wrapperStyle?: React.CSSProperties;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}
export default class Loading extends React.Component<LoadingProps> {
  render() {
    const sizePx = `${(this.props.size || 16)} px`
    return (
      <div className={this.props.className || ''} style={{
        ...(this.props.wrapperStyle || {}),
        position: 'relative',
        backgroundColor: 'transparent',
        display: !this.props.enabled ? 'none' : this.props.block ? 'block' : 'inline-block',
        width: sizePx, height: sizePx,
      }}>
        <CircularProgress color="secondary"/>
      </div>
    )
  }
}
