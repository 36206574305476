import React from 'react'
import CLIENT_SETTINGS from 'lib/client_settings'
import i18n from 'components/i18n'
import RefreshIcon from '@heroicons/react/24/solid/ArrowPathIcon'
import { showSnackbar } from 'components/snackbar'

function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(
    () => {
      ref.current = value
    },
    [value],
  )
  return ref.current
}

export default function () {
  const [hash, setHash] = React.useState('')
  const prevHash = usePrevious(hash)

  React.useEffect(
    () => {
      if (prevHash && prevHash !== hash) {
        showSnackbar(`${i18n.t('update')} 🎉`, 7200000, 'info', i18n.t('new_version_available_alert'), 'Reload', <RefreshIcon/>, () => location.reload())
      }
      const interval = setInterval(
        () => {
          fetch(`${CLIENT_SETTINGS.public.gnowbeDashboardUrl}/version.txt`, {cache: 'no-store'}).then(async (v) => {
            if (!v.ok) {
              console.log('error version checking', v.status)
              return
            }
            const test = await v.text()
            if (test !== hash) {
              setHash(test)
            }
            return
          }).catch((error) => {
            console.error('error version checking', error)
          })
        },
        10000,
      )
      return () => {
        clearInterval(interval)
      }
    },
    [hash],
  )

  return null
}
