import React from 'react'
import { createRoot } from 'react-dom/client'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import i18n from './i18n'
import clsx from 'clsx'
import TextField, { KeyDownType } from './TextField'
import { I18nextProvider } from 'react-i18next'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './Layout'
import XIcon from '@heroicons/react/24/solid/XMarkIcon'
import CheckCircleIcon from '@heroicons/react/24/solid/CheckCircleIcon'
import ExclamationCircleIcon from '@heroicons/react/24/solid/ExclamationCircleIcon'
import InformationCircleIcon from '@heroicons/react/24/solid/InformationCircleIcon'
import XCircleIcon from '@heroicons/react/24/solid/XCircleIcon'
import { Button } from './elements/Button'
import { ApolloProvider } from '@apollo/client'
import { client } from 'App'
import classNames from 'classnames'
import toast from 'react-hot-toast'

type SeverityType = 'success'|'error'|'info'|'warning'

export function showSnackbar(message: string, autohideAfter?: number, severity?: SeverityType, subtitle?: string, buttonText?: string, buttonIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined, buttonAction?: () => void) {
  const divCn = clsx({
    'min-w-[20rem] shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5': true,
    'bg-blue-50 border-blue-200': severity === 'info',
    'bg-yellow-50 border-yellow-200': severity === 'warning',
    'bg-red-50 border-red-200': severity === 'error',
    'bg-green-50 border-green-200': severity === 'success',
    'bg-white border-gray-200': !severity,
  })

  const buttonCn = clsx({
    'w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium': true,
    'text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500': severity === 'info',
    'text-yellow-600 hover:text-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500': severity === 'warning',
    'text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500': severity === 'error',
    'text-green-600 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-green-500': severity === 'success',
  })

  toast.custom(t => (
    <div className={`${t.visible ? 'animate-enter' : 'animate-leave'} ${divCn}`}>
      <div className="flex-1 p-4 flex items-center">
        {severity === 'info'
          ? <InformationCircleIcon className="w-5 h-5 text-blue-600 mr-3" />
          : severity === 'warning'
            ? <ExclamationCircleIcon className="w-5 h-5 text-yellow-600 mr-3"/>
            : severity === 'error'
              ? <XCircleIcon className="w-5 h-5 text-red-600 mr-3"/>
              : severity === 'success' && <CheckCircleIcon className="w-5 h-5 text-green-600 mr-3"/>}

        <div className="flex-1">
          <p className="text-sm font-medium text-gray-900">
            {message}
          </p>

          {subtitle && (
            <p className="mt-0.5 text-xs text-gray-500">
              {subtitle}
            </p>
          )}
        </div>

        {!buttonAction && <button onClick={() => toast.remove()}><XIcon className="w-5 h-5 text-gray-400 hover:text-gray-500"/></button>}
      </div>

      {buttonAction && (
        <div className="flex border-l border-gray-200">
          <Button
            onClick={buttonAction}
            className={buttonCn}
            text={buttonText}
            icon={buttonIcon}
          />
        </div>
      )}
    </div>),   {
      duration: autohideAfter,
    })
}

export enum OkCancel {ok, cancel}

// ==========================================================================================================

type MyModalPropsIn = {
  id?: string,
  title: string,
  description?: string|React.ReactNode,
  content?: {element: any, getCloseEvent?: () => void}[],
  actions?: {
    text: string,
    color?:'primary'|'secondary',
    className?: string, getActionEvent?: () => void,
    focused?: boolean,
  }[],
  modalClassname?: string,
  hideElements?: string[],
  onClose?: (data) => void,
}
export class MyModal extends React.Component<MyModalPropsIn, {open: boolean}> {
  constructor(props) {
    super(props)
    this.state = { open: true }
  }
  close(e?: any) {
    this.setState({open: false})
    this.props.onClose && this.props.onClose(e)
  }


  UNSAFE_componentWillReceiveProps(newProps: MyModalPropsIn) {
    if (newProps.id !== this.props.id) {
      this.props.id && document
        .removeEventListener(`closeDialog-${this.props.id}` as any, {} as EventListenerOrEventListenerObject)
      if (newProps.id) {
        document.addEventListener(`closeDialog-${newProps.id}` as any, (e: CustomEvent) => {
          this.close(e.detail)
        })
      }
    }
  }

  componentWillUnmount() {
    if (this.props.id) {
      document.removeEventListener(`closeDialog-${this.props.id}`, {} as EventListenerOrEventListenerObject)
    }
  }

  // todo: remove muiThemeProvider once we get rid of material-ui
  render () {
    const modalClassname = classNames({
      'inline-block relative align-bottom bg-white rounded-lg text-center shadow-xl transform transition-all sm:my-8 sm:align-middle sm:min-w-lg sm:max-w-2/3': true,
      [this.props.modalClassname || '']: !!this.props.modalClassname,
    })

    return <MuiThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <Transition.Root show={this.state.open} as={React.Fragment}>
            <HeadlessDialog
              as="div"
              static
              open={this.state.open}
              onClose={() => this.close(undefined)}
              className="fixed z-modal inset-0 overflow-y-auto"
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className={modalClassname}>
                    <div>
                      <div className="w-full text-center flex flex-row justify-center px-2 pt-2">
                        {!this.props.hideElements?.includes('title') && (
                          <h3 className="leading-9 text-2xl font-bold text-deepgray mt-4 px-4">
                            {this.props.title}
                          </h3>
                        )}

                        <button className="outline-none rounded-full fixed top-2 right-2" onClick={(e) => {
                          e.preventDefault()
                          this.close()
                        }}>
                          <XIcon className="text-medgray w-6 h-6" />
                        </button>
                      </div>

                      {this.props.description && (
                        <div className="text-medgray text-sm mt-4">
                          {this.props.description}
                        </div>
                      )}

                      {this.props.content && (
                        <div className="bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-b-lg flex flex-row gap-3 justify-center">
                          {this.props.content.map((cmp, i) => {
                            return <div className={`${clsx([{'w-full': this.props.actions && !cmp.getCloseEvent}])}`}
                              key={`content-my-modal-${i}`}
                              onClick={() => {
                                if (cmp.getCloseEvent) {
                                  this.close(cmp.getCloseEvent && cmp.getCloseEvent())
                                }
                                return
                              }}
                            >
                              {cmp.element}
                            </div>
                          })}
                        </div>
                      )}
                      {this.props.actions && (this.props.actions.length > 0) && (
                        <div className="bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-row gap-3 justify-end rounded-b-lg">
                          {this.props.actions.map((cmp, i) => {
                            return <Button
                              key={`action-my-modal-${i}`}
                              type={cmp.color === 'primary'
                                ? 'secondary' : cmp.color === 'secondary'
                                  ? 'destructive' : undefined}
                              onClick={() => this.close(cmp.getActionEvent && cmp.getActionEvent())}
                              text={cmp.text}
                            />
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </HeadlessDialog>
          </Transition.Root>
        </I18nextProvider>
      </ApolloProvider>
    </MuiThemeProvider>
  }
}

export function openDialog(data: MyModalPropsIn, onClose?: (data: any) => void) {
  const snackbars = document.getElementById('snackbars')
  const div = document.createElement('div')
  const root = createRoot(div)
  let removed = false
  const removeFromDOM = (data) => {
    onClose && onClose(data)
    if (removed) return
    removed = true
    setTimeout(() => { snackbars && snackbars.contains(div) && snackbars.removeChild(div) }, 5000)
  }
  snackbars?.appendChild(div)
  setTimeout(() => {
    root.render(
      <MyModal {...data} onClose={removeFromDOM} />,
    )
  }, 200)
}

export function showModal(data: MyModalPropsIn) {
  const { title, content, actions } = data
  const snackbars = document.getElementById('snackbars')
  const div = document.createElement('div')
  snackbars?.appendChild(div)
  const wrapper = <MyModal
    id={data.id}
    title={title}
    description={data.description}
    content={content}
    actions={actions}
    hideElements={data.hideElements || []}
    modalClassname={data.modalClassname || ''}
    onClose={() => {
      data.onClose && data.onClose(data)
      setTimeout(() => { snackbars && snackbars.contains(div) && snackbars.removeChild(div) }, 3000)
    }}
  />

  const root = createRoot(div)
  root.render(wrapper)

  return {
    close: () => {
      root.unmount()
      data.onClose && data.onClose(data)
    },
  }
}


type MyAlertPropsIn = {
  title: string,
  description: string,
}

const MyAlert = (props: MyAlertPropsIn&{onClose: () => void}) => {
  const [open, setOpen] = React.useState(true)
  const onClose = () => {
    setOpen(false)
    props.onClose()
  }
  return <I18nextProvider i18n={i18n}>
    <Transition.Root show={open} as={React.Fragment}>
      <HeadlessDialog
        as="div"
        static
        open={open}
        onClose={onClose}
        className="fixed z-modal inset-0 overflow-y-auto"
      >
        <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg p-8 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:min-w-lg sm:max-w-2/3">
              <h3 className="leading-9 text-2xl font-bold text-deepgray mt-4 px-4">
                {props.title}
              </h3>
              <div className="text-medgray text-sm mt-4">
                {props.description.split('\\n').map((v, i) => (
                  <span key={`myAlert-${i}`}>{v}<br /></span>
                ))}
              </div>
              <div className="w-full pt-4 flex flex-row justify-end items-end">
                <Button
                  className="uppercase"
                  text={i18n.t('ok')}
                  type="secondary"
                  onClick={onClose}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  </I18nextProvider>
}

export function openAlert(data: MyAlertPropsIn) {
  const snackbars = document.getElementById('snackbars')
  const div = document.createElement('div')
  const root = createRoot(div)

  let removed = false
  const removeFromDOM = () => {
    if (removed) return
    removed = true
    setTimeout(() => { snackbars && snackbars.contains(div) && snackbars.removeChild(div) }, 5000)
  }
  snackbars?.appendChild(div)
  setTimeout(() => {
    root.render(
      <MyAlert {...data} onClose={removeFromDOM} />,
    )
  }, 500)
}

export function openPrompt(settings: {title: string; text?: string; initialValue?: string; placeholder?: string}, onClose: (text: string) => void) {
  const id = Math.random().toString()
  let text = settings.initialValue || ''
  const okDialog = () => {
    document.dispatchEvent(new CustomEvent(`closeDialog-${id}`, {detail: text}))
  }
  const cancelDialog = () => {
    document.dispatchEvent(new CustomEvent(`closeDialog-${id}`, {detail: null}))
  }
  const data = {
    id,
    title: settings.title,
    content: [{
      element: <div>
        {settings.text ?
          <div className="text-left" style={{paddingBottom: '20px', lineHeight: '1.5em'}}>
            {settings.text.split('\n').map((t, i) => <span key={i}>{t}<br/></span>)}
          </div>
        : null}
        <TextField
          placeholder={settings.placeholder || undefined}
          onChange={ (val) => { text = val }}
          onKeyDown={ (val: KeyDownType) => {
            if (val === 'enter') {
              okDialog()
            }
            if (val === 'esc') {
              cancelDialog()
            }
          }}
        />
      </div>,
    }],
    actions: [
      {text: i18n.t('cancel'), getActionEvent: () => null},
      {text: String(i18n.t('ok')).toUpperCase(), getActionEvent: () => text, color: 'primary'},
    ],
  } as MyModalPropsIn

  openDialog(data, onClose)
}

export function openConfirm(settings: {
  title: string, text?: string, component?: React.ReactNode,
  defaultButton?: OkCancel, destructive?: boolean,
  confirmButtonClassname?: string, confirmButtonText?: string,
  alignTextLeft?: boolean,
}, onClose?: (confirm: boolean) => void) {
  return new Promise<boolean>((fRes) => {
    const id = Math.random().toString()
    const defaultButton = settings.defaultButton || OkCancel.ok

    const actions = [
      {
        text: settings.confirmButtonText || 'OK',
        getActionEvent: () => true,
        color: 'primary',
        focused: defaultButton === OkCancel.ok,
        className: settings.confirmButtonClassname,
      },
      {
        text: 'Cancel',
        getActionEvent: () => false,
        focused: defaultButton === OkCancel.cancel,
      },
    ]

    if (settings.destructive) {
      actions.reverse()
    }

    const data = {
      id,
      title: settings.title,
      content: [{
        element: <div>
          {settings.text ?
            <div className={`${settings.alignTextLeft ? 'text-left' : ''} flex flex-col gap-3`}>
              {settings.text.split('\n').map((t, i) => <div key={i}>{t}</div>)}
            </div>
          : null}
          {settings.component || null}
        </div>,
      }],
      actions,
    } as MyModalPropsIn
    setTimeout(() => {
      openDialog(data, (res) => {
        onClose && onClose(res)
        fRes(res)
      })
    }, 500)
  })
}


class Loader extends React.Component {
  render() {
    return <div className="fixed z-modal inset-0 overflow-y-auto bg-blend-overlay bg-black/40">
      <div id="gnome-loading" className="opacity-100">
        <img src="/images/gnome-airplane.png" style={{ height: '100px' }} />
      </div>
    </div>
  }
}
export function openLoader() {
  const snackbars = document.getElementById('snackbars')
  const div = document.createElement('div')
  const root = createRoot(div)
  snackbars?.appendChild(div)

  const loaderWrapper = <Loader />

  root.render(loaderWrapper)

  return {
    close: () => {
      snackbars && snackbars.contains(div) && snackbars.removeChild(div)
    },
  }
}

class ExpiredSubscriptionModal extends React.Component<{onClose: () => void}> {
  constructor(props) {
    super(props)
  }
  render() {
    return <div className="fixed z-modal inset-0 overflow-y-auto bg-blend-overlay bg-black/40">
      <div className="opacity-100 bottom-[50%] left-[50%] absolute translate-y-[50%] -translate-x-[50%]">
        <div className="rounded-lg shadow-md bg-white px-10 py-9">
          <div className="text-deepgray text-2xl font-semibold">Your subscription has expired.</div>
          <p className="mt-4 text-deepgray font-normal text-base">
            You no longer have access to the dashboard because your subscription has already expired.
            Please re-activate your subscription under “Settings” if you wish to continue using Gnowbe’s features.
          </p>
          <div className="mt-4 flex flex-row items-center justify-end">
            <Button type="secondary" text="Bring me to Settings"
              onClick={() => {
                this.props.onClose()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  }
}
export function showExipredSubscription(onClose: () => void) {
  const snackbars = document.getElementById('snackbars')
  const div = document.createElement('div')
  const root = createRoot(div)
  snackbars?.appendChild(div)

  const loaderWrapper = <ExpiredSubscriptionModal onClose={onClose} />

  root.render(loaderWrapper)

  return {
    close: () => {
      snackbars && snackbars.contains(div) && snackbars.removeChild(div)
    },
  }
}
class CanceledSubscriptionModal extends React.Component<{onClose: () => void, modal: () => void}> {
  constructor(props) {
    super(props)
  }
  render() {
    return <div className="fixed z-modal inset-0 overflow-y-auto bg-blend-overlay bg-black/40">
      <div className="opacity-100 bottom-[50%] left-[50%] absolute translate-y-[50%] -translate-x-[50%]">
        <div className="rounded-lg shadow-md bg-white px-10 py-9">
          <div className="text-deepgray text-2xl font-semibold">Your subscription has been canceled.</div>
          <p className="mt-4 text-deepgray font-normal text-base">
            Please re-activate your subscription under “Settings” if you wish to continue using Gnowbe’s features.
          </p>
          <div className="mt-4 flex flex-row items-center gap-1 justify-end">
            <Button type="secondary" text="Bring me to Settings"
              onClick={() => {
                this.props.onClose()
              }}
            />
            <Button text="Close"
              onClick={() => {
                this.props.modal()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  }
}
export function showCanceledSubscription(onClose: () => void, modal: () => void) {
  const snackbars = document.getElementById('snackbars')
  const div = document.createElement('div')
  const root = createRoot(div)
  snackbars?.appendChild(div)

  const loaderWrapper = <CanceledSubscriptionModal onClose={onClose} modal={modal} />

  root.render(loaderWrapper)

  return {
    close: () => {
      snackbars && snackbars.contains(div) && snackbars.removeChild(div)
    },
  }
}