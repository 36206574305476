import React from 'react'
import { UserProvider } from 'components/context'
import Routes from './Routes'

type UsersSettings = {
  onlyVisibleInAnalytics: boolean,
}

type UsersSettingsState = {
  settings: UsersSettings,
}

class RoutesWrapper extends React.Component<any, UsersSettingsState>{

  constructor(props: any) {
    super(props)
    this.state = {
      settings: {
        onlyVisibleInAnalytics: localStorage.getItem('onlyVisibleInAnalytics') !== null ?
          (localStorage.getItem('onlyVisibleInAnalytics')?.toLowerCase() === 'true') :
          false,
      },
    }
  }

  onChangeVisibleInAnalytics = (visible: boolean) => {
    this.setState(prevState => ({
      settings: {
        ...prevState.settings,
        onlyVisibleInAnalytics: visible,
      },
    }))

    // save default to localstorage
    localStorage.setItem('onlyVisibleInAnalytics', visible.toString())
  }

  render() {
    return (
      <UserProvider value={{
        settings: {
          onlyVisibleInAnalytics: this.state.settings.onlyVisibleInAnalytics,
          onChangeVisibleInAnalytics: this.onChangeVisibleInAnalytics,
        },
      }}>
        <Routes />
      </UserProvider>
    )
  }
}

export default RoutesWrapper
