import React from 'react'
import { FileExportStatusRes, GET_EXPORT_LIST } from 'subscriptions/export'
import { client } from 'App'
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import Button from '@material-ui/core/Button'
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import i18n from './i18n'
import { useQuery } from '@apollo/client'

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" gridGap={1} alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
}


const ReportList = (props: {
  exportList: FileExportStatusRes[],
  onClose: () => void,
}) => {

  const removeExportedFile = (exportId: string) => {
    client.writeQuery({
      query: GET_EXPORT_LIST,
      data: {
        ...props.exportList,
        exportList: props.exportList.filter(e => e.exportId !== exportId),
      },
    })
  }

  return (
    <div className="bg-white w-96 rounded-lg shadow-md transition-opacity duration-700">
      <Button onClick={() => props.onClose()} className="cursor-pointer rounded-b-none flex items-center justify-between h-11 w-full bg-listenblue text-white">
        <div><CloudDownloadRoundedIcon /></div>
        <div className="flex-1 ml-2 text-left text-sm lowercase">
          {props.exportList.length}&nbsp;{i18n.t('download_s')}
        </div>
        <div><KeyboardArrowUpRoundedIcon /></div>
      </Button>
      <ul className="even:bg-gray-50">
        {props.exportList.map((l, i) => {
          return <li key={l.exportId} className="h-12 border flex flex-row justify-between items-center gap-3">
            <div className="flex-1 pl-4 text-sm font-semibold truncate">
              {l.filename}
            </div>
            <div className="w-36">
              {l.status !== 'finished'
                && <LinearProgressWithLabel value={l.percentDone} />
                || <a className="text-green-500" download href={l.fileUrl}>
                <span className="underline text-xs mr-1">
                  {i18n.t('ready_for_download')}
                </span><CloudDownloadRoundedIcon />
              </a>}
            </div>
            <div className="pr-4" onClick={() => removeExportedFile(l.exportId)}>
              <CancelRoundedIcon fontSize="small" className="cursor-pointer text-medgray" />
            </div>
          </li>
        })}
      </ul>
    </div>
  )
}
export default function () {
  const [open, setOpen] = React.useState(false)
  const {loading, error, data} = useQuery(GET_EXPORT_LIST, {
    fetchPolicy: 'cache-only',
  })


  if (loading) return <div>{i18n.t('loading')}</div>
  if (error) return <div>{i18n.t('something_went_wrong')}</div>

  const handleOpen = () => {
    if (!data) return
    setOpen(prev => !prev)
  }

  return <>
    {data && (data.exportList.length > 0) && !open
      && <Button onClick={handleOpen} className="transition-opacity duration-700 cursor-pointer flex items-center justify-between rounded-lg h-11 w-48 bg-listenblue text-white">
          <div><CloudDownloadRoundedIcon /></div>
          <div className="flex-1 ml-2 text-left text-sm lowercase">
            {data.exportList.length}&nbsp;{i18n.t('download_s')}
          </div>
          <div><KeyboardArrowDownRoundedIcon /></div>
        </Button>
    }
    {open && data?.exportList.length > 0 && <ReportList
      onClose={handleOpen}
      exportList={data.exportList || []}
    />}
  </>
}
