import * as React from 'react'
import IconType from './iconType'

const PromoCodesIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 576 512"
      >
      <path d="M551.1 312.1c-31.13-26.36-69.26-16.12-88.39-1.75l-60.38 45.47h-3.375C399.7 317.9 369.3 288 330.7 288H186.7C158.3 288 130.3 297.4 108.1 314.4l-28.38 21.74H24C10.8 336.1 0 346.9 0 360.1c0 13.19 10.8 23.99 24 23.99l72-.0596l41.26-31.42c14-10.74 31.5-16.49 49.51-16.49h144c27.88 0 29 40.23-1.125 40.23H269.8c-7.5 0-13.75 6.123-13.75 13.74c0 7.619 6.252 13.87 13.75 13.87h134.6c9.711 0 19.16-3.158 26.92-8.996l61.26-46.1c8.252-6.121 20.5-6.621 28.38 0c10.12 8.496 9.375 23.11-.877 30.73l-100.6 75.46c-7.75 5.746-17.13 8.994-26.88 8.994H24C10.8 464 0 474.8 0 488C0 501.2 10.8 512 24 512h368.8c20 0 39.38-6.496 55.26-18.49l100.8-75.83c16.63-12.49 26.5-31.48 27.13-51.97C576.7 345.2 567.8 325.6 551.1 312.1zM275.2 250.5c7 7.375 18.5 7.375 25.5 0l108.1-114.2c31.5-33.13 29.72-88.1-5.65-118.7c-30.88-26.75-76.75-21.9-104.9 7.724L287.1 36.91L276.8 25.28C248.7-4.344 202.7-9.195 171.1 17.56C136.7 48.18 134.7 103.2 166.4 136.3L275.2 250.5z"
      />
    </svg>
  )
}

export default PromoCodesIcon
