import { gql } from '@apollo/client'
import { OrganizationProduct } from 'models/organization'

export const REMOVE_ORGANIZATION_INVITED_USER = gql`
  mutation RemoveOrganizationInvitedUser(
    $removeRoles: Boolean,
    $email: String!,
    $organizationId: String!
  ) {
    res: removeOrganizationInvitedUser(
      removeRoles: $removeRoles,
      email: $email,
      organizationId: $organizationId,
    ) {
      key
      addRemove
      user {
        ... on UserInvited {
          email
        }
      }
    }
  }
`

export type ToggleUserDisabledInOrgVars = {
  removeAsCreator: boolean,
  userId: string,
  organizationId: string,
  disabled: boolean,
}
export const TOGGLE_USER_DISABLED_IN_ORG = gql`
  mutation ToggleUserDisabledInOrg(
    $removeAsCreator: Boolean!,
    $disabled: Boolean!,
    $userId: String!,
    $organizationId: String!,
  ) {
    res: toggleUserDisabledInOrg(
      removeAsCreator: $removeAsCreator,
      disabled: $disabled,
      userId: $userId,
      organizationId: $organizationId,
    )
  }
`

export type ToggleMultipleUsersDisabledInOrgVars = {
  removeAsCreator: boolean,
  userIds: string[],
  organizationId: string,
  disabled: boolean,
}
export const TOGGLE_MULTIPLE_USERS_DISABLED_IN_ORG = gql`
  mutation ToggleMultipleUsersDisabledInOrg(
    $removeAsCreator: Boolean!,
    $disabled: Boolean!,
    $userIds: [String!]!,
    $organizationId: String!,
  ) {
    res: toggleMultipleUsersDisabledInOrg(
      removeAsCreator: $removeAsCreator,
      disabled: $disabled,
      userIds: $userIds,
      organizationId: $organizationId,
    )
  }
`

export type CreateFreeTierOrganizationVars = {
  organizationName?: string,
  userId: string,
}
export const CREATE_FREE_TIER_ORGANIZATION = gql`
  mutation CreateFreeTierOrganization(
    $organizationName: String
    $userId: String!
  ) {
    res: createFreeTierOrganization(
      organizationName: $organizationName,
      userId: $userId,
    )
  }
`

export type MigrateOrganizationsVars = {
  orgIds: string[]|null,
  from: OrganizationProduct,
  to: OrganizationProduct,
}
export const MIGRATE_ORGANIZATIONS = gql`
  mutation MigrateOrganizations(
    $orgIds: [String!],
    $from: OrganizationProduct!,
    $to: OrganizationProduct!
  ) {
    res: migrateOrganizations(
      orgIds: $orgIds,
      from: $from,
      to: $to
    )
  }
`
export const SET_ORGANIZATION_LANDING_PAGE_SETTINGS = gql`
  mutation UpsertOrganizationSettings($organizationId: String!, $landingPageEnabled: Boolean!, $customSubdomain: String!, $settings: OrganizationSettingsInput!) {
    res: upsertOrganizationSettings(organizationId: $organizationId, landingPageEnabled: $landingPageEnabled, customSubdomain: $customSubdomain, settings: $settings) {
      customSubdomain
      organizationId
      landingPageEnabled
      settings {
        logoImage
        bannerImage
        primaryColor
        secondaryColor
        backgroundColor
        description
        isPublic
        template
      }
    }
  }
`

