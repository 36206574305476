import * as React from 'react'
import IconType from './iconType'

const LibraryIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 512 512"
      >
      <path d="M459.9 192c-.625 0-1.375 0-2 .125C408 194.9 317.4 205.1 256 239.6c-61.38-34.5-152-44.75-201.9-47.5C53.5 192 52.75 192 52.12 192C31.75 192 0 206.4 0 242.2v177.1C0 447 21.25 469 48.38 470.4c34.38 1.875 120.5 9.125 177 37.5C230.8 510.6 236.5 512 242.4 512h27.12c5.75 0 11.75-1.375 17.12-4.125c56.5-28.38 142.6-35.63 177-37.5C490.8 469 512 447 512 420.2V242.2C512 206.4 480.3 192 459.9 192zM232 458.4c-60.62-25.75-138.1-33.62-181.1-35.88C49.12 422.4 48 421.2 48 420.2L47.5 241.9C48.25 241 50.88 240.1 51.38 240C87.25 242 176.5 250.1 232 281.2V458.4zM464 420.2c0 1-1.125 2.125-2.875 2.25c-42.88 2.25-120.4 10.12-181.1 36V281.2C335.4 250.2 424.1 242.1 459.9 240C461.4 240.1 463.8 241.1 464 242.2V420.2zM256 192c53 0 96-43 96-96s-43-96-96-96S160 43 160 96S203 192 256 192zM256 48c26.5 0 48 21.5 48 48S282.5 144 256 144S208 122.5 208 96S229.5 48 256 48z"
      />
    </svg>
  )
}

export default LibraryIcon
