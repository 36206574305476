import i18n from 'components/i18n';
import HeadlessSwitch from 'components/utils/HeadlessSwitch';
import React, { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import clsx from 'clsx'
import MyTextField from 'components/TextField'
import { CourseBasic, CourseCounts, CourseMeta, CourseTags, FutureDistribution } from 'models/course';

type  DistributeToModalProps = {
  course: CourseBasic | null,
  startDateDistribution: {
    enabled: boolean,
    value: number,
  },
  setStartDateDistribution: React.Dispatch<React.SetStateAction<{
    enabled: boolean;
    value: number;
}>>,
  availableUntil: {
    enabled: boolean,
    value: number,
  },
  setAvailableUntil: React.Dispatch<React.SetStateAction<{
    enabled: boolean;
    value: number;
}>>,
  availableFor: {
    enabled: boolean,
    value: number,
  },
  setAvailableFor: React.Dispatch<React.SetStateAction<{
    enabled: boolean;
    value: number;
}>>,
  dueDate: {
  enabled: boolean,
  value: number,
  },
  setDueDate: React.Dispatch<React.SetStateAction<{
  enabled: boolean;
  value: number;
}>>,
  manualReviewsSkipEndorsment: boolean,
  setManualReviewsSkipEndorsment: React.Dispatch<React.SetStateAction<boolean>>,
  showHintsAfterAssessmentsCompleted: boolean,
  setShowHintsAfterAssessmentsCompleted: React.Dispatch<React.SetStateAction<boolean>>,
  hideAssessmentsAfterCompleted: boolean,
  setHideAssessmentsAfterCompleted: React.Dispatch<React.SetStateAction<boolean>>,
  futureDistributionData?: {getFutureDistributedCoursesByCompany: (CourseBasic & CourseTags & CourseMeta & CourseCounts & FutureDistribution)[]},
}


const DistributeToModal = ({
  course,
  startDateDistribution,
  setStartDateDistribution,
  availableUntil,
  setAvailableUntil,
  availableFor,
  setAvailableFor,
  dueDate,
  setDueDate,
  manualReviewsSkipEndorsment,
  setManualReviewsSkipEndorsment,
  showHintsAfterAssessmentsCompleted,
  setShowHintsAfterAssessmentsCompleted,
  hideAssessmentsAfterCompleted,
  setHideAssessmentsAfterCompleted,
  futureDistributionData }: DistributeToModalProps) => {

  useEffect(() => {
    if (futureDistributionData?.getFutureDistributedCoursesByCompany.some(c => c.id === course?.id)) {
      setStartDateDistribution({ enabled: true, value: futureDistributionData?.getFutureDistributedCoursesByCompany.find(c => c.id === course?.id)?.futureDistribution || 0 })
    }
  }, [])

  useEffect(() => {
    if (startDateDistribution.enabled) {
      setAvailableUntil({ enabled: false, value: 0 })
      setAvailableFor({ enabled: false, value: 0 })
      setDueDate({ enabled: false, value: 0 })
    }
  }, [startDateDistribution])

  const divContainerCls = clsx({
    'flex flex-col items-center justify-center w-72 rounded-md px-6 pt-5 pb-5 space-y-6': true,
  })

  const descDivCls = clsx({
    'text-sm text-left h-16': true,
  })

  const datePickerCls = clsx({
    'border m-0 border-lightgray w-full bg-transparent border-solid p-2 rounded-lg': true,
  })

  return (
    <div>
      <div className="my-2 flex flex-row justify-between space-x-4">
        <div className={`${divContainerCls} ${startDateDistribution.enabled ? 'border-2 border-solid border-lake bg-alabastergray' : 'border-2 border-solid'}`}>
          <HeadlessSwitch
            tooltip={`${i18n.t('start_date_tooltip')}`}
            checked={startDateDistribution.enabled}
            onChange={toggled => setStartDateDistribution({
              enabled: toggled,
              value: startDateDistribution.value,
            })}
            label={`${i18n.t('start_date')}`}
            labelPlacementStart={true}
          />
          <div className="w-full" onClick={() => {
            if (!startDateDistribution.enabled) {
              setStartDateDistribution({
                enabled: true,
                value: startDateDistribution.value,
              })
            }
          }}>
            <DatePicker
              wrapperClassName="w-full"
              className={`${clsx([{'pointer-events-none bg-gray-50': !startDateDistribution.enabled}])} ${datePickerCls}`}
              minDate={new Date()}
              todayButton="Today"
              placeholderText="Never"
              selected={new Date(startDateDistribution.value) || new Date()}
              value={new Date(startDateDistribution.value) || new Date()}
              onChange={(e) => {
                setStartDateDistribution({
                  enabled: true,
                  value: e?.getTime() || new Date().getTime(),
                })
              }}
              showTimeSelect
              dateFormat="MM/d/yyyy h:mm aa"
              timeFormat="HH:mm"
              popperClassName="fixed"
              disablePast
              popperModifiers={{
                offset: {
                  enabled: true,
                },
              }}
            />
          </div>
          <div className={descDivCls}>{i18n.t('start_date_tooltip')}</div>
        </div>

        <div className={`${divContainerCls} ${availableUntil.enabled ? 'border-2 border-solid border-lake bg-alabastergray' : 'border-2 border-solid'}`}>
          <HeadlessSwitch
            tooltip={`${i18n.t('available_until_tooltip')}`}
            checked={availableUntil.enabled}
            onChange={toggled => {
              setAvailableUntil({ enabled: toggled, value: startDateDistribution.value || availableUntil.value })
              setAvailableFor({ enabled: false, value: 0 })
              setDueDate({ enabled: false, value: 0 })
            }}
            label={`${i18n.t('available_until')}`}
            labelPlacementStart={true}
          />
          <div className="w-full" onClick={() => {
            if (!availableUntil.enabled) {
              setAvailableUntil({ enabled: true, value: startDateDistribution.value || availableUntil.value })
              setAvailableFor({ enabled: false, value: 0 })
              setDueDate({ enabled: false, value: 0 })
            }
          }}>
            <DatePicker
              wrapperClassName="w-full"
              className={`${clsx([{'pointer-events-none bg-gray-50': !availableUntil.enabled}])} ${datePickerCls}`}
              minDate={startDateDistribution.enabled ? new Date(startDateDistribution.value) : new Date()}
              todayButton="Today"
              placeholderText="Never"
              selected={(availableUntil.enabled && availableUntil.value !== 0) ? new Date(availableUntil.value) : new Date()}
              value={(availableUntil.enabled && availableUntil.value !== 0) && new Date(availableUntil.value)}
              onChange={(e) => {
                setAvailableUntil({
                  enabled: true,
                  value: e?.getTime() || 0,
                })
              }}
              showTimeSelect
              dateFormat="MM/d/yyyy h:mm aa"
              timeFormat="HH:mm"
              popperClassName="fixed"
              disablePast
              popperModifiers={{
                offset: {
                  enabled: true,
                },
              }}
            />
          </div>
          <div className={descDivCls}>{i18n.t('available_until_tooltip')}</div>
        </div>

        <div className={`${divContainerCls} ${availableFor.enabled ? 'border-2 border-solid border-lake bg-alabastergray' : 'border-2 border-solid'}`}>
          <HeadlessSwitch
            tooltip={`${i18n.t('available_for_tooltip')}`}
            checked={availableFor.enabled}
            onChange={toggled => {
              setAvailableFor({ enabled: toggled, value: 0 })
              setAvailableUntil({ enabled: false, value: 0 })
              setDueDate({ enabled: false, value: 0 })
            }}
            label={`${i18n.t('available_for')}`}
            labelPlacementStart={true}
          />
          <div className="w-full" onClick={() => {
            if (!availableFor.enabled) {
              setAvailableFor({ enabled: true, value: availableFor.value })
              setAvailableUntil({ enabled: false, value: 0 })
              setDueDate({ enabled: false, value: 0 })
            }
          }}>
            <MyTextField type="number"
              value={String(availableFor.value)}
              disabled={!availableFor.enabled}
              rightEndorsement={<span className="lowercase mr-6">{i18n.t('day_plural')}</span>}
              min={0}
              onChange={value => setAvailableFor({
                enabled: true,
                value: Number(value),
              })}
              inputClassName="border-lightgray"/>
          </div>
          <div className={descDivCls}><p>{i18n.t('available_for_tooltip')}</p></div>
        </div>

        <div className={`${divContainerCls} ${dueDate.enabled ? 'border-2 border-solid border-lake bg-alabastergray' : 'border-2 border-solid'}`}>
          <HeadlessSwitch
            tooltip={`${i18n.t('due_date_tooltip')}`}
            checked={dueDate.enabled}
            onChange={toggled => {
              setDueDate({ enabled: toggled, value: startDateDistribution.value || dueDate.value })
              setAvailableFor({ enabled: false, value: 0 })
              setAvailableUntil({ enabled: false, value: 0 })
            }}
            label={`${i18n.t('due_date')}`}
            labelPlacementStart={true}
          />
          <div className="w-full" onClick={() => {
            if (!dueDate.enabled) {
              setDueDate({ enabled: true, value: startDateDistribution.value || dueDate.value })
              setAvailableFor({ enabled: false, value: 0 })
              setAvailableUntil({ enabled: false, value: 0 })
            }
          }}>
            <DatePicker
              wrapperClassName="w-full"
              className={`${clsx([{'pointer-events-none bg-gray-50': !dueDate.enabled}])} ${datePickerCls}`}
              minDate={startDateDistribution.enabled ? new Date(startDateDistribution.value) : new Date()}
              todayButton="Today"
              placeholderText="Never"
              selected={(dueDate.enabled && dueDate.value !== 0) ? new Date(dueDate.value) : new Date()}
              value={(dueDate.enabled && dueDate.value !== 0) && new Date(dueDate.value)}
              onChange={(e) => {
                setDueDate({
                  enabled: true,
                  value: e?.getTime() || 0,
                })
              }}
              showTimeSelect
              dateFormat="MM/d/yyyy h:mm aa"
              timeFormat="HH:mm"
              popperClassName="fixed"
              disablePast
              popperModifiers={{
                offset: {
                  enabled: true,
                },
              }}
            />
          </div>
          <div className={descDivCls}>{i18n.t('due_date_tooltip')}</div>
        </div>

      </div>
      {course?.manualReviews ? <div className="flex flex-col justify-start items-start w-full">
        <HeadlessSwitch
          tooltip={`${i18n.t('skip_manual_endorsement_tooltip')}`}
          checked={manualReviewsSkipEndorsment}
          onChange={toggled => setManualReviewsSkipEndorsment(toggled)}
          label={`${i18n.t('skip_manual_endorsement')}`}
        />
        </div>
      : null}
      {course?.hasAssessments && <div className="w-full flex flex-col justify-start items-start">
        <HeadlessSwitch
          checked={showHintsAfterAssessmentsCompleted}
          onChange={toggled => setShowHintsAfterAssessmentsCompleted(toggled)}
          label={`${i18n.t('show_hints_after_assessments_completed')}`}
        />
        <HeadlessSwitch
          checked={hideAssessmentsAfterCompleted}
          onChange={toggled => setHideAssessmentsAfterCompleted(toggled)}
          label={`${i18n.t('hide_assessments_after_completed')}`}
        />
      </div>}
    </div>
   );
}

export default DistributeToModal;