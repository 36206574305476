import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import XIcon from '@heroicons/react/24/outline/XMarkIcon'
import { useQuery } from '@apollo/client'
import { updateMetaDeeply } from 'lib/user_metadata'
import { track } from 'segment'
import { Button } from 'components/elements/Button'
import { GET_USER_METADATA } from 'queries/user'
import { GET_BANNER, GET_BANNER_RES } from 'queries/banners'
import { userRoles } from 'lib/utils'

const ShowBanner = ({ uid }) => {

  const { data: metaData } = useQuery(GET_USER_METADATA, {
    variables: { userId: uid },
  })

  const { data: bannerData } = useQuery<GET_BANNER_RES>(GET_BANNER)

  const [selectedValue, setSelectedValue] = useState<number|null>(null)

  const banners = (bannerData?.banners || []).filter(b => Number(new Date()) < b.expirationTime).filter((b) => {
    const dismissedBanner = metaData?.user?.metadata?.general?.dismissedBanners?.[b.bannerId]
    return b.showToPlatform.includes('dashboard') &&
      (!dismissedBanner // filter banners that are not dismissed
      || new Date() > dismissedBanner) // or if dismissed, the dismissed date is bigger than right now
  }) || []

  const banner = (banners.length > 0) ? banners[0] : null

  useEffect(
    () => {
      if (!banner) return

      track({
        event: 'Banner Shown',
        variables: {
          banner_id: banner.bannerId,
          user_id: uid,
          platform: 'dashboard',
        },
      })
    },
    [],
  )

  if (!metaData || !banner) {
    return null
  }

  const cta = banner.ctaButton
  const isRange = ['range10', 'range5'].includes(banner.type)

  const bannerCn = classNames({
    'w-full px-8 py-4 flex items-center justify-center text-deepgray gap-6 mb-4 -mt-4': true,
  })


  const handleCta = () => {
    if (!cta?.url) return

    track({
      event: 'Banner Clicked',
      variables: {
        banner_id: banner.bannerId,
        user_id: uid,
        platform: 'dashboard',
      },
    })

    hideBanner()

    window.open(cta.url, '_blank')
  }

  const handleRangeCta = () => {
    track({
      event: 'Banner Answered',
      variables: {
        banner_id: banner.bannerId,
        user_id: uid,
        answer: selectedValue,
        platform: 'dashboard',
      },
    })

    hideBanner()
  }

  const handleDismiss = () => {
    track({
      event: 'Banner Dismissed',
      variables: {
        banner_id: banner.bannerId,
        user_id: uid,
        platform: 'dashboard',
      },
    })

    const dismissUntil = (Number(new Date()) + 1000 * 60 * 60 * 24 * (banner.dismissForDays || 4880)).toString() // ~13.37 years
    updateMetaDeeply(`/dismissedBanners/${banner.bannerId}`, dismissUntil, 'dismissedBanners')
  }

  const hideBanner = () => {
    const dismissUntil = (Number(new Date()) + 1000 * 60 * 60 * 24 * 4880).toString() // ~13.37 years
    updateMetaDeeply(`/dismissedBanners/${banner.bannerId}`, dismissUntil, 'dismissedBanners')
  }

  const rangeLen = isRange && Number(banner.type.slice(5)) + 1 || 0

  return (
    <div className={bannerCn} style={{ backgroundColor: banner.bannerColor }}>
      {/* <div className="flex flex-row px-4"> */}
        <div className="flex flex-col gap-1">
          <div>{banner.bannerText}</div>

          {isRange && (
            <div className="flex items-center justify-center mb-4"> {/* border border-deepgray divide-x divide-deepgray rounded */}
              <div className="relative flex gap-1">
                {Array(rangeLen).fill(undefined).map((i, a) => {
                  if (banner.type === 'range5' && a === 0) return
                  return (
                    // ${a === 0 ? 'rounded-l' : undefined} ${a === 10 ? 'rounded-r' : undefined}
                    <button key={a} className={`py-2.5 w-10 text-center border border-solid border-deepgray rounded ${(selectedValue === a && banner.bannerColor === '#FFFFFF') ? 'bg-gray-300' : selectedValue === a ? 'bg-white' : undefined}`} onClick={() => setSelectedValue(a)}>
                      {a}
                    </button>
                  )
                })}

                <div className="absolute -bottom-5 left-0 right-0 flex justify-between text-sm">
                  <span>{banner.minRangeCopy || 'Least likely'}</span>
                  <span>{banner.maxRangeCopy || 'Most likely'}</span>
                </div>
              </div>

              <Button
                disabled={selectedValue === null}
                className="ml-4 flex-shrink-0"
                text={cta?.label || 'Submit'}
                onClick={handleRangeCta}
              />
            </div>
          )}
        </div>
      {/* </div> */}

      {cta && !isRange && (
        <Button
          text={cta.label}
          className="flex-shrink-0"
          onClick={handleCta}
        />
      )}

      <button onClick={handleDismiss}>
        <XIcon className="fixed right-8 top-4 w-4 h-4" />
      </button>
    </div>
  )
}

export default ShowBanner
