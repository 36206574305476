import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BannersTabs from './BannersTabs'

const BannersView = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(
    () => {
      if (!location.hash) {
        navigate('#create')
      }
    },
    [location.hash],
  )

  return (
    <>
      <header>
        <h2 className="text-deepgray text-3xl font-medium mb-2">Banner creation system (Internal)</h2>
        <h3 className="text-medgray font-normal text-sm mb-9">
          This is the banner creation system meant for <span className="font-bold">
            internal staff with supereditor access only</span>.
        </h3>
      </header>
      <BannersTabs />
    </>
  )
}

export default BannersView
