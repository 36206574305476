import { gql } from "@apollo/client"
import { getUserBasicFragment, getUserBasicFragmentName, UserBasic } from "./user"

export type UserRemoveConfirmation = {
  id: string,
  reason: 'removed_from_organization'|'organization_deleted',
  reasonRelationKey: string,
  userId: string,
  user: UserBasic|null,
  handled: boolean,
  handledBy: string,
  handledType: 'discarded'|'processed'|'',
  createdAt: number,
  updatedAt: number,
}

export const getUserRemoveConfirmationFragmentName = () => {
  return 'UserRemoveConfirmationFields'
}

export const getUserRemoveConfirmationFragment = () => {
  return `
    ${getUserBasicFragment()}
    fragment ${getUserRemoveConfirmationFragmentName()} on UserRemoveConfirmation {
      id,
      reason,
      reasonRelationKey
      userId,
      user {
        ...${getUserBasicFragmentName()}
      },
      handled,
      handledBy,
      createdAt,
      updatedAt
    }
  `
}


export const GET_USER_REMOVE_CONFIRMATIONS = gql`
  ${getUserRemoveConfirmationFragment()}
  query GetUserRemoveConfirmations($reason: String!, $handled: Boolean!, $searchText: String!, $skip: Float!, $limit: Float!) {
    confirmations: getUserRemoveConfirmations(reason: $reason, handled: $handled, searchText: $searchText, skip: $skip, limit: $limit) {
      ...${getUserRemoveConfirmationFragmentName()}
    }
  }
`
export type GetUserRemoveConfirmationVars = {
  reason: string,
  handled: boolean,
  searchText: string,
  skip: number,
  limit: number,
}
export type GetUserRemoveConfirmationRes = {
  confirmations: UserRemoveConfirmation[],
}



export const HANDLE_USER_REMOVE_CONFIRMATION = gql`
  mutation handleUserRemoveConfirmation($confirmationId: String!, $action: String!) {
    confirmation: handleUserRemoveConfirmation(confirmationId: $confirmationId, action: $action) {
      id
    }
  }
`