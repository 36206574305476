import { gql } from '@apollo/client'
import { EngagementSummary, getEngagementSummaryFragment, getEngagementSummaryFragmentName } from 'models/engagement_summary'
import { OrganizaitonLicenseLimits, OrganizationAddonsLimit, OrganizationBasic, OrganizationContact, OrganizationExternalUserAttributes, OrganizationResponseType, OrganizationSettings, OrganizationSsoSettings, OrganizationTags, getOrganizationAddonsFragment, getOrganizationAddonsFragmentName, getOrganizationFragment, getOrganizationFragmentName, getOrganizationInvitedUsersFragment, getOrganizationInvitedUsersFragmentName } from 'models/organization'


export const GET_ORGID_FROM_ENSURE = gql`
  query GetOrgIdFromEnsure {
    organizationId @client
  }
`
export const GET_MANAGERAGENT_ORGANIZATIONS = gql`
  ${getOrganizationFragment(['with_settings'])}
  query GetManagerAgentOrganizations($userId: String!) {
    organizations: getManagerAgentOrganizations(userId: $userId) {
      ...${getOrganizationFragmentName(['with_settings'])}
    }
  }
`

export type GetOrganizationVars = { organizationId: string }
export type GetOrganizationRes = { organization: OrganizationBasic & OrganizationContact & OrganizationTags & OrganizationSettings & OrganizationExternalUserAttributes }
export const GET_ORGANIZATION = (types: OrganizationResponseType[]) => gql`
  ${getOrganizationFragment(types)}
  query GetOrganization($organizationId: String!) {
    organization: getOrganization(organizationId: $organizationId) {
      ...${getOrganizationFragmentName(types)}
    }
  }
`
export const UPDATE_ORGANIZATION = (types: OrganizationResponseType[]) => gql`
  ${getOrganizationFragment(types)}
  mutation UpdateOrganization($organizationId: String!, $organizationUpdate: OrganizationUpdate!) {
    organization: updateOrganization(organizationId: $organizationId, organizationUpdate: $organizationUpdate) {
      ...${getOrganizationFragmentName(types)}
    }
  }
`

export type DeleteOrganizationVars = { organizationId: string }
export type DeleteOrganizationRes = { organization: OrganizationBasic }
export const DELETE_ORGANIZATION = gql`
  ${getOrganizationFragment([])}
  mutation DeleteOrganization($organizationId: String!) {
    organization: deleteOrganization(organizationId: $organizationId) {
      ...${getOrganizationFragmentName([])}
    }
  }
`
export const GET_ORGANIZATION_INVITED_USERS = () => gql`
  ${getOrganizationInvitedUsersFragment()}
  query GetOrganizationInvitedUsers(
    $organizationId: String!,
    $searchText: String!,
    $skip: Int!,
    $limit: Int!
  ) {
    res: getOrganizationInvitedUsers(
      organizationId: $organizationId,
      searchText: $searchText,
      skip: $skip,
      limit: $limit
    ) {
      usersCount
      users {
        ...${getOrganizationInvitedUsersFragmentName()}
      }
    }
  }
`

export const EXPORT_ORGANIZATION_COMPANIES = () => gql`
  query ExportOrganizationCompanies(
    $organizationId: String!,
    $onlyVisibleInAnalyticsInMetrics: Boolean!,
    $searchText: String!,
    $courseId: String
  ) {
    exportRes: exportOrganizationCompanies(
      organizationId: $organizationId,
      onlyVisibleInAnalyticsInMetrics: $onlyVisibleInAnalyticsInMetrics,
      searchText: $searchText,
      courseId: $courseId
    ){
      emailTo
      availableAt
      exportId
    }
  }
`
export const GET_ORGANIZATIONS = (types: OrganizationResponseType[]) => gql`
  ${getOrganizationFragment(types)}
  query GetOrganizations($searchText: String!, $skip: Int!, $limit: Int!,
    $limitToPartnerOrganizations: String, $limitToManagerOrganizations: String,
    $limitToUserOrganizations: String,
    $limitToProducts: [OrganizationProduct!],
    $isPilot: Boolean,
    $disabled: Boolean,
    $onlyOverquota: Boolean
  ) {
    organizationsRes: getOrganizations(searchText: $searchText, skip: $skip, limit: $limit,
      limitToPartnerOrganizations: $limitToPartnerOrganizations,
      limitToManagerOrganizations: $limitToManagerOrganizations,
      limitToUserOrganizations: $limitToUserOrganizations,
      limitToProducts: $limitToProducts,
      isPilot: $isPilot,
      disabled: $disabled,
      onlyOverquota: $onlyOverquota
    ) {
      organizations {
        ...${getOrganizationFragmentName(types)}
      }
      organizationsCount
    }
  }
`
export const CREATE_ORGANIZATION = (types: OrganizationResponseType[]) => gql`
  ${getOrganizationFragment(types)}
  mutation CreateOrganization($organizationCreate: OrganizationCreate!) {
    organization: createOrganization(organizationCreate: $organizationCreate) {
      ...${getOrganizationFragmentName(types)}
    }
  }
`

export type GetOrganizationEngagementSummaryVars = {
  onlyVisibleInAnalytics: boolean,
  courseId?: string,
  companyId?: string,
  organizationId: string,
}
export type GetOrganizationEngagementSummaryRes = {
  organizationEngagementSummary: {
    organizationId: string,
    companiesCount: number,
    engagementSummary: EngagementSummary,
  },
}
export const GET_ORGANIZATION_ENGAGEMENT_SUMMARY = gql`
  ${getEngagementSummaryFragment()}
  query GetOrganizationEngagementSummary(
    $onlyVisibleInAnalytics: Boolean!,
    $courseId: String,
    $companyId: String,
    $organizationId: String!
  ) {
    organizationEngagementSummary: getOrganizationEngagementSummary(
      onlyVisibleInAnalytics: $onlyVisibleInAnalytics,
      courseId: $courseId,
      companyId: $companyId,
      organizationId: $organizationId
    ) {
      companiesCount
      companyId
      courseId
      coursesCount
      engagementSummary {
        ...${getEngagementSummaryFragmentName()}
      }
      organizationId
    }
  }
`

export type GetOrganizationSsoSettingsVars = { organizationId: string }
export type GetOrganizationSsoSettingsRes = { organizationSsoSettings: OrganizationSsoSettings }
export const GET_ORGANIZATION_SSO_SETTINGS = gql`
  query GetOrganizationSsoSettings($organizationId: String!) {
    organizationSsoSettings: getOrganizationSSOSettings(organizationId: $organizationId) {
      businessId
      businessIdFriendly
      emailDomains
      organizationId
    }
  }
`

export type GetOrganizationLicenseLimitsVars = { organizationId: string }
export type GetOrganizationLicenseLimitsRes = { organizationLicenseLimits?: OrganizaitonLicenseLimits|null }
export const GET_ORGANIZATION_LICENSE_LIMITS = gql`
  query GetOrganizationLicenseLimits($organizationId: String!) {
    organizationLicenseLimits: getOrganizationLicenseLimits(organizationId: $organizationId) {
      usedGroups
      usedLearners
      usedLicenses
    }
  }
`

export const GET_ORGANIZATION_AND_SSO_SETTINGS = (types: OrganizationResponseType[]) => gql`
  ${getOrganizationFragment(types)}
  query GetOrganizationAndSsoSettings($organizationId: String!) {
    organization: getOrganization(organizationId: $organizationId) {
      ...${getOrganizationFragmentName(types)}
    }
    organizationSsoSettings: getOrganizationSSOSettings(organizationId: $organizationId) {
      businessId
      businessIdFriendly
      emailDomains
      organizationId
    }
  }
`

export type GetOrganizationAddonsLimitRes = {
  translations: OrganizationAddonsLimit,
  gnowbeAi: OrganizationAddonsLimit,
}

export type GetTranslatorLimitPerOrganizationVars = {
  organizationId: string;
}
export const GET_TRANSLATOR_LIMIT_PER_ORGANIZATION = gql`
  ${getOrganizationAddonsFragment()}
  query GetTranslatorLimitPerOrganization ($organizationId: String!) {
    res: getTranslatorLimitPerOrganization (organizationId: $organizationId){
      ...${getOrganizationAddonsFragmentName()}
    }
  }
`

export type GetGnowbeAiLimitPerOrganizationVars = {
  organizationId: string;
}
export const GET_GNOWBE_AI_LIMIT_PER_ORGANIZATION = gql`
  ${getOrganizationAddonsFragment()}
  query GetGnowbeAiLimitPerOrganization ($organizationId: String!) {
    res: getGnowbeAiLimitPerOrganization (organizationId: $organizationId){
      ...${getOrganizationAddonsFragmentName()}
    }
  }
`

export const GET_ORGANIZATION_ADDONS_LIMIT = gql`
  ${getOrganizationAddonsFragment()}
  query GetOrganizationAddonsLimit($organizationId: String!) {
    translations: getTranslatorLimitPerOrganization(organizationId: $organizationId) {
      ...${getOrganizationAddonsFragmentName()}
    }
    gnowbeAi: getGnowbeAiLimitPerOrganization(organizationId: $organizationId) {
      ...${getOrganizationAddonsFragmentName()}
    }
  }
`

export type GetAddonUsagePerOrganizationVars = {
  organizationId: string;
  fromDate: string;
  toDate: string;
}

export type GetAddonUsagePerOrganizationRes = {
  res: {
    addon: string;
    usage: {
      month: string;
      totalUsageCount: number;
    }[];
    total: number;
  }[]
}

export const GET_ADDONS_USAGE_PER_ORGANIZATION = gql`
  query GetAddonsUsagePerOrganization ($organizationId: String!, $fromDate: String, $toDate: String) {
    res: getAddonsUsagePerOrganization (organizationId: $organizationId, fromDate: $fromDate, toDate: $toDate){
        addon,
        usage {
          month
          totalUsageCount
        },
        total
    }
  }
`

export type GetHistoricalEnabledAndDisabledUsersVars = {
  organizationId: string;
  endDate: number;
  startDate: number;
}

type HistoricalUserCount = {
  countDisabled: number;
  countEnabled: number;
  countTotal: number;
  date: number;
}

export type GetHistoricalEnabledAndDisabledUsersRes = {
  res: {
    endDateData: HistoricalUserCount;
    startDateData: HistoricalUserCount;
  }
}

export const GET_HISTORICAL_ENABLED_DISABLED_USER_PER_ORGANIZATION = gql`
  query GetHistoricalEnabledAndDisabledUsers ($organizationId: String!, $endDate: Float!, $startDate: Float!) {
    res: getHistoricalEnabledAndDisabledUsers (organizationId: $organizationId, endDate: $endDate, startDate: $startDate){
      endDateData {
        countDisabled
        countEnabled
        countTotal
        date
      },
      startDateData {
        countDisabled
        countEnabled
        countTotal
        date
      }
    }
  }
`

export type GetOrganizationLandingPageSettings = {
  customSubdomain: string,
  organizationId: string,
  landingPageEnabled: boolean,
  settings: GetOrganizationLandingPage,
}
export type GetOrganizationLandingPage = {
  logoImage?: string,
  bannerImage?: string,
  primaryColor?: string,
  secondaryColor?: string,
  backgroundColor?: string,
  name?: string,
  description?: string,
  isPublic: boolean,
  template?: 'default'|'sky'|'luna'|'bubble',
}
export type GetOrganizationLandingPageSettingsVars = { organizationId: string }
export type GetOrganizationLandingPageSettingsRes = {
  res: {
    customSubdomain: string,
    organizationId: string,
    landingPageEnabled: boolean,
    settings: GetOrganizationLandingPage,
  },
}
export type GetOrganizationLandingPageSettingsContext = {
  customSubdomain: string,
  organizationId: string,
  landingPageEnabled: boolean,
  settings: GetOrganizationLandingPage,
}
export const GET_ORGANIZATION_LANDING_PAGE_SETTINGS = gql`
  query GetOrganizationSettings ($organizationId: String!) {
    res: getOrganizationSettings (organizationId: $organizationId) {
      organizationId
      landingPageEnabled
      customSubdomain
      settings {
        logoImage
        bannerImage
        primaryColor
        secondaryColor
        backgroundColor
        description
        isPublic
        template
      }
    }
  }
`
export const IS_LANDING_PAGE_SUBDOMAIN_TAKEN = gql`
  query IsSubdomainTaken ($organizationId: String!, $customSubdomain: String!) {
    isSubdomainTaken (organizationId: $organizationId, customSubdomain: $customSubdomain) {
        isSubdomainTaken
        organizationId
    }
  }
`
