export function initZendeskWidget(environment: string) {
  try { // Start of gnowbe Zendesk Widget script
    // @ts-ignore
    // tslint:disable-next-line
    window.zE||(function(e,t,s){var n=window.zE=window.zEmbed=function(){n._.push(arguments)}, a=n.s=e.createElement(t),r=e.getElementsByTagName(t)[0];n.set=function(e){ n.set._.push(e)},n._=[],n.set._=[],a.async=true,a.setAttribute("charset","utf-8"), a.src="https://static.zdassets.com/ekr/asset_composer.js?key="+s, n.t=+new Date,a.type="text/javascript",r.parentNode.insertBefore(a,r)})(document,"script","534708fc-0694-4741-8f94-4d1f0b156dcc");

    // @ts-ignore
     // tslint:disable-next-line
    zE(function() {zE.setLocale('en')})

    // Some stupid thing for Zendesk :)
    if (!window['__gCrWeb']) window['__gCrWeb'] = {}
  } catch (err) {
    console.error('Zendesk initialization error', err)
  }
}
