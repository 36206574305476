// import { hot } from 'react-hot-loader/root'
import React from 'react'
import { createRoot } from 'react-dom/client'
import CLIENT_SETTINGS from './lib/client_settings'
import { signinWithApiToken, getGnowbeApiToken, logoutHandler } from './lib/auth'
import { parseUrl } from './lib/utils'
import { ApolloClient, split, ApolloLink, ApolloProvider } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'
import { HttpLink } from '@apollo/client/link/http'
import { getMainDefinition } from '@apollo/client/utilities'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import i18n, { initLocalization, LocalesType } from './components/i18n'
import { showModal, showSnackbar } from 'components/snackbar'
import { I18nextProvider } from 'react-i18next'
import { GET_AUTHUSER } from 'models/user'
import CheckSessionTimeout from 'components/CheckSessionTimeout'
import { GET_EXPORT_LIST } from 'subscriptions/export'
import { initRollbar } from 'rollbar'
import { identifyOnSegment, initSegment } from 'segment'
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { GET_FILE_IMPORTS_LIST } from 'subscriptions/fileImport'
import './stylesheets/main.less'
import { getGnowbeUpdates, GET_GNOWBE_UPDATES } from 'queries/updates'
import { initZendeskWidget } from 'zendesk'
import signIn from 'components/account/SignIn'
import Layout from 'components/Layout'
import { BrowserRouter } from 'react-router-dom'
import { SubscriptionClient } from 'subscriptions-transport-ws'

Chart.register(
  ChartDataLabels, Legend, Title, Tooltip, SubTitle,
  CategoryScale, LinearScale,
  PointElement, LineElement, ArcElement, BarElement,
)

declare var Rollbar

export let client: ApolloClient<NormalizedCacheObject>
export let online: boolean = false
export let offlineSince: number = Number(new Date())

export function parse(url, whole = false) {
  if (whole) {
    url = url.substring(url.indexOf('?'))
  }
  const query: any = {}
  url.substring(1).split('&').map(e => {
    const el = e.split('=')
    query[el[0]] = el[1]
  })
  return query as {[field: string]: any}
}

async function init() {
  const environment = process.env.BUILD || 'development'
  // Rollbar
  initRollbar(environment)

  // Segment
  if (typeof Cypress === 'undefined') {
    initSegment()
  }

  // Zendesk
  initZendeskWidget(environment)

  // Token
  const parsedQuery = parseUrl(window.location.href, true)
  const apiTokenOnInitTmp = await getGnowbeApiToken() // Check local storage
  const apiTokenOnInit = apiTokenOnInitTmp.token
    ? apiTokenOnInitTmp
    : undefined
  {
    const authToken = parsedQuery.token_type && parsedQuery.access_token && parsedQuery.expires_at
      ? {
        token: parsedQuery.access_token, // if new access token lets login user with this one
        tokenType: parsedQuery.token_type,
        expiresAt: parsedQuery.expires_at,
      }
      : (apiTokenOnInit || '')

    if (authToken) {
      await signinWithApiToken(authToken.tokenType, authToken.token, authToken.expiresAt)
    }
  }

  const apiTokenTmp = await getGnowbeApiToken()
  const apiToken = apiTokenTmp.token
    ? apiTokenTmp
    : undefined

  if (apiToken) {
    identifyOnSegment(apiToken.uid, apiToken.email)
  }

  // Apollo init
  const httpLink = new HttpLink({
    uri: CLIENT_SETTINGS.public.gnowbeGraphUrl,
    headers: {
      Authorization: apiToken ? `${apiToken.tokenType} ${apiToken.token}` : undefined,
      'x-gnowbe-source': 'gnowbe-dashboard 2.0.0',
    },
  })

  const wsClient = new SubscriptionClient(CLIENT_SETTINGS.public.gnowbeGraphSUrl, {
    reconnect: true,
    timeout: 30000,
    connectionParams: {
      Authorization: apiToken ? `${apiToken.tokenType} ${apiToken.token}` : '',
      'x-gnowbe-source': 'gnowbe-dashboard 2.0.0',
    },
  })

  const wsLink = new WebSocketLink(wsClient)

  wsClient.onConnected((e) => {
    online = true
  })

  wsClient.onDisconnected((e) => {
    if (!online) return

    online = false
    offlineSince = Number(new Date())
  })

  wsClient.onReconnected((e) => {
    online = true
    if (Number(new Date()) - offlineSince < 15000) return

    const modal = showModal({
      title: 'Welcome Back',
      content: [{
        element: <div>
          <p>
            While you were offline the content of the page might have changed.
          </p>
          <p>Would you like to refresh the content?</p>
          <p className="m-0 p-0 text-coral">
            <strong>Unsaved changes will be lost.</strong>
          </p>
          </div>,
      }],
      actions: [
        {text: 'Close', getActionEvent: () => modal.close()},
        {text: 'Refresh Content', color: 'primary', getActionEvent: () => {
          modal.close()
          window.location.reload()
        }},
      ],
    })
    // wsClient.close()
  })

  const retryLink = new RetryLink()

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  )

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getOrganizationEngagementSummary: {
            merge(existing, incoming) {
              return incoming
            },
          },
          getAllNotificationBanners: {
            merge(existing, incoming) {
              return incoming
            },
          },
          exportList: {
            merge(existing, incoming) {
              return incoming
            },
          },
          fileImportsList: {
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
      BundlePromoCode: { keyFields: ['promoCode'] },
      Bundle: { keyFields: ['id'] },
      BundlePrice: { keyFields: ['priceCurrency', 'recurring'] },
      Library: {
        fields: {
          availableToOrganizations: {
            merge(existing = [], incoming) {
              return [...incoming]
            },
          },
        },
      },
    },
  })
  client = new ApolloClient({
    cache,
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path, extensions, source, originalError }) => {
            const match = message.match(/User not subscribed/)
            if (match && match.length > 0) {
              console.warn(
                `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
              )
            } else {
              if (extensions) {
                switch (extensions.code) {
                  case 'BAD_USER_INPUT':
                    showSnackbar(message, 3000, 'error')
                    return console.warn(`Error - ${message}`, 'danger')
                  case 'FORBIDDEN':
                    showSnackbar('Error - You are not allowed to perform this action.', 3000, 'error')
                    return console.warn('Error - You are not allowed to perform this action.', 'danger')
                  case 'INTERNAL_SERVER_ERROR': {
                    return
                  }
                  case 'UNAUTHENTICATED': {
                    logoutHandler()
                  }
                  case 'BAD_REQUEST': {
                    // showSnackbar(message, 3000, 'error')
                    return console.warn(`Error - ${message}`, 'danger')
                  }
                  case 'NOT_FOUND': {
                    return
                  }
                  // case 'LICENSES_EXCEEDED': {
                  //   openAlert({
                  //     title: 'Update',
                  //     description: message,
                  //   })
                  //   return
                  // }
                  default:
                    return (
                      console.error(
                        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
                        extensions,
                        source,
                        originalError,
                      )
                    )
                }
              }
              console.error(
                `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
                extensions,
                source,
                originalError,
              )
            }
          })
        }
        if (networkError) {
          console.error(`[Network error]: ${networkError}`)
          if (networkError['statusCode'] === 401) {
            logoutHandler()
          }
        }
      }),
      retryLink,
      link,
    ]),
    resolvers: {},
    name: document.location.hostname,
    version: '2.0.0',
  })

  const storedLanguage = localStorage.getItem('currentLanguage')
  const normalizedLanguage = storedLanguage && storedLanguage.replace('-', '_').split('_')[0]

  const language = normalizedLanguage as LocalesType
    || (window.navigator.languages || ['en'])[0].replace('-', '_').split('_')[0] as LocalesType
  await initLocalization(language)

  const query = parse(window.location.pathname)
  const continueAs = query.continueAs && decodeURIComponent(query.continueAs) || ''
  const redirectTo = window.location.href
    .replace(/&continueAs=[^&]+/, '')
    .replace(/\?continueAs=[^&]+/, '?')
    .replace('?&', '?')

  if (continueAs) {
    // If not logged in then just direct to login
    if (!apiToken) {
      const url = `${CLIENT_SETTINGS.public.gnowbeBeUrl}/signup/login?distinctId=${Math.random().toString(36).slice(-9)}&continueAs=${encodeURIComponent(continueAs)}&redirectTo=${encodeURIComponent(redirectTo)}`
      return window.location.replace(url)
    }

    // If logged in with different user ask if switch
    if (apiToken && (continueAs !== apiToken.email)) {
      const switchConfirmation = confirm(`Do you want to switch from ${apiToken.email}?`)
      if (switchConfirmation) {
        logoutHandler()
        const url = `${CLIENT_SETTINGS.public.gnowbeBeUrl}/signup/login?distinctId=${Math.random().toString(36).slice(-9)}&continueAs=${encodeURIComponent(continueAs)}&redirectTo=${encodeURIComponent(redirectTo)}`
        return window.location.replace(url)
      }
    }
  }

  if (apiToken) {
    Rollbar.configure({
      payload: {
        person: {
          id: apiToken.uid,
          email: apiToken.email,
        },
      },
    })
    client.writeQuery({
      query: GET_AUTHUSER,
      data: {
        authUser: {
          __typename: 'AuthUser',
          token: apiToken.token,
          uid: apiToken.uid,
          email: apiToken.email,
        },
      },
    })
    client.writeQuery({
      query: GET_EXPORT_LIST,
      data: {
        exportList: [],
      },
    })
    client.writeQuery({
      query: GET_FILE_IMPORTS_LIST,
      data: {
        fileImportsList: [],
      },
    })
    client.writeQuery({
      query: GET_GNOWBE_UPDATES,
      data: {
        gnowbeUpdates: getGnowbeUpdates(),
      },
    })
    // const orgIdFromEnsure = await client.mutate({
    //   mutation: ENSURE_ORGANIZATION_FOR_USER, variables: {userId: apiToken.uid},
    // })
    // client.writeQuery({
    //   query: GET_ORGID_FROM_ENSURE,
    //   data: {
    //     organizationId: orgIdFromEnsure.data.res,
    //   },
    // })
    return render(client)
  }
  return signIn(redirectTo)
}

init()

const render = (client: ApolloClient<any>) => {
  const container = document.getElementById('react-root')
  const root = createRoot(container!)

  const loadingScreen = document.getElementById('loading-screen')
  if (loadingScreen) {
    loadingScreen.style.display = 'none'
  }

  return (
    root.render(
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Layout />
            <CheckSessionTimeout />
          </BrowserRouter>
        </ApolloProvider>
      </I18nextProvider>,
    )
  )
}
