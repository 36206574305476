import React from 'react'
import { UserAuthenticated } from '../../models/user'
import { withAuthUser } from '../AuthUserWrapper'
import i18n from 'components/i18n'
import { logoutHandler } from 'lib/auth'
import { handleError } from '../utils'
import OrganizationSelect from '../organization/OrganizationSelect'
import clsx from 'clsx'
import { showModal } from 'components/snackbar'
import { Menu } from '@headlessui/react'
import UserCircleIcon from '@heroicons/react/24/outline/UserCircleIcon'
import LogoutIcon from '@heroicons/react/24/outline/ArrowRightOnRectangleIcon'
import OrganizationsIcon from 'components/icons/OrganizationsIcon'

type LoginStatusPropsIn = {
  organizationId: string,
  userAuthenticated?: UserAuthenticated|null,
  openDrawer: boolean,
}

const LoginStatus = (props: LoginStatusPropsIn) => {
  const onSignOut = async() => {
    try {
      showModal({
        id: 'logoutFromEverywhere',
        title: i18n.t('log_out_everywhere_title'),
        content: [{
          element: <div className="p-4">{`${i18n.t('log_out_everywhere_text')}`}</div>,
        }],
        actions: [
          {
            text: i18n.t('log_out_everywhere_button_everywhere'),
            getActionEvent: async () => await logoutHandler(false, true),
          },
          {
            text: i18n.t('log_out_everywhere_button_here'),
            color: 'primary', getActionEvent: async () => await logoutHandler(false),
          },
        ],
      })
    } catch (err: any) {
      handleError(err, undefined, 3000)
      return
    }
  }

  const openDrawerCheck = clsx({hidden: !props.openDrawer})

  return <Menu>
    <Menu.Button className="w-full">
      <div className="h-[46px] px-3 bg-[#EEE8D9] border-l-4 border-solid border-coral cursor-pointer flex items-center justify-between w-full">
        <div className="flex flex-row justify-start items-center">
          {props.userAuthenticated?.profile.imageUrl
            ? <img
              className="h-8 w-8 rounded-full mr-2"
              src={props.userAuthenticated.profile.imageUrl}
              alt={(props.userAuthenticated.profile.fullName || '').slice(0, 1)}
            />
            : <div className="bg-coral text-white mr-2 font-bold h-8 w-8 text-base inline-flex items-center justify-center rounded-full"
            >
              {(props.userAuthenticated?.profile.fullName || '').slice(0, 1)}
            </div>
          }
          <div className={openDrawerCheck}>
            <div className="text-left whitespace-nowrap w-40 overflow-hidden truncate text-base leading-5 font-medium text-deepgray group-hover:text-gray-900 group-focus:underline transition ease-in-out duration-150">
              {props.userAuthenticated?.profile.fullName || ''}
            </div>
            <div className="text-left w-40 text-xs truncate">
              {props.userAuthenticated?.email || ''}
            </div>
          </div>
        </div>
        <div className={openDrawerCheck}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
            style={{width: '10px', marginRight: '8px', marginLeft: '8px', marginTop: '2px'}}
          >
            <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/>
          </svg>
        </div>
      </div>
    </Menu.Button>
    <Menu.Items className="fixed bg-white rounded-xl z-modal shadow-md mx-2 px-2 py-2 max-h-[calc(100vh_-_20%)] overflow-y-auto">
      <Menu.Item>
        {({ active }) => (
          <a
            className="w-full text-medgray text-base flex flex-row items-center flex-nowrap hover:bg-gray-100 px-3 py-1 rounded-xl"
            href="/account"
          >
            <>
              <UserCircleIcon className="text-lightgray mr-3 h-6 w-6" />{i18n.t('account')}
            </>
          </a>
        )}
      </Menu.Item>
      <div className="border-b border-solid border-gray-100 my-1"></div>
      <Menu.Item>
        <div className="w-full text-medgray text-base flex flex-row items-center flex-nowrap px-3 py-1">
          <>
            <OrganizationsIcon className="text-lightgray mr-3 h-6 w-6" />{i18n.t('organization_plural')}
          </>
        </div>
      </Menu.Item>
      {props.userAuthenticated && <OrganizationSelect
        userAuthenticated={props.userAuthenticated}
        selectedOrganizationId={props.organizationId}
      />}
      <div className="border-b border-solid border-gray-100 my-1"></div>
      <Menu.Item>
        <button onClick={onSignOut} className="w-full text-medgray text-base flex flex-row items-center flex-nowrap hover:bg-gray-100 px-3 py-1 rounded-xl cursor-pointer">
          <>
            <LogoutIcon className="text-lightgray mr-3 h-6 w-6" />{i18n.t('signout')}
          </>
        </button>
      </Menu.Item>
    </Menu.Items>
  </Menu>
}

export default withAuthUser(LoginStatus)
