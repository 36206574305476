import React from 'react'
import clsx from 'clsx'
import HelpTooltip from './HelpTooltip'

export type KeyDownType = 'enter' | 'esc'
type PropsIn = {
  value?: string | number,
  label?: string,
  subtitle?: string,
  placeholder?: string,
  disabled?: boolean,
  isClearable?: boolean,
  onChange: (value: string) => void,
  error?: string,
  onKeyDown?: (val: KeyDownType) => void,
  helpTooltip?: string,
  type?: string,
  rightEndorsement?: any,
  boldLabel?: boolean,
  inputClassName?: string,
  min?: number,
  max?: number,
  onFocus?: () => void,
}
export default function (props: PropsIn) {
  const handleTextChange = (e: any) => {
    props.onChange(e.target.value)
  }
  return (
    <div>
      {props.label && (
        <div className={`text-sm w-full text-left text-medgray ${props.boldLabel ? 'font-bold mb-1' : ''}`}>
          {props.label} {props.helpTooltip && <HelpTooltip text={props.helpTooltip} />}
          <div className="font-normal">{props.subtitle}</div>
        </div>
      )}
      <div className="relative">
        <input
          type={props.type}
          disabled={props.disabled}
          placeholder={props.placeholder || 'Type here'}
          onChange={handleTextChange}
          value={props.value}
          onKeyDown={ (e) => {
            const key = e.key || e.keyCode
            if (key === 13 || key === 'Enter') {
              e.stopPropagation()
              e.preventDefault()
              props.onKeyDown && props.onKeyDown('enter')
            }
            if (key === 27 || key === 'Escape') {
              e.stopPropagation()
              e.preventDefault()
              props.onKeyDown && props.onKeyDown('esc')
            }
          }}
          className={`${clsx([{
            'border-coral text-red-900': props.error,
            'pr-14': props.rightEndorsement,
            'bg-gray-50 pointer-events-none': props.disabled,
          }])} block pl-3 py-2 pr-3 w-full rounded-lg border border-solid border-gray-200 focus:outline-none focus:ring-0 focus:shadow-outline text-medgray ${props.inputClassName}`}
          min={props.min}
          max={props.max}
          onFocus={props.onFocus}
        />
        {props.rightEndorsement && (
          <div className={`absolute inset-y-0 ${props.type === 'number' ? 'right-6' : 'right-0'} pr-3 flex items-center`}>
            <span className="text-gray-500 sm:text-sm">
              {props.rightEndorsement}
            </span>
          </div>
        )}
      </div>

      {props.error && <p className="w-full text-left text-sm text-coral mt-2" data-test="error-validation">{props.error || ''}</p>}
    </div>
  )
}
