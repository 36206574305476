import { gql } from "@apollo/client"

export type CourseRemoveConfirmation = {
  id: string,
  reason: 'user_deleted'|'organization_deleted',
  reasonRelationKey: string,
  courseId: string,
  courseTitle: string,
  handled: boolean,
  handledBy: string,
  handledType: 'discarded'|'processed'|'',
  createdAt: number,
  updatedAt: number,
}

export const getCourseRemoveConfirmationFragmentName = () => {
  return 'CourseRemoveConfirmationFields'
}

export const getCourseRemoveConfirmationFragment = () => {
  return `
    fragment ${getCourseRemoveConfirmationFragmentName()} on CourseRemoveConfirmation {
      id,
      reason,
      reasonRelationKey
      courseId,
      courseTitle,
      handled,
      handledBy,
      createdAt,
      updatedAt
    }
  `
}


export const GET_COURSE_REMOVE_CONFIRMATIONS = gql`
  ${getCourseRemoveConfirmationFragment()}
  query GetCourseRemoveConfirmations($reason: String!, $handled: Boolean!, $searchText: String!, $skip: Float!, $limit: Float!) {
    confirmations: getCourseRemoveConfirmations(reason: $reason, handled: $handled, searchText: $searchText, skip: $skip, limit: $limit) {
      ...${getCourseRemoveConfirmationFragmentName()}
    }
  }
`
export type GetCourseRemoveConfirmationVars = {
  reason: string,
  handled: boolean,
  searchText: string,
  skip: number,
  limit: number,
}
export type GetCourseRemoveConfirmationRes = {
  confirmations: CourseRemoveConfirmation[],
}



export const HANDLE_COURSE_REMOVE_CONFIRMATION = gql`
  mutation handleCourseRemoveConfirmation($confirmationId: String!, $action: String!) {
    confirmation: handleCourseRemoveConfirmation(confirmationId: $confirmationId, action: $action) {
      id
    }
  }
`