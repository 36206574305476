import React from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import XIcon from '@heroicons/react/24/solid/XMarkIcon'
import { Button } from './elements/Button'

export type ModalActionType = {
  text: any,
  color?: 'primary'|'secondary',
  className?: string,
  getActionEvent?: () => void,
  focused?: boolean,
  disabled?: boolean,
}
type MyModalPropsIn = {
  id?: string,
  title: string,
  description?: string|React.ReactNode,
  content?: {element: any, getCloseEvent?: () => void}[],
  actions?: ModalActionType[],
  onClose: () => void,
  open: boolean,
  steps?: {currentStep: number, totalSteps: number},
  fixedMaxWidth?: boolean,
}
// tslint:disable-next-line: function-name
export default function Modal(props: MyModalPropsIn) {
  return <Transition.Root show={props.open} as={React.Fragment}>
    <HeadlessDialog
      as="div"
      static
      open={props.open}
      onClose={props.onClose}
      className="fixed z-modal inset-0 overflow-y-auto"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block relative align-bottom bg-white rounded-lg text-center shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[2/3] md:max-w-[2/3]" style={{maxWidth: props.fixedMaxWidth ? '66.6%' : 'auto'}}>
            <div className="flex flex-row justify-between items-center px-2 mt-4 mx-4">
              <div>{props.steps
                ? <div className="bg-gray-200 font-bold text-sm py-2 px-4 rounded-full">
                    <span>Step {
                      props.steps.currentStep
                    }</span> of <span>{
                      props.steps.totalSteps
                    }</span>
                  </div>
                : undefined
              }</div>
              <div>
                <button className="outline-none rounded-full" onClick={(e) => {
                  e.preventDefault()
                  props.onClose()
                }}>
                  <XIcon className="text-medgray w-6 h-6" />
                </button>
              </div>
            </div>
            <h3 className="leading-9 text-2xl font-bold text-deepgray px-4 -mt-4">
              {props.title}
            </h3>
            {props.description && (
              <div className="text-medgray text-sm mt-4">
                {props.description}
              </div>
            )}
            {props.content && (
              <div className="bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-row gap-3 justify-center">
                {props.content.map((cmp, i) => {
                  return <div className="w-full" key={`content-my-modal-${i}`} onClick={() => {
                    if (cmp.getCloseEvent) {
                      cmp.getCloseEvent()
                      props.onClose()
                    }
                  }}>
                    {cmp.element}
                  </div>
                })}
              </div>
            )}
            {props.actions && (
              <div className="bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-row gap-3 justify-end rounded-b-lg">
                {props.actions.map((cmp, i) => {
                  return <button // ref={buttonFocusRef}
                    key={`gb-modal-action-${i}`}
                    type="button"
                    disabled={cmp.disabled}
                    autoFocus={cmp.focused} className={cmp.className ||
                      `${clsx([{
                        'bg-lake border-none': cmp.color === 'primary' && !cmp.disabled,
                        'bg-coral text-white border-none': cmp.color === 'secondary' && !cmp.disabled,
                        'bg-lake-lighter text-gray-500 border-none cursor-not-allowed': cmp.color === 'primary' && cmp.disabled,
                        'bg-coral-lighter text-gray-500 border-none cursor-not-allowed': cmp.color === 'secondary' && cmp.disabled,
                      }])} border outline-none capitalize border-solid border-lightgray text-deepgray font-bold rounded px-6 py-2 text-sm leading-5`}
                    onClick={() => {
                      if (cmp.getActionEvent) {
                        cmp.getActionEvent()
                      }
                    }}>
                    {cmp.text}
                  </button>
                })}
              </div>
            )}
          </div>
        </Transition.Child>
      </div>
    </HeadlessDialog>
  </Transition.Root>
}

type ModalWizardPropsIn = {
  steps: React.ReactNode,
  open: boolean,
  onClose: () => any,
  children: any,
  actions?: ModalActionType[],
}
export function ModalWizard(props: ModalWizardPropsIn) {
  const [open, setOpen] = React.useState(props.open)
  const close = () => {
    setOpen(false)
    props.onClose()
  }
  return <Transition.Root show={open} as={React.Fragment}>
    <HeadlessDialog
      as="div"
      static
      open={open}
      onClose={props.onClose}
      className="fixed z-modal inset-0"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block h-full relative align-bottom bg-white rounded-lg text-center shadow-xl transform transition-all sm:my-8 sm:align-middle sm:min-w-lg sm:max-w-5/6">
            {/* STEPS */}
            <div className="w-full text-center flex flex-row items-start justify-between px-2">
              <div></div>
              <h3 className="leading-9 flex-1 font-extrabold text-base text-deepgray mt-2">
                {props.steps}
              </h3>
              <button className="outline-none rounded-full pt-2" onClick={(e) => {
                e.preventDefault()
                close()
              }}>
                <XIcon className="text-medgray w-6 h-6" />
              </button>
            </div>

            {/* CONTENT */}
            <div style={{
              maxHeight: 'calc(100vh - 300px)',
            }} className={`${clsx({'rounded-b-lg': !props.actions})} bg-white w-full px-4 pb-4 sm:px-6 sm:pb-4 flex flex-row gap-3 justify-center`}>
              <div className="w-full overflow-x-hidden overflow-y-auto">{props.children}</div>
            </div>

            {/* ACTIONS */}
            {props.actions && (
              <div className="bg-white w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-row gap-3 justify-end rounded-b-lg">
                {props.actions.map((cmp, i) => {
                  return <Button
                    key={`${cmp.text}-${i}-actions`}
                    type={cmp.color === 'primary'
                      ? 'secondary' : cmp.color === 'secondary'
                        ? 'destructive' : undefined}
                    onClick={() => {
                      if (cmp.getActionEvent) {
                        cmp.getActionEvent()
                      }
                    }}
                    text={cmp.text}
                  />
                })}
              </div>
            )}
          </div>
        </Transition.Child>
      </div>
    </HeadlessDialog>
  </Transition.Root>
}
