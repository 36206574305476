import DotIcon from 'components/utils/Icons/DotIcon'
import React from 'react'
import clsx from 'clsx'
import CheckIcon from 'components/utils/Icons/CheckIcon'

type BannerCreationStepsProps = {
  step: number,
  isModalOpen: boolean,
}

const BannerCreationSteps = ({step, isModalOpen}: BannerCreationStepsProps) => {

  const firstTextCn = clsx({
    'text-deepgray font-medium': step === 1 || step === 2,
  })

  const secondTextCn = clsx({
    'text-medgray font-medium': step === 2,
  })

  return (
    <>
      <section className="flex justify-center items-center relative pt-8 mb-8">
        <div className="flex flex-col">
          <div className="w-[200px] h-10 text-center relative">
            {(step === 1 || step === 2) ?
              <CheckIcon
                className="fill-lake inline-block absolute right-6 top-2"
                h={14}
                w={14}
              />
              :
              <DotIcon
                className="fill-lake inline-block absolute right-6 top-2"
                h={14}
                w={14}
              />
            }
            <div className="inline-block w-[200px] absolute top-10 left-[75px] text-deepgray font-medium">
              {location.hash !== '#edit' ? 'Create your banner' : 'Edit your banner'}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="h-10 text-center">
            <div className={`w-[200px] h-1 rounded-md ${step === 1 || step === 2 ? 'bg-lake' : 'bg-medwarmgray'} mt-3.5 mr-6 `}></div>
          </div>
        </div>
        {step === 2 ?
          <div className="flex flex-col absolute">
            <div  className="w-[200px] h-10 text-center relative mt-1">
                <CheckIcon
                  h={14}
                  w= {14}
                  className="fill-lake absolute top-2 right-24 left-24"
                />
              <div className="inline-block w-[200px] mt-[38px] text-deepgray">
                Choose product code
              </div>
            </div>
          </div>
          :
          <div className="flex flex-col absolute">
            <div  className="w-[200px] h-10 text-center mt-1">
                <DotIcon
                  className={`${step === 1 ? 'fill-lake' : 'fill-medwarmgray'} inline-block`}
                  h={14}
                  w={14}
                />
              <div className={`inline-block mt-[14px] ${(step === 1 || step === 2) ? 'text-deepgray' : 'text-lightgray'}`}>
                Choose product code
              </div>
            </div>
          </div>
      }
        <div className="flex flex-col">
          <div className="h-10 text-center">
            <div className={`w-[200px] h-1 rounded-md ${step === 2 ? 'bg-lake' : 'bg-medwarmgray'} mt-3.5 ml-6`}></div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="w-[200px] h-10 text-center relative">
            {isModalOpen ?
            <CheckIcon
              className="fill-lake inline-block absolute left-6 top-2"
              h={14}
              w={14}
            />
            :
            <DotIcon
              className={`${step === 2 ? 'fill-lake' : 'fill-medwarmgray'} inline-block absolute left-6 top-2`}
              h={14}
              w={14}
            />
          }
            <div className={`inline-block w-[200px] absolute top-10 right-[75px] ${step === 2 ? 'text-deepgray' : 'text-lightgray'}`}>
              Review and launch
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BannerCreationSteps
