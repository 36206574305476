import clsx from 'clsx'
import i18n from 'components/i18n'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { NewFeatureBadge } from './NewFeatureBadge'
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined'
import { useQuery } from '@apollo/client'
import { GET_USER_METADATA } from 'queries/user'
import { withAuthUser } from './AuthUserWrapper'
import { UserAuthenticated } from 'models/user'
import { CombinedProps, fetchDataGApi } from './fetcher'
import { showModal } from './snackbar'
import { Button } from 'components/elements/Button'
import { updateMeta } from 'lib/user_metadata'
import Tippy from '@tippyjs/react'

type LastUpdatesItemType =  {
  '_archived': boolean,
  '_draft': boolean,
  'featured': boolean,
  'post-body': string,
  'name': string,
  'slug': string,
  'main-image': {
    'fileId': string,
    'url': string,
    'alt': string,
  },
  'summary': string,
  'updated-on': string,
  'updated-by': string,
  'created-on': string,
  'created-by': string,
  'published-on': string,
  'published-by': string,
  '_cid': string,
  '_id': string,
}
type LastUpdatesApiRes = { items: LastUpdatesItemType[] }
type PropsIn = { openDrawer: boolean, userAuthenticated: UserAuthenticated }
type WelcomeProps = CombinedProps<PropsIn, any, {}, LastUpdatesApiRes>

const UpdatesModalContent = ({updates, onClose}: {updates: LastUpdatesItemType[], onClose: () => void}) => {
  const [step, setStep] = React.useState(0)

  const handlePrevious = () => setStep((prev) => {
    if (prev === 0) return 0
    return prev - 1
  })
  const handleNext = () => setStep((prev) => {
    if (prev === 3) return 3
    return prev + 1
  })
  const handleClose = () => onClose()
  const handleReadMore = () => {
    window.open('https://www.gnowbe.com/feature-updates?utm_source=gnowbedashboard&utm_medium=dashboard&utm_campaign=feature-updates', '_blank')?.focus()
  }

  const item = updates[step]
  const imgUrl = item['main-image']?.url || '/images/product-update-img.svg'
  return (
    <div key={item._id}>
      <div className="h-auto w-[700px]">
        <img src={imgUrl} className="mt-4 mx-auto rounded-md" />
        <div className="text-center text-2xl mt-8 font-semibold">{item.name}</div>
        <p className="mt-4 font-semibold whitespace-pre-wrap text-left">
          {item['summary']} <a href={`https://www.gnowbe.com/feature-updates/${item.slug}?utm_source=gnowbedashboard&utm_medium=dashboard&utm_campaign=feature-updates`} target="_blank" rel="noopener noreferrer" className="font-semibold text-coral underline whitespace-pre-wrap">Read more</a>
        </p>
      </div>
      <div className="mt-6 flex flex-row w-full justify-between items-center">
        <div className="inline-flex items-center gap-2">
          {step !== 0 && <Button
            text={i18n.t('previous')}
            onClick={handlePrevious}
          />}
          {step !== 2 && <Button
            type="primary"
            text={i18n.t('next')}
            onClick={handleNext}
          />}
        </div>
        <div className="inline-flex items-center gap-2">
          {step === 2 && <Button
            type="text"
            className="text-listenblue"
            text="Read more"
            onClick={handleReadMore}
          />}
          <Button
            type="secondary"
            text={i18n.t('close')}
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  )
}

const WelcomeMenuItem = (props: WelcomeProps) => {
  const {loading: metaLoading, error: metaError, data: metaData } = useQuery(GET_USER_METADATA, {
    variables: { userId: props.userAuthenticated.id },
  })

  const showUpdatesModal = (updates: LastUpdatesItemType[]) => {
    updateMeta('gnowbeUpdates/lastSeen', String(Date.now()))
    const updatesModal = showModal({
      hideElements: ['title'],
      title: '',
      content: [{
        element: <UpdatesModalContent updates={updates} onClose={() => updatesModal.close()} />,
      }],
    })
  }

  const updates = props.fData.resType === 'success'
    ? props.fData.data.items
    : []
  const lastUpdate = updates.length > 0
    ? new Date(updates.sort((a, b) => new Date(b['published-on']).getTime() - new Date(a['published-on']).getTime())[0]['published-on']).getTime()
    : 0

  const lastSeenGnowbeUpdates = Number(metaData?.user?.metadata?.general?.gnowbeUpdates?.lastSeen || 0)
  const {openDrawer} = props
  const checkHidden = clsx({hidden: !openDrawer, 'whitespace-nowrap': true})

  return (
    <button onClick={() => showUpdatesModal(updates)} className="w-full group flex items-center py-2 mt-1 text-base leading-6 font-medium text-medgray hover:bg-gray-100 hover:text-deepgray rounded-md focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
      <Tippy content={'What\'s new?'}>
        <div className="flex flex-col items-center">
          <div className="relative">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="stroke-listenblue ml-3 mr-3">
              <path d="M5 12V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V12M12 8V21V8ZM12 8V6C12 5.60444 12.1173 5.21776 12.3371 4.88886C12.5568 4.55996 12.8692 4.30362 13.2346 4.15224C13.6001 4.00087 14.0022 3.96126 14.3902 4.03843C14.7781 4.1156 15.1345 4.30608 15.4142 4.58579C15.6939 4.86549 15.8844 5.22186 15.9616 5.60982C16.0387 5.99778 15.9991 6.39992 15.8478 6.76537C15.6964 7.13082 15.44 7.44318 15.1111 7.66294C14.7822 7.8827 14.3956 8 14 8H12ZM12 8V5.5C12 5.00555 11.8534 4.5222 11.5787 4.11108C11.304 3.69995 10.9135 3.37952 10.4567 3.1903C9.99989 3.00108 9.49723 2.95157 9.01227 3.04804C8.52732 3.1445 8.08186 3.3826 7.73223 3.73223C7.3826 4.08187 7.1445 4.52732 7.04804 5.01228C6.95157 5.49723 7.00108 5.99989 7.1903 6.45671C7.37952 6.91352 7.69995 7.30397 8.11107 7.57867C8.5222 7.85338 9.00555 8 9.5 8H12ZM5 12H19H5ZM5 12C4.46957 12 3.96086 11.7893 3.58579 11.4142C3.21071 11.0391 3 10.5304 3 10C3 9.46957 3.21071 8.96086 3.58579 8.58579C3.96086 8.21072 4.46957 8 5 8H19C19.5304 8 20.0391 8.21072 20.4142 8.58579C20.7893 8.96086 21 9.46957 21 10C21 10.5304 20.7893 11.0391 20.4142 11.4142C20.0391 11.7893 19.5304 12 19 12H5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {!openDrawer && lastUpdate > lastSeenGnowbeUpdates && <div style={{width: 6, height: 6}} className="rounded-full -bottom-2 left-5 bg-coral absolute"/>}
          </div>
        </div>
      </Tippy>
      <div className={`${checkHidden}`} >
        <NewFeatureBadge badgeText="latest" text="What’s new?"
          position="right"
          hideBadge={lastSeenGnowbeUpdates >= lastUpdate} />
      </div>
    </button>
  )
}

const GetLastUpdates = fetchDataGApi<PropsIn, any, {}, LastUpdatesApiRes>(
  {
    getEndpoint: p => '/api/v1/integrations/webflow/features?limit=3',
    getDataFields: [],
    paramsFromCmpDefault: {},
  },
  WelcomeMenuItem,
)
export default withAuthUser(GetLastUpdates)
