import React from 'react'
import Drawer from '@material-ui/core/Drawer'

export type RightDrawerOptions = {
  toggleDrawer: (open: boolean) => void,
  setContent: (content: any) => void,
}

type RightDrawerPropsIn = {
  children?: any,
  onClose?: () => void,
  zIndexApplied?: boolean,
}

export default React.forwardRef((props: RightDrawerPropsIn, ref) => {
  const [state, setState] = React.useState(false)
  const [content, setContent] = React.useState(props.children || undefined)
  const toggleDrawer = (open: boolean) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (!open) {
      setContent(undefined)
      props.onClose && props.onClose()
    }
    setState(open)
  }

  React.useImperativeHandle(ref, () => ({
    toggleDrawer: (open: boolean) => {
      setState(open)
      setContent(undefined)
    },
    setContent: (content: any) => setContent(content),
  }))

  return <Drawer disableEnforceFocus anchor="right" open={state} onClose={toggleDrawer(false)}  style={{zIndex: `${props.zIndexApplied ? '2300' : '1200'}`}}>
    {content && <div style={{width: '750px', marginBottom: 70}}>{content}</div>}
    {props.children && <div style={{width: '750px', marginBottom: 70}}>{props.children}</div>}
  </Drawer>
})
