import { Combobox, Transition } from '@headlessui/react'
import CheckIcon from '@heroicons/react/24/solid/CheckIcon'
import SelectorIcon from '@heroicons/react/24/solid/ArrowsUpDownIcon'
import React, { Fragment, useEffect } from 'react'
import { BannerStateType } from './BannerDetails'
import Joi from '@hapi/joi'

type CtaSectionProps = {
  state: BannerStateType
  handleFieldChange: (value: string, e: string) => void,
  setState: (value: BannerStateType) => void,
  checkField: (field: string) => void,
  setCta: (val: boolean) => void,
  cta: boolean,
}


const CtaSection = ({state, handleFieldChange, setState, checkField, cta, setCta}: CtaSectionProps) => {
  const urlValidator = Joi.string().uri({scheme: ['http', 'https']})
  const isUrlValid = urlValidator.validate(state.ctaButton && state.ctaButton.url)

  const bannerTypes = [
    {
      type: 'text',
      name: 'Text - button',
    },
    {
      type: 'range5',
      name: 'Range 1-5',
    },
    {
      type:'range10',
      name: 'Range 0-10',
    },
  ]

  const enableCta = () => {
    setCta(!cta)
    if (!cta) {
      setState({
        ...state,
        type: 'text',
        minRangeCopy: null,
        maxRangeCopy: null,
        ctaButton: {
          url: '',
          label: '',
        },
      })
    }
  }

  useEffect(
    () => {
      if (!cta) {
        setState({
          ...state,
          type: 'text',
          minRangeCopy: null,
          maxRangeCopy: null,
          ctaButton: null,
        })
      }
    },
    [cta],
  )

  useEffect(
    () => {
      if (state.type !== 'text') {
        setState({
          ...state,
          ctaButton: {
            label: state.ctaButton?.label || '',
            url: 'https://gnowbe.com',
          },
        })
      }
    },
    [state.type],
  )

  const urlSetter = (value: string) => {
    setState({
      ...state,
      ctaButton: {
        label: state.ctaButton?.label || '',
        url: value,
      },
    })
  }
  const labelSetter = (value: string) => {
    setState({
      ...state,
      ctaButton: {
        label: value,
        url: state.ctaButton?.url || '',
      },
    })
  }

  return (
    <section  className="mt-6 p-4 bg-[#F3F4F5] rounded">
      <div className="flex">

        <input
          type="checkbox"
          id="cta-button"
          className={`${location.hash !== '#edit' ? 'text-lake' : 'text-gray-300'} focus:ring-0 h-5 w-5 rounded-sm form-checkbox mr-5`}
          checked={cta}
          onChange={enableCta}
          disabled={location.hash === '#edit'}
        />
        <label htmlFor="cta-button" className="text-medgray text-sm">Add a CTA button (optional)</label>
      </div>
      {cta && <div className="ml-10 mr-8">
        <div className="text-sm text-medgray font-bold mt-3 mb-1">
          CTA type
        </div>
        <Combobox disabled={location.hash === '#edit'} value={state} onChange={type => handleFieldChange('type', type.type)}>
          <div className="relative mt-1">
            <div className="relative w-1/3 cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                placeholder={state.type}
                value="Text - button"
                className={`w-full rounded-lg border ${location.hash !== '#edit' ? 'bg-white' : 'bg-gray-300'} border-gray-300 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0`}
                onChange={e => e.preventDefault()}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Combobox.Options className="absolute z-modal mt-1 max-h-60 w-1/3 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {bannerTypes.map((type) => {
                  return (
                    <Combobox.Option
                      key={type.type}
                      className={({ active }) =>
                        `relative cursor-default select-none py-1.5 pl-10 pr-text-gray-900 v ${
                          active && 'bg-gray-200'
                        }`
                      }
                      value={type}>
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-bold' : 'font-normal'
                            }`}>
                            {type.name}<br />
                            <span className="text-lightgray italic">{type.name}</span>
                          </span>

                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active && 'bg-gray-200'
                              }`}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  )
                })
                }
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
          <div>
            {(state.type === 'range5' || state.type === 'range10') && <div className="flex mt-4">
              <div className={'w-1/2 mr-2'}>
                <div className="text-sm text-medgray font-bold mt-2">
                  Minimum range copy
                </div>
                <div className="text-sm text-medgray font-normal">
                  {state.type === 'range5' ? 'Insert the copy you want to appear below the “1” range.' : 'Insert the copy you want to appear below the “0” range.'}
                </div>
                <input type="text"
                  placeholder="e.g. “Least likely”"
                  className={`w-full border border-lightgray rounded mt-1 ${checkField('Minimum range copy')}`}
                  onChange={e => handleFieldChange('minRangeCopy', e.currentTarget.value)}
                  value={state.minRangeCopy || ''}
                />
                {(!state.minRangeCopy) && <div className="text-coral">
                  Range copy required.
                </div>}
              </div>
              <div className={'w-1/2 ml-2'}>
                <div className="text-sm text-medgray font-bold mt-2">
                  Maximum range copy
                </div>
                <div className="text-sm text-medgray font-normal">
                 {state.type === 'range5' ? 'Insert the copy you want to appear below the “5” range' : 'Insert the copy you want to appear below the “10” range'}
                </div>
                <input type="text"
                  placeholder="e.g. “Most likely”"
                  className={`w-full border border-lightgray rounded mt-1 ${checkField('Maximum range copy')}`}
                  onChange={e => handleFieldChange('maxRangeCopy', e.currentTarget.value)}
                  value={state.maxRangeCopy || ''}
                />
                {(!state.maxRangeCopy) && <div className="text-coral">
                  Range copy required.
                </div>}
              </div>
            </div>}
          <div className="flex mt-4">
            <div className={'w-1/2 mr-2'}>
              <div className="text-sm text-medgray font-bold mt-2">
                CTA text
              </div>
              <div className="text-sm text-medgray font-normal">
                Insert the text for your CTA button.
              </div>
              <input type="text"
                placeholder="e.g. “Learn More”"
                className={`w-full border border-lightgray rounded mt-1 ${checkField('CTA text')}`}
                onChange={e => labelSetter(e.currentTarget.value)}
                value={state.ctaButton?.label}
              />
              {(state.ctaButton && ((state.ctaButton.label?.length || 0) < 3)) && <div className="text-coral">
                Button copy required.
              </div>}
            </div>
            {state.type === 'text' && <div className={'w-1/2 ml-2'}>
              <div className="text-sm text-medgray font-bold mt-2">
                Link
              </div>
              <div className="text-sm text-medgray font-normal">
                Insert the link that your users will be redirected to upon clicking the CTA.
              </div>
              <input type="text"
                placeholder="Insert link here"
                className={`w-full border border-lightgray rounded mt-1 ${checkField('Link')}`}
                onChange={e => urlSetter(e.currentTarget.value)}
                value={state.ctaButton?.url}
              />
              {(isUrlValid.error) && <div className="text-coral">
                {isUrlValid.error.message}
              </div>}
            </div>}
          </div>
        </div>
      </div>}
    </section>
  )
}

export default CtaSection
