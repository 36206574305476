import * as React from 'react'
import IconType from './iconType'

const SalesIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 512 512"
      >
      <path d="M276.8 147.8C286.5 149 296.2 151.7 305.8 154.4C307.6 154.9 309.5 155.5 311.3 155.1C322.8 159.2 329.6 171.1 326.4 182.6C323.2 194.1 311.3 200.8 299.8 197.6C298.3 197.2 296.7 196.8 295.2 196.3C288.2 194.4 281.2 192.4 274.1 191C260.9 188.5 245.6 189.7 233.2 195C222.2 199.8 213.1 211.4 225.6 219.4C235.5 225.7 247.4 228.9 258.8 231.1C261.1 232.6 263.5 233.3 265.8 233.9C281.4 238.4 301.3 244 316.2 254.2C335.6 267.5 344.7 289.2 340.5 312.4C336.3 334.8 320.8 349.5 302 357C294.2 360.2 285.7 362.2 276.8 363.2L276.8 378.4C276.8 390.3 267.1 400 255.2 400C243.3 400 233.6 390.3 233.6 378.4L233.6 361C219.1 357.8 204.9 353.1 190.8 348.5C179.5 344.8 173.3 332.5 177.1 321.2C180.8 309.9 193.1 303.7 204.4 307.5C206.9 308.3 209.4 309.2 211.9 310C223.2 313.8 234.8 317.8 246.4 319.6C263.4 322.1 276.1 320.6 285.9 316.1C297.9 312.1 303.5 297.9 291.8 289.9C281.7 282.1 269.2 279.6 257.3 276.4C254.1 275.7 252.7 275.1 250.5 274.5C235.4 270.2 216.6 264.9 202.4 255.8C182.9 243.3 172.9 222.5 177.1 199.4C181.1 177.6 198.1 163.1 216.1 155.4C221.6 152.1 227.5 151.1 233.6 149.7V133.6C233.6 121.7 243.3 111.1 255.2 111.1C267.1 111.1 276.8 121.7 276.8 133.6L276.8 147.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
      />
    </svg>
  )
}

export default SalesIcon
