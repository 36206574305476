import { Action, ActionWithStudyContent, getActionWithStudyContentFragment, getActionWithStudyContentFragmentName } from './action'
import { Engagement, getEngagementFragment, getEngagementFragmentName } from './engagement'
// import { Timestamps } from './timestamps'

export type AssessmentMode = {
  enabled: boolean,
  randomized: boolean,
  limit?: number,
  choicesRandomized?: boolean,
  timeLimit?: number,
}

export type Chapter = {
  id: string,
  courseId: string,
  order: number,
  title: string,
  description?: string,
  imageUrl?: string,
  imageUrlAlt?: string,
  videoUrl?: string,
  videoUrl_720p?: string,
  videoUrlThumbnail?: string
  videoUrlAlt?: string,
  isSequential?: boolean,
  tagline?: string,
  disabledUntilDay?: number,
  assessmentMode?: AssessmentMode,
  // timestamps: Timestamps,
  morningPush: string,
  morningEmail: string,
  actions: Action[],
}

export type ChapterWithStudyContent = {
  userId: string,
  companyId: string,
  courseId: string,
  chapter: Chapter,
  engagement?: Engagement,
  actions: ActionWithStudyContent[],
}

export const getChapterFragmentName = () => {
  return 'ChapterFields'
}

export const getChapterFragment = () => `
  fragment ${getChapterFragmentName()} on Chapter {
    id
    order
    title
    description
  }
`


export const getChapterWithStudyContentFragmentName = () => {
  return 'ChapterWithStudyContentFields'
}
export const getChapterWithStudyContentFragment = () =>  `
  ${getChapterFragment()}
  ${getEngagementFragment([])}
  ${getActionWithStudyContentFragment(['with_study'])}
  fragment ${getChapterWithStudyContentFragmentName()} on ChapterWithStudyContent {
    chapter {
      ...${getChapterFragmentName()}
    }
    engagement {
      ...${getEngagementFragmentName([])}
    }
    actions {
      ...${getActionWithStudyContentFragmentName(['with_study'])}
    }
  }
`


