import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import React from 'react'
import { escapeStrForRegExp, getNumberToLocaleString } from '../../../../lib/utils'
import { getUserOrganizationCompanyFragmentName, getUserOrganizationCompanyFragment, UserOrganizationCompany, UserOrganizationCompaniesRes, CompanyBasic } from '../../../../models/company'
import ErrorCmp from '../../../Error'
import i18n from '../../../i18n'
import IfHasRole from '../../../user/IfHasRole'
import { openConfirm, showSnackbar, openAlert } from '../../../snackbar'
import { UserContext } from '../../../context'
import Loading from 'components/Loading'
import { handleError } from 'components/utils'
import { exportToCsv } from 'utils/csv'
import HelpTooltip from 'components/HelpTooltip'
import { gql, useMutation, useQuery } from '@apollo/client'
import Table2, { TableOptions } from 'components/Table2'
import { useNavigate } from 'react-router-dom'


type CompaniesPropsIn = {
  userId: string,
  userFullName: string,
  organizationId: string,
  companiesLoading: boolean,
  companies: UserOrganizationCompany[],
  refreshCompanies: () => void,
  removeFromCompany: (companyId: string, userId: string) => void,
}

type CompaniesProps = CompaniesPropsIn&{onlyVisibleInAnalytics?: boolean}

const CompaniesTab = (props: CompaniesProps) => {
  const navigate = useNavigate()
  const [options, setOptions] = React.useState<TableOptions>({
    filterText : '', page: 0, rowsPerPage: 10,
  })

  const onOptionsChange = (o: TableOptions) => {
    setOptions(o)
  }

  const openCompany = (e: React.MouseEvent, company: { organizationId: string, companyId: string, userId: string }) => {
    e.preventDefault()
    navigate(`/organization/${company.organizationId}/company/${company.companyId}/users/${company.userId}`)
  }

  const removeFromCompany = (userId: string, company: CompanyBasic) => {
    openConfirm({title: `${i18n.t('unsubscribe_user_from_group')} ${company.name}?`}, async (res) => {
      if (!res) return
      props.removeFromCompany(company.id, userId)
    })
  }

  const exportCompanies = () => {
    try {
      const {companies} = props
      exportToCsv(
        ([
          [
            i18n.t('group_name'),
            i18n.t('user'),
            i18n.t('minutes_spent'),
            i18n.t('completed_chapters'),
            i18n.t('completion_rate'),
          ],
        ] as any[])
        .concat(companies.map(
          c => [
            c.company.name,
            props.userFullName,
            getNumberToLocaleString(c.engagementSummary.minutesSpent),
            getNumberToLocaleString(c.engagementSummary.numOfCompletedChapters),
            `${Math.round(c.engagementSummary.avgCompletion)}%`,
          ],
        ))
      , 'groups.csv')
      showSnackbar(i18n.t('successfully_exported'), 3000, 'success')
    } catch (err) {
      openAlert({title: i18n.t('error'), description: `${i18n.t('an_error_happened_exporting_data')} ${i18n.t('please_try_again')}..\n\n${err.message}`})
    }
  }

  const { companies, userId, companiesLoading } = props

  let companiesPage = options.filterText
    ? companies.filter(c => new RegExp(escapeStrForRegExp(options.filterText), 'i').test(c.company.name))
    : companies
  companiesPage = companiesPage
    .slice(options.page * options.rowsPerPage, options.page * options.rowsPerPage + options.rowsPerPage)

  return (
    <div className="p-7">
      <Table2
        loading={companiesLoading}
        options={options}
        onOptionsChange={onOptionsChange}
        count={companies.length}
        toolbar={{
          placeholder: i18n.t('filter_by_name'),
          onRefresh: props.refreshCompanies,
          exportData: {
            onExportFiltered: exportCompanies,
          },
        }}
        columns={[
          {accessor: 'name', Header: i18n.t('name'), disableSortBy: true, width: 200, align: 'start', sticky: 'left'},
          {accessor: 'minutesSpent', Header: <span className="whitespace-nowrap flex-nowrap">
            {i18n.t('minutes_spent')}
          </span>, disableSortBy: true, align: 'end', width: 100},
          {accessor: 'numberCompletedSessions', Header: <span className="whitespace-nowrap flex-nowrap">
            {i18n.t('completed_chapters')}
          </span>, disableSortBy: true, align: 'end'},
          {accessor: 'completionRate', Header: <span className="whitespace-nowrap flex-nowrap">
            {i18n.t('%_completion_rate')}&nbsp;<HelpTooltip text={i18n.t('completed_total_actions_for_starters_tooltip')} />
          </span>, disableSortBy: true, align: 'end', width: 110},
          {accessor: 'actions', Header: i18n.t('actions'), disableSortBy: true, width: 80, align: 'center',
            sticky: 'right'},
        ]}
        data={companiesPage.map((cmp) => {
          const eng = cmp.engagementSummary
          return {
            id: cmp.company.id,
            name: <a href="#" onClick={(e) => {
              openCompany(e, {
                organizationId: cmp.company.organizationId,
                companyId: cmp.company.id,
                userId,
              })
            }}>
              {cmp.company.name}
            </a>,
            minutesSpent: getNumberToLocaleString(eng.minutesSpent),
            numberCompletedSessions: getNumberToLocaleString(eng.numOfCompletedChapters),
            completionRate: `${Math.round(eng.avgCompletion)}%`,
            actions: <PopupState variant="popover" popupId="more-agent-actions">
              {popupState => (
                <>
                  <IconButton {...bindTrigger(popupState)}>
                    <MoreVertIcon />
                  </IconButton>
                  <IfHasRole
                    role="manager|agent"
                    organizationId={cmp.company.organizationId}
                    companyId={cmp.company.id}
                  >
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          removeFromCompany(userId, cmp.company)
                        }}
                        style={{ marginRight: 10 }}
                      >
                        {i18n.t('remove_from_group')}
                      </MenuItem>
                    </Menu>
                  </IfHasRole>
                </>
              )}
            </PopupState>,
          }
        })}
      />
    </div>
  )
}


const REMOVE_COMPANY_USER = gql`
  mutation RemoveCompanyUser($companyId: String!, $userId: String!) {
    toggleRes: toggleCompanyUser(companyId: $companyId, userIdOrEmail: $userId, addRemove: remove, noEmail: true) {
      key
      addRemove
    }
  }
`

export const GET_USER_ORGANIZATION_COMPANIES2 = gql`
  ${getUserOrganizationCompanyFragment()}
  query GetUserOrganizationCompanies2(
    $organizationId: String!,
    $userId: String!
  ) {
    res: getUserOrganizationCompanies2(
      organizationId: $organizationId,
      userId: $userId
    ) {
      id
      organizationId
      userId
      companies {
        ...${getUserOrganizationCompanyFragmentName()}
      }
    }
  }
`
export type GetUserOrganizationCompanies2Vars = {
  userId: string,
  organizationId: string,
}
export type GetUserOrganizationCompanies2Res = {
  res: UserOrganizationCompaniesRes,
}

type UserCompaniesPropsIn = {
  userId: string,
  userFullName: string,
  organizationId: string,
}

// tslint:disable-next-line: function-name
export default function UserCompaniesWrapper(props: UserCompaniesPropsIn) {
  const userData = React.useContext(UserContext)
  const {
    data,
    loading,
    error,
    refetch,
    variables,
  } = useQuery<GetUserOrganizationCompanies2Res, GetUserOrganizationCompanies2Vars>(GET_USER_ORGANIZATION_COMPANIES2, {
    variables: {
      userId: props.userId,
      organizationId: props.organizationId,
    },
  })

  const [removeUserFromGroupM, {client}] = useMutation(REMOVE_COMPANY_USER, {
    onCompleted: (data) => {
      const companyId = data.toggleRes.key
      const queryData: GetUserOrganizationCompanies2Res|null = client.readQuery({
        query: GET_USER_ORGANIZATION_COMPANIES2,
        variables,
      })
      if (!queryData) return
      client.writeQuery({
        query: GET_USER_ORGANIZATION_COMPANIES2,
        variables,
        data: {
          res: {
            ...queryData.res,
            companies: queryData.res.companies.filter(c => c.company.id !== companyId),
          },
        },
      })
      showSnackbar(i18n.t('user_successfully_removed_from_group'), 3000, 'success')
    },
    onError: error => handleError(error, undefined, 3000),
  })

  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />

  return <CompaniesTab
    companiesLoading={loading}
    refreshCompanies={() => refetch()}
    {...props}
    onlyVisibleInAnalytics={userData.settings.onlyVisibleInAnalytics}
    companies={data?.res.companies || []}
    removeFromCompany={(companyId, userId) => {
      removeUserFromGroupM({variables: {companyId, userId}})
    }}
  />
}
