import { ApolloError, NetworkStatus, OperationVariables } from '@apollo/client'
import { logoutHandler } from '../lib/auth'


export function handleApolloError(error: ApolloError) {
  if (error.networkError && error.networkError['statusCode'] === 401) {
    logoutHandler()
  } else if (error.graphQLErrors && error.graphQLErrors.some(e => e.extensions && e.extensions.code === 'UNAUTHENTICATED')) {
    logoutHandler()
  }
}

export function parseQueryData<TData, TGraphQLVariables = OperationVariables>(
  data: any, // DataValue<TData, TGraphQLVariables>|undefined,
) {
  if (!data) {
    return {
      error: new Error('No data available'),
      resType: 'error' as 'error',
    }
  }
  if (data.error) {
    handleApolloError(data.error)
    return {
      error: data.error as ApolloError,
      resType: 'error' as 'error',
    }
  }
  if (data.loading) {
    const loadingType =
      data.networkStatus === NetworkStatus.setVariables ? ('setVariables' as 'setVariables') :
      data.networkStatus === NetworkStatus.fetchMore ? ('fetchMore' as 'fetchMore') :
      data.networkStatus === NetworkStatus.refetch ? ('refetch' as 'refetch') :
      data.networkStatus === NetworkStatus.poll ? ('poll' as 'poll') :
      ('setVariables' as 'setVariables')
    return {
      ...data, // (data as GraphqlQueryControls<TGraphQLVariables>),
      resType: 'loading' as 'loading',
      reloading: loadingType === 'fetchMore' || loadingType === 'refetch' || loadingType === 'poll',
      loadingType,
    }
  }
  return {
    ...data, // (data as GraphqlQueryControls<TGraphQLVariables> & TData),
    resType: 'success' as 'success',
  }
}
