import React from 'react'
import clsx from 'clsx'
import { Switch } from '@headlessui/react'
import { Tooltip } from '@material-ui/core'

const HeadlessSwitch = ({ label, checked, tooltip, onChange, disabled, labelPlacementStart, className }: {
  label: string|JSX.Element, checked: boolean, tooltip?: string,
  onChange: (checked: boolean) => void, disabled?: boolean, labelPlacementStart?: boolean,
  className?: string
}) => {
  return (
    <Switch.Group as="div" className={`flex items-center ${className} ${labelPlacementStart && 'justify-between w-full'}`}>
      {labelPlacementStart &&<Switch.Label as="span" className={"mr-3"}>
        <span className="text-base leading-6 font-bold text-gray-700 cursor-pointer">{label}</span>
      </Switch.Label>}
      <Tooltip title={tooltip || ''}>
        <div>
          <Switch
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
            className={clsx(
              checked ? 'bg-[#AEE0E1]' : 'bg-[#E3E4E5]',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors ease-in-out duration-200 items-center'
            )} data-test="switch-toggle-btn">
            <span
              aria-hidden="true"
              className={clsx(
                checked ? 'translate-x-[1.325rem]' : 'translate-x-0.5',
                'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
              data-test="toggle-switch-btn"/>
          </Switch>
        </div>
      </Tooltip>
      {!labelPlacementStart && <Switch.Label as="span" className={"ml-3"}>
        <span className="text-sm leading-5 font-bold text-gray-700 cursor-pointer">{label}</span>
      </Switch.Label>
      }
    </Switch.Group>
  )
}

export default HeadlessSwitch
