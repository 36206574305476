import React from 'react'
import { useParams } from 'react-router-dom'
import i18n from 'components/i18n'
import { Button } from 'components/elements/Button'
import { showSnackbar } from 'components/snackbar'
import { copyToClipboard } from 'lib/utils'
import { Trans } from 'react-i18next'
import ReferralLinkSignup from './ReferralLinkSignup'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ORGANIZATION } from 'queries/organization'
import { GetOrganizationWithSettingsRes, GetOrganizationWithSettingsVars } from 'utils/queries'
import PageNotFound from 'components/PageNotFound'
import { isFreetierOrCreator } from 'models/organization'
import ReferralsSuccessfulSignups from './ReferralsSuccessfulSignups'
import InviteByEmail from 'components/user/InviteByEmail'
import {ADD_USER_REFERRAL} from 'mutations/referral'
import { handleError } from 'components/utils'

const Referrals = () => {
  const params = useParams()
  const [addUserReferral] = useMutation(ADD_USER_REFERRAL, {
    onError: error => handleError(error),
    onCompleted: () => showSnackbar(i18n.t('success'), 3000, 'success'),
  })
  const {loading, error, data} = useQuery<GetOrganizationWithSettingsRes, GetOrganizationWithSettingsVars>(
    GET_ORGANIZATION(['with_settings']), {
      variables: {organizationId: params.organizationId || ''},
    })
  if (loading) return <div>{`${i18n.t('loading')}`}</div>
  if (error) return <PageNotFound />

  if (data && !isFreetierOrCreator(data.organization.product)) {
    return <PageNotFound />
  }
  return (
    <div className="bg-white px-6 py-8 rounded-md border border-lightwarmgray">
      <section className="text-center">
        <h1 className="font-bold text-3xl"><>{i18n.t('referral_page_title')}</></h1>
        <h2 className="text-xl mt-2">
          <Trans
            i18nKey={'referral_page_subtitle_intro'}
            values={{organizationName: data?.organization.name || 'unknown'}}
            components={{bold: <b />}}
          />
        </h2>
        <h2 className="text-xl mt-2"><>{i18n.t('referral_page_subtitle')}</></h2>
      </section>

      <section className="flex gap-6 mt-6">
        <div className="flex-1 border border-lightwarmgray px-16 py-8 rounded-md">
          <div className="text-center flex h-14 items-center justify-center">
            <svg width="81" height="40" viewBox="0 0 81 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block">
              <path d="M40.75 22.5C46.1833 22.5 50.9833 23.8 54.8833 25.5C58.4833 27.1 60.75 30.7 60.75 34.6V40H20.75V34.6333C20.75 30.7 23.0167 27.1 26.6167 25.5333C30.5167 23.8 35.3167 22.5 40.75 22.5ZM14.0833 23.3333C17.75 23.3333 20.75 20.3333 20.75 16.6667C20.75 13 17.75 10 14.0833 10C10.4167 10 7.41667 13 7.41667 16.6667C7.41667 20.3333 10.4167 23.3333 14.0833 23.3333ZM17.85 27C16.6167 26.8 15.3833 26.6667 14.0833 26.6667C10.7833 26.6667 7.65 27.3667 4.81667 28.6C2.35 29.6667 0.75 32.0667 0.75 34.7667V40H15.75V34.6333C15.75 31.8667 16.5167 29.2667 17.85 27ZM67.4167 23.3333C71.0833 23.3333 74.0833 20.3333 74.0833 16.6667C74.0833 13 71.0833 10 67.4167 10C63.75 10 60.75 13 60.75 16.6667C60.75 20.3333 63.75 23.3333 67.4167 23.3333ZM80.75 34.7667C80.75 32.0667 79.15 29.6667 76.6833 28.6C73.85 27.3667 70.7167 26.6667 67.4167 26.6667C66.1167 26.6667 64.8833 26.8 63.65 27C64.9833 29.2667 65.75 31.8667 65.75 34.6333V40H80.75V34.7667ZM40.75 0C46.2833 0 50.75 4.46667 50.75 10C50.75 15.5333 46.2833 20 40.75 20C35.2167 20 30.75 15.5333 30.75 10C30.75 4.46667 35.2167 0 40.75 0Z" fill="#5EC3C5"/>
            </svg>
          </div>

          <h3 className="font-bold text-xl text-actions-multiple_choice mt-6 mb-2 text-center"><>{i18n.t('referral_invite_friends')}</></h3>
          <p className="text-base">
            <Trans
              i18n={i18n}
              i18nKey="referral_invite_friends_text"
              components={[<strong className="font-bold"></strong>]}
            />
          </p>
        </div>

        <div className="flex-1 border border-lightwarmgray px-16 py-8 rounded-md">
          <div className="text-center flex h-14 items-center justify-center">
            <svg width="35" height="60" viewBox="0 0 35 60" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block">
              <path d="M18.5833 26.3333C11.0167 24.3667 8.58332 22.3333 8.58332 19.1667C8.58332 15.5333 11.95 13 17.5833 13C23.5166 13 25.7166 15.8333 25.9166 20H33.2833C33.05 14.2667 29.55 9 22.5833 7.3V0H12.5833V7.2C6.11665 8.6 0.91665 12.8 0.91665 19.2333C0.91665 26.9333 7.28332 30.7667 16.5833 33C24.9166 35 26.5833 37.9333 26.5833 41.0333C26.5833 43.3333 24.95 47 17.5833 47C10.7166 47 8.01665 43.9333 7.64998 40H0.31665C0.71665 47.3 6.18332 51.4 12.5833 52.7667V60H22.5833V52.8333C29.0833 51.6 34.25 47.8333 34.25 41C34.25 31.5333 26.15 28.3 18.5833 26.3333Z" fill="#5EC3C5"/>
            </svg>
          </div>

          <h3 className="font-bold text-xl text-actions-multiple_choice mt-6 mb-2 text-center"><>{i18n.t('referral_keep_earning')}</></h3>
          <p className="text-base">
            <Trans
              i18n={i18n}
              i18nKey="referral_keep_earning_text"
              components={[<strong className="font-bold"></strong>]}
            />
          </p>
        </div>
      </section>

      <section className="border-t border-lightwarmgray mt-6 pt-6">
        <div className="font-bold uppercase text-sm mb-1 text-actions-multiple_choice tracking-wider"><>{i18n.t('referral_link')}</></div>
        <p className="font-bold"><>{i18n.t('referral_link_copy')}</></p>

        <div>
          <div className="p-4 bg-deadyellow border border-lightwarmgray inline-flex my-4 rounded-md">
            <img src={'/images/gnowbe-coral-logomark.png'} width={40} height={40} />

            <div className="ml-4 mr-8">
              <strong className="font-bold">
                <Trans
                  i18n={i18n}
                  i18nKey="referral_link_invitation"
                  components={[<span className="text-deadblue">bumbar</span>]}
                />
              </strong>

              <div className="text-sm">
                <ReferralLinkSignup organizationId={`${params.organizationId}`} />
              </div>
            </div>

            <div>
              <Button
                type="default"
                text={`${i18n.t('copy_link')}`}
                icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  strokeWidth={1.5} stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                </svg>}
                onClick={() => {
                  copyToClipboard(document.getElementById('signup-referral-url')?.innerText || '')
                  showSnackbar(i18n.t('copied_to_clipboard'), undefined, 'success')
                }}
              />
            </div>
          </div>
        </div>

        <p className="font-bold mb-2"><>{i18n.t('referral_link_invite_by_email')}</></p>
        <div className="mb-4">
          <InviteByEmail buttonText={`${i18n.t('send')}`} onInvite={(userIdOrEmail: string, callback: Function) => {
            addUserReferral({
              variables: {organizationId: params.organizationId, inviteeEmail: userIdOrEmail},
            })
            callback()
          }} />
        </div>

        <p className="font-bold mb-2"><>{i18n.t('referral_link_share')}</></p>

        <div className="flex gap-2">
          <Button type="default" text={`${i18n.t('email')}`}
            icon={<svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
          </svg>
          }
            onClick={() => window.open(`mailto:?subject=Join Gnowbe&body=Hi!%0D%0A%0D%0AI want to invite you to join me at creating with Gnowbe.%0D%0A%0D%0AUse this link to save 50% on the subscription of your choice!%0D%0A%0D%0A${document.getElementById('signup-referral-url')?.innerText || ''}`)}
          />
          <Button type="default" text="Facebook"
            icon={<svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 6.66667H0V10H2.5V20H6.66667V10H9.66667L10 6.66667H6.66667V5.25C6.66667 4.5 6.83333 4.16667 7.58333 4.16667H10V0H6.83333C3.83333 0 2.5 1.33333 2.5 3.83333V6.66667Z" fill="#1877F2"/>
            </svg>
            }
            onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${document.getElementById('signup-referral-url')?.innerText || ''}`)}
          />
          <Button type="default" text="Twitter"
            icon={<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.28982 18C13.8373 18 17.9648 11.0755 17.9648 5.07125C17.9648 4.87451 17.9612 4.67879 17.953 4.48382C18.7541 3.84248 19.4507 3.04194 20 2.13107C19.2648 2.4928 18.4737 2.73639 17.6436 2.84622C18.4907 2.28372 19.1413 1.39351 19.4482 0.332508C18.6552 0.853184 17.7771 1.23154 16.8427 1.43558C16.0936 0.552416 15.0272 0 13.8473 0C11.581 0 9.7433 2.0351 9.7433 4.54352C9.7433 4.90021 9.77947 5.24682 9.84953 5.57958C6.4395 5.3899 3.41549 3.58151 1.3919 0.832277C1.03909 1.50359 0.835959 2.28397 0.835959 3.116C0.835959 4.69239 1.56046 6.08413 2.6621 6.89853C1.98901 6.8756 1.35664 6.67056 0.803658 6.32999C0.802748 6.34888 0.802748 6.36803 0.802748 6.38793C0.802748 8.58877 2.21739 10.4261 4.09472 10.8425C3.7501 10.9466 3.38751 11.0025 3.01309 11.0025C2.74877 11.0025 2.49173 10.9733 2.24173 10.9206C2.76401 12.7257 4.27897 14.0396 6.07509 14.0767C4.67045 15.2956 2.90117 16.0218 0.978811 16.0218C0.64784 16.0218 0.32119 16.0009 0 15.9589C1.81591 17.2476 3.97211 18 6.28982 18Z" fill="#1DA1F2"/>
            </svg>
            }
            onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURI('Subscribe to Gnowbe with this link and save 50%')}&url=${document.getElementById('signup-referral-url')?.innerText || ''}`)}
          />
          <Button type="default" text="LinkedIn"
            icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16667 2.17391C4.16667 3.3913 3.25 4.34783 2.08333 4.34783C0.916667 4.34783 0 3.3913 0 2.17391C0 0.956522 0.916667 0 2.08333 0C3.25 0 4.16667 0.956522 4.16667 2.17391ZM4.16667 6.08696H0V20H4.16667V6.08696ZM10.8333 6.08696H6.66667V20H10.8333V12.6957C10.8333 8.6087 15.8333 8.26087 15.8333 12.6957V20H20V11.2174C20 4.34783 12.5833 4.6087 10.8333 8V6.08696Z" fill="#2867B2"/>
            </svg>
            }
            onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${document.getElementById('signup-referral-url')?.innerText || ''}&title=${encodeURI('Subscribe to Gnowbe with this link and save 50%')}`)}
          />
        </div>
      </section>

      <section className="border-t border-lightwarmgray mt-6 pt-6">
        <div className="font-bold uppercase text-sm mb-1 text-actions-multiple_choice tracking-wider"><>{i18n.t('how_referrals_work')}</></div>
        <ol className="list-decimal ml-6 mt-3 text-sm space-y-2">
          <li>
            If a friend uses your unique referral link to create a Gnowbe account, you will be credited with $5. Credits can be used to offset your paid Gnowbe subscription. You can invite up to 10 friends.
            <br />
            *This perk is only available for users with existing Gnowbe subscription plans (Creator Starter, Pro, or Enterprise)</li>
          <li>If your friend subscribes to a paid Gnowbe plan within 60 days of account activation, you will both enjoy 10% off your respective first/subsequent invoice.</li>
          <li>Rewards apply towards the currently active workspace <i>({data?.organization.name || 'undefined'})</i></li>
          <li>Gnowbe reserves the right to terminate or change any referral program at any time for any reason. This includes changing a referrer or referee's ability to participate in any referral program or receive rewards at any time for any reason, including suspected fraud, abuse, or any violation of our terms of use. If Gnowbe ends any referral program, you will have 30 days' notice to use your existing credits before they are forfeited.</li>
          <li className="link-target-blank">Refer to <a href="https://www.gnowbe.com/referral-program" target="_blank">
            this page</a> for a list of Terms and Conditions (T&Cs) and Frequently Asked Questions (FAQs)</li>
        </ol>
      </section>

      <section className="border-t border-lightwarmgray mt-6 pt-6">
        <div className="font-bold uppercase text-sm mb-1 text-actions-multiple_choice tracking-wider"><>{i18n.t('referral_successful_signups')}</></div>
        <p className="font-bold mb-4"><>{i18n.t('referral_successful_signups_text')}</></p>
        <ReferralsSuccessfulSignups organizationId={params.organizationId || ''} />
      </section>
    </div>
  )
}

export default Referrals
