import CLIENT_SETTINGS from '../../lib/client_settings'

function signIn(redirectToAfterLogin?: string) {
  const rootUrls = {
    api: CLIENT_SETTINGS.public.gnowbeBeUrl,
    dashboard: document.location && document.location.origin || 'https://dashboard.gnowbe.com',
  }
  return window.location.assign(`${rootUrls.api}/signup/login?redirectTo=${redirectToAfterLogin ? encodeURIComponent(redirectToAfterLogin) : '/'}&rememberMe=true&distinctId=${Math.random().toString(36).slice(-9)}`)
}

export default signIn
