import * as React from 'react'
import IconType from './iconType'

const ArrowRotateRightIcon = (props: IconType) => {

  return (
    <svg
      className={props.className}
      height={props.h}
      width={props.w}
      viewBox="0 0 512 512"
      >
      <path fill="currentColor" d="M496 72v128C496 213.3 485.3 224 472 224h-128c-13.19 0-24-10.66-24-23.98c0-6.247 2.432-12.39 7.033-16.99L379.2 130.9C346.9 99.29 303.1 80 256 80C158.1 80 80 158.1 80 256s78.97 176 176 176c81.21 0 107.1-46.34 127.1-46.34c10.18 0 24.02 8.003 24.02 24.01c0 25.11-75.1 70.27-152.1 70.27c-123.5 0-223.9-100.4-223.9-223.9s100.4-223.1 223.9-223.1c60.03 0 116 24.58 157.1 64.95l41.1-41.1c4.601-4.601 10.74-7.019 16.99-7.019C485.4 48.01 496 58.83 496 72z"
      />
    </svg>
  )
}

export default ArrowRotateRightIcon
