import BannerIcon from 'components/utils/Icons/BannerIcon'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import HistoryIcon from 'components/utils/Icons/HistoryIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import BannerCreation, { charLimit } from './BannerCreation'
import { Button } from 'components/elements/Button'
import ArrowBackIcon from 'components/utils/Icons/ArrowBackIcon'
import ArrowForwardIcon from 'components/utils/Icons/ArrowForwardIcon'
import { BannerStateType } from './BannerDetails'
import BannerCreationSteps from './BannerCreationSteps'
import BannerProductCodeView from './BannerProductCodeView'
import ReviewAndLaunchView from './ReviewAndLaunchView'
import CheckIcon from 'components/utils/Icons/CheckIcon'
import ArrowRotateRightIcon from 'components/utils/Icons/ArrowRotateRightIcon'
import { openLoader, showModal, showSnackbar } from 'components/snackbar'
import LaunchModal from './LaunchModal'
import { useMutation } from '@apollo/client'
import { omit, pickBy } from 'ramda'
import BannerHistoryView from './BannerHistoryView'
import { OrganizationProductType } from 'models/organization'
import { CREATE_BANNER, EDIT_BANNER } from 'mutations/banners'
import i18n from 'components/i18n'
import EditPenIcon from 'components/utils/Icons/EditPenIcon'

export const defaultBannerState: any = {
  bannerId: '',
  type: 'text',
  bannerText: '',
  bannerColor: '#FFFFFF',
  ctaButton: null,
  showToPlatform: [],
  showToProduct: [],
  enabled: true,
  expirationTime: Number(new Date()) + 30 * 24 * 60 * 60 * 1000,
  minRangeCopy: null,
  maxRangeCopy: null,
  dismissForDays: 7,
}
export const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
export const regex = new RegExp(expression)
export const expressionBannerId = /^[a-zA-Z0-9-_]+$/
export const regexBannerId = new RegExp(expressionBannerId)


const BannersTabs = () => {

  const [step, setStep] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const [cta, setCta] = useState(false)
  const [isDate, setIsDate] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [state, setState] = useState<BannerStateType>(defaultBannerState)
  const platformChecker: any = ['web', 'dashboard']

  const orgProducts: OrganizationProductType[] = ['FT', 'CR', 'UL', 'XL', 'VL', 'CL', 'GT', 'OP', 'IN', 'DM']

  const clearState = () => {
    setState(defaultBannerState)
    setCta(false)
    setIsDate(false)
    setStep(0)
  }

  useEffect(
    () => {
      if (isModalOpen) {
        setIsModalOpen(false)
      }
    },
    [state],
  )

  const button1Cn = clsx({
    'py-4 px-6 block hover:text-listenblue focus:outline-none border-b-2 border-solid border-transparent': true,
    'text-listenblue border-listenblue font-medium': location.hash === '#create',
    'text-medgray font-medium':location.hash === '#history',
  })

  const button2Cn = clsx({
    'py-4 px-6 block hover:text-listenblue focus:outline-none border-b-2 border-solid border-transparent': true,
    'text-listenblue border-listenblue font-medium':location.hash === '#history',
    'text-medgray font-medium': location.hash === '#create',
  })

  const bannerIconCn = clsx({
    'mx-3': true,
    'fill-listenblue': location.hash === '#create',
    'fill-medgray':location.hash === '#history',
  })

  const historyIconCn = clsx({
    'mx-3': true,
    'fill-listenblue':location.hash === '#history',
    'fill-medgray': location.hash === '#create',
  })

  const setTab = (tab: number) => {
    if (tab === 0) {
      navigate('#create')
      clearState()
    }
    if (tab === 1) {
      navigate('#history')
    }
  }

  useEffect(
    () => {
      if (location.hash === '#create') {
        clearState()
      }
    },
    [location],
  )

  const launchModal = async () => {
    const modal = showModal({
      title: 'Your banners have been successfully launched!',
      hideElements: ['actions'],
      content: [{
        element: (
          <LaunchModal
            setCta={setCta}
            setIsDate={setIsDate}
            setTab={setTab}
            setState={setState}
            state={state}
            setStep={setStep}
            setIsModalOpen={setIsModalOpen}
            onClose={() => {
              modal.close()
            }}
          />
        ),
      }],
    })
  }

  const [create, createRes] = useMutation(CREATE_BANNER, {
    onCompleted: () => showSnackbar(i18n.t('success'), 3000, 'success'),
  })
  const [edit, editRes] = useMutation(EDIT_BANNER, {
    onCompleted: () => showSnackbar(i18n.t('success'), 3000, 'success'),
  })

  return (
    <>
      <nav className="text-sm leading-5 border border-solid border-lightwarmgray rounded-t-lg bg-white flex flex-col sm:flex-row">
        <button className={button1Cn}
          onClick={
            () => {
              setTab(0)
            }
          }
        >
          <div className="flex">
            <div>
              <BannerIcon className={bannerIconCn} w={18} h={18} />
            </div>
            <div>
              Banner creation
            </div>
          </div>
        </button>
        <button className={button2Cn}
          onClick={
            () => {
              setTab(1)
            }
          }
        >
          <div className="flex">
            <div>
              <HistoryIcon className={historyIconCn} w={18} h={18} />
            </div>
            <div>
              Active/Inactive banners
            </div>
          </div>
        </button>
        {location.hash === '#edit' && <button
          className="py-4 px-6 block focus:outline-none border-b-2 border-solid text-listenblue border-listenblue font-medium"
          onClick={e => e.preventDefault()}
        >
          <div className="flex">
            <div>
              <EditPenIcon className="mx-3 fill-listenblue" w={18} h={18} />
            </div>
            <div>
              Edit Banner
            </div>
          </div>
        </button>}
      </nav>
      <section className="bg-white border-x border-b rounded-b-lg border-lightwarmgray">
        {(location.hash === '#create' || location.hash === '#edit') && <BannerCreationSteps
        isModalOpen={isModalOpen}
        step={step} />}

        {(location.hash === '#create' && step === 0) && <BannerCreation
          state={state}
          isDate={isDate}
          setIsDate={setIsDate}
          setState={setState}
          cta={cta}
          setCta={setCta} />}
        {(location.hash === '#create' && step === 1) && <BannerProductCodeView
          state={state}
          setState={setState} />}
        {(location.hash === '#create' && step === 2) && <ReviewAndLaunchView
          state={state}
          setState={setState}
          cta={cta}
          isDate={isDate} />}

        {location.hash === '#history' && <BannerHistoryView
          setState={setState}
          setCta={setCta}
          setIsDate={setIsDate} />}

        {(location.hash === '#edit' && step === 0) && <BannerCreation
          state={state}
          isDate={isDate}
          setIsDate={setIsDate}
          setState={setState}
          cta={cta}
          setCta={setCta} />}
        {(location.hash === '#edit' && step === 1) && <BannerProductCodeView
          state={state}
          setState={setState} />}
        {(location.hash === '#edit' && step === 2) && <ReviewAndLaunchView
          isDate={isDate}
          state={state}
          setState={setState}
          cta={cta} />}

      </section>
      {(location.hash === '#create' || location.hash === '#edit') && step !== 2 ?
        <footer className="flex justify-end items-end mt-4 py-4 fixed bottom-0 right-0 left-0 bg-white border border-sodlid border-[#E3E4E5]">
          <div className="mr-2">
            <Button
              className={`${step === 0 ? 'text-lightgray border-lightgray' : 'text-medgray border-medgray'}`}
              text="Back"
              icon={<ArrowBackIcon className={`${step === 0 ? 'fill-lightgray' : 'fill-medgray'} "w-5 h-5"`}/>}
              onClick={
                () => {
                  if (step > 0) {
                    setStep(step - 1)
                  }
                  if (isModalOpen) {
                    setIsModalOpen(false)
                  }
                }
              }
            />
          </div>
          <div>
            <Button
              text="Next"
              type="secondary"
              className="mr-36"
              rightIcon={<ArrowForwardIcon className="w-5 h-5"/>}
              onClick={
                (e) => {
                  if (step < 2) {
                    setStep(step + 1)
                  }
                  if ((state.ctaButton && state.type === 'text') && (cta && !state.ctaButton.url.match(regex))) {
                    e.preventDefault()
                    setStep(0)
                    alert('Invalid link format')
                  }
                  if (state.ctaButton && (cta && !state.ctaButton.label.length)) {
                    setStep(0)
                    alert('Button text is mandatory')
                  }

                  if (!state.bannerId.match(regexBannerId)) {
                    setStep(0)
                    alert('Banner Id allows only alphanumeric, dash and underscore without spaces')
                  }

                  if (state.bannerId.length < 3 || state.bannerText.length < 3) {
                    e.preventDefault()
                    setStep(0)
                    alert(`${state.bannerId.length < 3 ? 'Banner Id should be longer than 3 characters' : state.bannerText.length < 3 ? 'Banner text should be longer than 3 characters' : ''}`)
                  }
                  if (state.bannerText.length > charLimit) {
                    setStep(0)
                    alert('Banner text should not be longer than 280 characters')
                  }
                  if (!platformChecker.some(platform => state.showToPlatform.includes(platform))) {
                    setStep(0)
                    alert('Please choose the platform where the banner will be displayed')
                  }
                  if (step === 1 && !orgProducts.some(p => state.showToProduct.includes(p))) {
                    e.preventDefault()
                    alert('Plese choose at least one product')
                    setStep(1)
                  }
                }
              }
            />
          </div>
        </footer>
        : (location.hash === '#create' || location.hash === '#edit') &&
        <footer className="flex justify-between items-end mt-4 py-4 fixed bottom-0 right-0 left-0 bg-white border border-sodlid border-[#E3E4E5]">
          <div className="bottom-0 left-10">
            <Button
              type="destructive"
              text="Start Over"
              className="ml-24"
              disabled={location.hash === '#edit'}
              icon={<ArrowRotateRightIcon className="fill-coral-light w-5 h-5"/>}
              onClick={
                () => {
                  setState({
                    ...defaultBannerState,
                  })
                  setStep(0)
                }
              }
            />
          </div>
          <div className="flex">
            <div className="mr-2">
            <Button
              className="text-medgray border-medgray"
              text="Back"
              icon={<ArrowBackIcon className="fill-medgray w-5 h-5"/>}
              onClick={
                () => {
                  if (step > 0) {
                    setStep(step - 1)
                  }
                  if (isModalOpen) {
                    setIsModalOpen(false)
                  }
                }
              }
            />
            </div>
            {location.hash === '#create' && <div>
              <Button
                text="Launch banner(s)"
                type="secondary"
                className="mr-36"
                icon={<CheckIcon className="w-5 h-5"/>}
                onClick={
                  () => {
                    const loadingModal = openLoader()
                    setIsModalOpen(true)
                    create({
                      variables: {
                        bannerData: pickBy(v => v != null, {
                          ...state,
                          expirationTime: isDate ? state.expirationTime : Number(new Date()) + 488339 * 24 * 60 * 60 * 1000,
                        }),
                      },
                    }),
                    setTimeout(
                      () => {
                        loadingModal.close()
                        launchModal()
                      },
                      4000)
                  }
                }
              />
            </div>}
            {location.hash === '#edit' && <div>
              <Button
                text="Save changes"
                type="secondary"
                className="mr-36"
                icon={<CheckIcon className="w-5 h-5"/>}
                onClick={
                  () => {
                    edit({
                      variables: {
                        bannerId: state.bannerId,
                        bannerData: omit(['bannerId', 'type'], pickBy(v => v != null, {
                          ...state,
                          ctaButton: omit(['__typename'], state.ctaButton),
                          expirationTime: isDate ? state.expirationTime : Number(new Date()) + 488339 * 24 * 60 * 60 * 1000,
                        })),
                      },
                    })
                  }
                }
              />
            </div>}
          </div>
        </footer>
      }
    </>
  )
}

export default BannersTabs
