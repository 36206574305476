import { useQuery } from '@apollo/client'
import { UserAuthenticated } from 'models/user'
import { GET_USER_METADATA } from 'queries/user'
import React from 'react'
import spacetime from 'spacetime'
import { withAuthUser } from './AuthUserWrapper'
import i18n from './i18n'

type PropsIn = {
  userAuthenticated?: UserAuthenticated|null,
  feature?: string,
}
export function withNewFeatureBadgeShown<TProps>(Cmp: React.ComponentType<TProps>) {
  const CmpRet = (props: TProps&PropsIn) => {
    const {loading: metaLoading, error: metaError, data: metaData } = useQuery(GET_USER_METADATA, {
      variables: { userId: props.userAuthenticated?.id },
      skip: !props.userAuthenticated || !props.feature,
    })

    if (metaLoading) {
      return <div className="bg-transparent">{i18n.t('loading')}</div>
    }

    if (props.feature && metaData?.user?.metadata?.general?.features?.[props.feature]?.firstSeen) {
      const firstSeen = metaData?.user?.metadata?.general?.features?.[props.feature]?.firstSeen
      const hideTimeExpired = spacetime(Number(firstSeen)).diff(spacetime.now(), 'week') >= 1
      return <Cmp {...props} hideTimeExpired={hideTimeExpired} />
    }
    return <Cmp {...props} />
  }
  return withAuthUser(CmpRet)
}
