import * as React from 'react'
import IconType from './iconType'

const TravelIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 640 512"
      >
      <path d="M375.1 104L448.1 65.35C470.7 53.95 494.9 48 519.5 48H570.3C616.6 48 647.2 96.1 627.7 138.1C606 184.4 569.8 222.4 524.5 246.1L274.1 377.6C266.1 381.8 257.2 384 248.1 384H126.7C110.4 384 94.84 376.9 84.2 364.4L11.11 279.2C-5.821 259.4 .32 229 23.6 217.4L55.76 201.3C70.73 193.8 88.26 193.4 103.5 200.2L151.1 221.4L192 199.9L85.32 132.8C57.89 115.5 61.32 74.45 91.25 61.98L135.1 43.33C156.1 34.94 178.7 34.33 199.3 41.63L375.1 104zM123.1 100.4L288.2 203.8L152.9 274.6L84.05 244.1C81.87 243.1 79.36 243.2 77.23 244.2L54.23 255.7L120.6 333.2C122.2 334.1 124.4 336 126.7 336H248.1C249.4 336 250.7 335.7 251.8 335.1L502.2 203.6C538.2 184.7 566.1 154.6 584.2 117.8C588.9 107.6 581.5 95.1 570.3 95.1H519.5C502.7 95.1 486.1 100.1 471.2 107.9L378.8 156.3L183.2 86.87C173.9 83.55 163.6 83.82 154.5 87.64L123.1 100.4zM616 464C629.3 464 640 474.7 640 488C640 501.3 629.3 512 616 512H24C10.75 512 0 501.3 0 488C0 474.7 10.75 464 24 464H616zM66.49 222.8L55.76 201.3L66.49 222.8zM84.2 364.4L101.8 349.4z"
      />
    </svg>
  )
}

export default TravelIcon
