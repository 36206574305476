import React from 'react'
import { BannerStateType } from './BannerDetails'

type BannerPlatformsProps = {
  state: BannerStateType
  setState: (value: BannerStateType) => void,
}



const BannerPlatforms = ({state, setState}: BannerPlatformsProps) => {

  const handleChange = (field: ('ios'|'android'|'web'|'dashboard')) => {
    if (!state.showToPlatform.includes(field)) {
      const array = state.showToPlatform.concat(field)
      setState({
        ...state,
        showToPlatform: array,
      })
    } else {
      const array = state.showToPlatform
      const index = state.showToPlatform.indexOf(field)
      array.splice(index, 1)
      setState({
        ...state,
        showToPlatform: array,
      })
    }
  }

  return (
    <>
      <section  className="mt-6 p-4 bg-[#F3F4F5] rounded">
          <div className="items-center">
            <div className="text-sm text-medgray font-bold mb-2">
              Select which platform(s) you would like your banner to be displayed:
            </div>
            <div className="flex ml-10 mb-1">
              <input
                type="checkbox"
                className="text-lake focus:ring-0 h-5 w-5 rounded-sm form-checkbox mr-4"
                id="gnowbe-web"
                checked={state.showToPlatform.includes('web')}
                onChange={() => handleChange('web')}
              />
              <label htmlFor="gnowbe-web" className="text-medgray text-sm mr-4">Gnowbe Web</label>
            </div>
            <div className="flex ml-10 mt-2">
              <input
              type="checkbox"
              className="text-lake focus:ring-0 h-5 w-5 rounded-sm form-checkbox mr-4"
              id="gnowbe-dashboard"
              checked={state.showToPlatform.includes('dashboard')}
              onChange={() => handleChange('dashboard')}
            />
              <label htmlFor="gnowbe-dashboard" className="text-medgray text-sm mr-4">Gnowbe Dashboard</label>
            </div>
          </div>
      </section>
      <div className="bg-white h-0.5 rounded my-2"></div>
    </>
  )
}

export default BannerPlatforms
