import { gql } from '@apollo/client'

export const DISABLE_OR_ENABLE_BANNER = gql`
  mutation UpdateNotificationBanner($bannerId: String!, $bannerData: BannerUpdate!) {
    banner: updateNotificationBanner(bannerId: $bannerId, bannerData: $bannerData) {
      bannerId
      enabled
    }
  }
`

export const DELETE_BANNER = gql`
  mutation UpdateNotificationBanner($bannerId: String!, $bannerData: BannerUpdate!) {
    banner: updateNotificationBanner(bannerId: $bannerId, bannerData: $bannerData) {
    bannerId
    deleted
    }
  }
`

export const CREATE_BANNER = gql`
  mutation CreateNotificationBanner($bannerData: BannerCreate!) {
    banner: createNotificationBanner(bannerData: $bannerData) {
      bannerId
      bannerText
      type
      creatorId
    }
  }
`

export const EDIT_BANNER = gql`
  mutation UpdateNotificationBanner($bannerId: String!, $bannerData: BannerUpdate!) {
    banner: updateNotificationBanner(bannerId: $bannerId, bannerData: $bannerData) {
    bannerId
    }
  }
`
