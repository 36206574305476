import { Button } from 'components/elements/Button'
import React from 'react'
import { BannerStateType } from './BannerDetails'
import { defaultBannerState } from './BannersTabs'

type LaunchModalProps = {
  setTab: (value: number) => void,
  onClose: () => void,
  setState: (value: BannerStateType) => void,
  setStep: (value: number) => void,
  setIsModalOpen: (value: boolean) => void,
  setCta: (val: boolean) => void,
  setIsDate: (value: boolean) => void,
  state: BannerStateType,
}

const LaunchModal = ({
  setTab,
  onClose,
  setState,
  setStep,
  setIsModalOpen,
  setIsDate,
  setCta,
  state,
}: LaunchModalProps) => {

  return (
    <>
      <div className="text-deepgray mt-[-16px] mb-6">
        They will appear on the Gnowbe Web and/or Dashboard page immediately.
      </div>
      <div className="flex justify-end">
        <Button
          type="text"
          text="Create another banner"
          className="text-listenblue"
          onClick={
            () => {
              setTab(0)
              onClose()
              setIsModalOpen(false)
            }
          }

        />
        <Button
          type="secondary"
          text="OK"
          size="medium"
          className="px-10 ml-4"
          onClick={
            () => {
              setTab(1)
              setIsDate(false)
              setCta(false)
              setStep(0)
              onClose()
              setIsModalOpen(false)
              setState({
                ...defaultBannerState,
              })
            }

          } />
      </div>
    </>
  )
}

export default LaunchModal
