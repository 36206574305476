import Joi from '@hapi/joi'
import i18n from 'components/i18n'
import { CompanyAccessCodes, CompanyBasic, getCompanyFragment, getCompanyFragmentName } from './company'
import { EngagementSummary, getEngagementSummaryFragment, getEngagementSummaryFragmentName } from './engagement_summary'
import { OrganizationBasic, OrganizationSettings, orgHasFeatureFlag } from './organization'
import { UserBasic, UserBasicExtra, getUserBasicExtraFragment, getUserBasicExtraFragmentName, getUserBasicFragment, getUserBasicFragmentName } from './user'

export enum ChaptersUnlocked {
  daily = 'Daily',
  streak = 'Streak',
  unlocked = 'Unlocked',
}

export enum CourseTypeEnum {
  gnowbe = 'Gnowbe',
  scorm = 'Scorm',
}

export type CourseDistributionParams = {
  courseId: string,
  manualReviewsSkipEndorsment?: boolean,
  showHintsAfterAssessmentsCompleted?: boolean,
  hideAssessmentsAfterCompleted?: boolean,
  futureDistribution?: number,
  absoluteDeadline?: number,
  relativeDeadline?: number,
  dueDate?: number,
}
export type CourseDistributionParamsInput = {
  courseId: string,
  manualReviewsSkipEndorsment: boolean,
  showHintsAfterAssessmentsCompleted: boolean,
  hideAssessmentsAfterCompleted: boolean,
}

export type CourseBasic = {
  id: string;
  title: string;
  imageUrl?: string;
  curatorName?: string;
  curatorTitle?: string;
  curatorCompany?: string;
  curatorPhotoUrl?: string;
  description: string;
  graduationGrade?: number;
  manualReviews?: boolean;
  contentPartnerId?: string;
  chaptersUnlocked?: ChaptersUnlocked;
  passingGrade?: number;
  canRetakeActionAssessments?: boolean;
  maxActionAssessmentsRetakes?: number;
  workbookDisabled?: boolean;
  allowMediaDownload?: boolean;
  denyContentCopy?: boolean;
  showActionAssessmentsResultsPerAction?: boolean;
  curatorSignatureDisabled?: boolean;
  curatorBio?: string;
  curatorChatEnabled?: boolean;
  isTemplate: boolean;
  tagline?:string
  hasAssessments: boolean;
}

export type CourseTags = {
  tags: string[];
}

export type CourseOwnerType = 'organization' | 'user'


export type CourseOwner = {
  owner: {
    id: string,
    organization?: OrganizationBasic,
    type: CourseOwnerType,
    user?: UserBasic,
  },
}

export type FutureDistribution = {
  futureDistribution: number,
  absoluteDeadline?: number,
  relativeDeadline?: number,
  dueDate?: number,
}


export type CourseMeta = {
  published: boolean;
  status2?: 'draft' | 'ready' | 'live' | 'archived';
  category?: string;
  categoryText?: string;
  skills?: string[];
  difficulty?: string;
}

export type CourseAvailability = {
  parentCourseId?: string;
  masterCourseId?: string;
  allowPublicRatingResults: boolean;
  availableToCompanies?: string[];
  availableToOrganizations?: string[];
}

export type GetCoursesVariables = {
  searchText?: string,
  skip?: number,
  limit?: number,
  categories?: string[],
  difficulties?: string[],
  onlyAvailableToCompanyId?: string,
  onlyIds?: string[],
}

export type CourseCounts = {
  chaptersCount: number;
  actionsCount: number;
  interactionTime: number;
}

export type CourseType = {
  type: CourseTypeEnum,
}

export type CourseTestCompany = {
  testCompany: (CompanyBasic&CompanyAccessCodes)|undefined;
}

export type CourseEditors = {
  editors: UserBasicExtra[];
}

export type CourseResponseType = 'with_tags'|'with_meta'|'with_availability'|'with_counts'|'with_testcompany'|'with_editors'|'with_type'|'with_owner'

export const getCourseFragmentName = (types: CourseResponseType[]) => {
  return `CourseFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getCourseFragment = (types: CourseResponseType[]) => `
  ${types.indexOf('with_testcompany') >= 0 ? getCompanyFragment(['with_accesscodes']) : ''}
  ${types.indexOf('with_editors') >= 0 ? getUserBasicExtraFragment() : ''}
  ${types.indexOf('with_owner') >= 0 ? getUserBasicFragment() : ''}
  fragment ${getCourseFragmentName(types)} on Course {
    id
    title
    imageUrl
    curatorName
    curatorTitle
    curatorCompany
    curatorPhotoUrl
    ${types.indexOf('with_tags') >= 0 ? `
    tags` : ''}
    ${types.indexOf('with_meta') >= 0 ? `
    published
    status2
    category
    categoryText
    skills
    description
    difficulty
    ` : ''}
    ${types.indexOf('with_availability') >= 0 ? `
    allowPublicRatingResults
    parentCourseId
    masterCourseId
    availableToCompanies
    availableToOrganizations` : ''}
    ${types.indexOf('with_counts') >= 0 ? `
    chaptersCount
    actionsCount
    interactionTime` : ''}
    ${types.indexOf('with_testcompany') >= 0 ? `
    testCompany {
      ...${getCompanyFragmentName(['with_accesscodes'])}
    }` : ''}
    ${types.indexOf('with_editors') >= 0 ? `
    editors {
      ...${getUserBasicExtraFragmentName()}
    }` : ''}
    graduationGrade
    contentPartnerId
    manualReviews
    chaptersUnlocked
    passingGrade
    canRetakeActionAssessments
    maxActionAssessmentsRetakes
    workbookDisabled
    allowMediaDownload
    denyContentCopy
    showActionAssessmentsResultsPerAction
    curatorSignatureDisabled
    curatorBio
    curatorChatEnabled
    isTemplate
    ${types.indexOf('with_type') >= 0 ? `
    type` : ''}
    tagline
    hasAssessments
    ${types.indexOf('with_owner') >= 0 ? `
    owner {
      type
      id
      organization {
        id
        name
      }
      user {
        ...${getUserBasicFragmentName()}
      }
    }` : ''}
  }
`

export type CourseUpdate = Pick<
  CourseBasic&CourseTags&CourseMeta&CourseAvailability&CourseOwner,
  'category'|'categoryText'|'skills'|'difficulty'|'tags'|'status2'|'published'|'allowPublicRatingResults'|'contentPartnerId'|'isTemplate'|'owner'
>

export const CourseUpdate = Joi.object().keys({
  category: Joi.string().label('Category').allow('', null),
  categoryText: Joi.string().label('Category Text').allow('', null),
  skills: Joi.array().label('Skills').items(Joi.string().label('Skill')).allow('', null),
  difficulty: Joi.string().label('Difficulty').allow('', null),
  tags: Joi.array().label('Hashtags').items(Joi.string().label('Tag')).allow('', null),
  status2: Joi.string().label('Status').allow('', null),
  published: Joi.boolean().label('Published').required(),
  allowPublicRatingResults: Joi.boolean().allow('', null),
  contentPartnerId: Joi.string().label('Partner').allow(''),
  isTemplate: Joi.boolean().label('Template'),
  owner: Joi.object().label('Owner').allow(null),
})

export type CompanyCoursesUpdate = {
  addedCourseIds?: string[],
  removedCourseIds?: string[],
  addedDisabledCourseIds?: string[],
  removedDisabledCourseIds?: string[],
  updatedCoursesDistributionParams?: CourseDistributionParamsInput[],
}

export type OrganizationCourse = {
  companiesCount: number, // Companies count course is distributed to
  companyIds: string[], // Company ids course is distributed to
  course: CourseBasic&CourseType&CourseEditors&CourseCounts,
  courseId: string,
  engagementSummary: EngagementSummary,
  id: string,
  isGnowbeLearnCourse: boolean,
  onlyVisibleInAnalyticsInMetrics: boolean,
  organizationId: string,
}

export const getOrganizationCourseFragmentName = () => {
  return 'OrganizationCourseFields'
}

export const getOrganizationCourseFragment = () => {
  return `
  ${getCourseFragment(['with_type', 'with_editors', 'with_counts'])}
  ${getEngagementSummaryFragment()}
  fragment ${getOrganizationCourseFragmentName()} on OrganizationCourse {
    id
    companiesCount
    companyIds
    course {
      ...${getCourseFragmentName(['with_type', 'with_editors', 'with_counts'])}
    }
    courseId
    engagementSummary {
      ...${getEngagementSummaryFragmentName()}
    }
    isGnowbeLearnCourse
    onlyVisibleInAnalyticsInMetrics
    organizationId
  }
  `
}

export function notAllowedDistributeManualReviews(course: CourseBasic, organization: OrganizationBasic&OrganizationSettings) {
  return course.manualReviews
    && !orgHasFeatureFlag(organization, 'manualReviews')
}
export function notAllowedDistributeScorm(course: CourseBasic&CourseType, organization: OrganizationBasic&OrganizationSettings) {
  return CourseTypeEnum[course.type] === CourseTypeEnum.scorm
    && !orgHasFeatureFlag(organization, 'scorm')
}
export function notAllowedDistributeAssessment(course: CourseBasic, organization: OrganizationBasic&OrganizationSettings) {
  return course.hasAssessments && !orgHasFeatureFlag(organization, 'assessments')
}
export function notAllowedDistributeBecauseOfFeature(course: CourseBasic&CourseType, organization: OrganizationBasic&OrganizationSettings) {
  return notAllowedDistributeManualReviews(course, organization)
    || notAllowedDistributeScorm(course, organization)
    || notAllowedDistributeAssessment(course, organization)
}
export function getErrorMessageIfNotAllowedDistribute(course: CourseBasic&CourseType, organization: OrganizationBasic&OrganizationSettings) {
  if (notAllowedDistributeManualReviews(course, organization)) {
    return i18n.t('not_allowed_distribute_course_with_manual_review')
  }
  if (notAllowedDistributeAssessment(course, organization)) {
    return i18n.t('not_allowed_distribute_course_with_assessment')
  }
  if (!course.type) return ''
  if (notAllowedDistributeScorm(course, organization)) {
    return i18n.t('not_allowed_distribute_course_with_scorm')
  }
  return ''
}
