import { gql } from '@apollo/client'

export const USER_SUBSCRIPTION = gql`
  subscription ListenUserChanges($userId: String!) {
    listenUserChanges(userId: $userId) {
      ... on UserUpdated {
        id
        user {
          id
          agentCompanies
          editorOrganizations
          editorCompanies
          managerOrganizations
          partnerOrganizations
          billingManagerOrganizations
        }
      }
    }
  }
`
// todo: billingService
// billingManagerOrganizations
