import React from 'react'
import { BannerStateType } from './BannerDetails'
import BannerProductCode from './BannerProductCode'

type BannerProductCodeViewProps = {
  state: BannerStateType
  setState: (value: BannerStateType) => void,
}

const BannerProductCodeView = ({state, setState}: BannerProductCodeViewProps) => {


  return (
      <div className="bg-white px-8 rounded-b-lg mb-52">
        <div className="font-medium text-sm text-listenblue pt-8 uppercase">
          Choose product code
        </div>
          <div className="font-normal text-medgray text-sm mb-4">
            Select at least one product code to feature your banner in. Your banner will be displayed to everyone in that product code.
          </div>
          <BannerProductCode state={state} setState={setState}/>
      </div>
  )
}

export default BannerProductCodeView
