import { gql } from '@apollo/client'
import { client } from 'App'
import { produce } from 'immer'
import { FileImport, FileImportType, getFileImportFragment, getFileImportFragmentName } from 'models/fileImport'

export type ListenFileImportChangesVars = {
  companyId?: string,
  organizationId: string,
  importId: string,
}
export const LISTEN_FILE_IMPORT_CHANGES = gql`
  ${getFileImportFragment()}
  subscription ListenFileImportChanges(
    $companyId: String,
    $organizationId: String!,
    $importId: String!
  ) {
    status: listenFileImportChanges(
      companyId: $companyId,
      organizationId: $organizationId,
      importId: $importId
    ) {
      ...${getFileImportFragmentName()}
    }
  }
`
export const GET_FILE_IMPORTS_LIST = gql`
  query GetFileImportsList {
    fileImportsList @client {
      id
      companyId
      createdAt
      errors
      fileName
      finishedAt
      organizationId
      processedCount
      progress
      status
      type
      updatedAt
    }
  }
`
const addRemoveFileImportId = (fileImportData: FileImport) => {

  const cacheres = client.readQuery<{fileImportsList: FileImport[]}>({
    query: GET_FILE_IMPORTS_LIST,
  })
  fileImportData['__typename'] = 'FileImportList'
  client.writeQuery({
    query: GET_FILE_IMPORTS_LIST,
    data: produce(cacheres, (draft) => {
      const fileImportIndex = draft?.fileImportsList.findIndex(e => e.id === fileImportData.id)
      const test = fileImportData
      if (fileImportIndex === -1) {
        draft?.fileImportsList.unshift(fileImportData)
      } else {
        draft?.fileImportsList[fileImportIndex] = fileImportData
      }
    }),
  })
}
export const addFileImportList = (
  fileImportId: string,
  organizationId: string,
  type: FileImportType,
  subType: string,
  companyId?: string,
) => {
  addRemoveFileImportId({
    id: fileImportId,
    fileName: 'waiting...',
    progress: 0,
    status: 'inprogress',
    createdAt: 0,
    organizationId,
    companyId: companyId || '',
    type,
    updatedAt: 0,
    finishedAt: 0,
    processedCount: 0,
    errors: [],
    subType,
  })
  const subscription = client.subscribe({
    query: LISTEN_FILE_IMPORT_CHANGES,
    variables: {
      importId: fileImportId,
      companyId,
      organizationId,
    },
  }).subscribe({
    next: ({errors, data}) => {
      if (errors) {
        console.error(errors[0].message)
        subscription.unsubscribe()
      }
      if (data.status.status === 'done') {
        subscription.unsubscribe()
      }
      addRemoveFileImportId(data.status)
    },
    error: error => console.error(error),
  })
}

