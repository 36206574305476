import React from 'react'
import { equals, groupBy, mergeAll, sortBy } from 'ramda'
import i18n from './i18n'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import clsx from 'clsx'
import PopoverOwn from './Popover'
import ChevronDownIcon from '@heroicons/react/24/solid/ChevronDownIcon'
import XIcon from '@heroicons/react/24/solid/XMarkIcon'
import RefreshIcon from '@heroicons/react/24/solid/ArrowPathIcon'
import SearchIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon'
import EyeOffIcon from '@heroicons/react/24/solid/EyeSlashIcon'
import { useDebounce } from './utils'
import TableFilter, { FilterHeader, FilterRow } from './TableFilter'
import { Button, ButtonType } from './elements/Button'
import Tippy from '@tippyjs/react'


type ButtonToolbarType = {
  buttonText: string,
  tooltip?: string,
  componentType: 'button',
  type: ButtonType,
  disabled?: boolean,
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  role?: string,
  onClick?: (e: React.SyntheticEvent<any>) => void,
  hidden?: boolean,
  listItemStyle?: string,
}
type PopoverToolbarType = {
  buttonText: string,
  tooltip?: string,
  componentType: 'popover',
  type: ButtonType,
  popoverItems: {
    text: string,
    onClick: () => void,
    disabled?: boolean,
    role?: string,
    tooltip?: string,
    hidden?: boolean,
    icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  }[],
  disabled?: boolean,
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  role?: string,
  onClick?: (e: React.SyntheticEvent<any>) => void,
  hidden?: boolean,
}
type ComponentToolbarType = {
  type: ButtonType | 'other',
  tooltip?: string,
  componentType: 'component',
  component: React.ReactNode,
  disabled?: boolean,
  role?: string,
  hidden?: boolean,
  onClick?: (e: React.SyntheticEvent<any>) => void,
}
type ToolbarCommandType<T> =
  T extends 'button' ? ButtonToolbarType :
  T extends 'popover' ? PopoverToolbarType :
  T extends 'component' ? ComponentToolbarType :
  never

export type ToolbarCommands = ToolbarCommandType<'button'|'popover'|'component'>[]
export type TableToolbarProps = {
  columns?: any,
  searchText?: {
    onChange: (filterText: string) => void;
    placeholder?: string,
    textInitial?: string,
    className?: string,
    disabled?: boolean,
  },
  onRefresh?: () => void,
  exportData?: {
    onExportAll?: () => void,
    onExportFiltered?: () => void,
    additionalExports?: {text: string, disabled?: boolean,
      icon?: React.ReactElement,
      onClick: () => void, key: string}[],
  },
  getCommands?: ToolbarCommands,
  getCommandsLeft?: () => React.ReactNode,
  hideLimitMessage?: boolean,
  filters?: {
    headers: FilterHeader[],
    values?: FilterRow[],
    onFilterChange: (rows: FilterRow[]) => void,
  },
  actionButtonName?: string,
}

const MIN_FILTER_TEXT_CHARS = 3

const buttonTypeAvailable = ['other', 'secondary', 'primary']
const byButtonType = groupBy((toolbarCommand: ComponentToolbarType|ButtonToolbarType|PopoverToolbarType) => {
  if (toolbarCommand.componentType === 'button') {
    return buttonTypeAvailable.includes(toolbarCommand.type)
      ? toolbarCommand.type
      : toolbarCommand.type !== 'default'
        ? 'secondary'
        : 'other'
  }
  return buttonTypeAvailable.includes(toolbarCommand.type)
    ? toolbarCommand.type
    : 'other'
})

export const TableToolbar = (props: TableToolbarProps) => {
  const [filterText, setFilterText] = React.useState<string>(props.searchText?.textInitial || '')
  const filterTextDebounced = useDebounce(filterText, 2000)

  const filterTextChanged = () => {
    if (!props.searchText) return
    const filterTextTmp = filterText.trim()
    // at least 4 characters
    if (filterTextTmp.length > 0 && filterTextTmp.length < MIN_FILTER_TEXT_CHARS && !props.hideLimitMessage) return
    props.searchText.onChange(filterTextTmp)
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) => {
    setFilterText(e.target.value)
  }

  const handleFilterKeyEvent = (e: React.KeyboardEvent<HTMLTextAreaElement|HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      filterTextChanged()
    }
  }

  const handleRefresh = (e?: React.SyntheticEvent) => {
    e && e.preventDefault()
    props.onRefresh && props.onRefresh()
  }

  const handleClear = (e?: React.SyntheticEvent) => {
    e && e.preventDefault()
    setFilterText('')
    props.searchText && props.searchText.onChange('')
  }

  React.useEffect(
    () => {
      filterTextChanged()
    },
    [filterTextDebounced],
  )

  const filterTextFormatted = /^_new=[a-z0-9]+$/i.test(filterText)
    ? 'Newly created'
    : filterText
  const limitFilterText = filterTextFormatted.length > 0
    && filterText.length < MIN_FILTER_TEXT_CHARS
    && !props.hideLimitMessage
  const filterPlaceholder = props.searchText && props.searchText?.disabled
    ? 'Filter (disabled)'
    : props.searchText
      ? props.searchText.placeholder
      : 'Filter'

  const numHiddenFields = props.columns?.map(c => c.isVisible).filter(c => !c).length || 0

  const getCommandsByKey = !props.getCommands
    ? null : byButtonType(props.getCommands.filter(c => !c.hidden || (c.componentType === 'component' && !c.component)))

  return <div className="flex flex-row flex-wrap items-end justify-between mb-2 mt-2">
    {!props.hideLimitMessage && <div className={`${clsx([{'text-coral': limitFilterText}])} w-full text-sm text-left`}>
      {`${i18n.t('filter_search_form_helper')}`}
    </div>}
    <div>
      <div className="relative w-full">
        <input
          onChange={handleFilterChange}
          onKeyUp={handleFilterKeyEvent}
          disabled={props.searchText?.disabled}
          value={filterTextFormatted}
          className={`${clsx([{'border-coral': limitFilterText}])} pl-10 pr-16 py-2 rounded-lg border border-solid border-gray-300 focus:outline-none focus:ring-transparent focus:shadow-outline text-medgray text-sm`}
          placeholder={filterPlaceholder} data-test="search-box" />
        <div className="absolute top-0 left-0 inline-flex items-center p-2">
          <SearchIcon className="text-gray-500 w-5 h-5" />
        </div>
        <div className="absolute top-0 right-0 inline-flex items-center p-2">
          <button
            onClick={handleClear}
            style={{ visibility: !filterText ? 'hidden' : 'visible' }}
          >
            <XIcon className="w-5 h-5" />
          </button>
          {props.onRefresh
            ? <Tippy content={<span>Refresh Data</span>} placement="right">
              <button
                onClick={handleRefresh}
                className="hover:text-gray-500 hover:shadow rounded-full"
              >
                <RefreshIcon className="w-5 h-5" />
              </button>
            </Tippy>
            : <button
              onClick={handleRefresh}
              disabled={true}
            >
              <RefreshIcon className="w-5 h-5 cursor-not-allowed text-gray-400"/>
            </button>}
        </div>
      </div>
    </div>
    <div className="flex flex-row items-center mt-1">
      {props.getCommandsLeft && <div className="flex flex-row items-start mr-2">
        {props.getCommandsLeft()}
      </div>}
      {props.filters && (
        <div className="mr-2">
          <TableFilter
            header={props.filters.headers}
            values={props.filters.values}
            onFiltersChange={props.filters.onFilterChange}
          />
        </div>
      )}
      {<PopoverOwn
        className="whitespace-nowrap"
        fullWidth={false}
        text={`${numHiddenFields} hidden fields`}
        icon={<EyeOffIcon />}
        content={<div style={{minWidth: 170}} className="bg-white whitespace-nowrap shadow-lg rounded-lg flex flex-col items-start justify-items-center px-4 py-2" data-test="hidden-fields-list">
          <ul>
            {props.columns?.filter(c => c.id !== 'selection').map((column, index) => {
              return <li className="py-2" key={column.id}>
                <label>
                  <input className="text-lake focus:ring-0 h-5 w-5 rounded-sm form-checkbox mr-4"
                    type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                  {column.Header}
                </label>
              </li>
            }) || 'No data'}
          </ul>
        </div>}
      />}
      {getCommandsByKey && sortBy(
        (k) => {
          if (k === 'other') return 1
          if (k === 'secondary') return 2
          return 3
        },
        Object.keys(getCommandsByKey)).map((key) => {
          const toolbarCmdArr = getCommandsByKey[key]
          if (key === 'secondary' && toolbarCmdArr.length > 1) {
            return <PopoverOwn
              key={`popover-toolbar-popover-secondary-actions${Math.random()}`}
              className="ml-2"
              text={`${props.actionButtonName || i18n.t('actions')}`}
              type="secondary"
              rightIcon={<ChevronDownIcon />}
              content={<div className="bg-white min-w-[150px] whitespace-nowrap shadow-lg rounded-lg flex flex-col gap-3 items-start justify-items-center">
                <ul className="w-full">
                  {toolbarCmdArr.map((popoverItem, index) => {
                    return (
                      <Tippy
                        key={`popover-toolbar-item-${Math.random()}-${index}`}
                        content={popoverItem.tooltip || undefined}
                        // visible={popoverItem.tooltip}
                        disabled={!popoverItem.tooltip}
                      >
                        <li onClick={(e) => {
                          !popoverItem.disabled && popoverItem.onClick(e)
                        }} className={`${clsx({'cursor-not-allowed text-lightgray': popoverItem.disabled})} w-full px-4 cursor-pointer hover:bg-lightwarmgray h-12 flex flex-row gap-3 items-center justify-start ${popoverItem.listItemStyle || ''}`}
                        >
                          {popoverItem.icon
                            && React.cloneElement(popoverItem.icon, {className: 'mr-2 w-5 h-5 text-listenblue'})
                          }
                          {popoverItem.buttonText}
                        </li>
                      </Tippy>
                    )
                  }) || undefined}
                </ul>
              </div>}
            />
          }
          return toolbarCmdArr.map((toolbarCmd) => {
            if (toolbarCmd.componentType === 'component' && toolbarCmd.component) {
              return <div key={`popover-toolbar-component-${Math.random()}`} className="ml-2">
                {toolbarCmd.component}
              </div>
            }
            if (toolbarCmd.componentType === 'button') {
              return (
                <Tippy
                  key={`popover-toolbar-button-${Math.random()}`}
                  content={toolbarCmd.tooltip || undefined}
                  disabled={!toolbarCmd.tooltip}
                >
                  <Button
                    className="ml-2"
                    size="medium"
                    type={toolbarCmd.type}
                    onClick={e => toolbarCmd.onClick && toolbarCmd.onClick(e)}
                    disabled={toolbarCmd.disabled}
                    text={toolbarCmd.buttonText || ''}
                    icon={toolbarCmd.icon}
                  />
                </Tippy>
              )
            }
            if (toolbarCmd.componentType === 'popover') {
              return (
                <Tippy content={toolbarCmd.tooltip || undefined} disabled={!toolbarCmd.tooltip}>
                  <PopoverOwn
                    key={`popover-toolbar-popover-${Math.random()}`}
                    className="ml-2"
                    text={toolbarCmd.buttonText || ''}
                    type={toolbarCmd.type}
                    rightIcon={<ChevronDownIcon />}
                    content={<div className="bg-white whitespace-nowrap shadow-lg rounded-lg flex flex-col gap-3 items-start justify-items-center">
                      <ul>
                        {toolbarCmd.popoverItems?.map((popoverItem, index) => {
                          return (
                            <Tippy
                              key={`popover-toolbar-item-${Math.random()}-${index}`}
                              content={popoverItem.tooltip || undefined}
                              disabled={!popoverItem.tooltip}
                            >
                              <li onClick={() => {
                                !popoverItem.disabled && popoverItem.onClick()
                              }} className={`${clsx({'cursor-not-allowed text-lightgray': popoverItem.disabled})} w-full px-4 cursor-pointer hover:bg-lightwarmgray h-12 flex flex-row gap-3 items-center justify-start`}
                              >
                                {popoverItem.icon
                                  && React.cloneElement(popoverItem.icon, {className: 'mr-2 w-5 h-5 text-listenblue'})
                                }
                                {popoverItem.text}
                              </li>
                            </Tippy>
                          )
                        }) || undefined}
                      </ul>
                    </div>}
                  />
                </Tippy>
              )
            }
          })
        })
      }
      {props.exportData && (
        <PopoverOwn
          className="ml-2"
          text={`${i18n.t('export')}`}
          type="primary"
          rightIcon={<ChevronDownIcon />}
          content={<div className="bg-white whitespace-nowrap shadow-lg rounded-lg flex flex-col gap-3 items-start justify-items-center">
            <ul>
              {props.exportData.onExportAll && (
                <li
                  onClick={props.exportData.onExportAll}
                  className="w-full px-4 cursor-pointer hover:bg-lightwarmgray h-12 flex flex-row gap-3 items-center justify-start"
                >
                  <CloudDownloadIcon className="mr-2 text-listenblue" />
                  Export all
                </li>
              )}
              {props.exportData.onExportFiltered && (
                <Tippy content={i18n.t('filtered_description')}>
                  <li
                    onClick={props.exportData.onExportFiltered}
                    className="w-full px-4 cursor-pointer hover:bg-lightwarmgray h-12 flex flex-row gap-3 items-center justify-start"
                  >
                    <CloudDownloadIcon className="mr-2 text-listenblue" />
                    Filtered
                  </li>
                </Tippy>
              )}
              {props.exportData?.additionalExports?.map((ae) => {
                return <li
                  key={ae.key}
                  onClick={() => {
                    !ae.disabled && ae.onClick()
                  }}
                  className={`${clsx({'cursor-not-allowed text-lightgray': ae.disabled})} w-full px-4 cursor-pointer hover:bg-lightwarmgray h-12 flex flex-row gap-3 items-center justify-start`}
                >
                  {ae.icon
                    ? React.cloneElement(ae.icon, {className: 'mr-2 text-listenblue'})
                    : <CloudDownloadIcon className={`${clsx({'text-listenblue-light': ae.disabled})} mr-2 text-listenblue`} />}
                  {ae.text}
                </li>
              }) || undefined}
            </ul>
          </div>}
        />
      )}
    </div>
  </div>
}

export function getRealFilterText(filterText: string) {
  if (!filterText) return filterText
  if (/^_new=[a-z0-9]+$/i.test(filterText)) {
    return filterText.substr(5)
  }
  return filterText
}

export function getSelectedRows<T>(
  selectedIds: string[],
  items: T[],
  keyFn: (o: T) => string,
) {
  const s = mergeAll(selectedIds.map((v, i) => {
    const index = items.findIndex(d => keyFn(d) === v)
    return {
      [String(keyFn(items[index]))]: true,
    }
  }))
  delete s[-1]
  return s
}

export function getNewSelection<T>(
  multiSelectable: boolean,
  keyFn: (o: T) => string,
  items: T[],
  selectedIdsBefore: Set<string>,
  toggledIds: string[],
  mode: 'checked'|'unchecked',
) {
  if (mode === 'unchecked') {
    return items.filter(i =>
      !toggledIds.includes(keyFn(i)) &&
      selectedIdsBefore.has(keyFn(i)),
    )
  }
  return items.filter(i =>
    toggledIds.includes(keyFn(i)) ||
    multiSelectable && selectedIdsBefore.has(keyFn(i)),
  )
}

export function isSelectionChanged<T>(
  keyFn: (o: T) => string,
  itemsBeforeArr: T[],
  itemsNowArr: T[],
  selectedIds: string[],
) {
  const itemsBefore = itemsBeforeArr.reduce((acc, i) => acc.set(keyFn(i), i), new Map<string, T>())
  const itemsNow = itemsNowArr.reduce((acc, i) => acc.set(keyFn(i), i), new Map<string, T>())
  let reportChanges = false
  const selectedItems: T[] = []
  for (const id of selectedIds) {
    const org = itemsNow.get(id)
    if (org) {
      selectedItems.push(org)
    }
    if (!org || !equals(org, itemsBefore.get(id))) {
      reportChanges = true
    }
  }
  if (reportChanges) {
    return selectedItems
  }
  return false
}
