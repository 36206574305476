import React, { useEffect } from 'react'
import { BannerStateType } from './BannerDetails'

type BannerDateProps = {
  state: BannerStateType,
  setState: (value: BannerStateType) => void,
  isDate: boolean,
  setIsDate: (value: boolean) => void,
}

const BannerDate = ({state, setState, isDate, setIsDate}: BannerDateProps) => {

  useEffect(
    () => {
      if (!isDate) {
        setState({
          ...state,
          expirationTime: Number(new Date()) + 30 * 24 * 60 * 60 * 1000,
        })
      }
    },
    [isDate],
  )

  const enableExpiryDate = () => {
    setIsDate(!isDate)
  }

  const setExpiryState = (e) => {
    if (isDate) {
      const date = e.target.value ? new Date(e.target.value).getTime() : Number(new Date()) + 30 * 24 * 60 * 60 * 1000
      setState({
        ...state,
        expirationTime: date,
      })
    }
  }

  return (
    <section  className="mt-6 p-4 bg-[#F3F4F5] rounded">
        <div className="flex items-center">
          <input
            type="checkbox"
            className="text-lake focus:ring-0 h-5 w-5 rounded-sm form-checkbox mr-5"
            id="date-id"
            checked={isDate}
            onChange={enableExpiryDate}
          />
          <label htmlFor="date-id" className="text-medgray text-sm mr-4">Add an expiry date (optional):</label>
          <input
            type="date"
            disabled={!isDate}
            min={Date.now()}
            className="border border-lightgray rounded h-10"
            value={new Date(state.expirationTime).toISOString().substring(0, 10)}
            onChange={setExpiryState}
          />
        </div>
    </section>
  )
}

export default BannerDate
