import i18next from 'i18next'
const enLanguage = require('../locales/en.json')

declare const require

export type LocalesType = 'en'|'ko'|'ja'

export const initLocalization = async (language: LocalesType) => {
  const locales = {
    en: {
      translations: enLanguage,
    },
    ko: {
      translations: '',
    },
    ja: {
      translations: '',
    },
  }
  switch (language) {
    case 'en':
      require.ensure(
        [
          '../locales/en.json',
        ],
        (require) => {
          locales.en = {
            translations: require('../locales/en.json'),
          }
        },
        'en',
      )
      break
    case 'ko':
      require.ensure(
        [
          '../locales/ko.json',
        ],
        (require) => {
          locales.ko = {
            translations: require('../locales/ko.json'),
          }
        },
        'ko',
      )
      break
    case 'ja':
      require.ensure(
        [
          '../locales/ja.json',
        ],
        (require) => {
          locales.ja = {
            translations: require('../locales/ja.json'),
          }
        },
        'ja',
      )
      break
  }
  await i18next.init(
    {
      compatibilityJSON:'v3',
      resources: locales,
      lng: language,
      // debug: true,
      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations',

      keySeparator: false, // we use content as keys
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
      },
      react: {
      },
    },
    (err, t) => {
      if (err) {
        console.error(err)
      }
    },
  )
}


export const languageLabeled = new Map<string, string>()
languageLabeled.set('en', 'English')
languageLabeled.set('ko', 'Korean')
languageLabeled.set('ja', 'Japanese')

export default i18next
