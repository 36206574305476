import React from 'react'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type AlertPropsIn = {
  message: string|React.ReactElement;
  className?: string;
}

const AlertStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  borderRadius: '5px',
  padding: '15px',
  fontWeight: 'bold',
} as CSSProperties

const DangerStyle = {
  ... AlertStyle,
  backgroundColor: '#FF5A4B',
  color: 'white',
  textTransform: 'uppercase',
} as CSSProperties

const WarningStyle = {
  ... AlertStyle,
  backgroundColor: '#F7DD79',
  color: '#385735',
} as CSSProperties

export const Danger = ({message}: AlertPropsIn) => {
  return <div style={DangerStyle}>
    {message}
  </div>
}

export const Warning = ({message, className}: AlertPropsIn) => {
  return <div className={className} style={WarningStyle}>
    {message}
  </div>
}
