import { gql } from '@apollo/client'
import { CourseBasic, CourseOwner, CourseResponseType, getCourseFragment, getCourseFragmentName, getOrganizationCourseFragment, getOrganizationCourseFragmentName, OrganizationCourse } from 'models/course'

export const DELETE_COURSE = gql`
  mutation DeleteCourse($courseId: String!) {
    deletedCourse: deleteCourse(courseId: $courseId) {
      id
    }
  }
`
export const GET_COURSE = (types: CourseResponseType[]) => gql`
  ${getCourseFragment(types)}
  query GetCourse($courseId: String!) {
    res: getCourse(courseId: $courseId) {
      ...${getCourseFragmentName(types)}
    }
  }
`
export const GET_COURSES = (types: CourseResponseType[]) => gql`
  ${getCourseFragment(types)}
  query GetCourses(
    $searchText: String!,
    $skip: Int!,
    $limit: Int!,
    $categories: [String!],
    $difficulties: [String!],
    $onlyAvailableToCompanyId: String,
    $onlyIds: [String!],
    $contentPartnerIds: [String!]
  ) {
    coursesRes: getCourses(
      searchText: $searchText,
      skip: $skip,
      limit: $limit,
      categories: $categories,
      difficulties: $difficulties,
      onlyAvailableToCompanyId: $onlyAvailableToCompanyId,
      onlyIds: $onlyIds,
      contentPartnerIds: $contentPartnerIds
    ) {
      courses {
        ...${getCourseFragmentName(types)}
      }
      coursesCount
    }
  }
`

export type UpdateCoursesRes = {
  courses: CourseBasic[],
}
type CoursesUpdate = {
  contentPartnerId: string,
}
export type UpdateCoursesVars = {
  coursesUpdate: CoursesUpdate,
  courseIds: string[],
}
export const UPDATE_COURSES = (types: CourseResponseType[]) => gql`
  ${getCourseFragment(types)}
  mutation UpdateCourses(
    $coursesUpdate: CoursesUpdate!,
    $courseIds: [String!]!
  ) {
    courses: updateCourses(
      coursesUpdate: $coursesUpdate,
      courseIds: $courseIds
    ) {
      ...${getCourseFragmentName(types)}
    }
  }
`

export type ExportCoursesRes = {
  emailTo: string,
  availableAt: string,
  exportId: string,
}
export type ExportCoursesVars = {
  searchText: string,
  categories?: string[],
  difficulties?: string[],
  onlyAvailableToCompanyId?: string,
  onlyIds?: string[],
  contentPartnerIds?: string[],
}
export const EXPORT_COURSES = gql`
  query ExportCourses(
    $searchText: String!,
    $categories: [String!],
    $difficulties: [String!],
    $onlyAvailableToCompanyId: String,
    $onlyIds: [String!],
    $contentPartnerIds: [String!]
  ) {
    exportCourses(
      searchText: $searchText,
      categories: $categories,
      difficulties: $difficulties,
      onlyAvailableToCompanyId: $onlyAvailableToCompanyId,
      onlyIds: $onlyIds,
      contentPartnerIds: $contentPartnerIds
    ) {
      emailTo
      availableAt
      exportId
    }
  }
`
export const EXPORT_ORGANIZATION_USER_COURSES = gql`
  query ExportOrganizationUserCourses(
    $userId: String!,
    $organizationId: String!,
    $companyId: String,
    $searchText: String!
  ) {
    exportRes: exportOrganizationUserCourses(
      userId: $userId,
      organizationId: $organizationId,
      companyId: $companyId,
      searchText: $searchText
    ) {
      emailTo
      availableAt
      exportId
    }
  }
`
export const GET_COURSE_CATEGORIES = gql`
  query GetCourseCategories {
    res: getCourseCategories {
      id
      order
      title
      description
    }
  }
`

export type GetOrganizationCoursesRes = {
  res: {
    courses: OrganizationCourse[],
    coursesCount: number,
  },
}
export type GetOrganizationCoursesVars = {
  organizationId: string,
  searchText: string,
  skip: number,
  limit: number,
  onlyVisibleInAnalyticsInMetrics?: boolean,
}
export const GET_ORGANIZATION_COURSES = gql`
  ${getOrganizationCourseFragment()}
  query GetOrganizationCourses(
    $organizationId: String!,
    $searchText: String!,
    $skip: Int!,
    $limit: Int!,
    $onlyVisibleInAnalyticsInMetrics: Boolean
  ) {
    res: getOrganizationCourses(
      organizationId: $organizationId,
      searchText: $searchText,
      skip: $skip,
      limit: $limit,
      onlyVisibleInAnalyticsInMetrics: $onlyVisibleInAnalyticsInMetrics
    ) {
      courses {
        ...${getOrganizationCourseFragmentName()}
      }
      coursesCount
    }
  }
`

type OrganizationCoursesExportRes = {
  availableAt: string,
  emailTo: string,
  exportId: string,
}
export type ExportOrganizationCoursesRes = {
  res: OrganizationCoursesExportRes,
}
export type ExportOrganizationCoursesVars = {
  organizationId: string,
  searchText: string,
  onlyVisibleInAnalyticsInMetrics?: boolean,
}
export const EXPORT_ORGANIZATION_COURSES = gql`
  query ExportOrganizationCourses(
    $organizationId: String!,
    $searchText: String!,
    $onlyVisibleInAnalyticsInMetrics: Boolean
  ) {
    res: exportOrganizationCourses(
      organizationId: $organizationId,
      searchText: $searchText,
      onlyVisibleInAnalyticsInMetrics: $onlyVisibleInAnalyticsInMetrics
    ) {
      emailTo
      availableAt
      exportId
    }
  }
`
export type GetCompanyIdsCourseAlreadyDistributedToVars = {
  organizationId?: string,
  courseId: string,
}
export type GetCompanyIdsCourseAlreadyDistributedToRes = {
  res: string[],
}
export const GET_COMPANYIDS_COURSE_ALREADY_DISTRIBUTED_TO = gql`
  query GetCompanyIdsCourseAlreadyDistributedTo(
    $organizationId: String,
    $courseId: String!
  ) {
    res: getCompanyIdsCourseAlreadyDistributedTo(
      organizationId: $organizationId,
      courseId: $courseId
    )
  }
`
export const CREATE_SCORM = gql`
  mutation CreateScormCourse($fileUrl: String!, $organizationId: String!) {
    courseId: createScormCourse(fileUrl: $fileUrl, organizationId: $organizationId)
  }
`

export type ExportCourseContentVars = {
  courseId: string;
}

export const EXPORT_COURSE_CONTENT = gql`
  query ExportCourseContent ($courseId: String!) {
    exportCourseContent (courseId: $courseId) {
      availableAt
      emailTo
      exportId
    }
  }
`

export type GetProgramOwnerVar = {
  courseId: string;
}

export type GetProgramOwnerRes = {
  course: CourseOwner;
}

export const GET_PROGRAM_OWNER = gql`
  query getCourse($courseId: String!) {
    course: getCourse(courseId: $courseId) {
      id
      owner {
        type
        organization {
          name
          id
        }
        user {
          id
          profile {
            fullName
          }
        }
      }
    }
  }
`

export type GetFutureDistributedProgramsVar = {
  companyId: string;
}

export const GET_FUTURE_DISTRIBUTED_PROGRAMS = gql`
  query GetFutureDistributedCoursesByCompany($companyId: String!) {
    getFutureDistributedCoursesByCompany(companyId: $companyId) {
      id,
      title,
      imageUrl,
      curatorName,
      curatorTitle,
      curatorCompany,
      curatorPhotoUrl,
      description,
      graduationGrade,
      manualReviews,
      contentPartnerId,
      chaptersUnlocked,
      passingGrade,
      canRetakeActionAssessments,
      maxActionAssessmentsRetakes,
      workbookDisabled,
      allowMediaDownload,
      denyContentCopy,
      showActionAssessmentsResultsPerAction,
      curatorSignatureDisabled,
      curatorBio,
      curatorChatEnabled,
      isTemplate,
      tagline,
      hasAssessments,
      futureDistribution
      absoluteDeadline
      relativeDeadline
      dueDate
    }
  }
`
