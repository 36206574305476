import { ChapterWithStudyContent, getChapterWithStudyContentFragment, getChapterWithStudyContentFragmentName } from './chapter'
import { CompanyBasic, getCompanyFragment, getCompanyFragmentName } from './company'
import { CourseBasic, CourseMeta, getCourseFragment, getCourseFragmentName } from './course'
import { getUserCourseEngagementSummaryFragment, getUserCourseEngagementSummaryFragmentName, UserCourseEngagementSummary, UserCourseEngagementSummaryChapters } from './engagement_summary'
import { getUserBasicExtraFragment, getUserBasicExtraFragmentName, UserBasicExtra } from './user'

export type UserCourse = {
  userId: string,
  companyId: string,
  courseId: string,
  isDisabled: boolean,
}
export type UserCourseUser = {
  user: UserBasicExtra,
}
export type UserCourseCompany = {
  company: CompanyBasic,
}
export type UserCourseCourse = {
  course: CourseBasic&CourseMeta,
}
export type UserCourseEngSummary = {
  engagementSummary: UserCourseEngagementSummary,
}
export type UserCourseEngSummaryWithCh = {
  engagementSummary: UserCourseEngagementSummary&UserCourseEngagementSummaryChapters,
}
export type UserCourseChapterWithStudyContent = {
  chapters: ChapterWithStudyContent[],
}
export type UserCourseStudyContent = {
  userCourseStudyContent: UserCourse&UserCourseChapterWithStudyContent,
}
export type UserCourseStudyContentVariables = {
  courseId: string,
  companyId: string,
  userId: string,
}
export type UserCourses = {
  userId: string,
  organizationId: string,
  companyIds: string[],
  coursesCount: number,
  courses: (UserCourse&UserCourseCompany&UserCourseCourse&UserCourseEngSummary&UserCourseEngSummaryWithCh)[],
}
export type UserCourseResponseType = 'with_user'|'with_company'|'with_course'|'with_eng_summary'|'with_eng_summary_chapters'|'with_chapter_study_content'
export const getUserCourseFragmentName = (types: UserCourseResponseType[]) => {
  return `UserCourseFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getUserCourseFragment = (types: UserCourseResponseType[]) => `
  ${types.includes('with_user') ? getUserBasicExtraFragment() : ''}
  ${types.includes('with_company') ? getCompanyFragment([]) : ''}
  ${types.includes('with_course') ? getCourseFragment(['with_meta']) : ''}
  ${types.includes('with_eng_summary')
    ? getUserCourseEngagementSummaryFragment(types.includes('with_eng_summary_chapters') ? ['with_chapters'] : [])
    : ''}
  ${types.includes('with_chapter_study_content')
    ? getChapterWithStudyContentFragment()
    : ''}
  fragment ${getUserCourseFragmentName(types)} on UserCourse {
    userId
    companyId
    courseId
    isDisabled
    ${types.includes('with_user') ? `
    user {
      ...${getUserBasicExtraFragmentName()}
    }
    ` : ''}
    ${types.includes('with_company') ? `
    company {
      ...${getCompanyFragmentName([])}
    }
    ` : ''}
    ${types.includes('with_course') ? `
    course {
      ...${getCourseFragmentName(['with_meta'])}
    }
    ` : ''}
    ${types.includes('with_eng_summary') ? `
    engagementSummary {
      ...${getUserCourseEngagementSummaryFragmentName(types.includes('with_eng_summary_chapters') ? ['with_chapters'] : [])}
    }
    ` : ''}
    ${types.includes('with_chapter_study_content') ? `
    chapters {
      ...${getChapterWithStudyContentFragmentName()}
    }
    ` : ''}
  }
`
