import * as React from 'react'
import IconType from './iconType'

const ProgramsIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 576 512"
      >
      <path d="M576 256V48C576 21.53 554.5 0 528 0h-256C227.9 0 192 35.88 192 80v256C192 380.1 227.9 416 272 416h280c13.25 0 24-10.75 24-24s-10.75-24-24-24H544V301.1C562.6 294.4 576 276.8 576 256zM496 368h-224c-17.64 0-32-14.34-32-32s14.36-32 32-32h224V368zM528 256h-256c-11.38 0-22.2 2.375-32 6.688V80c0-17.66 14.36-32 32-32h256V256zM360 464H352l0-16H304v16h-224c-17.64 0-32-14.34-32-32s14.36-32 32-32h100.3c-9.805-13.99-16.16-30.36-18.72-48H80c-11.38 0-22.2 2.375-32 6.688V176c0-17.66 14.36-32 32-32H160V96H80C35.89 96 0 131.9 0 176v256C0 476.1 35.89 512 80 512h280c13.25 0 24-10.75 24-24S373.3 464 360 464z"
      />
    </svg>
  )
}

export default ProgramsIcon
