import React from 'react'
import { AuthUser, GetAuthUserRes, GetUserAuthenticatedRes, GET_AUTHUSER, GET_USER_AUTHENTICATED, UserAuthenticated } from '../models/user'
import ErrorCmp from './Error'
import { handleApolloError } from '../utils/graphql'
import { logoutHandler } from '../lib/auth'
import Loading from './Loading'
import { ApolloError, useQuery } from '@apollo/client'

export function withAuthUser<TProps>(
  Cmp: React.ComponentType<TProps&{
    authUser?: AuthUser|null;
    userAuthenticated?: UserAuthenticated|null;
    subscribeToMore?: any
  }>,
  opts?: {ErrorCmpC?: React.JSXElementConstructor<{error: string}>, LoadingCmpC?: React.JSXElementConstructor<any>},
) {
  const CmpRet: React.FunctionComponent<TPropsOut> = (props: TProps) => {
    const { data, loading, error } = useQuery<GetAuthUserRes>(GET_AUTHUSER, {
      fetchPolicy: 'cache-first',
    })

    if (error) {
      handleApolloError(error)
      if (opts && opts.ErrorCmpC) return <opts.ErrorCmpC error={error.message} />
      return <ErrorCmp error={error.message} />
    }
    if (loading) {
      if (opts && opts.LoadingCmpC) return <opts.LoadingCmpC />
      return <Loading className="w-screen h-screen flex justify-center items-center" enabled={loading} />
    }

    const authUser = data && data.authUser

    if (!authUser) {
      return <Cmp
        {...props}
        authUser={null}
        userAuthenticated={null}
      />
    }

    const {
      data: lazyData,
      loading: lazyLoading,
      error: lazyError,
      subscribeToMore,
    } = useQuery<GetUserAuthenticatedRes>(
      GET_USER_AUTHENTICATED, {
        variables: {userId: authUser.uid},
        fetchPolicy: 'cache-first',
        skip: !authUser,
      },
    )
    if (lazyError) {
      handleApolloError(lazyError)
      if (opts && opts.ErrorCmpC) return <opts.ErrorCmpC error={lazyError.message} />
      return <ErrorCmp error={lazyError} />
    }
    if (lazyLoading) {
      if (opts && opts.LoadingCmpC) return <opts.LoadingCmpC />
      // tslint:disable-next-line: max-line-length
      return <Loading className="w-screen h-screen flex justify-center items-center" enabled={lazyLoading} />
    }

    if (!lazyData) {
      logoutHandler().then(() => window.location.assign('/'))
      return
    }

    return (
      <Cmp
        {...props}
        authUser={authUser}
        userAuthenticated={lazyData.userAuthenticated}
        subscribeToMore={subscribeToMore}
      />
    )
  }

  type TPropsOut = Omit<TProps, 'authUser'|'userAuthenticated'>
  return (props: TPropsOut) => <CmpRet {...props} />
}


export function useAuthUser(): {loading?: boolean, error?: ApolloError, data?: GetUserAuthenticatedRes|null} {
  const { data, loading, error } = useQuery<GetAuthUserRes>(GET_AUTHUSER, {
    fetchPolicy: 'cache-first',
  })

  if (error) {
    handleApolloError(error)
    return {
      loading,
      error,
      data: null,
    }
  }

  if (loading) {
    return {
      loading,
      error,
      data: null,
    }
  }

  const authUser = data && data.authUser

  if (!authUser) {
    return {
      loading: undefined,
      error: undefined,
      data: null,
    }
  }

  { // Fetch actual user
    const { data, loading, error } = useQuery<GetUserAuthenticatedRes>(
      GET_USER_AUTHENTICATED, {
        variables: {userId: authUser.uid},
        fetchPolicy: 'cache-first',
      },
    )
    if (error) {
      handleApolloError(error)
      return {
        loading,
        error,
        data,
      }
    }
    if (loading) {
      return {
        loading,
        error,
        data,
      }
    }

    if (!data) {
      logoutHandler().then(() => window.location.assign('/'))
    }

    return {
      loading,
      error,
      data,
    }
  }
}
