import TrashIcon from '@heroicons/react/24/solid/TrashIcon'
import React from 'react'
import i18n from './i18n'
import Select from './Select'
import { FilterHeader, FilterOperator, FilterRow, FILTER_OPERATORS } from './TableFilter'
import TextField from './TextField'

function friendlyOperator(op: FilterOperator) {
  switch (op) {
    case 'lt': return 'less than (<)'
    case 'gt': return 'greater than (>)'
    case 'eq': return 'equals to (=)'
    case 'lte': return 'less or equal than (≤)'
    case 'gte': return 'greater or equal than (≥)'
    // case 'contains': return 'contains'
    default: return ''
  }
}

type TableFilterMenuItemsProps = {
  primaryKey: number,
  header: FilterHeader[],
  selected: FilterRow,
  onChangeFilter: (key: number, target: 'header'|'operator'|'inputValue', value: any) => void,
  removeItem: (key: number) => void,
}

export default function (props: TableFilterMenuItemsProps) {
  return (
    <div className="w-full flex flex-row items-center gap-2" key={props.primaryKey}>
      <div className="w-48 pb-5">
        <Select
          label={props.primaryKey === 0 ? i18n.t('column') : undefined}
          value={props.selected.filter.field}
          values={props.header.map((value) => {
            return {
              key: value.field,
              value: value.name,
            }
          })}
          onChange={(key) => {
            props.onChangeFilter(props.primaryKey, 'header', key)
          }}
        />
      </div>
      <div className={`${props.primaryKey !== 0 && '-mt-5'}`}>
        <span>is</span>
      </div>
      <div className="w-60 pb-5">
        <Select
          label={props.primaryKey === 0 ? i18n.t('operator'): undefined}
          value={props.selected.operator}
          values={props.selected.filter.type === 'number' ?
            FILTER_OPERATORS.number.map((data) => {
              return {
                value: friendlyOperator(data),
                key: data,
              }
            })
            :
            FILTER_OPERATORS.text.map((data) => {
              return {
                value: friendlyOperator(data),
                key: data,
              }
            })
          }
          onChange={(key) => {
            props.onChangeFilter(props.primaryKey, 'operator', key)
          }}
        />
      </div>
      <div className="w-24 pb-5">
        <TextField
          label={props.primaryKey === 0 ? i18n.t('value') : undefined}
          value={props.selected.filter.type === 'text'
            ? props.selected.value
            : props.selected.value.toString(10)
          }
          type={props.selected.filter.type  === 'number' ? 'number' : 'text'}
          onChange={(value) => {
            props.onChangeFilter(props.primaryKey, 'inputValue', value)
          }}
        />
      </div>
      <div>
        <button name="remove-filters-query-button" className={`text-coral whitespace-nowrap flex flex-row items-center ${props.primaryKey !== 0 && '-mt-5'}`}
          onClick={(e) => {
            props.removeItem(props.primaryKey)
          }
        }>
          <TrashIcon width={20} height={20} /><span className="ml-1">{`${i18n.t('delete')}`}</span>
        </button>
      </div>
    </div>
  )
}
