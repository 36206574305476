import { Timestamps } from './timestamps'

export type Engagement = {
  engagement: number,
  assessmentsStartedAt: number,
}
export type EngagementTimestamps = {
  timestamps: Timestamps,
  reviewTimestamps: Timestamps,
}

export type EngagementResponseType = 'with_timestamps'

export const getEngagementFragmentName = (types: EngagementResponseType[]) => {
  return `EngagementFields${types.sort().map(t => `_${t}`).join('')}`
}

export const getEngagementFragment = (types: EngagementResponseType[]) => {
  return `
    fragment ${getEngagementFragmentName(types)} on Engagement {
      engagement
      assessmentsStartedAt
    }
  `
}
