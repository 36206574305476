import { gql } from '@apollo/client'
import { CompanyBasic, getCompanyBasicFragment, getCompanyBasicFragmentName, getCompanyFragment, getCompanyFragmentName } from './company'
import { UserCourse, UserCourseCompany, UserCourseEngSummary, UserCourseEngSummaryWithCh, UserCourseUser } from './user_course'

export type UserToggled = (UserBasic | UserInvited)

// **** AuthUser is data that we have in local state that we get from auth token
export type AuthUser = {
  uid: string;
  email: string;
  token: string;
}
export const GET_AUTHUSER = gql`
  query GetAuthUser {
    authUser @client {
      uid
      email
      token
    }
  }
`
export type GetAuthUserRes = {
  authUser: AuthUser|null,
}

export type CompanyUserInvitation = {
  organizationId: string,
  companyId: string,
  userIdOrEmail: string,
  email: string,
  invitedBy: string,
  invitedAt: number,
  user: UserBasicExtra,
  invitedByUser: UserBasicExtra,
  company: CompanyBasic,
}
export const getCompanyUserInvitationFragmentName = () => {
  return 'CompanyUserInvitationFragmentFields'
}
export const getCompanyUserInvitationFragment = () => {
  return `
    ${getUserBasicExtraFragment()}
    ${getCompanyFragment([])}
    fragment ${getCompanyUserInvitationFragmentName()} on CompanyUserInvitation {
      organizationId
      companyId
      userIdOrEmail
      email
      invitedBy
      invitedAt
      user {
        ...${getUserBasicExtraFragmentName()}
      }
      invitedByUser {
        ...${getUserBasicExtraFragmentName()}
      }
      company {
        ...${getCompanyFragmentName([])}
      }
    }
  `
}

// *** Main user models

export type UserRole = 'admin'|'superEditor'|'sales'|'partner'|'billingManager'

type UserProfile = {
  // accessCode?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  timezone?: number;
  // currentCompanyIdCourseId?: string;
}
export type ExternalUser = {
  id: string,
  organizationId: string,
  externalUserId: string,
  gnowbeUserId?: string,
  attributes: object,
}
export type UserBasic = {
  id: string,
  profile: UserProfile,
  createdAt: Date,
  language: string,
}
export const getUserBasicFragmentName = () => {
  return 'UserBasicFields'
}
export const getUserBasicFragment = () => {
  return `
    fragment ${getUserBasicFragmentName()} on UserBasic {
      id
      profile {
        fullName
        firstName
        lastName
        imageUrl
        timezone
      }
    }
  `
}

export type UserEmail = {
  email: string;
  emailVerified?: boolean;
}

export type Feature = 'show_extend_upsell'
  |'show_gnowbelearn_banner'
  |'show_explore_tab'
  // |'editor_has_proctoring'
  |'show_refer_banner'
  |'course-from_ppt'
  |'course-manualReviews'
  |'action-word_puzzle'
  |'action-assessment'
  |'action-certificate'
  |'user-hide_marketing'
  |'referrals'

export type UserFeatures = {
  features: Feature[];
}

export type UserRoles = {
  roles: (UserRole)[];
  editorCourses: string[];
  agentCompanies: string[];
  editorCompanies: string[];
  editorOrganizations: string[];
  partnerOrganizations: string[];
  managerOrganizations: string[];
  billingManagerOrganizations: string[];
  creatorInOrganizations: string[];
  memberOfOrganizations: string[];
}
export type UserNotifSettings = {
  disabledNotifications: 'PUSH_PRIVATE_MSGS'|'PUSH_CURATOR_MSGS'|'PUSH_COMMENTS_LIKES'|'PUSH_MENTIONS'|'PUSH_DAILY_COURSE_MSGS'|'PUSH_INACTIVITY'|'PUSH_GROUP_AGENT_MSGS'|'EMAIL_DAILY_COURSE_MSGS'|'EMAIL_COURSE_COMPLETION_MSGS'|'EMAIL_WEEKLY_JOURNEY'|'EMAIL_ACTIVITY_NOTIFICATIONS'|'EMAIL_GROUP_AGENT_MSGS'|'EMAIL_MONTLY_AGENT_REPORT',
  editorCourseNotifications: GnowbeUserCourseNotifications[],
}
export type GnowbeUserCourseNotifications = {
  userId: string,
  courseId: string,
  distributionStatusUpdates: boolean,
}
export type UserResponseType = 'with_email'|'with_roles'|'with_notif_settings'|'with_features'
export const getUserFragmentName = (types: UserResponseType[]) => {
  return `UserFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getUserFragment = (types: UserResponseType[]) => `
  fragment ${getUserFragmentName(types)} on User {
    id
    profile {
      fullName
      firstName
      lastName
      imageUrl
      timezone
    }
    createdAt
    ${types.indexOf('with_email') >= 0 ? `
    email
    emailVerified` : ''}
    ${types.indexOf('with_features') >= 0 ? `
    features` : ''}
    ${types.indexOf('with_roles') >= 0 ? `
    roles
    editorCourses
    agentCompanies
    editorCompanies
    editorOrganizations
    partnerOrganizations
    managerOrganizations
    billingManagerOrganizations
    creatorInOrganizations
    memberOfOrganizations` : ''}
    ${types.indexOf('with_notif_settings') >= 0 ? `
    disabledNotifications
    editorCourseNotifications {
      userId
      courseId
      distributionStatusUpdates
    }` : ''}
    language
  }
`

export type UserAuthenticated = UserBasic&UserEmail&UserRoles&UserNotifSettings&UserFeatures
export const getUserAuthenticatedFragment = () =>
  getUserFragment(['with_email', 'with_roles', 'with_notif_settings', 'with_features'])
export const getUserAuthenticatedFragmentName = () =>
  getUserFragmentName(['with_email', 'with_roles', 'with_notif_settings', 'with_features'])

export const GET_USER_AUTHENTICATED = gql`
  ${getUserAuthenticatedFragment()}
  query GetUserAuthenticated($userId: String!) {
    userAuthenticated: getUser(userId: $userId) {
      ...${getUserAuthenticatedFragmentName()}
    }
  }
`
export type GetUserAuthenticatedRes = {
  userAuthenticated: UserAuthenticated,
}


// *** UserBasicExtra is used when we embed user in places (like course.editors, ...)

export type UserBasicExtra = {
  id: string;
  email: string;
  profile: UserProfile;
  createdAt: Date;
}
export const getUserBasicExtraFragmentName = () => { return 'UserBasicExtraFields' }
export const getUserBasicExtraFragment = () => `
  fragment ${getUserBasicExtraFragmentName()} on UserBasicExtra {
    id
    profile {
      fullName
      firstName
      lastName
      imageUrl
      timezone
    }
    createdAt
    email
  }
`

// *** User from API

export type UserAPI = {
  userId: string;
  email: string;
  profile: UserProfile;
  createdAt: number;
  updatedAt: number;
  visibleInAnalytics?: boolean;
  isManager?: boolean;
  isAgent?: boolean;
  isEditor?: boolean;
}

// *** User Invited models
export type UserInvited = {
  email: string,
  employeeId?: string,
  firstName?: string,
  lastName?: string,
}
export const getUserInvitedFragmentName = () => {
  return 'UserInvitedFields'
}
export const getUserInvitedFragment = () => `
  fragment ${getUserInvitedFragmentName()} on UserInvited {
    email
    employeeId
    firstName
    lastName
  }
`

// *** CompanyUser and OrganizationUser models
export type UserEngSummary = {
  userId: string;
  opened: number;
  started: number;
  avgDiscoverRate: number;
  avgEngagement: number;
  avgCompletion: number;
  avgAssessmentsGrade?: number;
  avgAssessmentsGradeReg?: number;
  numOfCompletedChapters: number;
  numOfCompletedActions: number;
  minutesSpent: number;
  graduated: number;
}


export type CompanyUser = {
  id: string;
  userId: string;
  employeeId: string;
  companyId: string;
  user: UserBasicExtra;
  engSummary: UserEngSummary;
  visibleInAnalytics: boolean;
  roles: ('manager'|'agent'|'editor')[];
  joinedAt: number;
  isDisabled: boolean;
}
export const getCompanyUserFragmentName = () => {
  return 'CompanyUserFields'
}
export const getCompanyUserFragment = () => `
  ${getUserBasicExtraFragment()}
  fragment ${getCompanyUserFragmentName()} on CompanyUser {
    id
    userId
    employeeId
    companyId
    user {
      ...${getUserBasicExtraFragmentName()}
    }
    engSummary {
      userId
      opened
      started
      avgDiscoverRate
      avgEngagement
      avgCompletion
      avgAssessmentsGrade
      avgAssessmentsGradeReg
      numOfCompletedChapters
      numOfCompletedActions
      minutesSpent
    }
    visibleInAnalytics
    roles
    joinedAt
    isDisabled
  }
`


export type OrganizationUser = {
  id: string;
  userId: string;
  employeeId?: string;
  organizationId: string;
  user: UserBasic&UserEmail;
  engSummary: UserEngSummary;
  roles: ('manager'|'agent'|'editor')[];
  companyIdsPartOf: string[];
  companiesPartOf: CompanyBasic[];
  isDisabled: boolean;
  joinedAt: number;
}
export const getOrganizationUserFragmentName = () => {
  return 'OrganizationUserFields'
}
export const getOrganizationUserFragment = () => `
  ${getUserBasicExtraFragment()}
  ${getCompanyBasicFragment()}
  fragment ${getOrganizationUserFragmentName()} on OrganizationUser {
    id
    userId
    employeeId
    organizationId
    user {
      ...${getUserBasicExtraFragmentName()}
    }
    engSummary {
      userId
      opened
      started
      avgDiscoverRate
      avgEngagement
      avgCompletion
      avgAssessmentsGrade
      avgAssessmentsGradeReg
      numOfCompletedChapters
      numOfCompletedActions
      minutesSpent
      graduated
    }
    roles
    companyIdsPartOf
    companiesPartOf {
      ...${getCompanyBasicFragmentName()}
    }
    joinedAt
    isDisabled
  }
`

// **** OrganizationUsers QUERIES ****
export const GET_ORGANIZATION_USERS = gql`
  ${getOrganizationUserFragment()}
  query GetOrganizationUsers(
    $organizationId: String!,
    $searchText: String!, $sortBy: OrganizationUsersSortField!, $sortDirection: SortDirection!,
    $skip: Int!, $limit: Int!, $filters: [OrganizationUserFilter!], $filters2: [OrganizationUserFilterWithCA!]
    $excludeDisabled: Boolean, $onlyDisabled: Boolean
  ) {
    usersRes: getOrganizationUsers(
      organizationId: $organizationId,
      searchText: $searchText, sortBy: $sortBy, sortDirection: $sortDirection,
      skip: $skip, limit: $limit, filters: $filters, filters2: $filters2, excludeDisabled: $excludeDisabled, onlyDisabled: $onlyDisabled
    ) {
      users {
        ...${getOrganizationUserFragmentName()}
      }
      usersCount
    }
  }
`
export const EXPORT_ORGANIZATION_USERS = gql`
  query ExportOrganizationUsers($organizationId: String!, $searchText: String!,
    $sortBy: OrganizationUsersSortField!, $sortDirection: SortDirection!,
    $filters: [OrganizationUserFilter!], $filters2: [OrganizationUserFilterWithCA!],
    $withCourseCompletion: Boolean, $excludeDisabled: Boolean, $onlyDisabled: Boolean
  ) {
    exportRes: exportOrganizationUsers(organizationId: $organizationId, searchText: $searchText,
      sortBy: $sortBy, sortDirection: $sortDirection,
      filters: $filters, filters2: $filters2,
      withCourseCompletion: $withCourseCompletion, excludeDisabled: $excludeDisabled, onlyDisabled: $onlyDisabled
    ) {
      emailTo
      availableAt
      exportId
    }
  }
`
export const REMOVE_ORGANIZATION_USER = gql`
  ${getUserFragment(['with_email'])}
  ${getUserInvitedFragment()}
  mutation RemoveOrganizationUser(
    $organizationId: String!,
    $userId: String!,
    $removeRoles: Boolean
  ) {
    toggleRes: removeOrganizationUser(
      organizationId: $organizationId,
      userId: $userId,
      removeRoles: $removeRoles
    ) {
      key
      addRemove
      user {
        ... on User {
          ...${getUserFragmentName(['with_email'])}
        }
        ... on UserInvited {
          ...${getUserInvitedFragmentName()}
        }
      }
    }
  }
`
export const REMOVE_ORGANIZATION_USERS = gql`
  mutation RemoveOrganizationUsers(
    $organizationId: String!,
    $userIds: [String!]!,
    $removeRoles: Boolean
  ) {
    res: removeOrganizationUsers(
      organizationId: $organizationId,
      userIds: $userIds,
      removeRoles: $removeRoles
    )
  }
`

// **** CompanyUsers Queries ****
export const GET_COMPANY_USERS = gql`
  ${getCompanyUserFragment()}
  query GetCompanyUsers($organizationId: String!, $companyId: String!, $searchText: String!,
    $sortBy: CompanyUsersSortField!, $sortDirection: SortDirection!,
    $skip: Int!, $limit: Int!, $filters: [CompanyUserFilter!], $filters2: [CompanyUserFilterWithCA!], $excludeDisabled: Boolean, $onlyDisabled: Boolean
  ) {
    usersRes: getCompanyUsers(organizationId: $organizationId, companyId: $companyId, searchText: $searchText,
      sortBy: $sortBy, sortDirection: $sortDirection,
      skip: $skip, limit: $limit, filters: $filters, filters2: $filters2, excludeDisabled: $excludeDisabled, onlyDisabled: $onlyDisabled
    ) {
      users {
        ...${getCompanyUserFragmentName()}
      }
      usersCount
    }
  }
`
export const REMOVE_COMPANY_USER = gql`
  ${getUserFragment(['with_email'])}
  ${getUserInvitedFragment()}
  mutation RemoveCompanyUser($companyId: String!, $userId: String!) {
    toggleRes: toggleCompanyUser(companyId: $companyId, userIdOrEmail: $userId, addRemove: remove, noEmail: true) {
      key
      addRemove
      user {
        ... on User {
          ...${getUserFragmentName(['with_email'])}
        }
        ... on UserInvited {
          ...${getUserInvitedFragmentName()}
        }
      }
    }
  }
`
export const REMOVE_COMPANY_USERS = gql`
  mutation RemoveCompanyUsers($companyId: String!, $userIds: [String!]!) {
    res: toggleCompanyUsers(companyId: $companyId, userIdsOrEmails: $userIds, addRemove: remove, noEmail: true)
  }
`
export const EXPORT_COMPANY_USERS = gql`
  query ExportCompanyUsers($organizationId: String!, $companyId: String!, $searchText: String!,
    $sortBy: CompanyUsersSortField!, $sortDirection: SortDirection!,
    $filters: [CompanyUserFilter!], $filters2: [CompanyUserFilterWithCA!], $excludeDisabled: Boolean, $onlyDisabled: Boolean
  ) {
    exportRes: exportCompanyUsers(organizationId: $organizationId, companyId: $companyId, searchText: $searchText,
      sortBy: $sortBy, sortDirection: $sortDirection,
      filters: $filters, filters2: $filters2, excludeDisabled: $excludeDisabled, onlyDisabled: $onlyDisabled
    ) {
      emailTo
      availableAt
      exportId
    }
  }
`



export type OrganizationCourseUsers = {
  courseId: string,
  organizationId: string,

  usersCount: number
  users: (UserCourse&UserCourseUser&UserCourseEngSummary&UserCourseCompany&UserCourseEngSummaryWithCh)[],
}
export type GetOrganizationCourseUsersRes = {
  usersRes: OrganizationCourseUsers,
}

export const EXPORT_ORGANIZATION_COURSE_USERS = gql`
  query ExportOrganizationCourseUsers($organizationId: String!, $courseId: String, $companyId: String, $searchText: String!,
    $sortBy: OrganizationUsersSortField!, $sortDirection: SortDirection!, $filters: [OrganizationUserFilter!], $filters2: [OrganizationUserFilterWithCA!], $includeChapterSecondsSpent: Boolean
  ) {
    exportRes: exportOrganizationCourseUsers2(organizationId: $organizationId, companyId: $companyId, courseId: $courseId, searchText: $searchText,
      sortBy: $sortBy, sortDirection: $sortDirection, filters: $filters, filters2: $filters2, includeChapterSecondsSpent: $includeChapterSecondsSpent
    ) {
      emailTo
      availableAt
      exportId
    }
  }
`
