import React from 'react'
import i18n from 'components/i18n'
import { useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded'
import { FileImport } from 'models/fileImport'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { client } from 'App'
import { GET_FILE_IMPORTS_LIST } from 'subscriptions/fileImport'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" gridGap={1} alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box className="ml-1" minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
}

const FileImportsProgressList = (props: {
  onClose: () => void,
  fileImportsList: FileImport[],
}) => {
  const removeFileImport = (id: string) => {
    client.writeQuery({
      query: GET_FILE_IMPORTS_LIST,
      data: {
        ...props.fileImportsList,
        fileImportsList: props.fileImportsList.filter(e => e.id !== id),
      },
    })
  }
  return (
    <div className="bg-white w-96 rounded-lg shadow-md transition-opacity duration-700">
      <Button onClick={() => props.onClose()} className="cursor-pointer rounded-b-none flex items-center justify-between h-11 w-full bg-listenblue text-white">
        <div>
          <CloudUploadRoundedIcon />
        </div>
        <div className="flex-1 ml-2 text-left text-sm lowercase">
          {props.fileImportsList.length}&nbsp;{i18n.t('file_import_log')}
        </div>
        <div><KeyboardArrowUpRoundedIcon /></div>
      </Button>
      <ul className="even:bg-gray-50">
        {props.fileImportsList.map((l, i) => {
          return <li key={l.id} className="h-12 border flex flex-row justify-between items-center gap-3">
            <div className="flex-1 pl-4 text-sm font-semibold truncate">
              {l.fileName}
            </div>
            <div className="w-36">
              <LinearProgressWithLabel value={l.progress} />
            </div>
            <div className="pr-4" onClick={() => removeFileImport(l.id)}>
              <CancelRoundedIcon fontSize="small" className="cursor-pointer text-medgray" />
            </div>
          </li>
        })}
      </ul>
    </div>
  )
}

export default function () {
  const [open, setOpen] = React.useState(false)
  const {loading, error, data} = useQuery(GET_FILE_IMPORTS_LIST, {
    fetchPolicy: 'cache-only',
  })

  if (loading) return <div>{i18n.t('loading')}</div>
  if (error) return <div>{i18n.t('something_went_wrong')}</div>

  const handleOpen = () => {
    if (!data) return
    setOpen(prev => !prev)
  }

  return <>
    {data && (data.fileImportsList.length > 0) && !open
      && <Button onClick={handleOpen} className="transition-opacity duration-700 cursor-pointer flex items-center justify-between rounded-lg h-11 w-48 bg-listenblue text-white">
        <div>
          <CloudUploadRoundedIcon />
        </div>
        <div className="flex-1 ml-2 text-left text-sm lowercase">
          {data.fileImportsList.length}&nbsp;{i18n.t('file_import_log')}
        </div>
        <div><KeyboardArrowDownRoundedIcon /></div>
      </Button>
    }
    {open && data?.fileImportsList.length > 0 && <FileImportsProgressList
      onClose={handleOpen}
      fileImportsList={data.fileImportsList || []}
    />}
  </>
}
