import React, { useContext } from 'react'
import { canViewAllCompanies, canViewAllPrograms, canViewAllUsers, canViewPartners, isAdmin, isEditorAnyCourse2, isPartner2, isSales2, isSubscriptionExpired, isSuperEditor2, shouldAnonymize } from 'lib/acl_logic'
import { logoutHandler } from 'lib/auth'
import { Navigate, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import { GET_COMPANY_WITH_TAGS_SUBSCRIPTIONS_COURSES } from 'utils/queries'
import { useAuthUser } from './AuthUserWrapper'
import ErrorCmp from './Error'
import Loading from './Loading'
import { LoggingIn } from './account/AccountsView'
import PageNotFound from './PageNotFound'
import { JobRunner } from './admin/JobRunner'
import DeleteCourseConfirmation from './admin/manual_data_cleanup/DeleteCourseConfirmation'
import DeleteUserConfirmation from './admin/manual_data_cleanup/DeleteUserConfirmation'
import { updateMetaTour } from 'lib/user_metadata'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GetOrganizationRes, GET_ORGANIZATION, GET_ORGID_FROM_ENSURE } from 'queries/organization'
import ListOrganizations from './ListOrganizations'
import { OrganizationBasic, OrganizationSettings } from 'models/organization'
import { OrganizationContext } from './context'
const AccountsView = React.lazy(() => import('./account/AccountsView'))
const BundleView = React.lazy(() => import('./bundle/View'))
const CompaniesView = React.lazy(() => import('./company/CompaniesView'))
const CourseStatusView = React.lazy(() => import('./course/CourseStatusView'))
const SuperEditorCoursesView = React.lazy(() => import('./course/SuperEditorCoursesView'))
const ForbiddenView = React.lazy(() => import('./ForbiddenView'))
const OrgCompanyCourseView = React.lazy(() => import('./metrics/company/course/View'))
const OrgCompanyUserView = React.lazy(() => import('./metrics/company/user/View'))
const OrgCompanyView = React.lazy(() => import('./metrics/company/View'))
const OrgUserView = React.lazy(() => import('./metrics/organization/user/View'))
const OrganizationView = React.lazy(() => import('./metrics/organization/View'))
const PartnersView = React.lazy(() => import('./partner/PartnersView'))
const UsersView = React.lazy(() => import('./user/UsersView'))
const StreamDetails = React.lazy(() => import('./admin/redis_streams/StreamDetails'))
const DLQDetails = React.lazy(() => import('./admin/redis_streams/DLQDetails'))
const Topics = React.lazy(() => import('./admin/redis_streams/Topics'))
const Topic = React.lazy(() => import('./admin/redis_streams/Topic'))
const OrgCourseView = React.lazy(() => import('./metrics/organization/course/View'))


export const RootHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  const [getCompany, {
    data: companyData, loading: companyLoading, error: companyError,
  }] = useLazyQuery(GET_COMPANY_WITH_TAGS_SUBSCRIPTIONS_COURSES)

  if (userAuthLoading || companyLoading) return <Loading enabled={userAuthLoading || companyLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (companyError) return <ErrorCmp error={companyError} />

  if (companyData) {
    return (
      <Navigate to={`/organization/${companyData.company.organizationId}/company/${companyData.company.id}`}
        replace
      />
    )
  }

  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (userAuthData.userAuthenticated.profile &&
    (!userAuthData.userAuthenticated.profile.firstName || !userAuthData.userAuthenticated.profile.lastName)) {
    return <Navigate to="/account" replace />
  }
  if (isSuperEditor2(userAuthData.userAuthenticated) || isSales2(userAuthData.userAuthenticated)) {
    return <Navigate to="/organizations" replace />
  }
  if (isPartner2(userAuthData.userAuthenticated)) {
    return <Navigate to="/organizations" replace />
  }
  if (userAuthData.userAuthenticated.managerOrganizations.length > 0) {
    const orgId = userAuthData.userAuthenticated.managerOrganizations[0]
    return <Navigate to="/welcome" replace />
  }
  if (userAuthData.userAuthenticated.agentCompanies.length > 0) {
    getCompany({variables: {companyId: userAuthData.userAuthenticated.agentCompanies[0]}})
    return <div>Redirecting...</div>
  }

  if (isEditorAnyCourse2(userAuthData.userAuthenticated)) {
    if (userAuthData.userAuthenticated.editorCourses.length === 1) {
      return <Navigate to={`/program/${userAuthData.userAuthenticated.editorCourses[0]}/status`} replace />
    }
    return <Navigate to="/curator/programs" replace />
  }

  return <ListOrganizations />
}
export const AccountHandler = () => {
  const params = useParams()
  if (params.token_type && params.access_token && params.expires_at) {
    return <LoggingIn
      tokenType={params.token_type}
      accessToken={params.access_token}
      expiresAt={parseInt(params.expires_at, 10)}
      redirectTo={params.redirectTo ? params.redirectTo : ''}
    />
  }
  const continueAs = params.continueAs ? params.continueAs.replace(' ', '+') : null
  return <AccountsView
    redirectTo={params.redirectTo ? params.redirectTo : ''}
    autoAuthUserEmail={continueAs || ''}
  />
}
export const OrganizationViewHandler = () => {
  const location = useLocation()
  const organizationData = useContext(OrganizationContext)

  if (organizationData.organizationLodaing) return <Loading enabled={organizationData.organizationLodaing} />
  if (!organizationData.organization && !organizationData.organizationLodaing) return <Navigate to="/welcome" replace />

  const organization = organizationData.organization
  if (isSubscriptionExpired(organization)
    && !location.hash.includes('general') && !location.hash.includes('settings')) {
    return <Navigate to={`/organization/${organization?.id}#general`} />
  }
  return <OrganizationView organizationId={organization!.id} hash={location.hash}/>
}
export const CompanyViewHandler = () => {
  const params = useParams()
  const location = useLocation()
  const organizationId = params.organizationId ? params.organizationId : ''
  const companyId = params.companyId ? params.companyId : ''
  const hash = location.hash

  const {data, loading, error} = useQuery(GET_ORGANIZATION(['with_settings']), {
    variables: {organizationId},
    skip: !organizationId,
  })
  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />
  const organization = data.organization
  if (isSubscriptionExpired(organization)) {
    return <Navigate to={`/organization/${organizationId}#general`} />
  }
  return <OrgCompanyView organizationId={organizationId} companyId={companyId} hash={hash} />
}
export const CompanyCourseViewHandler = () => {
  const params = useParams()
  const location = useLocation()
  const organizationId = params.organizationId || ''
  const companyId = params.companyId || ''
  const courseId = params.courseId || ''
  const hash = location.hash
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  const {data, loading, error} = useQuery(GET_ORGANIZATION(['with_settings']), {
    variables: {organizationId},
    skip: !organizationId,
  })
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />
  const organization = data.organization as OrganizationBasic&OrganizationSettings
  if (isSubscriptionExpired(organization)) {
    return <Navigate to={`/organization/${organizationId}#general`} />
  }
  const shouldAnon = shouldAnonymize(organizationId, companyId, userAuthData?.userAuthenticated)

  return <OrgCompanyCourseView
    organizationId={organizationId}
    companyId={companyId}
    courseId={courseId}
    shouldAnonimize={shouldAnon}
    hash={hash}
  />
}
export const OrganizationCourseViewHandler = () => {
  const params = useParams()
  const location = useLocation()
  const organizationId = params.organizationId || ''
  const courseId = params.courseId || ''
  const hash = location.hash
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  const {data, loading, error} = useQuery(GET_ORGANIZATION(['with_settings']), {
    variables: {organizationId},
    skip: !organizationId,
  })
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />

  const organization = data.organization
  if (isSubscriptionExpired(organization)) {
    return <Navigate to={`/organization/${organizationId}#general`} />
  }
  const shouldAnon = shouldAnonymize(organizationId, undefined, userAuthData?.userAuthenticated)
  return <OrgCourseView
    organizationId={organizationId}
    courseId={courseId}
    shouldAnonimize={shouldAnon}
    hash={hash}
  />
}
export const CompanyUserViewHandler = () => {
  const params = useParams()
  const location = useLocation()
  const organizationId = params.organizationId || ''
  const companyId = params.companyId || ''
  const userId = params.userId || ''
  const hash = location.hash
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  const {data, loading, error} = useQuery(GET_ORGANIZATION(['with_settings']), {
    variables: {organizationId},
    skip: !organizationId,
  })
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />

  const organization = data.organization
  if (isSubscriptionExpired(organization)) {
    return <Navigate to={`/organization/${organizationId}#general`} />
  }
  return <OrgCompanyUserView
    hash={hash}
    authUser={userAuthData.userAuthenticated}
    organizationId={organizationId}
    companyId={companyId}
    userId={userId}
  />
}
export const OrganizationUserViewHandler = () => {
  const params = useParams()
  const location = useLocation()
  const loaderRef = React.useRef<{close: () => void}>()
  const organizationId = params.organizationId || ''
  const userId = params.userId || ''
  const hash = location.hash
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  const {data, loading, error} = useQuery(GET_ORGANIZATION(['with_settings']), {
    variables: {organizationId},
    skip: !organizationId,
  })
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />

  const organization = data.organization
  if (isSubscriptionExpired(organization)) {
    return <Navigate to={`/organization/${organizationId}#general`} />
  }
  return <OrgUserView authUser={userAuthData.userAuthenticated} organizationId={organizationId}
    userId={userId} hash={hash}
  />
}
export const OrganizationBundleViewHandler = () => {
  const params = useParams()
  const location = useLocation()
  const organizationId = params.organizationId || ''
  const bundleId = params.bundleId || ''
  const hash = location.hash

  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  const {data, loading, error} = useQuery(GET_ORGANIZATION(['with_settings']), {
    variables: {organizationId},
    skip: !organizationId,
  })
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />

  const organization = data.organization
  if (isSubscriptionExpired(organization)) {
    return <Navigate to={`/organization/${organizationId}#general`} />
  }

  return <BundleView authUser={userAuthData.userAuthenticated}
    organizationId={organizationId} bundleId={bundleId} hash={hash}
  />
}
export const CoursesViewHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (!canViewAllPrograms(userAuthData.userAuthenticated)) {
    return <ForbiddenView />
  }
  return <SuperEditorCoursesView />
}
export const CourseStatusViewHandler = () => {
  const params = useParams()
  const courseId = params.courseId || ''
  return <CourseStatusView courseId={courseId} />
}
export const UsersViewHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (canViewAllUsers(userAuthData.userAuthenticated)) {
    return <UsersView />
  }
  return <ForbiddenView />
}
export const CompaniesViewHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (canViewAllCompanies(userAuthData.userAuthenticated)) {
    return <CompaniesView />
  }
  return <ForbiddenView />
}
export const PartnersViewHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (canViewPartners(userAuthData.userAuthenticated)) {
    return <PartnersView />
  }
  return <ForbiddenView />
}
export const GnowbeBundleViewHandler = () => {
  const params = useParams()
  const location = useLocation()
  const bundleId = params.bundleId
  const hash = location.hash
  if (!bundleId) return <PageNotFound />
  return <BundleView hash={hash} bundleId={bundleId} />
}
export const SuperAdminJobRunnerHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (isAdmin(userAuthData.userAuthenticated)) {
    return <JobRunner />
  }
  return <ForbiddenView />
}
export const SuperAdminRedisTopicsHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (isAdmin(userAuthData.userAuthenticated)) {
    return <Topics />
  }
  return <ForbiddenView />
}
export const SuperAdminRedisInstaceTopicHandler = () => {
  const params = useParams()
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (!params.instance || !params.name) {
    return <PageNotFound />
  }
  if (isAdmin(userAuthData.userAuthenticated)) {
    return <Topic
      instance={decodeURIComponent(params.instance)}
      topic={decodeURIComponent(params.name)}
    />
  }
  return <ForbiddenView />
}
export const SuperAdminRedisInstanceStreamHandler = () => {
  const params = useParams()
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (!params.instance || !params.name) {
    return <PageNotFound />
  }
  if (isAdmin(userAuthData.userAuthenticated)) {
    return <StreamDetails
      instance={decodeURIComponent(params.instance)}
      stream={decodeURIComponent(params.name)}
    />
  }
  return <ForbiddenView />
}
export const SuperAdminRedisInstanceDLQHandler = () => {
  const params = useParams()
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (!params.instance || !params.name) {
    return <PageNotFound />
  }
  if (isAdmin(userAuthData.userAuthenticated)) {
    return <DLQDetails
      instance={decodeURIComponent(params.instance)}
      dlq={decodeURIComponent(params.name)}
    />
  }
  return <ForbiddenView />
}
export const SuperAdminCourseDeleteHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (isAdmin(userAuthData.userAuthenticated)) {
    return <DeleteCourseConfirmation />
  }
  return <ForbiddenView />
}
export const SuperAdminUserDeleteHandler = () => {
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (isAdmin(userAuthData.userAuthenticated)) {
    return <DeleteUserConfirmation />
  }
  return <ForbiddenView />
}
export const InternalDLOrganizationHandler = () => {
  const params = useParams()
  const location = useLocation()
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (!params.organizationId) return <PageNotFound />
  return <Navigate to={`/organization/${params.organizationId}${location.search}${location.hash}`} replace />
}
export const ExternalDLPlanUpgradeHandler = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  const [getOrgIdFromEnsure, {
    data: ensureData, loading: ensureLoading, error: ensureError,
  }] = useLazyQuery(GET_ORGID_FROM_ENSURE)
  const [getOrganization, {
    data, loading, error,
  }] = useLazyQuery<GetOrganizationRes>(GET_ORGANIZATION(['with_settings']))


  React.useEffect(() => {
    if (params.organizationId === 'undefined') {
      getOrgIdFromEnsure()
      return
    }
    if (!params.organizationId) {
      return
    }
    getOrganization({variables: {organizationId: params.organizationId}})
  }, [params.organizationId])

  React.useEffect(() => {
    if (ensureData && ensureData.organizationId) {
      navigate(`/d/organization/${ensureData.organizationId}/upgrade`, {replace: true})
      getOrganization({variables: {organizationId: ensureData.organizationId}})
    }
  }, [ensureData?.organizationId])

  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (!params.organizationId) return <PageNotFound />
  if (loading || ensureLoading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />
  if (ensureError) return <ErrorCmp error={ensureError} />
  if (!data) return <PageNotFound />

  const organization = data.organization
  if (!organization.billingService
    || (organization.billingService && organization.billingService.pricingType === 'block')
    || (organization.billingService && organization.billingService.planId?.includes('v5'))
    || (organization.billingService && organization.billingService.planId?.startsWith('cu'))
  ) {
    return <Navigate to={`/organization/${organization.id}`} />
  }
  return (
    <Navigate to={`/organization/${organization.id}?scrollTo=settings-billing-details&upgrade=true#settings`}
      replace />
  )
}
export const ExternalDLOrganizationHandler = () => {
  const params = useParams()
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (!params.organizationId) return <PageNotFound />
  return <Navigate to={`/organization/${params.organizationId}`} replace />
}
export const ExternalDLMainGuideHandler = () => {
  const params = useParams()
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  if (userAuthLoading) return <Loading enabled={userAuthLoading} />
  if (userAuthError) return <ErrorCmp error={userAuthError} />
  if (!userAuthData) {
    logoutHandler()
    return <Navigate to="/" replace />
  }
  if (!params.organizationId) return <PageNotFound />
  updateMetaTour('tour/mainDashboardTour', 'false')
  return <Navigate to={`/organization/${params.organizationId}`} replace />
}
