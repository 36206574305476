export type UserReferral = {
  id: string,
  invitationDate: number,
  invitedById: string,
  inviteeEmail: string,
  inviteeId?: string,
  inviteeJoinDate?: number,
  inviteePromoCodeCreatedAt?: number,
  inviteePromoCodeDiscount?: number,
  inviteeSubscriptionStartDate?: number,
  inviterPromoCode?: string,
  inviterPromoCodeCreatedAt?: number,
  inviterPromoCodeDiscount?: number,
  organizationId?: string,
}

export const getUserReferralFragmentName = () => {
  return 'UserReferralFields'
}
export const getUserReferralFragment = () => {
  // tslint:disable-next-line: prefer-template
  return `
    fragment ${getUserReferralFragmentName()} on UserReferral {
      id
      invitationDate
      invitedById
      inviteeEmail
      inviteeId
      inviteeJoinDate
      inviteePromoCodeCreatedAt
      inviteePromoCodeDiscount
      inviteeSubscriptionStartDate
      inviterPromoCode
      inviterPromoCodeCreatedAt
      inviterPromoCodeDiscount
      organizationId
    }
  `
}
