import React, {useState} from 'react'
import { BannerStateType } from './BannerDetails'
import BannerDisclosure from './BannerDisclosure'
import { regexBannerId } from './BannersTabs'

type BannerCreationType = {
  state: BannerStateType,
  setState: (value: BannerStateType) => void,
  cta: boolean,
  setCta: (value: boolean) => void,
  isDate: boolean,
  setIsDate: (value: boolean) => void,
}
export const charLimit = 280
export const idLimit = 3
export const colors = ['#FFFFFF', '#C7D6B0', '#ACFCD9', '#A7ABDD', '#B4D4EE', '#F8C8C5', '#F2CBB8', '#F9DC5C', '#C9B1BD', '#CBD2D0']

const BannerCreation = ({ state, setState, cta, setCta, isDate, setIsDate }: BannerCreationType) => {

  const [selected, setSelected] = useState(0)
  const numOfChar = state.bannerText.length
  const numOfIdChar = state.bannerId.length

  const handleFieldChange = (field, value) => {
    setState({
      ...state,
      [field]: value,
    })
  }

  // TODO(greg) instead of this check, I suggest to use Joi validation which falilies to check the validity of the data
  const checkField = (field: string) => {
    let error = false


    if (field === 'bannerId' && numOfIdChar < 3) {
      error = true
    }

    if (field === 'bannerId' && !state.bannerId.match(regexBannerId)) {
      error = true
    }

    if (field === 'bannerText' && ((numOfChar > charLimit) || !numOfChar)) {
      error = true
    }

    if (field === 'type' && !state.type) {
      error = true
    }
    if (field === 'CTA text' && (state.ctaButton && !state.ctaButton.label)) {
      error = true
    }
    if (field === 'Link' && (state.ctaButton && !state.ctaButton.url)) {
      error = true
    }
    if (field === 'Minimum range copy' && !state.minRangeCopy) {
      error = true
    }
    if (field === 'Maximum range copy' && !state.maxRangeCopy) {
      error = true
    }

    if (error) {
      return 'border-coral bg-form-error'
    }

    return undefined
  }

  return (
    <>
      <div className="bg-white px-8 rounded-b-lg">
        <div className="font-medium text-sm text-listenblue pt-8 uppercase">
        {/* check on staging how does it look */}
          Create your banner
        </div>
        <div className="font-normal text-medgray text-sm mb-4">
          Create one or more banners here. You'll be able to select which banners will be featured in which product code in the next step.
        </div>
        <div className="pb-5">
          <BannerDisclosure
            state={state}
            setState={setState}
            cta={cta} setCta={setCta}
            numOfChar={numOfChar}
            numOfIdChar={numOfIdChar}
            handleFieldChange={handleFieldChange}
            checkField={checkField}
            selected={selected}
            setSelected={setSelected}
            isDate={isDate}
            setIsDate={setIsDate}
          />
        </div>
      </div>
    </>
  )
}

export default BannerCreation
