import React from 'react'
import { Button, ButtonType } from 'components/elements/Button'
import { useMutation } from '@apollo/client'
import { CreateFreeTierOrganizationVars, CREATE_FREE_TIER_ORGANIZATION } from 'mutations/organization'
import { openPrompt, showSnackbar } from './snackbar'
import { handleError } from './utils'
import { track } from 'segment'

export default function ({
  userId, onCompleted, buttonType, buttonSize, fullWidth,
}: {
  userId: string, onCompleted: (orgId: string) => void, buttonType?: ButtonType, buttonSize?: 'big'|'medium'|'small',
  fullWidth?: boolean,
}) {
  const [createOrgM] = useMutation<{res: string}, CreateFreeTierOrganizationVars>(CREATE_FREE_TIER_ORGANIZATION, {
    onError: error => handleError(error),
    onCompleted: (data) => {
      track({event: 'Workspace Created', variables: {
        changedToWorkspaceId: data.res,
        platform: 'dashboard',
      }})
      showSnackbar('Workspace successfully created!', 3000, 'success')
      onCompleted(data.res)
    },
  })
  const createFreeTierOrg = () => {
    openPrompt(
      {
        title: 'Create Free Tier Workspace',
        text: `Enter the name of the new Workspace.
          The field can be left empty to create a new Workspace similar to your user name, this can be changed at any time under Workspace settings.`,
      },
      (text) => {
        if (text == null) return
        createOrgM({
          variables: {
            organizationName: text || undefined,
            userId,
          },
        })
      },
    )
  }
  return (
    <Button type={buttonType || 'default'}
      text="Create my workspace" onClick={createFreeTierOrg}
      size={buttonSize || 'small'} className={fullWidth ? 'w-full' : ''} />
  )
}
