import React from 'react'
import { isAdmin, isAgent, isEditor, isManager, isOrganizationPartner, isSales, isSuperEditor, isCourseEditor, isCompanyEditor, isManager2, isCourseEditor2, isBillingManager } from '../../lib/acl_logic'
import { UserAuthenticated } from '../../models/user'
import { withAuthUser } from '../AuthUserWrapper'

type IfHasRolePropsIn = React.PropsWithChildren<{
  role: string,
  organizationId?: string,
  companyId?: string,
  courseId?: string,
  emptyElement?: JSX.Element,
}>

type IfHasRoleProps = IfHasRolePropsIn & {
  userAuthenticated: UserAuthenticated|null,
}

class IfHasRole extends React.Component<IfHasRoleProps> {
  childrenElements() {
    return typeof this.props.children === 'string' ? <span>{this.props.children}</span> : this.props.children
  }

  emptyElement() {
    if (typeof this.props.emptyElement !== 'undefined') return this.props.emptyElement
    return null
  }

  render() {
    const {userAuthenticated, role, organizationId, companyId, courseId} = this.props

    const roles = role.split('|')
    const hasRole = roles.some((roleInner) => {
      let hasRoleInner = false
      if (roleInner === 'admin') hasRoleInner = isAdmin(userAuthenticated)
      else if (roleInner === 'superEditor') hasRoleInner = isSuperEditor(userAuthenticated)
      else if (roleInner === 'sales') hasRoleInner = isSales(userAuthenticated)
      else if (roleInner === 'partner' && organizationId) {
        hasRoleInner = isOrganizationPartner(userAuthenticated, organizationId)
      } else if (roleInner === 'editor') hasRoleInner = isEditor(userAuthenticated)
      else if (roleInner === 'courseEditor') hasRoleInner = isCourseEditor(userAuthenticated, courseId)
      else if (roleInner === 'courseEditor2') hasRoleInner = isCourseEditor2(userAuthenticated, courseId || '-1')
      else if (roleInner === 'companyEditor' && companyId) hasRoleInner = isCompanyEditor(userAuthenticated, companyId)
      else if (roleInner === 'manager') hasRoleInner = isManager(userAuthenticated, organizationId)
      else if (roleInner === 'manager2' && organizationId) hasRoleInner = isManager2(userAuthenticated, organizationId)
      else if (roleInner === 'agent') hasRoleInner = isAgent(userAuthenticated, companyId || '-1')
      else if (roleInner === 'billingManager' && organizationId) {
        hasRoleInner = isBillingManager(userAuthenticated, organizationId)
      }
      return hasRoleInner
    })

    return (hasRole ? this.childrenElements() : this.emptyElement()) as any
  }
}

export default withAuthUser(IfHasRole)
