export const contentTypes = [
  {payload: 'qa', text: 'Q & A'},
  {payload: 'text', text: 'Text'},
  {payload: 'multiple_choice', text: 'Multiple Choice'},
  {payload: 'video', text: 'Video'},
  {payload: 'photo', text: 'Photo'},
  {payload: 'quote', text: 'Quote'},
  {payload: 'image', text: 'Image'},
  {payload: 'audio', text: 'Audio'},
  {payload: 'auth_web_link', text: 'Authenticated Web Link'},
  {payload: 'link', text: 'Web Link'},
  {payload: 'external', text: 'External Web Link'},
  {payload: 'certificate', text: 'Certificate of completion'},
  {payload: 'word_puzzle', text: 'Word Puzzle'},
]

export const userStudyActionContentTypes = ['qa', 'photo', 'multiple_choice', 'assessment', 'upload', 'word_puzzle']

export const contentTypeToText: {[contentType: string]: string} = contentTypes.reduce((acc, ct) => { acc[ct.payload] = ct.text; return acc }, {} as any)

export const userActions = [
  {payload: 'do', text: 'Do'},
  {payload: 'reflect', text: 'Reflect'},
  {payload: 'choose', text: 'Choose'},
  {payload: 'read', text: 'Read'},
  {payload: 'watch', text: 'Watch'},
  {payload: 'view', text: 'View'},
  {payload: 'listen', text: 'Listen'},
  {payload: 'capture', text: 'Capture'},
  {payload: 'quote', text: 'Quote'},
  {payload: 'graduate', text: 'Graduate'},
]

export function contentTypeToUserAction(contentType: string) {
  switch (contentType) {
    case 'video': return 'watch'
    case 'audio': return 'listen'
    case 'text': return 'read'
    case 'quote': return 'quote'
    case 'link': return 'view'
    case 'external': return 'view'
    case 'image': return 'view'
    case 'qa': return 'do'
    case 'multiple_choice': return 'choose'
    case 'rating': return 'choose'
    case 'photo': return 'capture'
    case 'certificate': return 'graduate'
    case 'auth_web_link': return 'view'
    default: return ''
  }
}

export function getRelevantUserActions(contentType: string) {
  switch (contentType) {
    case 'video':
      return [{payload: 'watch', text: 'Watch'}]
    case 'audio':
      return [{payload: 'listen', text: 'Listen'}]
    case 'text':
      return [{payload: 'read', text: 'Read'}, {payload: 'do', text: 'Do'}]
    case 'quote':
      return [{payload: 'quote', text: 'Quote'}]
    case 'link':
    case 'external':
      return [{
        payload: 'view', text: 'View',
      }, {
        payload: 'watch', text: 'Watch',
      }, {
        payload: 'listen', text: 'Listen',
      }, {
        payload: 'read', text: 'Read',
      }]
    case 'auth_web_link':
    case 'image':
      return [{payload: 'view', text: 'View'}]
    case 'qa':
      return [{payload: 'do', text: 'Do'}, {payload: 'reflect', text: 'Reflect'}]
    case 'multiple_choice':
      return [{payload: 'do', text: 'Do'}, {payload: 'choose', text: 'Choose'}, {payload: 'reflect', text: 'Reflect'}]
    case 'photo':
      return [{payload: 'do', text: 'Do'}, {payload: 'capture', text: 'Capture'}]
    case 'certificate':
      return [{payload: 'graduate', text: 'Graduate'}]
    default:
      return [{payload: 'view', text: 'View'}]
  }
}

export const coursePublishedValues = [
  {payload: true, text: 'Reusable'}, {payload: false, text: 'Custom-Client'},
]

export const courseStatuses = [
  {payload: 'draft', text: 'Draft'},
  {payload: 'pipeline', text: 'Pipeline'},
  {payload: 'ready', text: 'Ready'},
  {payload: 'live', text: 'Live'},
  {payload: 'archived', text: 'Archived'},
]

export const courseDifficulties = [
  {id: 'basic', title: 'Basic', order: 1},
  {id: 'intermediate', title: 'Intermediate', order: 2},
  {id: 'advanced', title: 'Advanced', order: 3},
  {id: 'universal', title: 'Universal', order: 4},
]
