import React from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import LeftDrawerItems from './LeftDrawerItems'
import { withAuthUser } from './AuthUserWrapper'
import { UserAuthenticated } from '../models/user'
import RoutesWrapper from './RoutesWrapper'
import CheckReportAvailable from './CheckReportAvailable'
import CheckUpdates from '../CheckUpdates'
import { GET_COURSE_CATEGORIES } from 'queries/course'
import { CourseCategoriesProvider } from './context'
import Loading from './Loading'
import { useQuery } from '@apollo/client'
import { USER_SUBSCRIPTION } from 'subscriptions/user'
import BottomNotificationArea from './BottomNotificationArea'
import CheckFileImportProgress from './CheckFileImportProgress'
import { Toaster } from 'react-hot-toast'
import ShowBanner from './notifications/banners/ShowBanner'

type LayoutPropsIn = {
  organizationId?: string;
  subscribeToMore?: any;
  history?: any;
}
type LayoutProps = LayoutPropsIn & {
  userAuthenticated?: UserAuthenticated|null;
}

export const ownTheme = {
  spacing: 1,
  palette: {
    primary: {
      main: '#5EC3C5',
    },
    secondary: {
      main: '#B50700',
    },
    text: {
      primary: '#4a4a4a',
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        margin: '10px 0',
        minWidth: 200,
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: 'rgba(249, 250, 251, 1)',
        },
        '&$selected': {
          background: 'rgba(151, 232, 236, 0.5)',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '0px solid',
        fontFamily: 'NotoSans-light',
      },
      head: {
        color: '#a2a2a2',
      },
    },
    MuiAccordionSummary: {
      root: {
        marginLeft: 15,
        marginRight: 15,
      },
    },
    MuiAccordionDetails: {
      root: {
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 15,
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiPaper: {
      rounded: {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important',
        borderRadius: '0.5rem !important',
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'NotoSans-light',
        textTransform: 'capitalize',
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: '#101010',
      },
      containedSecondary: {
        color: 'white',
      },
    },
    MuiTablePagination: {
      root: {
        borderTop: 'solid 1px #e0e0e0',
      },
    },
    MuiSwitch: {
      switchBase: {
        '&$disabled': {
          '&$checked': {
            color: 'rgb(203, 240, 224)',
          },
          '&:not($checked)' : {
            color: 'rgb(250, 215, 217)',
          },
        },
      },
    },
    MuiTooltip: {
      popper: {
        zIndex: 2001, // need to be over modals too
      },
    },
  },
}

export const theme = createTheme(ownTheme)

// tslint:disable-next-line: function-name
function Layout(props: LayoutProps) {
  const [courseCategories, setCourseCategories] = React.useState([])
  const [themetmp, setThemetmp] = React.useState(theme)

  const {data, error, loading} = useQuery(GET_COURSE_CATEGORIES)
  React.useEffect(
    () => {
      if (props.userAuthenticated) {
        props.subscribeToMore({
          document: USER_SUBSCRIPTION,
          variables: { userId: props.userAuthenticated.id },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            if (!prev) return
          },
        })
      }
    },
    [props.userAuthenticated],
  )

  React.useEffect(
    () => {
      if (!loading && !error && data) {
        setCourseCategories(data.res)
      }
    },
    [data],
  )

  if (loading) {
    return <div className="flex flex-row h-screen items-center justify-center">
      <Loading enabled={loading} />
    </div>
  }

  return (
  <MuiThemeProvider theme={themetmp}>
    <CourseCategoriesProvider value={{
      categories: courseCategories,
      categoryTitleById: (id: string) => {
        const category = courseCategories.find(c => c.id === id)
        if (!!category) {
          return category.title
        }
        return 'Not Set'
      },
    }}>
      <div className="flex">
        {/* Sidebar */}
        <LeftDrawerItems
          setThemetmp={setThemetmp}
        />

        {/* Content */}
        <div id="app-container" className="pl-24 lg:pl-8 w-full overflow-x-hidden h-screen overflow-y-auto pt-6 px-8">
          {/* FIXED HEADER */}
          <div className="gnowbe-banner sticky -top-2 -mt-6 -mx-8 mb-6 z-[1200]">
            {props.userAuthenticated?.id && <ShowBanner uid={props.userAuthenticated?.id} />}
          </div>
          <div className="pb-20 ">
            <RoutesWrapper />
          </div>
        </div>
      </div>
    </CourseCategoriesProvider>

    {/* Snackbar */}
    <div id="snackbars" className="z-50" />

    <BottomNotificationArea>
      <CheckFileImportProgress />
      <CheckReportAvailable />
    </BottomNotificationArea>

    {/* Check for updates */}
    <CheckUpdates />
    {/* Toaster */}
    <div><Toaster
      position="bottom-center"
      reverseOrder={false}/>
    </div>
  </MuiThemeProvider>
  )
}
export default withAuthUser(Layout)


