import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import fetch from 'isomorphic-fetch'
import { pick } from 'ramda'
import React from 'react'
import * as logger from '../../lib/console_logger'
import { getGnowbeApiToken, logoutHandler, signinWithApiToken } from '../../lib/auth'
import CLIENT_SETTINGS from '../../lib/client_settings'
import { UserAuthenticated } from '../../models/user'
import { withAuthUser } from '../AuthUserWrapper'
import i18n, { initLocalization, languageLabeled } from '../i18n'
import { openAlert, showModal, showSnackbar } from '../snackbar'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Tabs from '@material-ui/core/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { useNavigate } from 'react-router-dom'


type ProfileEditProps = {
  userAuthenticated: UserAuthenticated|null;
}

function a11yProps(index: any) {
  return {
    id: `tab-account-settings-${index}`,
    'aria-controls': `tabpanel-account-settings-${index}`,
  }
}

class ProfileEdit extends React.Component<ProfileEditProps, {firstName?: string; lastName?: string, dirty?: boolean}> {
  constructor(props: ProfileEditProps) {
    super(props)
    this.state = pick(['firstName', 'lastName'], props.userAuthenticated?.profile || { firstName: 'John', lastName: 'Doe' }) as any
    this.saveUser = this.saveUser.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps: ProfileEditProps) {
    if (!newProps.userAuthenticated) return

    this.setState(pick(['firstName', 'lastName'], newProps.userAuthenticated.profile) as any)
  }

  private async saveUser(event: React.FormEvent<any>) {
    event.preventDefault()
    if (this.props.userAuthenticated && this.state.firstName && this.state.lastName) {
      try {
        const apiToken = await getGnowbeApiToken()
        const res = await fetch(`${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/users/${this.props.userAuthenticated.id}/profile`, {
          method: 'PUT',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'x-gnowbe-source': 'gnowbe-dashboard 1',
            authorization: `${apiToken.tokenType} ${apiToken.token}`,
          } as any,
          body: JSON.stringify({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            fullName: `${this.state.firstName} ${this.state.lastName}`,
          }),
        })


        if (res.status === 401) {
          logoutHandler()
        }
        const json = await res.json()
        if (json.error) {
          throw new Error(json.error.message)
        }

        if (res.status === 200) {
          // const cacheres = this.props.client.readQuery<GetUserAuthenticatedRes>({
          //   query: GET_USER_AUTHENTICATED,
          //   variables: {
          //     userId: this.props.userAuthenticated.id,
          //   },
          // })

          // this.props.client.writeQuery({
          //   query: GET_USER_AUTHENTICATED,
          //   variables: {
          //     userId: this.props.userAuthenticated,
          //   },
          //   data: {
          //     ...cacheres,
          //     userAuthenticated: {
          //       ...cacheres.userAuthenticated,
          //       profile: {
          //         ...cacheres.userAuthenticated.profile,
          //         firstName: this.state.firstName,
          //         lastName: this.state.lastName,
          //       },
          //     },
          //   },
          // })
          showSnackbar(i18n.t('profile_updated_successfully'), undefined, 'success')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      } catch (err: any) {
        logger.warn('Error while saving first and last name', err)
        openAlert(
          {
            title: i18n.t('error'),
            description: `${i18n.t('an_error_happened')} ${i18n.t('first_last_name_not_saved')}.\n\n${err.message}`,
          },
        )
      }
    } else {
      openAlert({
        title: i18n.t('missing_data'),
        description: i18n.t('fill_in_first_and_last_name'),
      })
    }
  }

  handleFirstname = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({firstName: e.target.value}, this.checkDirty)
  }

  handleLastname = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({lastName: e.target.value}, this.checkDirty)
  }

  checkDirty = () => {
    if (this.props.userAuthenticated
      && (this.state.firstName !== this.props.userAuthenticated.profile.firstName
      || this.state.lastName !== this.props.userAuthenticated.profile.lastName)) {
      return this.setState({dirty: true})
    }

    this.setState({dirty: false})
  }

  restoreDefault = () => {
    this.setState({
      dirty: false,
      firstName: this.props.userAuthenticated?.profile.firstName || '',
      lastName: this.props.userAuthenticated?.profile.lastName || '',
    })
  }

  render() {
    return <div>
      <form onSubmit={this.saveUser}>
        <TextField
          label={i18n.t('first_name')}
          value={this.state.firstName}
          onChange={this.handleFirstname}
        />
        <br/>
        <TextField
          label={i18n.t('last_name')}
          value={this.state.lastName}
          onChange={this.handleLastname}
        />
        <br/>
        <Divider style={{margin: '1em 0 1em'}} />
        <Button
          disabled={!this.state.dirty}
          type="submit"
          variant="contained"
          color="primary"
        >
          {i18n.t('update')}
        </Button>

        {this.state.dirty && <Button style={{marginLeft: 5}} onClick={this.restoreDefault} variant="outlined">{i18n.t('undo')}</Button>}
      </form>
    </div>
  }
}


type LoggingInProps = {
  tokenType?: string;
  accessToken?: string;
  expiresAt?: number;
  redirectTo?: string;
}

export const LoggingIn = (props: LoggingInProps) => {
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = React.useState('')

  React.useEffect(() => {
    signinWithApiToken(props.tokenType, props.accessToken, props.expiresAt)
    .then(() => {
      setTimeout(() => navigate(props.redirectTo || '/home'), 300)
    })
    .catch((err) => {
      setErrorMsg(err.message)
    })
  }, [])

  if (errorMsg) {
    return <div className="container">
      <h3 style={{marginBottom: '25px'}}>
        {i18n.t('an_error_happen')}
      </h3>

      {errorMsg}<br/>

      <br/>

      <Button color="primary" variant="contained" onClick={() => navigate('/')}>{i18n.t('try_again')}</Button>
    </div>
  }

  return <div className="container">
    {i18n.t('please_wait_while_we_log_you_in')} ...
  </div>
}


type LanguagePropsIn = {
  language: string;
}

class LanguageHandle extends React.Component<{}, LanguagePropsIn> {
  constructor(props) {
    super(props)
    this.state = {
      language: i18n.language,
    }
  }

  onChangeLanguage = async(event) => {
    await initLocalization(event.target.value)
    this.setState({language: event.target.value}, () => {
      localStorage.setItem('currentLanguage', event.target.value)
      window.location.reload()
    })
  }

  render() {
    return <div>
      <TextField
        id="select_language_id"
        select
        label={i18n.t('language')}
        value={this.state.language || 'en'}
        onChange={this.onChangeLanguage}
        margin="normal"
      >
        {Array.from(languageLabeled.keys()).map((option) => {
          return <MenuItem key={option} value={option}>
            {languageLabeled.get(option)}
          </MenuItem>
        })}
      </TextField>
    </div>
  }
}


type AccountViewPropsIn = {
  autoAuthUserEmail?: string;
  redirectTo?: string;
}
type AccountViewProps = AccountViewPropsIn & {
  userAuthenticated: UserAuthenticated|null,
}

class AccountView extends React.Component<AccountViewProps, {tab: number}> {
  constructor(props: AccountViewProps) {
    super(props)
    this.state = {tab: 0}
  }

  switchToAccount(email) {
    const rootUrls = {
      api: CLIENT_SETTINGS.public.gnowbeBeUrl,
      dashboard: document.location && document.location.origin || 'https://dashboard.gnowbe.com'
    }
    const redirectTo = `${rootUrls.dashboard}/account${(this.props.redirectTo ? `?redirectTo=${encodeURIComponent(this.props.redirectTo)}` : '')}`
    window.location.assign(`${rootUrls.api}/signup/login?redirectTo=${encodeURIComponent(redirectTo)}&continueAs=${encodeURIComponent(email)}&rememberMe=true&distinctId=${Math.random().toString(36).slice(-9)}`)
  }

  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({tab: newValue})
  }

  render() {
    if (this.props.autoAuthUserEmail
      && this.props.userAuthenticated
      && this.props.userAuthenticated.email === this.props.autoAuthUserEmail
    ) {
      setTimeout(() => window.location.assign('/'), 100)
      return <div className="container">
        {i18n.t('redirecting')}...
      </div>
    }
    if (this.props.autoAuthUserEmail) {
      return <div className="container">
        <div className="accounts-dialog">
          {i18n.t('recommended')}:<br/>
          <br/>

          <button className="login-button btn btn-default continue-as"
            onClick={e => void e.preventDefault() || this.switchToAccount(this.props.autoAuthUserEmail)}>
            {i18n.t('Continue as')} {this.props.autoAuthUserEmail}
          </button>

          {this.props.userAuthenticated ?
            <div>
              <br/>
              OR<br/>
              <br/>
              <button className="login-button btn btn-primary continue-as"
                onClick={e => void e.preventDefault() ||  window.location.assign(this.props.redirectTo || '/home')}>
                {i18n.t('Continue as')} {this.props.userAuthenticated.email}
              </button>
            </div>
          : null}
        </div>
      </div>
    }

    return <div className="h-screen -my-20">
      <div className="welcome-container">
        <Grid container direction="row" justifyContent="space-around" alignItems="flex-start" spacing={10}>
          {this.props.userAuthenticated
            && <Grid item style={{marginRight: 10}}>
              <Grid
                style={{
                  boxShadow: '0 1px 1px rgba(0, 0, 0, 0.2)',
                  minWidth: 250,
                  backgroundColor: '#fff',
                  borderRadius: '10px', padding: 15,
                  margin: 10,
                }}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={10}
              >
                <Grid item>
                  {this.props.userAuthenticated.profile.imageUrl
                    ? <Avatar
                        style={{height: 80, width: 80}}
                        src={this.props.userAuthenticated.profile.imageUrl}
                        alt={(this.props.userAuthenticated.profile.fullName || '').slice(0, 1)}
                      />
                    : <Avatar
                        style={{ background: '#FF5A4B', height: 80, width: 80, fontSize: '2.5em' }}
                      >
                        {(this.props.userAuthenticated.profile.fullName || '').slice(0, 1)}
                      </Avatar>
                  }
                </Grid>
                <Grid item>
                  <b className="text-coral">
                    {this.props.userAuthenticated.profile.fullName}
                  </b>
                </Grid>
                <Grid item className="text-teak">
                  {this.props.userAuthenticated.email}
                  <Divider light style={{margin: '1em 0 1em'}} />
                </Grid>
                <Grid item style={{width: '100%'}}>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item><Typography variant="subtitle2"># {i18n.t('organizations_admin')}</Typography></Grid>
                    <Grid item>
                      <Typography>
                        {this.props.userAuthenticated.managerOrganizations.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{width: '100%'}}>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle2"># {i18n.t('organizations_curator')}</Typography>
                    </Grid>
                    <Grid item><Typography>{this.props.userAuthenticated.editorOrganizations.length}</Typography></Grid>
                  </Grid>
                </Grid>
                <Grid item style={{width: '100%'}}>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle2"># {i18n.t('groups_curator')}</Typography>
                    </Grid>
                    <Grid item><Typography>{this.props.userAuthenticated.editorCompanies.length}</Typography></Grid>
                  </Grid>
                </Grid>
                <Grid item style={{width: '100%'}}>
                  <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle2"># {i18n.t('programs_curator')}</Typography>
                    </Grid>
                    <Grid item><Typography>{this.props.userAuthenticated.editorCourses.length}</Typography></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid item>
            <Grid
              container
              style={{
                boxShadow: '0 1px 1px rgba(0, 0, 0, 0.2)',
                border: '1px solid #efefef',
                borderRadius: '10px',
                minWidth: '500px',
                margin: 10,
                backgroundColor: '#fff',
              }}
              direction="column"
              spacing={10}
            >
              <Grid item>
                <AppBar
                  elevation={0}
                  style={{
                    backgroundColor: 'transparent',
                    borderBottom: '1px solid #efefef',
                    color: '#4a4a4a',
                  }}
                  position="static"
                >
                  <Tabs value={this.state.tab} onChange={this.handleChangeTab}>
                    <Tab label="Profile" {...a11yProps(0)} />
                    <Tab label="Settings" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <div style={{padding: 15}}>
                  {this.state.tab === 0 && <div>
                    <ProfileEdit userAuthenticated={this.props.userAuthenticated} />
                  </div>
                  }
                  {this.state.tab === 1 && <LanguageHandle />
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="flex justify-center">
            <Button onClick={() => {
              showModal({
                id: 'logoutFromEverywhere',
                title: i18n.t('log_out_everywhere_title'),
                content: [{
                  element: <div className="p-4">{i18n.t('log_out_everywhere_text')}</div>,
                }],
                actions: [
                  {text: i18n.t('log_out_everywhere_button_everywhere'), getActionEvent: async () => await logoutHandler(false, true)},
                  {text: i18n.t('log_out_everywhere_button_here'), color: 'primary', getActionEvent: async () => await logoutHandler(false)},
                ],
              })
            }} variant="contained" color="secondary">Logout</Button>
          </Grid>
        </Grid>
      </div>

      <img src="/img/gnomes/greeter.png" style={{ height: '200px', position: 'absolute', right: '40px', bottom: 0 }} />

    </div>
  }
}

export default withAuthUser(AccountView)
