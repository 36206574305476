import { gql } from '@apollo/client'
import { getOrganizationFragment, getOrganizationFragmentName } from 'models/organization'

export const ORGANIZATION_SUBSCRIPTION = gql`
  ${getOrganizationFragment(['with_contact', 'with_tags', 'with_settings', 'with_externalUserAttributes'])}
  subscription ListenOrganizationChanges($organizationId: String!) {
    organization: listenOrganizationChanges(organizationId: $organizationId) {
      ...${getOrganizationFragmentName(['with_contact', 'with_tags', 'with_settings', 'with_externalUserAttributes'])}
    }
  }
`
