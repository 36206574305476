import React from 'react'
import { CompanyBasic, CompanyOrganizationSettings, CompanySubscriptions, isCourseDistributedToCmp } from '../../models/company'
import ErrorCmp from '../Error'
import { withAuthUser } from '../AuthUserWrapper'
import { UserAuthenticated } from '../../models/user'
import i18n from '../i18n'
import Checkbox from '@material-ui/core/Checkbox'
import { handleApolloError } from 'utils/graphql'
import { openDialog, showSnackbar } from '../snackbar'
import Modal from '../Modal'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { CourseBasic, CourseDistributionParams, CourseType, getErrorMessageIfNotAllowedDistribute, notAllowedDistributeBecauseOfFeature } from 'models/course'
import { useMutation, useQuery } from '@apollo/client'
import Label from 'components/Label'
import DatePicker from 'react-datepicker'
import { GetCompaniesAllowedToDistributeCourseToRes, GetCompaniesAllowedToDistributeCourseToVars, GetCompaniesWithCourseDistributionRes, GetCompaniesWithCourseDistributionVars, GET_COMPANIES_ALLOWED_TO_DISTRIBUTE_COURSE_TO, GET_COMPANIES_WITH_COURSE_DISTRIBUTION, ToggleOrgCourseDistSelection, ToggleOrgsCourseDistributionVars, TOGGLE_ORGS_COURSE_DISTRIBUTION } from 'queries/company'
import { pickBy, uniq } from 'ramda'
import Tooltip from '@material-ui/core/Tooltip'
import { UserContext } from 'components/context'
import { GetCompanyIdsCourseAlreadyDistributedToRes, GetCompanyIdsCourseAlreadyDistributedToVars, GET_COMPANYIDS_COURSE_ALREADY_DISTRIBUTED_TO } from 'queries/course'
import { Warning } from 'components/notifications/Alerts'
import Badge from '@material-ui/core/Badge'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Trans } from 'react-i18next'
import clsx from 'clsx'
import MyTextField from 'components/TextField'
import Table2, { TableOptions } from 'components/Table2'
import { ToolbarCommands } from 'components/TableToolbar'
import { isSubscriptionExpired } from 'lib/acl_logic'
import { track } from 'segment'
import { userRoles } from 'lib/utils'
import { logoutHandler } from 'lib/auth'
import { GetUserOrganizationCompanies2Res, GetUserOrganizationCompanies2Vars, GET_USER_ORGANIZATION_COMPANIES2 } from 'components/metrics/organization/user/Companies'
import DistributeToModal from './DistributeToModal'
import { GetStatusByString } from 'components/utils/StatusCircle'

type CompaniesTableProps = {
  options: TableOptions,
  onOptionsChange: (o: TableOptions) => void,
  loading: boolean,
  course: CourseBasic&CourseType,
  companies: (CompanyBasic&CompanySubscriptions&CompanyOrganizationSettings&{isCourseDistributed: boolean})[],
  companiesCount: number,
  companiesWithCourseDistribution?: string[],
  selectionMode: 'selectOneByOne'|'selectedAll',
  onSelectionModeChange: (mode: 'selectOneByOne'|'selectedAll') => void,
  onToggleDistribution: (companyId: CompanyBasic&CompanySubscriptions, distributed: boolean) => void,
  isDirty: {hasChanges: boolean, newDistributions: boolean, numToDist: number, numToUndist: number},
  onSave: (distParams: Partial<Omit<CourseDistributionParams, 'courseId'>>) => void,
  onCancel: () => void,
  disableDitributionToAll?: boolean,
  refetch: () => void,
}

const CompaniesTable = (props: CompaniesTableProps) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [startDateDistribution, setStartDateDistribution] = React.useState<{
    enabled: boolean,
    value: number,
  }>({enabled: false, value: new Date().getTime()})

  const [availableUntil, setAvailableUntil] = React.useState<{
    enabled: boolean,
    value: number,
  }>({enabled: false, value: new Date().getTime()})

  const [availableFor, setAvailableFor] = React.useState<{
    enabled: boolean,
    value: number,
  }>({enabled: false, value: 0})

  const [dueDate, setDueDate] = React.useState<{
    enabled: boolean,
    value: number,
  }>({enabled: false, value: new Date().getTime()})


  const [manualReviewsSkipEndorsment, setManualReviewsSkipEndorsment] = React.useState(false)
  const [showHintsAfterAssessmentsCompleted, setShowHintsAfterAssessmentsCompleted] = React.useState(false)
  const [hideAssessmentsAfterCompleted, setHideAssessmentsAfterCompleted] = React.useState(false)

  const closeModal = () => {
    setOpenModal(false)
  }

  const saveChanges = () => {
    if (props.isDirty.newDistributions && !openModal) {
      // Show modal to set distribution params
      setOpenModal(true)
      return
    }
    props.onSave({
      manualReviewsSkipEndorsment,
      showHintsAfterAssessmentsCompleted,
      hideAssessmentsAfterCompleted,
      absoluteDeadline: availableUntil.enabled ? availableUntil.value : undefined,
      dueDate: dueDate.enabled ? dueDate.value : undefined,
      relativeDeadline: availableFor.enabled ? availableFor.value : undefined,
      futureDistribution: startDateDistribution.enabled ? startDateDistribution.value : undefined,
    })
    setOpenModal(false)
  }

  if (!props.loading && props.companies.length < 1) {
    return <div style={{padding: 15}} >
      <h4>{i18n.t('no_privileges_to_distribute_program_or_not_published')}</h4>
    </div>
  }

  const columns = [
    {accessor: 'actions', Header: i18n.t('distributed'), disableSortBy: true, align: 'start', width: 80},
    {accessor: 'name', Header: i18n.t('name'), disableSortBy: true, align: 'start'},
    {accessor: 'organizatioName', Header: i18n.t('organization_name'), disableSortBy: true, align: 'start'},
  ]

  return (
    <div>
      <h4 className="text-sm">
        {i18n.t('explanation_distribution_adding_group_to')}
      </h4>

      {props.isDirty.hasChanges && <div className="mt-4">
        <Warning message={<div className="flex flex-col">
          {props.isDirty.numToDist > 0 && <div>
            <Trans i18nKey="course_will_be_distributed_to_preview" values={{numToDist: props.isDirty.numToDist}} />
          </div>}
          {props.isDirty.numToUndist > 0 && <div>
            <Trans i18nKey="course_will_be_undist_from_preview" values={{numToUndist: props.isDirty.numToUndist}} />
          </div>}
        </div>} />
      </div>}
      {/* CHOOSE SELECTION MODE */}
      {/* {!props.disableDitributionToAll && <FormControlLabel
        className="mt-4 -mr-0 -mb-9 float-right"
        control={
          <Switch
            color="primary"
            size="medium"
            checked={props.selectionMode === 'selectOneByOne' ? true : false}
            onChange={(_, checked) =>
              props.onSelectionModeChange(checked ? 'selectOneByOne' : 'selectedAll')
            }
          />
        }
        label={
          <Label label={i18n.t('select_groups_manually')} helpTooltip={i18n.t('clear_all_selected_companies')} />
        }
      />} */}
      {!props.disableDitributionToAll && <FormControlLabel
        className="mt-4 -mr-0 -mb-9 float-right"
        control={
          <Checkbox
            color="primary"
            size="medium"
            checked={props.selectionMode === 'selectOneByOne' ? false : true}
            onChange={(_, checked) =>
              props.onSelectionModeChange(checked ? 'selectedAll' : 'selectOneByOne')
            }
          />
        }
        label={
          <Label label="Select all groups" helpTooltip={`${i18n.t('clear_all_selected_companies')}`} />
        }
      />

      }

      {/* TABLE OF COMPANIES */}
      <Table2
        options={props.options}
        onOptionsChange={props.onOptionsChange}
        count={props.companiesCount}
        loading={props.loading}
        toolbar={{
          placeholder: i18n.t('filter_by_name'),
          onRefresh: props.refetch,
          getCommands: [{
            componentType: 'button',
            buttonText: i18n.t('undo'),
            disabled: !props.isDirty.hasChanges,
            onClick: props.onCancel,
          }, {
            componentType: 'button',
            buttonText: `${i18n.t('save')} (${props.isDirty.numToDist + props.isDirty.numToUndist})`,
            onClick: saveChanges,
            disabled: !props.isDirty.hasChanges,
            type: 'secondary',
          }] as ToolbarCommands,
        }}
        columns={columns}
        data={props.companies.map((cmp, i) => {
          return {
            id: cmp.id,
            actions: <Tooltip title={isSubscriptionExpired(cmp.organization)
              ? 'Not allowed. Subscription is expired.'
              : getErrorMessageIfNotAllowedDistribute(props.course, cmp.organization)}>
              <div>
                <Checkbox
                  icon={<Badge color="secondary"
                      variant="dot"
                      invisible={cmp.isCourseDistributed === isCourseDistributedToCmp(props.course.id, cmp) || props.companiesWithCourseDistribution?.includes(cmp.id)}
                    ><CheckBoxOutlineBlankIcon /></Badge>
                  }
                  checkedIcon={<Badge color="secondary"
                      variant="dot"
                      invisible={cmp.isCourseDistributed === isCourseDistributedToCmp(props.course.id, cmp) || props.companiesWithCourseDistribution?.includes(cmp.id)}
                    ><CheckBoxIcon /></Badge>
                  }
                  checked={cmp.isCourseDistributed && !props.companiesWithCourseDistribution?.includes(cmp.id)}
                  color="primary"
                  disabled={(notAllowedDistributeBecauseOfFeature(props.course, cmp.organization)
                    || isSubscriptionExpired(cmp.organization)) && !cmp.isCourseDistributed || props.companiesWithCourseDistribution?.includes(cmp.id)}
                  onClick={(e) => {
                    e.stopPropagation()
                    props.onToggleDistribution(cmp, !!e.target['checked'])
                  }}
                />
                {
                props.companiesWithCourseDistribution?.includes(cmp.id)
                  ? GetStatusByString('scheduled')
                  : isCourseDistributedToCmp(props.course.id, cmp)
                    ? GetStatusByString('active')
                    : ''
                }
              </div>
            </Tooltip>,
            name: cmp.name,
            organizatioName: cmp.organizationName,
          }
        })}
      />

      {/* MODAL FOR DISTRIBUTION PARAMS */}
      <Modal title={i18n.t('disitrbute_program_to_all_groups')}
        open={openModal} onClose={closeModal}
        actions={[
          {text: i18n.t('distribute'), getActionEvent: saveChanges, color: 'primary'},
          {text: i18n.t('cancel'), getActionEvent: closeModal},
        ]}
        description={i18n.t('settings_for_distribution_title')}
        content={[{
          element: <DistributeToModal course={props.course} startDateDistribution={startDateDistribution} setStartDateDistribution={setStartDateDistribution} availableUntil={availableUntil} setAvailableUntil={setAvailableUntil} availableFor={availableFor} setAvailableFor={setAvailableFor} dueDate={dueDate} setDueDate={setDueDate} manualReviewsSkipEndorsment={manualReviewsSkipEndorsment} setManualReviewsSkipEndorsment={setManualReviewsSkipEndorsment} showHintsAfterAssessmentsCompleted={showHintsAfterAssessmentsCompleted} setShowHintsAfterAssessmentsCompleted={setShowHintsAfterAssessmentsCompleted} hideAssessmentsAfterCompleted={hideAssessmentsAfterCompleted} setHideAssessmentsAfterCompleted={setHideAssessmentsAfterCompleted} />,
        }]}
      />
    </div>
  )
}


const ROWS_PER_PAGE_DEFAULT = 10

type PropsIn = {
  userAuthenticated: UserAuthenticated|null;
  course: CourseBasic&CourseType;
  initialOptions?: TableOptions;
  onSaveDistribution?: () => void;
  onOptionsChange?: (o: TableOptions) => void;
  organizationId?: string;
  disableDitributionToAll?: boolean;
}

type IncludeExcludeArrayType = {companyId: string, organizationId: string}[]

// tslint:disable-next-line: function-name
function Companies(p: PropsIn) {
  const [options, setOptions] = React.useState({
    filterText:  p.initialOptions && p.initialOptions.filterText || '',
    page: p.initialOptions && p.initialOptions.page || 0,
    rowsPerPage: p.initialOptions && p.initialOptions.rowsPerPage || ROWS_PER_PAGE_DEFAULT,
  })
  const countRef = React.useRef(0)
  const userData = React.useContext(UserContext)

  const {
    data: distributedToData,
    loading: distributedToLoading,
    error: distributedToError,
    refetch: distributedToRefetch,
  } = useQuery<GetCompanyIdsCourseAlreadyDistributedToRes, GetCompanyIdsCourseAlreadyDistributedToVars>(
    GET_COMPANYIDS_COURSE_ALREADY_DISTRIBUTED_TO, {
      variables: {
        organizationId: p.organizationId,
        courseId: p.course.id,
      },
      fetchPolicy: 'cache-and-network',
    },
  )
  if (distributedToError) return <ErrorCmp error={distributedToError} />
  const courseAlreadyDistributedTo = (distributedToLoading || !distributedToData) ? [] : distributedToData.res

  const {
    data,
    loading,
    error,
    refetch,
    variables,
  } = useQuery<GetCompaniesAllowedToDistributeCourseToRes, GetCompaniesAllowedToDistributeCourseToVars>(
    GET_COMPANIES_ALLOWED_TO_DISTRIBUTE_COURSE_TO(['with_subscriptions', 'with_organization_settings']), {
      variables: {
        searchText: options.filterText,
        skip: options.page * options.rowsPerPage,
        limit: options.rowsPerPage,
        courseId: p.course.id,
        userId: p.userAuthenticated!.id,
        organizationId: p.organizationId,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  const companiesWithCourseDistribution = useQuery<GetCompaniesWithCourseDistributionRes, GetCompaniesWithCourseDistributionVars>(
    GET_COMPANIES_WITH_COURSE_DISTRIBUTION(), {
      variables: {
        courseId: p.course.id,
        organizationId: p.organizationId!,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  const companiesData = useQuery<GetUserOrganizationCompanies2Res, GetUserOrganizationCompanies2Vars>(
    GET_USER_ORGANIZATION_COMPANIES2, {
      variables: {
        userId: p.userAuthenticated!.id || '',
        organizationId: p.organizationId!,
      },
      fetchPolicy: 'cache-first',
    },
  )

  const companies = !error && !loading && data ? data.companiesRes.companies : []
  const companiesCount = !error && !loading && data ? data.companiesRes.companiesCount : countRef.current
  countRef.current = companiesCount

  const [selectionMode, setSelectionMode] = React.useState<'selectOneByOne'|'selectedAll'>('selectOneByOne')
  // For selectOneByOne contains companyIds course needs to be distributed to
  // For selectAll always empty
  const [includeCmpIds, setIncludeCmpIds] = React.useState([] as IncludeExcludeArrayType)
  const [excludeCmpIds, setExcludeCmpIds] = React.useState([] as IncludeExcludeArrayType)
  // Includes companyIds for which course should be undistributed from (for both modes)
  const [undistFromCmpIds, setUndistFromCmpIds] = React.useState([] as IncludeExcludeArrayType)

  const [
    updateMultipleDistribution,
  ] = useMutation<any, ToggleOrgsCourseDistributionVars>(TOGGLE_ORGS_COURSE_DISTRIBUTION, {
    onError: error => handleApolloError(error),
    onCompleted: (res) => {
      p.onSaveDistribution && p.onSaveDistribution()
      showSnackbar(i18n.t('success'), 2000, 'success')
    },
  })

  if (error) return <ErrorCmp error={error} />

  const isDistributed = (cmp: CompanyBasic&CompanySubscriptions) => {
    if (selectionMode === 'selectOneByOne') {
      if (undistFromCmpIds.some(un => un.companyId === cmp.id)) return false
      if (includeCmpIds.some(incl => incl.companyId === cmp.id)) return true
      if (courseAlreadyDistributedTo.includes(cmp.id)) return true
      return false
    }
    if (selectionMode === 'selectedAll') {
      return excludeCmpIds.every(excl => excl.companyId !== cmp.id)
    }
    return false
  }

  const onSelectionModeChange = (mode: 'selectOneByOne'|'selectedAll') => {
    if (p.disableDitributionToAll) return
    if (selectionMode === mode) return
    setSelectionMode(mode)
    setIncludeCmpIds([])
    setExcludeCmpIds([])

    const tmpUndist = mode === 'selectOneByOne'
      ? courseAlreadyDistributedTo.map(c => {
        return {
          companyId: c,
          organizationId: p.organizationId,
        }
      })
      : []
    setUndistFromCmpIds(tmpUndist as IncludeExcludeArrayType)
  }

  const toggleDistribution = (company: CompanyBasic&CompanySubscriptions, distributed: boolean) => {
    if (distributed) {
      setUndistFromCmpIds(undistFromCmpIds.filter(cmp => cmp.companyId !== company.id))
      if (!courseAlreadyDistributedTo.includes(company.id) && selectionMode !== 'selectedAll') {
        setIncludeCmpIds(uniq(includeCmpIds.concat({companyId: company.id, organizationId: company.organizationId})))
      }
      if (selectionMode === 'selectedAll') {
        setExcludeCmpIds(excludeCmpIds.filter(cmp => cmp.companyId !== company.id))
      }
    } else {
      setExcludeCmpIds(uniq(excludeCmpIds.concat({companyId: company.id, organizationId: company.organizationId})))
      if (courseAlreadyDistributedTo.includes(company.id)) {
        setUndistFromCmpIds(uniq(undistFromCmpIds.concat({
          companyId: company.id, organizationId: company.organizationId,
        })))
      }

      setIncludeCmpIds(includeCmpIds.filter(cmp => cmp.companyId !== company.id))
    }
  }

  const onCancel = () => {
    setSelectionMode('selectOneByOne')
    setIncludeCmpIds([])
    setExcludeCmpIds([])
    setUndistFromCmpIds([])
  }

  const isDirty = () => {
    if (selectionMode === 'selectOneByOne') {
      return {
        hasChanges: (includeCmpIds.length || undistFromCmpIds.length) ? true : false,
        newDistributions: includeCmpIds.length > 0,
        numToDist: includeCmpIds.length,
        numToUndist: undistFromCmpIds.length,
      }
    }

    const companiesInFutureDistribution = companiesWithCourseDistribution.data?.companies || []

    const numToDist = companiesCount - companiesInFutureDistribution.length - uniq(excludeCmpIds.map(cmp => cmp.companyId)
      .concat(undistFromCmpIds.map(cmp => cmp.companyId))
      .concat(courseAlreadyDistributedTo)).length
    const numToUndist = undistFromCmpIds.length

    return {
      hasChanges: numToDist > 0 || numToUndist > 0,
      newDistributions: numToDist > 0,
      numToDist,
      numToUndist,
    }
  }

  /**
   *
   * @param organizations (array of uniq organizationIds)
   * @returns map<organizationId, addTo>
   */
  const getAddToGroupByOrg = (organizations: string[]) => {
    const map = new Map<string, ToggleOrgCourseDistSelection>()
    for (let i = 0; i < organizations.length; i = i + 1) {
      map.set(organizations[i], selectionMode === 'selectOneByOne'
        ? {onlyCompanyIds: includeCmpIds
          .filter(incl => incl.organizationId === organizations[i])
          .map(incl => incl.companyId)}
        : {searchText: options.filterText, excludeCompanyIds: excludeCmpIds
          .filter(excl => excl.organizationId === organizations[i])
          .map(excl => excl.companyId)})
    }
    return map
  }

  /**
   *
   * @param organizations (array of uniq organizationIds)
   * @returns map<organizationId, removeFrom>
   */
  const getRemoveFromGroupByOrg = (organizations: string[]) => {
    const map = new Map<string, ToggleOrgCourseDistSelection>()
    for (let i = 0; i < organizations.length; i = i + 1) {
      map.set(organizations[i], {
        onlyCompanyIds: undistFromCmpIds
          .filter(und => und.organizationId === organizations[i])
          .map(und => und.companyId),
      })
    }
    return map
  }

  const onSave = (distParams: Partial<Omit<CourseDistributionParams, 'courseId'>>) => {
    const organizations = uniq(includeCmpIds.map(i => i.organizationId)
      .concat(excludeCmpIds.map(e => e.organizationId))
      .concat(undistFromCmpIds.map(u => u.organizationId)))

    if (organizations.length === 0 && !!p.organizationId) {
      organizations.push(p.organizationId)
    }
    const addToGroupByOrg = getAddToGroupByOrg(organizations)
    const removeFromGroupByOrg = getRemoveFromGroupByOrg(organizations)

    openDialog(
      {
        title: i18n.t('confirmation'),
        content: [{
          element:  <div>
            <div>{i18n.t('are_you_sure_to_continue')}</div>
            <div className="mt-3 text-coral">{i18n.t('users_remove_wait_before_refreshing_table')}</div>
          </div>,
        }],
        actions: [
          {text: i18n.t('cancel'), getActionEvent: () => 'no'},
          {text: i18n.t('save'), getActionEvent: () => 'yes', color: 'primary'},
        ],
      },
      (data) => {
        if (data && data === 'yes') {
          return updateMultipleDistribution({
            variables: {
              organizations: organizations.map((orgId) => {
                return {
                  organizationId: orgId,
                  addTo: addToGroupByOrg.get(orgId),
                  removeFrom: removeFromGroupByOrg.get(orgId),
                }
              }),
              courseId: p.course.id,
              distributionParams: pickBy(v => v, {
                courseId: p.course.id,
                manualReviewsSkipEndorsment: distParams.manualReviewsSkipEndorsment,
                hideAssessmentsAfterCompleted: distParams.hideAssessmentsAfterCompleted,
                showHintsAfterAssessmentsCompleted: distParams.showHintsAfterAssessmentsCompleted,
                ...(distParams.absoluteDeadline != null ? {absoluteDeadline: distParams.absoluteDeadline} : {}),
                ...(distParams.relativeDeadline != null ? {relativeDeadline: distParams.relativeDeadline} : {}),
                ...(distParams.dueDate != null ? {dueDate: distParams.dueDate} : {}),
                ...(distParams.futureDistribution != null ? {futureDistribution: distParams.futureDistribution} : {}),
              }),
              onlyVisibleInAnalyticsInMetrics: userData.settings.onlyVisibleInAnalytics,
            },
          }).then((data) => {
            if (!p.userAuthenticated) {
              return logoutHandler()
            }
            if (data.data && !data.errors) {
              track({event: 'Program Distributed', variables: {
                organization_id: p.organizationId,
                workspaceId: p.organizationId,
                course_id: p.course.id,
                user_id: p.userAuthenticated?.id || '',
                to: uniq((distributedToData?.res || [])
                  .filter(o => !undistFromCmpIds.map(u => u.companyId).includes(o))
                  .concat(includeCmpIds.map(i => i.companyId))).length,
                from: distributedToData?.res.length || 0,
                groupIds: companiesData.data?.res.companies.map(c => c.company.id),
                roles: userRoles(p.userAuthenticated, p.organizationId),
                programId: p.course.id,
                platform: 'dashboard',
              }})
            }
          })
        }
        return
      },
    )
  }

  return <CompaniesTable
    {...p}
    options={options}
    onOptionsChange={(o) => {
      setOptions(o)
      p.onOptionsChange && p.onOptionsChange(o)
    }}
    loading={loading}
    course={p.course}
    companies={companies.map(cmp => ({...cmp, isCourseDistributed: isDistributed(cmp)}))}
    companiesCount={companiesCount}
    companiesWithCourseDistribution={companiesWithCourseDistribution.data?.companies || []}
    selectionMode={selectionMode}
    onSelectionModeChange={onSelectionModeChange}
    onToggleDistribution={toggleDistribution}
    isDirty={isDirty()}
    onSave={onSave}
    onCancel={onCancel}
    refetch={() => {
      refetch()
      distributedToRefetch()
    }}
  />
}

export default withAuthUser(Companies)
