import * as React from 'react'
import IconType from './iconType'

const CloseIcon = (props: IconType) => {

  return (
      <div>
        <svg
          className={`fill-listenblue ${props.className}`}
          height={props.h}
          width={props.w}
          viewBox="0 0 448 512"
          >
          <path d="M399.1 88v336c0 13.25 10.76 24 24.02 24S448 437.3 448 424V88C448 74.75 437.2 64 423.1 64S399.1 74.75 399.1 88zM176.3 366.5L84.28 280h243.6c13.26 0 24.05-10.75 24.05-24S341.2 232 327.9 232h-243.6l91.98-86.53C181.3 140.8 183.8 134.4 183.8 128c0-5.906-2.158-11.81-6.536-16.44C168.2 101.9 153 101.4 143.4 110.5l-136.1 128c-9.694 9.062-9.694 25.88 0 34.94l136.1 128c9.663 9.094 24.86 8.625 33.93-1.031C186.4 390.8 185.1 375.6 176.3 366.5z"/>
        </svg>
      </div>
  )
}

export default CloseIcon
