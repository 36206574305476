import React from 'react'
// import Tooltip from '@material-ui/core/Tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tippy from '@tippyjs/react'

export default function HelpTooltip({text, noSup, danger}: {text: React.ReactNode, noSup?: boolean, danger?: boolean}) {
  return <Tippy className="z-auto" content={text || ''}>
    {noSup
      ? <InfoOutlinedIcon className="text-base" htmlColor="grey" />
      : <sup><InfoOutlinedIcon className="text-base" htmlColor={danger ? 'red' : 'grey'} /></sup>
    }
  </Tippy>
}
