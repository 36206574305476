declare var __ENV__: any

const absoluteUrl = window.location.toString()

let environment
let gnowbeDashboardUrl
let gnowbeApiUrl
let gnowbeGraphUrl
let gnowbeGraphSUrl
let gnowbeBeUrl
let gnowbeShortUrl
let gnowbeWebUrl
let filepickerKey
let chargebeeSite
let segmentSourceId
let segmentWriteKey

if (process.env.BUILD === 'production') {
  environment = 'production'
  gnowbeDashboardUrl = /localhost:|\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b:/i.test(absoluteUrl)
    ? 'http://localhost:3002'
    : 'https://dashboard.gnowbe.com'
  gnowbeApiUrl = 'https://api.gnowbe.com'
  gnowbeBeUrl = 'https://be.gnowbe.com'
  gnowbeShortUrl = 'https://gnow.be'
  gnowbeGraphUrl = 'https://graph.gnowbe.com/graphql?p=dashboard'
  gnowbeGraphSUrl = 'wss://graph.gnowbe.com/graphqls?p=dashboard'
  gnowbeWebUrl = 'https://web.gnowbe.com'
  filepickerKey = { key: 'A5csFmKMpSDSxyqkD9N7rz' }
  chargebeeSite = 'gnowbe'
  segmentSourceId = 'i2TiKb165vEBTCuXHGqxit'
  segmentWriteKey = 'XQVlLCqTDw313RcaAe4v7teCyDyX7GXj'
}
if (process.env.BUILD === 'staging') {
  environment = 'staging'
  gnowbeDashboardUrl = /localhost:|\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b:/i.test(absoluteUrl)
    ? 'http://localhost:3002'
    : 'https://dashboard.staging.sandbox.gnowbe.com'
  gnowbeApiUrl = 'https://api.staging.sandbox.gnowbe.com'
  gnowbeBeUrl = 'https://be.staging.sandbox.gnowbe.com'
  gnowbeShortUrl = 'https://be.staging.sandbox.gnowbe.com'
  gnowbeGraphUrl = 'https://graph.staging.sandbox.gnowbe.com/graphql?p=dashboard'
  gnowbeGraphSUrl = 'wss://graph.staging.sandbox.gnowbe.com/graphqls?p=dashboard'
  gnowbeWebUrl = 'https://web.staging.sandbox.gnowbe.com'
  filepickerKey = { key: 'AyT80aphgRw6zEnk5qPJiz' }
  chargebeeSite = 'gnowbe-test'
  segmentSourceId = '72YeVrsYo1S1yiLV3SVpcn'
  segmentWriteKey = '1ldeS6X6tuizjnsssD4VB9jgyxSIIlFu'
}
if (process.env.BUILD === 'development') {
  environment = 'development'
  gnowbeDashboardUrl = 'http://localhost:3002'
  gnowbeApiUrl = 'http://localhost:3005'
  gnowbeBeUrl = 'http://localhost:3005'
  gnowbeShortUrl = 'http://localhost:3005'
  gnowbeGraphUrl = 'http://localhost:3005/graphql?p=dashboard'
  gnowbeGraphSUrl = 'ws://localhost:3005/graphqls?p=dashboard'
  gnowbeWebUrl = 'http://localhost:8080'
  filepickerKey = { key: 'AyT80aphgRw6zEnk5qPJiz' }
  chargebeeSite = 'gnowbe-test'
  segmentSourceId = ''
  segmentWriteKey = ''
}

const CLIENT_SETTINGS = {
  public: {
    environment,
    gnowbeDashboardUrl,
    gnowbeApiUrl,
    gnowbeGraphUrl,
    gnowbeGraphSUrl,
    gnowbeBeUrl,
    gnowbeShortUrl,
    gnowbeWebUrl,
    filepicker: filepickerKey,
    chargebeeSite,
    segmentSourceId,
    segmentWriteKey,
  },
}

export default CLIENT_SETTINGS
