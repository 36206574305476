import React from 'react'
import { useTable, usePagination, useRowSelect, useFlexLayout, useResizeColumns, useSortBy, useExpanded } from 'react-table'
import { TableToolbar, ToolbarCommands } from './TableToolbar'
import ChevronRightIcon from '@heroicons/react/24/solid/ChevronRightIcon'
import ChevronLeftIcon from '@heroicons/react/24/solid/ChevronLeftIcon'
import clsx from 'clsx'
import { updateMetaRowsPerPage } from './DefaultRowsPerPage'
import { useSticky } from 'react-table-sticky'
import { FilterHeader, FilterRow } from './TableFilter'
import SortAscendingIcon from '@heroicons/react/24/solid/BarsArrowUpIcon'
import SortDescendingIcon from '@heroicons/react/24/solid/BarsArrowDownIcon'
import { equals, mergeAll } from 'ramda'
import i18n from './i18n'
import { withDefaultHiddenFields } from './DefaultHiddenFieldsPerTable'

export type TableOptions = {
  page: number,
  rowsPerPage: number,
  filterText: string,
  sort?: {fieldName: string, direction: 'asc'|'desc'},
  filters?: FilterRow[],
}

export type DataType = {
  id: string,
  [key: string]: any,
  isRowDisabled?: boolean,
}

type TableProps = {
  tableId?: string,
  toolbar?: {
    actionButtonName?: string,
    listItemStyle?: string,
    searchDisabled?: boolean,
    placeholder: string,
    onRefresh?: () => void,
    getCommands?: ToolbarCommands,
    getCommandsLeft?: () => React.ReactNode,
    exportData?: {
      onExportAll?: () => void,
      onExportFiltered?: () => void,
      additionalExports?: {text: string,
        icon?: React.ReactElement,
        onClick: () => void, key: string, disabled?: boolean}[],
    },
    filters?: {
      headers: FilterHeader[],
      values?: FilterRow[],
    },
    hideLimitMessage?: boolean,
  },
  columns: any,
  data: DataType[],
  noDataText?: string,
  count?: number,
  loading?: boolean,
  options: TableOptions,
  onOptionsChange: (o: TableOptions) => void,
  select?: {
    selected: string[],
    allowRowClick?: boolean,
    multiSelectable?: boolean,
    onSelect: (ids: string[], which: 'row'|'checkbox', checked?: boolean) => void,
  },
  renderRowSubComponent?: any,
  hiddenFields?: string[],
  onFieldHide?: ((fieldsToHide: string[]) => void) | undefined,
}

type IndeterminateCheckboxProps = {
  indeterminate: boolean,
  id: string,
  disabled?: boolean,
}
const IndeterminateCheckbox = React.forwardRef(
  ({indeterminate, disabled, id, ...rest}: IndeterminateCheckboxProps, ref: any) => {
    const defaultRef = React.useRef<boolean>(false)
    const resolvedRef = ref || defaultRef

    React.useEffect(
      () => {
        if (!!resolvedRef.current) {
          resolvedRef.current.indeterminate = indeterminate
        }
      },
      [resolvedRef, indeterminate],
    )

    return (
      <div>
        <input className={`${clsx({'bg-gray-50 border-lightgray cursor-not-allowed': disabled})} text-lake focus:ring-0 h-5 w-5 rounded-sm form-checkbox`}
          type="checkbox"
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation()
          }}
          ref={resolvedRef} {...rest} />
      </div>
    )
  },
)

const Table = (props: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    allColumns,
    visibleColumns,
    hiddenColumns,
    gotoPage,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: {
        pageIndex: props.options.page,
        pageSize: props.options.rowsPerPage,
        ...(props.options.sort
          ? {sortBy: [{
            id: props.options.sort.fieldName,
            desc: props.options.sort.direction === 'desc',
          }]}
          : {}
        ),
        hiddenColumns: props.hiddenFields,
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: Math.ceil((props.count || 0) / props.options.rowsPerPage),
      autoResetSelectedRows: false,
      autoResetHiddenColumns: false,
      getRowId: (row) => {
        // force our own id (for example userId)
        return row.id
      },
      manualSortBy: true,
      disableMultiSort: true,
      stateReducer: (newState, action) => {
        if (action.type === 'toggleRowSelected' && !!props.select && !props.select.multiSelectable) {
          newState.selectedRowIds = {
            [action.id]: action.value,
          }
          props.select?.onSelect(
            Object.keys(newState.selectedRowIds),
            'checkbox',
            action.value,
          )
          return newState
        }
        if (action.type === 'toggleRowSelected' && !!props.select && props.select.multiSelectable) {
          if (!equals(Object.keys(newState.selectedRowIds), props.select?.selected)) {
            props.select?.onSelect(
              Object.keys(newState.selectedRowIds),
              'checkbox',
              action.value,
            )
          }
          return newState
        }
        if (
          !!props.select
          && action.type !== 'toggleRowSelected'
          && action.type !== 'resetSelectedRows'
          && action.type !== 'toggleAllPageRowsSelected'
        ) {
          newState.selectedRowIds = mergeAll(props.select.selected.map((s) => {
            return {[s]: true}
          }))
        }
        if (!!props.select && action.type === 'toggleAllPageRowsSelected' && props.select?.multiSelectable) {
          props.select?.onSelect(
            Object.keys(newState.selectedRowIds),
            'checkbox',
            action.value,
          )
        }
        if (action.type === 'toggleHideColumn' && props.onFieldHide) {
          props.onFieldHide(newState.hiddenColumns)
        }
        return newState
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    useSticky,
    (hooks) => {
      if (!props.select) return
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          disableResizing: true,
          align: 'center',
          minWidth: 56,
          width: 56,
          maxWidth: 56,
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <IndeterminateCheckbox
              disabled={!props.select?.multiSelectable}
              {...getToggleAllPageRowsSelectedProps()}
            />
          ),
          Cell: ({ row }) => {
            return <IndeterminateCheckbox
              // disabled={row.original?.isRowDisabled}
              id={row.original.id}
              {...row.getToggleRowSelectedProps()}
            />
          },
        },
        ...columns,
      ])
    },
  )

  React.useEffect(
    () => {
      if (pageIndex === props.options.page) return

      props.onOptionsChange && props.onOptionsChange({
        ...props.options,
        page: pageIndex,
      })
    },
    [pageIndex],
  )

  React.useEffect(
    () => {
      if (pageSize === props.options.rowsPerPage) return
      props.onOptionsChange && props.onOptionsChange({
        ...props.options,
        rowsPerPage: pageSize,
      })
      if (props.tableId) {
        updateMetaRowsPerPage(props.tableId, Number(pageSize))
      }
    },
    [pageSize],
  )

  React.useEffect(
    () => {
      sortBy && Array.isArray(sortBy) && props.onOptionsChange({
        ...props.options,
        sort: {
          fieldName: sortBy[0]?.id || props.options.sort?.fieldName,
          direction: sortBy[0]?.desc ? 'desc' : 'asc',
        },
      })
    },
    [sortBy],
  )

  React.useEffect(
    () => {
      if (props.options.page !== pageIndex) {
        gotoPage(props.options.page)
      }
    },
    [props.options.page]
  )

  const pagefrom = (pageIndex * pageSize) + 1
  const pagetoTmp = (pageIndex * pageSize) + props.options.rowsPerPage
  const pageto = pagetoTmp > (props.count || 0) ? props.count : pagetoTmp

  const allHTMLCells = ['scheduledShare', 'distributedOn', 'absoluteDeadline', 'dueDate']

  return (
    <div>
      {props.toolbar && <TableToolbar
        exportData={props.toolbar.exportData}
        columns={allColumns}
        onRefresh={props.toolbar.onRefresh}
        filters={props.toolbar.filters ? {
          ...props.toolbar.filters,
          onFilterChange: (rows) => {
            props.onOptionsChange && props.onOptionsChange({
              ...props.options,
              filters: rows,
              page: 0,
            })
          },
        } : undefined}
        searchText={{
          disabled: props.toolbar?.searchDisabled,
          onChange: (text) => {
            props.onOptionsChange && props.onOptionsChange({
              page: 0,
              rowsPerPage: pageSize,
              filterText: text,
            })
          },
          placeholder: props.toolbar.placeholder,
        }}
        hideLimitMessage={props.toolbar.hideLimitMessage}
        getCommands={props.toolbar.getCommands}
        getCommandsLeft={props.toolbar.getCommandsLeft}
        actionButtonName={props.toolbar.actionButtonName}
      />}
      <div className="rounded-lg w-full border border-solid border-gray-200">
        <div className={`${clsx({'rounded-b-lg': !props.count})} w-full rounded-t-lg overflow-auto`}>
          <table className="table rounded-t-lg sticky rounded-lg divide-y divide-gray-200 w-full"
            {...getTableProps()}>
            <thead className="header bg-gray-50 rounded-t-lg border-b border-solid border-gray-200">
              {// Loop over the header rows
              headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr className="tr rounded-t-lg" {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                    headerGroup.headers.map((column, index) => {
                      const columnRendered = column.render('Header')
                      const title = column.disableDefaultTooltip ? '' : typeof(columnRendered) === 'string'
                        ? columnRendered
                        : columnRendered.props?.children?.length > 0
                          ? columnRendered.props.children[0]
                          : ''

                      return (
                        // Apply the header cell props
                        <th className={`${column.headerClassName || ''} ${clsx({[`justify-${column.align}`]: column.align})} bg-gray-50 th whitespace-nowrap flex flex-row items-center rounded-t-lg px-3 py-2 text-medgray text-sm font-semibold capitalize tracking-wider`}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          {/*  Render the header */}
                          <div title={title} className="truncate disable-tooltip-safari">{column.render('Header')} <div></div></div>
                          <div>
                            {!props.options.filterText && <>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <button className="ml-2 cursor-pointer transition-all duration-200 hover:text-gray-500">
                                      <SortDescendingIcon className="h-5 w-5" />
                                    </button>
                                  : <button className="ml-2 cursor-pointer transition-all duration-200 hover:text-gray-500">
                                      <SortAscendingIcon className="h-5 w-5" />
                                    </button>
                                : ''}
                                {!column.isSorted && column.canSort
                                  ? <button className="ml-2 cursor-pointer transition-all duration-200 text-gray-300">
                                      <SortAscendingIcon className="h-5 w-5" />
                                    </button>
                                  : ''}
                            </>}
                          </div>
                          <div></div>
                          <div
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                            {...!!column.getResizerProps ? column.getResizerProps() : undefined}
                            className={`${index !== (visibleColumns.length - 1) ? 'resizer' : ''} ${
                              column.isResizing ? 'isResizing' : ''
                            }`}
                          />
                        </th>
                      )
                    })
                  }
                </tr>
              ))}
            </thead>
            {/* Apply the table body props */}
            <tbody className="body bg-white divide-y divide-gray-200" {...getTableBodyProps()}>
              { props.loading ? <>{Array.apply(null, Array(pageSize)).map((_, i) => {
                return <tr key={`row-pulse-${i}`} className={i % 2 === 0 ? 'bg-gray-50 animate-pulse' : 'bg-gray-200 animate-pulse'}>
                  <td style={{height: 48}} colSpan={props.columns.length}>
                  </td>
                </tr>
              })}</> :
              // Loop over the table rows
              (!page || (page.length === 0))
                ? <tr className="tr bg-white">
                  <td style={{height: 48, paddingLeft: 14}} className={props.noDataText ? 'text-center text-gray-500' : undefined} colSpan={props.columns.length}>
                    <>{props.noDataText ?? `${i18n.t('no_data')}...`}</>
                  </td>
                </tr>
                : page.map((row, rowIndex) => {
                  const isRowSelected = props.select?.selected.includes(row.id)
                  const evenRow = rowIndex % 2 === 0
                  // Prepare the row for display
                  prepareRow(row)
                  return <React.Fragment key={Math.random()}>
                    <tr className={`${isRowSelected && 'bg-[#A4E7E9]/[.24]'} ${clsx({'bg-white': evenRow && !isRowSelected, 'bg-gray-50': !evenRow && !isRowSelected, 'cursor-pointer': props.select?.allowRowClick, 'text-lightgray italic': row.original.isRowDisabled })} items-center tr hover:bg-verylightbrown`}
                      {...row.getRowProps()}
                      onClick={() => {
                        if (row.original?.isRowDisabled) return
                        props.select?.allowRowClick && props.select.onSelect([row.original.id], 'row', true)
                      }}
                    >
                      {// Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td className={`${clsx({[`justify-${cell.column.align}`]: cell.column.align})} td truncate text-sm px-3 py-2 h-full flex flex-row items-center whitespace-nowrap`} {...cell.getCellProps()}>
                            {allHTMLCells.includes(cell.column.id) ? (
                              <div className='justify-center text-center' dangerouslySetInnerHTML={{ __html: cell.value }} />
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        )
                      })}
                    </tr>
                    {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length} className="text-sm px-3 py-2">
                        {props.renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                  </React.Fragment>
                })}
            </tbody>
          </table>
        </div>
        {props.count ? <div className="flex flex-row items-center justify-between bg-white rounded-b-lg py-2 px-4 border-t border-solid border-gray-200text-sm">
          <div>
            {(props.select && selectedFlatRows.length > 0) && <span>{selectedFlatRows.length} row(s) selected</span>}
          </div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center justify-center gap-1">
              <span>Rows per page:</span>
              <select
                className="border-none ring-0"
                value={props.options.rowsPerPage}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <span className="ml-2">
              {pagefrom}-{pageto} of {props.count}</span>
            <div className="ml-2 flex flex-row items-center">
              <button
                onClick={() => previousPage()}
                className={clsx({'text-lightgray cursor-not-allowed': !canPreviousPage})}
                disabled={!canPreviousPage}>
                <ChevronLeftIcon className="w-6 h-6" />
              </button>
              <button
                onClick={() => nextPage()}
                className={clsx({'text-lightgray cursor-not-allowed': !canNextPage})}
                disabled={!canNextPage}>
                <ChevronRightIcon className="ml-2 w-6 h-6" />
              </button>
            </div>
          </div>
        </div> : null}
      </div>
    </div>
  )
}

export default withDefaultHiddenFields(Table)
