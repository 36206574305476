import * as React from 'react'
import IconType from './iconType'

const DotIcon = (props: IconType) => {

  return (
      <div>
        <svg
          className={`${props.className ? props.className : 'fill-listenblue'}`}
          height={props.h}
          width={props.w}
          viewBox="0 0 320 512"
          >
          <path d="M320 256C320 344.4 248.4 416 160 416C71.63 416 0 344.4 0 256C0 167.6 71.63 96 160 96C248.4 96 320 167.6 320 256z"/>
        </svg>
      </div>
  )
}

export default DotIcon