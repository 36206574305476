import Switch from 'components/Switch'
import React  from 'react'
import { BannerStateType } from './BannerDetails'
import PreviewBanner from './PreviewBanner'

type ReviewAndLaunchProps = {
  state: BannerStateType
  cta: boolean,
  isDate: boolean,
  setState: (value: BannerStateType) => void,
}

const ReviewAndLaunch = ({ state, cta, setState, isDate }: ReviewAndLaunchProps) => {

  const toggleTrue = () => {
    setState({
      ...state,
      enabled: !state.enabled,
    })
  }

  const date = new Date(state.expirationTime)

  return (
    <div className="bg-white rounded-lg px-6 py-4 mb-52 border border-[#E3E4E5]">
      <div className="flex justify-between mb-9">
        <div className="text-deepgray font-medium">
          {state.bannerId || 'Banner id'}
        </div>
        <div className="flex">
          <>
          <Switch checked={state.enabled} onChange={toggleTrue} />
          <div className="ml-2 text-medgray">{state.enabled ? 'Banner Active' : 'Banner Inactive'}</div>
          </>
        </div>
      </div>
      <PreviewBanner state={state} cta={cta} />
      <div className="flex mt-8">
        {state.type === 'text' && <div className="w-1/2 mr-5">
          <div className="text-sm text-medgray font-bold mt-2">
            Link
          </div>
          <div
            className={'w-full border border-lightwarmgray rounded mt-1 px-4 py-3'} >
            {state.ctaButton && state.ctaButton.url || '-'}
          </div>
        </div>}
        <div className="w-1/2 ml-5">
          <div>
            <div className="text-sm text-medgray font-bold mt-2">
              Expires on
            </div>
            <div
              className={'w-full border border-lightwarmgray rounded mt-1 px-4 py-3'} >
              {isDate && date.toLocaleDateString('en-US') || '-'}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="text-sm text-medgray font-bold mt-4">
          Product
        </div>
        <div className="flex">
          {state.showToProduct.map((product, i) => {
            return (
              <div key={i} className="bg-[#DFF0FF] rounded uppercase text-deepgray px-2 py-0.5 mt-1 mr-2">
                {product}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ReviewAndLaunch
