import React from 'react';
import i18n from './i18n';

type ErrorCmpProps = {
  error: Error|string;
}

export default class ErrorCmp extends React.Component<ErrorCmpProps> {
  render() {
    const {error} = this.props
    let msg = ''
    if (typeof(error) === 'string') {
      msg = error
    } else {
      msg = error.message
    }

    return <div style={{margin: '10px', padding: '10px', backgroundColor: '#FF5A4B'}}>
      <h2>{i18n.t('error')}</h2>
      <p>
        {msg.replace('GraphQL error: ', '')}
      </p>
    </div>
  }
}