
function replaceForbiddenContent(s: string|undefined|null) {
  if (!s) return s
  return s.replace(/\r/g, '').replace(/\n/g, '    ').replace(/"/g, "'")
}
export function exportToCsv(data: any[][], filename: string) {
  let csvContent = '' // "data:text/csv;charset=utf-8,";
  data.forEach((infoArray, index) => {
    const dataString = infoArray.map(item =>
      typeof item === 'number' ? item.toString() :
      typeof item === 'string' ? `"${replaceForbiddenContent(item)}"` :
      '',
    ).join(',')
    csvContent += index < data.length ? `${dataString}\n` : dataString
  })

  const blob = new Blob([`\ufeff${csvContent}`], { type: 'text/csv;charset=utf-8;' })

  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link['download'] !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  // let encodedUri = encodeURI(csvContent);
  // window.open(encodedUri, '_blank');

  // var link = document.createElement("a");
  // link.setAttribute("href", encodedUri);
  // link.setAttribute("download", "my_data.csv");
  // link.click(); // This will download the data file named "my_data.csv".
}
