import { Button } from 'components/elements/Button'
import React from 'react'
import { BannerStateType } from './BannerDetails'

type PreviewBannerProps = {
  state: BannerStateType,
  cta: boolean,
}

const PreviewBanner = ({state, cta}: PreviewBannerProps) => {

  const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <div className={`mx-20 text-sm font-normal p-5
        ${state.type === 'text'
          ? 'flex justify-center items-center text-center h-16'
          : 'text-center overflow-auto h-32'}`}
        style={{backgroundColor: state.bannerColor || '#FFFFFF'}}
      >
        <div className="inline-block">
          <div className={`${state.type === 'text'
            ? 'flex items-center justify-center'
            : 'text-start'}
            ${state.bannerText && 'text-deepgray'}`}
          >
            {state.bannerText || 'Live banner preview'}
            <div className="flex justify-center items-center">
              {((state.type === 'range5' && cta)) && <div className="flex">
                {nums.slice(1, 6).map((num) => {
                  if (num === 1) {
                    return (
                      <div key={num} className="flex flex-col w-10 items-center justify-center">
                          <div
                            style={{backgroundColor: state.bannerColor || '#FFFFFF'}}
                            className={'w-10 h-10 border border-black rounded my-1 mr-1 flex items-center justify-center text-black'}
                          >
                            {num}
                          </div>
                          <div className="whitespace-nowrap">{state.minRangeCopy || 'e.g. “Least likely”'}</div>
                      </div>
                    )
                  }
                  if (num === 5) {
                    return (
                      <div key={num + 11} className="flex flex-col w-10 items-center justify-center">
                          <div
                            style={{backgroundColor: state.bannerColor || '#FFFFFF'}}
                            className={'w-10 h-10 border border-black rounded my-1 flex items-center justify-center text-black'}
                          >
                            {num}
                          </div>
                          <div className="whitespace-nowrap">{state.maxRangeCopy || 'e.g. “Most likely”'}</div>
                      </div>
                    )
                  }
                  return (
                    <div
                      key={num + 22}
                      style={{backgroundColor: state.bannerColor || '#FFFFFF'}}
                      className={'w-10 h-10 border border-black rounded my-1 mr-1 flex items-center justify-center text-black'}
                    >
                      {num}
                    </div>
                  )

                })}
              </div>}
              {((state.type === 'range10' && cta)) && <div className="flex">
                {nums.slice(0, nums.length + 1).map((num) => {
                  if (num === 0) {
                    return (
                      <div key={num + 33} className="flex flex-col w-10 items-center justify-center">
                          <div
                            style={{backgroundColor: state.bannerColor || '#FFFFFF'}}
                            className={'w-10 h-10 border border-black rounded my-1 mr-1 flex items-center justify-center text-black'}
                          >
                            {num}
                          </div>
                          <div className="whitespace-nowrap">{state.minRangeCopy || 'e.g. “Least likely”'}</div>
                      </div>
                    )
                  }
                  if (num === 10) {
                    return (
                      <div key={num + 44} className="flex flex-col w-10 items-center justify-center">
                          <div
                            style={{backgroundColor: state.bannerColor || '#FFFFFF'}}
                            className={'w-10 h-10 border border-black rounded my-1 flex items-center justify-center text-black'}
                          >
                            {num}
                          </div>
                          <div className="whitespace-nowrap">{state.maxRangeCopy || 'e.g. “Most likely”'}</div>
                      </div>
                    )
                  }
                  return (
                    <div
                      style={{backgroundColor: state.bannerColor || '#FFFFFF'}}
                      className={'w-10 h-10 border border-black rounded my-1 mr-1 flex items-center justify-center text-black'}
                      key={num + 55}
                    >
                      {num}
                    </div>
                  )
                })}
              </div>}
              {cta && <Button
                className={`${(state.type === 'range5' || state.type === 'range10') && 'mb-5'} ml-4`}
                text={(state.ctaButton && state.ctaButton.label) || 'CTA Button'}
                onClick={e => e.preventDefault()} />
                }
            </div>
          </div>
        </div>
      </div>
  )
}

export default PreviewBanner
