import Joi from '@hapi/joi'
import i18n from 'components/i18n'
import { BillingCycle, BundleType } from 'models/bundle'
import { clone, equals, keys, lensPath, mergeDeepRight, props, view as rView, reduce, set } from 'ramda'
import React from 'react'
import { warn } from '../lib/console_logger'
import { CompanyPeerInvitationMode } from '../models/company'
import { OrganizationProduct } from '../models/organization'
import { showSnackbar } from './snackbar'

const rewardsInLokalise = {
  hJ65dmQlPaksjw37j: 'reward_various',
  '8dhsmJGD8362qlsdk': 'reward_happiness',
  k3mJD39LATRyz9lB4: 'reward_effectiveness',
  '4jdnLc1d9np7Fa1jp': 'reward_quality',
}

export type JoiValidationHelperForwadRefType = {
  setState: (key: string, value: any) => void,
  validate: () => void,
  isValid: () => void,
  validateFutureState: (futureState) => {
    errors: {};
    obj: any;
  },
  getErrorStr: () => void,
}

export const JoiValidationHelperForwardRef = React.forwardRef(
  (props: {
    setOriginalState: React.Dispatch<React.SetStateAction<any>>,
    schema: Joi.ObjectSchema,
    originalState: any,
  }, ref) => {
    React.useImperativeHandle(ref, () => ({

      setState(key: string, value: any) {
        props.setOriginalState((oldState) => {
          const lens = lensPath(key.split('.')) // dobi not bundle.title
          const view = rView(lens, oldState) // v primeru bundla je lens = ['bundle', 'title'] in si zna ramda sestavit
          if (view && typeof view === 'object' && !Array.isArray(view) && !(value instanceof Date)) {
            value = mergeDeepRight(view, value)
          } else if (value && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date)) {
            value = clone(value)
          }
          const state = set(lens, value, oldState) // tukaj si sestavi nazaj nov state

          state.errors = {}
          const errors = {}
          const validation = props.schema.validate(state, { stripUnknown: true, abortEarly: false })
          validation.error && validation.error.details.forEach((k) => {
            errors[k.path.join('.')] = k.message
          })

          return {...validation.value,
            errors,
          }
        })
      },

      validate() {
        props.setOriginalState((oldState) => {
          const state = clone(oldState)
          if (props.schema && state.errors) {
            state.errors = {}
            const validation: any = props.schema.validate(state, { stripUnknown: true, abortEarly: false })
            validation.error && validation.error.details.forEach(k => {
              state.errors[k.path.join('.')] = k.message
            })
          }
          return state
        })
      },

      isValid() {
        const state = clone(props.originalState)
        const res = props.schema.validate(state, { stripUnknown: true, abortEarly: true })
        return !res.error
      },

      validateFutureState(futureState) {
        const errors = {}
        const validation = props.schema.validate(futureState, { stripUnknown: true, abortEarly: false })
        validation.error && validation.error.details.forEach(k => {
          errors[k.path.join('.')] = k.message
        })
        return {
          errors,
          obj: validation.value,
        }
      },

      getErrorStr() {
        const errors = props.originalState.errors || {}
        return reduce((acc, item) => `${acc} - ${errors[item]}`, '', keys(errors))
      },

    }))

    return null
  },
)


export class JoiValidationHelper<T = any> {
  constructor(private schema: Joi.ObjectSchema, private component: React.Component<any, any>) {
  }

  setState(key: string, value: any) {
    this.component.setState((oldState) => {
      const lens = lensPath(key.split('.')) // dobi not bundle.title
      const view = rView(lens, oldState) // v primeru bundla je lens = ['bundle', 'title'] in si zna ramda sestavit
      if (view && typeof view === 'object' && !Array.isArray(view) && !(value instanceof Date)) {
        value = mergeDeepRight(view, value)
      } else if (value && typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date)) {
        value = clone(value)
      }
      const state = set(lens, value, oldState) // tukaj si sestavi nazaj nov state

      state.errors = {}
      const errors = {}
      const validation = this.schema.validate(state, { stripUnknown: true, abortEarly: false })
      validation.error && validation.error.details.forEach((k) => {
        errors[k.path.join('.')] = k.message
      })

      return {...validation.value,
        errors,
      }
    })
  }

  validate() {
    this.component.setState((oldState) => {
      const state = clone(oldState)
      if (this.schema && state.errors) {
        state.errors = {}
        const validation: any = this.schema.validate(state, { stripUnknown: true, abortEarly: false })
        validation.error && validation.error.details.forEach(k => {
          state.errors[k.path.join('.')] = k.message
        })
      }

      return state
    })
  }

  isValid() {
    const state = clone(this.component.state)
    const res = this.schema.validate(state, { stripUnknown: true, abortEarly: true })
    return !res.error
  }

  validateFutureState(futureState) {
    const errors = {}
    const validation = this.schema.validate(futureState, { stripUnknown: true, abortEarly: false })
    validation.error && validation.error.details.forEach(k => {
      errors[k.path.join('.')] = k.message
    })
    return {
      errors,
      obj: validation.value,
    }
  }

  getErrorStr() {
    const errors = this.component.state.errors || {}
    return reduce((acc, item) => `${acc} - ${errors[item]}`, '', keys(errors))
  }

}

export function handleError(err: Error, msgPrefix?: string, autoHideTimer?: number) {
  let msg = (msgPrefix || '') + (err?.message || err['reason'] || 'Unknown error')
  if (msg.includes('GraphQL error: ')) {
    msg = msg.slice(15, msg.length)
  }
  warn(msg, err)
  showSnackbar(msg, autoHideTimer, 'error')
}


// This function is meant to be used when checking if form is dirty
export function objectEquals(fields: string[], obj1: any, obj2: any) {
  const mapValues = v => (v === '' || v === false || v === 0 || v == null) ? undefined : v
  return equals(
    props(fields, obj1).map(mapValues),
    props(fields, obj2).map(mapValues),
  )
}

export function randomBetween(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function copyToClipboard(text) {
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      const res = document.execCommand('copy')  // Security exception may be thrown by some browsers.
      showSnackbar('Copied to clipboard', 3000, 'success')
      return res
    } catch (ex) {
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }

  return false
}

export function validateEmail(email: string) {
  if (/^\w+([.-]?\w+)*(\+?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  return (false)
}

export function randomHex() {
  // tslint:disable-next-line: prefer-template
  return '#' + Math.floor(Math.random() * 16777215).toString(16)
}

export function getRewardTitleLocalizedById(id: string) {
  if (rewardsInLokalise[id] === undefined) {
    return undefined
  }
  return i18n.t(rewardsInLokalise[id])
}

export function getPeerInvitationModeDefault(product: OrganizationProduct) {
  if (product === OrganizationProduct.UL) return CompanyPeerInvitationMode.approval
  if (product === OrganizationProduct.XL) return CompanyPeerInvitationMode.approval
  if (product === OrganizationProduct.VL) return CompanyPeerInvitationMode.approval
  if (product === OrganizationProduct.CR) return CompanyPeerInvitationMode.approval
  if (product === OrganizationProduct.DM) return CompanyPeerInvitationMode.approval
  if (product === OrganizationProduct.IN) return CompanyPeerInvitationMode.approval
  return CompanyPeerInvitationMode.closed
}

export function getBundleTypeLabel(type: BundleType|null) {
  if (type === 'subscription') return 'Subscription'
  if (type === 'oneTimePurchase') return 'One time purchase'
  return null
}

export function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)
  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      },                         delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

// TODO localize
export function getBillingCycleLocalized(period: BillingCycle) {
  if (period === 'monthly') return 'Monthly'
  if (period === 'quarterly') return 'Quarterly'
  if (period === 'yearly') return 'Annually'
  return undefined
}

export function getBillingPeriodLevel(period: BillingCycle|undefined) {
  if (period === 'monthly') return 0
  if (period === 'quarterly') return 1
  if (period === 'yearly') return 2
  return 0
}

export function nl2br(str) {
  return (`${str}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
}

export function isCuPlan(planId: string | undefined) {
  return planId?.includes('v5') || planId?.startsWith('cu')
}

export function isLearnerAddon(id: string) {
  return id.includes('users-addons') || id.startsWith('al')
}
export function isTranslationsAddon(id: string) {
  return id.includes('translations-addons') || id.startsWith('at')
}
export function isGnowbeAiAddon(id: string) {
  return id.includes('gnowbe-ai-addons') || id.startsWith('ai') // new ids not defined yet, assuming for now with "gai" or "ai"
}