import { useMutation, useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import { client } from 'App'
import ErrorCmp from 'components/Error'
import i18n from 'components/i18n'
import { getRealFilterText } from 'components/TableToolbar'
import { handleError } from 'components/utils'
import { GetUserRemoveConfirmationRes, GetUserRemoveConfirmationVars, GET_USER_REMOVE_CONFIRMATIONS, HANDLE_USER_REMOVE_CONFIRMATION } from 'models/user_remove_confirmation'
import React from 'react'
import format from 'date-fns/format'
import Table2, { TableOptions } from 'components/Table2'


type Props = {
  reason: 'removed_from_organization'|'organization_deleted',
}

const DeleteUserConfirmation = (props: Props) => {
  const optionsRef = React.useRef({
    filterText: '',
    page: 0,
    rowsPerPage: 50,
  })
  // const countRef = React.useRef(0)

  // tslint:disable-next-line: max-line-length
  const {data, loading, error, refetch, variables} = useQuery<GetUserRemoveConfirmationRes, GetUserRemoveConfirmationVars>(
    GET_USER_REMOVE_CONFIRMATIONS, {
      variables: {
        reason: props.reason,
        handled: false,
        searchText: getRealFilterText(optionsRef.current.filterText || ''),
        skip: (optionsRef.current.page || 0) * (optionsRef.current.rowsPerPage || 10),
        limit: optionsRef.current.rowsPerPage || 10,
      },
    },
  )

  const [handleConfirmation] = useMutation(HANDLE_USER_REMOVE_CONFIRMATION, {
    onError: (err) => { handleError(err, undefined, 2000) },
    onCompleted: (d) => {
      const queryData = client.readQuery<GetUserRemoveConfirmationRes>({ query: GET_USER_REMOVE_CONFIRMATIONS, variables })
      client.writeQuery({
        query: GET_USER_REMOVE_CONFIRMATIONS,
        variables,
        data: {
          ...queryData,
          confirmations: queryData?.confirmations.filter(l => l.id !== d.confirmation.id) || [],
        },
      })
    },
  })

  if (error) return <ErrorCmp error={error} />

  const confirmations =  !loading && !!data ? data.confirmations : []
  // const logsCount = !loading && !!data ? data.confirmations.length : countRef.current
  // countRef.current = logsCount

  const onOptionsChange = (o: TableOptions) => {
    optionsRef.current = o
    refetch({
      ...variables,
      searchText: getRealFilterText(o.filterText),
      skip: o.page * o.rowsPerPage,
      limit: o.rowsPerPage,
    })
  }

  return <div className="w-full">
    <Table2
      loading={loading}
      count={0 /* countRef.current */}
      options={optionsRef.current}
      onOptionsChange={onOptionsChange}
      toolbar={{
        onRefresh: refetch,
        placeholder: 'Filter',
      }}
      columns={[
        {accessor: 'reason', Header: 'Reason', disableSortBy: true},
        {accessor: 'reasonRelationKey', Header: 'Reason relation key', disableSortBy: true},
        {accessor: 'userId', Header: 'User Id', disableSortBy: true},
        {accessor: 'userName', Header: "User's Name", disableSortBy: true},
        {accessor: 'dateCreated', Header: 'Date Created', disableSortBy: true},
        {accessor: 'actions', Header: i18n.t('actions'), disableSortBy: true, sticky: 'right'},
      ]}
      data={confirmations.map((row) => {
        return {
          id: row.id,
          reason: row.reason,
          reasonRelationKey: row.reasonRelationKey,
          userId: <span className="font-bold text-medgray">{row.userId}</span>,
          userName: row.user?.profile.fullName || '',
          dateCreated: format(row.createdAt, 'HH:MM - MMM D, YYYY'),
          actions: <div className="flex flex-row gap-2 items-center">
            <Button
              size="small"
              onClick={(e) => {
                e.preventDefault()
                handleConfirmation({
                  variables: {
                    confirmationId: row.id,
                    action: 'process',
                  },
                })
              }}
              title="Proceed with User deletion"
              variant="contained"
              color="primary"
            >
              <CheckRoundedIcon fontSize="small" />&nbsp;{i18n.t('process')}
            </Button>
            <Button
              size="small"
              onClick={(e) => {
                e.preventDefault()
                handleConfirmation({
                  variables: {
                    confirmationId: row.id,
                    action: 'discard',
                  },
                })
              }}
              title="Discard (Keep User)"
              variant="contained"
              color="secondary"
            >
              <DeleteRoundedIcon fontSize="small" />&nbsp;{i18n.t('discard')}
            </Button>
          </div>,
        }
      })}
    />
  </div>
}

export default function (props: {}) {
  return <>
    <div>
      <h3 className="text-teak">
        Confirm user deletion becuase removed from workspace
      </h3>
      <DeleteUserConfirmation reason="removed_from_organization" />
    </div>
    <div className="mt-8">
      <h3 className="text-teak">
        Confirm user deletion becuase workspace deleted
      </h3>
      <DeleteUserConfirmation reason="organization_deleted" />
    </div>
  </>
}
