import { gql } from '@apollo/client'
import { client } from 'App'

export type FileExportStatusRes = {
  exportId: string,
  status: string,
  percentDone?: number,
  fileUrl?: string,
  filename: string,
}
export const LISTEN_FILE_EXPORT_STATUS = gql`
  subscription ListenFileExportStatus($exportId: String) {
    status: listenFileExportStatus(exportId: $exportId) {
      exportId
      status
      percentDone
      fileUrl
      filename
    }
  }
`
export const GET_EXPORT_LIST = gql`
  query GetExportList {
    exportList @client {
      exportId
      status
      percentDone
      fileUrl
      filename
    }
  }
`

const addRemoveExportId = (exportData: FileExportStatusRes) => {
  const cacheres = client.readQuery<{exportList: FileExportStatusRes[]}>({
    query: GET_EXPORT_LIST,
  })
  exportData['__typename'] = 'ExportList'
  client.writeQuery({
    query: GET_EXPORT_LIST,
    data: {
      ...cacheres,
      exportList: cacheres?.exportList.findIndex(e => e.exportId === exportData.exportId) === -1
        ? [exportData, ...cacheres.exportList]
        : cacheres?.exportList.map((e) => {
          if (e.exportId === exportData.exportId) {
            return exportData
          }
          return e
        }),
    },
  })
}

export const addExportId = (
  exportId: string,
) => {
  addRemoveExportId({
    exportId,
    fileUrl: '',
    filename: 'waiting...',
    percentDone: 0,
    status: 'inprogress',
  })
  const subscription = client.subscribe({
    query: LISTEN_FILE_EXPORT_STATUS,
    variables: {
      exportId,
    },
  }).subscribe({
    next: ({errors, data}) => {
      if (errors) {
        subscription.unsubscribe()
        console.error(errors[0].message)
      }
      if (data.status.status === 'finished') {
        subscription.unsubscribe()
      }
      addRemoveExportId(data.status)
    },
    error: error => console.error(error),
  })
}
