import { useMutation } from '@apollo/client'
import { Button } from 'components/elements/Button'
import i18n from 'components/i18n'
import { showSnackbar } from 'components/snackbar'
import { DELETE_BANNER } from 'mutations/banners'
import React from 'react'

const DeleteModal = ({onClose, bannerId}) => {

  const [updateDelete, updateDeleteRes] = useMutation(DELETE_BANNER, {
    onError: (error) => {
      showSnackbar(error.message, 3000, 'error')
    },
    onCompleted: () => {
      showSnackbar(i18n.t('success'), 3000, 'success')
      onClose()
    },
  })

  return (
    <>
      <div className="text-deepgray mt-[-16px] mb-6">
        Are you sure you want to delete this banner? <span className="font-bold">This action cannot be undone.</span>
      </div>
      <div className="flex justify-end">
        <Button
          type="text"
          text="Cancel"
          className="text-listenblue"
          onClick={
            () => {
              onClose()
            }
          }

        />
        <Button
          type="danger"
          text="Delete banner"
          size="medium"
          className="px-10 ml-4"
          onClick={
            () => {
              updateDelete({
                variables: {
                  bannerId,
                  bannerData: {
                    deleted: true,
                  },
                },
              },
              )
            }
          } />
      </div>
    </>
  )
}

export default DeleteModal
