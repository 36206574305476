import { useQuery } from '@apollo/client'
import ChevronRightIcon from '@heroicons/react/24/solid/ChevronRightIcon'
import CLIENT_SETTINGS from 'lib/client_settings'
import { OrganizationBasic, OrganizationSettings } from 'models/organization'
import { UserAuthenticated } from 'models/user'
import { GET_MANAGERAGENT_ORGANIZATIONS } from 'queries/organization'
import React from 'react'
import { withAuthUser } from './AuthUserWrapper'
import ErrorCmp from './Error'
import Loading from './Loading'
import CreateFreeTierOrganization from './CreateFreeTierOrganization'

type QueryRes = {organizations: (OrganizationBasic&OrganizationSettings)[]}
type Variables = {userId: string}

const ListOrganizationsMember = (p: {userAuthenticated: UserAuthenticated}) => {
  const {loading, data, error} = useQuery<QueryRes, Variables>(GET_MANAGERAGENT_ORGANIZATIONS, {
    variables: {userId: p.userAuthenticated.id},
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <Loading enabled={loading} />
  if (error) return <ErrorCmp error={error} />

  const organizations = data?.organizations ?? []
  return (
    <div className="flex flex-col justify-start items-left">
      <header className="left-0">
        <div><h2 className="text-deepgray text-3xl font-medium mb-2">Welcome to Gnowbe</h2></div>
        {organizations.length > 0 && <div><h3 className="text-medgray font-normal text-sm mb-9">
          You are a member of the following workspaces. Click on the workspace you would like to open in the
          dashboard.
        </h3></div>}
      </header>
      {data?.organizations.length === 0 && <div className="">
        <div><h3 className="text-deepgray font-normal text-base mb-6">
          🔔 You are not a member of any workspaces. Click the button below to create your free workspace.
        </h3></div>
        <CreateFreeTierOrganization buttonType="primary" buttonSize="big" userId={p.userAuthenticated.id}
          onCompleted={(orgId) => {
          }
        } />
      </div>}
      <div className="overflow-hidden bg-white shadow sm:rounded-md lg:w-2/3">
        <ul role="list" className="divide-y divide-gray-200">
          {organizations.map((o, index) => {
            return <li key={`menu-organization-${o.id}`}>
              <a href={`${CLIENT_SETTINGS.public.gnowbeDashboardUrl}/organization/${o.id}`} className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="truncate">
                      <div className="flex text-sm">
                        <p className="truncate font-bold text-lg text-listenblue">{o.name}</p>
                        {/* <p className="ml-1 flex-shrink-0 font-normal text-gray-500">

                        </p> */}
                      </div>
                      <div className="mt-1 flex">
                        <div className="flex items-center text-sm text-gray-500">
                          {/* <DollarIcon className="mr-1.5 h-3 w-3 flex-shrink-0 text-gray-400" aria-hidden="true" /> */}
                          <p className=" mr-1.5 font-semibold">Current plan:</p>
                          <p className="italic">
                            {o.billingService?.planName || '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-5 flex-shrink-0">
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </a>
            </li>
          })}
        </ul>
      </div>
    </div>
  )
}

export default withAuthUser(ListOrganizationsMember)
