import fetch from 'isomorphic-fetch'
import JwtDecode from 'jwt-decode'
import { resetSegment } from 'segment'
import CLIENT_SETTINGS from './client_settings'

// *** Gnowbe API

type ApiToken = {tokenType; token; uid; email; expiresAt}

function getTokenFromLS(): ApiToken|undefined {
  const s = localStorage.getItem('GnowbeApiToken')

  if (!s) return undefined

  try {
    const t = JSON.parse(s)
    if (t.tokenType && t.token && t.expiresAt) {
      if (t.expiresAt < ((Date.now() + 10 * 60 * 1000) / 1000)) {
        return undefined
      }
      return t
    }
    return undefined
  } catch (err) {
    return undefined
  }
}

let _gnowbeApiToken: ApiToken|undefined = getTokenFromLS()

export async function signinWithApiToken(tokenType, token, expiresAt) {
  let authToken = token
  let authTokenPayload: any = JwtDecode(token)
  // If token is not "real" token but just auth token, let's get "real"
  if (authTokenPayload.aud === 'api.gnowbe.com/auth') {
    try {
      const res = await fetch(`${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/auth/login`, {
        method: 'POST',
        headers: {
          Authorization: `${tokenType} ${authToken}`,
          Accept: 'application/json',
          'x-gnowbe-source': 'gnowbe-dashboard 1',
        },
      })
      if (res.status === 401) {
        // logoutHandler(authTokenPayload.email)
        logoutHandler()
        throw new Error('Unexpected error while logging in')
        // If we would do below we would end up in "deadlock"
        // window.location.reload()
        // return
      }
      if (res.status !== 200) {
        alert('Something went wrong and we had to log you out.\nPlease log in again.')
        window.location.reload()
        return
      }
      const tokenData = await res.json()
      authToken = tokenData.access_token
      expiresAt = tokenData.expires_at
      authTokenPayload = JwtDecode(authToken) // decodamo pravi accessToken ki dobimo od apija
    } catch (error) {
      console.error(error)
    }
  }

  _gnowbeApiToken = {
    tokenType,
    token: authToken,
    expiresAt,
    uid: authTokenPayload.uid,
    email: authTokenPayload.email,
  }
  localStorage.setItem('GnowbeApiToken', JSON.stringify(_gnowbeApiToken))
}

export async function getGnowbeApiToken(): Promise<ApiToken> {
  if (_gnowbeApiToken && _gnowbeApiToken.expiresAt > (Date.now() / 1000 + 120)) {
    return _gnowbeApiToken
  }
  localStorage.removeItem('GnowbeApiToken')
  return {
    tokenType: '',
    token: '',
    uid: '',
    email: '',
    expiresAt: 0,
  }
}

// *** Logout handler
export async function logoutHandler(noReload?: boolean, fromEverywhere?: boolean) {
  const apiToken = await getGnowbeApiToken()
  localStorage.removeItem('GnowbeApiToken')
  _gnowbeApiToken = undefined

  try {
    await fetch(`${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/auth/logout${fromEverywhere ? '?endAllSessions=true' : ''}`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'x-gnowbe-source': 'gnowbe-dashboard 1',
        authorization: `${apiToken.tokenType} ${apiToken.token}`,
      } as any,
    })
    resetSegment()
  } catch (err) {}
  if (document.location && !noReload) {
    document.location.assign('/')
  }
}

export async function removeToken() {
  localStorage.removeItem('GnowbeApiToken')
  _gnowbeApiToken = undefined
}
