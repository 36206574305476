import { OrganizationContextType } from 'components/context'
import { OrganizationBasic, OrganizationSettings, OrganizationTags } from 'models/organization'
import { UserAuthenticated } from '../models/user'
import { logoutHandler } from './auth'


export function isAdmin(authUser: UserAuthenticated|null|undefined) {
  return authUser?.roles?.includes('admin') || false
}

export function isSuperEditor(user: UserAuthenticated|null|undefined) {
  return user && user.roles.includes('superEditor') || false
}

export function isSuperEditor2(authUser: UserAuthenticated|null|undefined) {
  return authUser?.roles.includes('superEditor') || false
}

export function isSales(user: UserAuthenticated|null|undefined) {
  return isSuperEditor2(user) || user && user.roles.includes('sales') || false
}

export function isSales2(authUser: UserAuthenticated|null|undefined) {
  return authUser?.roles.includes('sales') || false
}


export function isPartner(user: UserAuthenticated|null|undefined) {
  return isSales(user) || user && user.roles.includes('partner') || false
}

export function isPartner2(user: UserAuthenticated|null|undefined) {
  return user?.roles.includes('partner') || false
}

export function isOrganizationPartner(user: UserAuthenticated|null|undefined, organizationId: string) {
  if (isSales(user)) return true
  return isPartner(user) && user && user.partnerOrganizations.includes(organizationId) || false
}

export function isManagerAnyOrganization(user: UserAuthenticated|null|undefined): boolean {
  if (isSuperEditor(user)) return true
  return user && user.managerOrganizations.length > 0 || false
}

export function isManager(user: UserAuthenticated|null|undefined, organizationId: string|null|undefined): boolean {
  if (isSuperEditor(user)) return true
  if (!organizationId) return false
  if (user) return user.managerOrganizations.includes(organizationId) || false
  return false
}

export function isManager2(authUser: UserAuthenticated|null|undefined, organizationId: string) {
  return authUser?.managerOrganizations?.includes(organizationId) || false
}

export function isAgentAnyCompany(user: UserAuthenticated|null|undefined): boolean {
  if (isSuperEditor(user)) return true
  return user && user.agentCompanies.length > 0 || false
}

export function isAgent(user: UserAuthenticated|null|undefined, companyId: string|null|undefined): boolean {
  if (isSuperEditor(user)) return true
  if (!companyId) return false
  if (user) return user.agentCompanies.includes(companyId)
  return false
}

export function isAgent2(authUser: UserAuthenticated|null|undefined, companyId: string) {
  return authUser?.agentCompanies.includes(companyId) || false
}

export function isEditor(user: any) {
  if (isSuperEditor(user)) return true
  return user && (user.editorCourses.length > 0 || user.editorCompanies.length > 0) || false
}

export function isEditorAnyOrganization(user: UserAuthenticated|null|undefined) {
  if (isSuperEditor(user)) return true
  return user && user.editorOrganizations.length > 0 || false
}

export function isEditorAnyCompany(user: UserAuthenticated|null|undefined) {
  if (isSuperEditor(user)) return true
  return user && user.editorCompanies.length > 0 || false
}

export function isEditorAnyCourse(user: UserAuthenticated|null|undefined) {
  if (isSuperEditor(user)) return true
  return user && user.editorCourses.length > 0 || false
}

export function isEditorAnyCourse2(user: UserAuthenticated|null|undefined) {
  return user?.editorCourses?.length || false
}

export function isCourseEditor(user: UserAuthenticated|null|undefined, courseId: string|null|undefined) {
  return isMyCourseId(user, courseId)
}

export function isCourseEditor2(authUser: UserAuthenticated|null|undefined, courseId: string) {
  return authUser?.editorCourses?.includes(courseId) || false
}

export function isCompanyEditor(user: UserAuthenticated|null|undefined, companyId: string) {
  return isSuperEditor(user) || user && user.editorCompanies.includes(companyId) || false
}

export function isOrganizationEditor(user: UserAuthenticated|null|undefined, organizationId: string) {
  if (!user) return false
  return isSuperEditor(user) || user && user.editorOrganizations.includes(organizationId) || false
}

export function getMyCourseIds(user: UserAuthenticated|null|undefined): string[]|undefined { // if returning undefined means all
  if (isSuperEditor(user)) return undefined
  if (isEditor(user)) return (user && user.editorCourses || [])
  return []
}

export function isMyCourseId(user: UserAuthenticated|null|undefined, courseId: string|null|undefined) {
  const myCourseIds = getMyCourseIds(user)
  if (myCourseIds == null) return true
  return myCourseIds.includes(courseId || '')
}

export function isBillingManager(user: UserAuthenticated|null|undefined, organizationId: string) {
  if (!user) return false
  return user.billingManagerOrganizations.includes(organizationId)
}

export const isManagerOfSsoOrg = (user: UserAuthenticated|null|undefined, orgContext: OrganizationContextType) => {
  if (isSuperEditor(user) && orgContext?.organizationSsoSettings) return true
  if (!user) return false
  if (!orgContext) return false
  return isManager(user, orgContext.organization?.id) && !!orgContext?.organizationSsoSettings
}


export function canViewAllPrograms(authUser: UserAuthenticated|null|undefined) {
  return isSuperEditor2(authUser)
}

export function canViewBundles(authUser: UserAuthenticated|null|undefined) {
  return isSuperEditor2(authUser) || isSales2(authUser)
}

export function canViewAllUsers(authUser: UserAuthenticated|null|undefined) {
  return isSuperEditor2(authUser)
}

export function canViewAllCompanies(authUser: UserAuthenticated|null|undefined) {
  return isSuperEditor2(authUser) || isSales2(authUser)
}

export function canViewPartners(authUser: UserAuthenticated|null|undefined) {
  return isSuperEditor2(authUser) || isSales2(authUser)
}

export function canViewOrganizationUser(
  authUser: UserAuthenticated|null|undefined,
  organizationId: string,
) {
  if (!authUser) return false
  return isSuperEditor2(authUser) ||
    isSales2(authUser) ||
    isManager2(authUser, organizationId)
}

export function canViewCompany(
  authUser: UserAuthenticated|null|undefined,
  company: {id: string, organizationId: string, courseId?: string},
) {
  if (!authUser) return false
  return isSuperEditor2(authUser) ||
    isSales2(authUser) ||
    isManager2(authUser, company.organizationId) ||
    isAgent2(authUser, company.id) ||
    // We don't allow curators to see #test company on dashboard.
    // If we decide we want to allow this we have to allow on backend too.
    // (company.courseId && company.companyType === 'test' && isCourseEditor2(authUser, company.courseId)) ||
    false
}

export function canViewCompanyCourse(
  authUser: UserAuthenticated|null|undefined,
  companyId: string|undefined,
  organization: OrganizationBasic&OrganizationTags,
  courseId: string|undefined,
) {
  if (!authUser) return false
  return isSuperEditor2(authUser) ||
    isSales2(authUser) ||
    isManager2(authUser, organization.id) ||
    (companyId && isAgent2(authUser, companyId)) ||
    (isCourseEditor(authUser, courseId)
      && (organization.product === 'OP' || organization.tags.includes('test_groups'))) ||
    false
}

export function canViewCompanyUser(
  authUser: UserAuthenticated|null|undefined,
  company: {id: string, organizationId: string, courseId: string|undefined},
) {
  if (!authUser) return false
  return isSuperEditor2(authUser) ||
    isSales2(authUser) ||
    isManager2(authUser, company.organizationId) ||
    isAgent2(authUser, company.id) ||
    // We don't allow curators to see #test company on dashboard.
    // If we decide we want to allow this we have to allow on backend too.
    // (company.courseId && company.companyType === 'test' && isCourseEditor2(authUser, company.courseId)) ||
    false
}
export function canViewOrganizationBundle(
  authUser: UserAuthenticated|null|undefined,
  organizationId: string,
) {
  if (!authUser) return false
  return isSuperEditor2(authUser) ||
    isManager2(authUser, organizationId)
}

export function shouldAnonymize(
  organizationId: string, companyId: string|undefined,
  user: UserAuthenticated|null|undefined,
) {
  if (!user) return true
  if (user.managerOrganizations.includes(organizationId)) {
    return false
  }
  if (companyId && user.agentCompanies.includes(companyId)) {
    return false
  }
  return true
}

export function isSubscriptionExpired(organization: (OrganizationBasic&OrganizationSettings)|null) {
  if (!organization) return true
  return organization.disabled && organization.billingService?.type === 'chargebee'
}

export const isMe = (userIdOrEmail: string, userAuthenticated: UserAuthenticated | undefined) => {
  if (!userAuthenticated) {
    logoutHandler()
    return false
  }
  if (userIdOrEmail === userAuthenticated.id || userIdOrEmail === userAuthenticated.email) {
    return true
  }
  return false
}
