import { client } from 'App'
import { produce } from 'immer'
import { checkHttpStatus } from 'lib/utils'
import { GET_USER_METADATA } from 'queries/user'
import { showSnackbar } from '../components/snackbar'
import { getGnowbeApiToken } from './auth'
import CLIENT_SETTINGS from './client_settings'

export async function updateUserMetadataSettingsTable(value: string) {
  const key = 'settings/table/rowsPerPage'
  const apiToken = await getGnowbeApiToken()
  const res = await fetch(
  `${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/user/metadata/general`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken.token}`,
      'x-gnowbe-source': 'gnowbe-dashboard 1',
    },
    body: JSON.stringify({
      key,
      value,
    }),
  })
  return res
}


export async function updateMeta(key: string, value: string) {
  const apiToken = await getGnowbeApiToken()
  const res = await fetch(
  `${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/user/metadata/general`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken.token}`,
      'x-gnowbe-source': 'gnowbe-dashboard 1',
    },
    body: JSON.stringify({
      key,
      value,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (!response.error) {
      const clientData = client.readQuery({
        query: GET_USER_METADATA,
        variables: {
          userId: apiToken.uid,
        },
      })
      let last
      const path = key.split('/').filter(v => v)
      const newData = path.reduce((obj, val, i) => {
        if (typeof last === 'object') {
          last = last[val] = path.length === i + 1 ? value : {}
        } else {
          last = obj[val] = {}
        }
        return obj
      },                          {})

      client.writeQuery({
        query: GET_USER_METADATA,
        variables: {
          userId: apiToken.uid,
        },
        data: produce(clientData, (draft) => {
          if (!!draft.user.metadata?.general) {
            draft.user.metadata.general = {
              ...draft.user.metadata.general,
              ...newData,
              __typename: 'UserMetaData',
            }
          } else {
            draft.user.metadata = {
              ...(draft.user.metadata || {}),
              general: {
                ...newData,
                __typename: 'UserMetaData',
              },
              __typename: 'User',
            }
          }
        }),
      })
    } else {
      const error = Error(response.error)
      showSnackbar(`Error 1: ${error.message}.`, 5000, 'error')
    }
  })
  .catch((err) => {
    showSnackbar(`Error 2: ${Error(err).message}`, 5000, 'error')
  })
  return res
}

export async function updateMetaTour(key: string, value: string) {
  const apiToken = await getGnowbeApiToken()
  const res = await fetch(
  `${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/user/metadata/general`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken.token}`,
      'x-gnowbe-source': 'gnowbe-dashboard 1',
    },
    body: JSON.stringify({
      key,
      value,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (!response.error) {
      const clientData = client.readQuery({
        query: GET_USER_METADATA,
        variables: {
          userId: apiToken.uid,
        },
      })
      let last
      const path = key.split('/').filter(v => v)
      const newData = path.reduce((obj, val, i) => {
        if (typeof last === 'object') {
          last = last[val] = path.length === i + 1 ? value : {}
        } else {
          last = obj[val] = {}
        }
        return obj
      },                          {})

      client.writeQuery({
        query: GET_USER_METADATA,
        variables: {
          userId: apiToken.uid,
        },
        data: produce(clientData, (draft) => {
          if (!!draft.user.metadata?.general?.tour) {
            draft.user.metadata.general.tour = {
              ...draft.user.metadata.general.tour,
              ...newData['tour'],
              lastChanges: Date.now(),
            }
          } else if (!!draft.user.metadata?.general) {
            draft.user.metadata.general = {
              ...draft.user.metadata.general,
              ...newData,
              __typename: 'UserMetaData',
            }
          } else {
            draft.user.metadata = {
              ...(draft.user.metadata || {}),
              general: {
                ...newData,
                __typename: 'UserMetaData',
              },
              __typename: 'User',
            }
          }
        }),
      })
    } else {
      const error = Error(response.error)
      showSnackbar(`Error 1: ${error.message}.`, 5000, 'error')
    }
  })
  .catch((err) => {
    showSnackbar(`Error 2: ${Error(err).message}`, 5000, 'error')
  })
  return res
}

export async function updateMetaDeeply(key: string, value: string, deepKey: string) {
  const apiToken = await getGnowbeApiToken()
  const res = await fetch(
  `${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/user/metadata/general`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken.token}`,
      'x-gnowbe-source': 'gnowbe-dashboard 1',
    },
    body: JSON.stringify({
      key,
      value,
    }),
  })
  .then(response => checkHttpStatus(response, true))
  .then(response => response.json())
  .then((response) => {
    if (!response.error) {
      const clientData = client.readQuery({
        query: GET_USER_METADATA,
        variables: {
          userId: apiToken.uid,
        },
      })
      let last
      const path = key.split('/').filter(v => v)
      const newData = path.reduce((obj, val, i) => {
        if (typeof last === 'object') {
          last = last[val] = path.length === i + 1 ? value : {}
        } else {
          last = obj[val] = {}
        }
        return obj
      },                          {})

      client.writeQuery({
        query: GET_USER_METADATA,
        variables: {
          userId: apiToken.uid,
        },
        data: produce(clientData, (draft) => {
          if (!!draft.user.metadata?.general?.[deepKey]) {
            draft.user.metadata.general[deepKey] = {
              ...draft.user.metadata.general[deepKey],
              ...newData[deepKey],
              lastChanges: Date.now(),
            }
          } else if (!!draft.user.metadata?.general) {
            draft.user.metadata.general = {
              ...draft.user.metadata.general,
              ...newData,
              __typename: 'UserMetaData',
            }
          } else {
            draft.user.metadata = {
              ...(draft.user.metadata || {}),
              general: {
                ...newData,
                __typename: 'UserMetaData',
              },
              __typename: 'User',
            }
          }
        }),
      })
    } else {
      const error = Error(response.error)
      showSnackbar(`Error 1: ${error.message}.`, 5000, 'error')
    }
  })
  .catch((err) => {
    showSnackbar(`Error 2: ${Error(err).message}`, 5000, 'error')
  })
  return res
}
