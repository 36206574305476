import * as React from 'react'
import IconType from './iconType'

type FlagIconType = {
  isOrganization: boolean,
}

const FlagIcon = (props: IconType&FlagIconType) => {
  return (
    <svg
      className={`${props.isOrganization ? 'fill-listenblue' : 'fill-lightgray'} ${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 512 512"
      >
      <path d="M64 496C64 504.8 56.75 512 48 512h-32C7.25 512 0 504.8 0 496V32c0-17.75 14.25-32 32-32s32 14.25 32 32V496zM476.3 0c-6.365 0-13.01 1.35-19.34 4.233c-45.69 20.86-79.56 27.94-107.8 27.94c-59.96 0-94.81-31.86-163.9-31.87C160.9 .3055 131.6 4.867 96 15.75v350.5c32-9.984 59.87-14.1 84.85-14.1c73.63 0 124.9 31.78 198.6 31.78c31.91 0 68.02-5.971 111.1-23.09C504.1 355.9 512 344.4 512 332.1V30.73C512 11.1 495.3 0 476.3 0z"
      />
    </svg>
  )
}

export default FlagIcon
