import React from 'react'
import classNames from 'classnames'
import { withNewFeatureBadgeShown } from './NewFeatureBadgeShownWrapper'

const NewFeatureComponentStyle = {
  display: 'flex',
  alignItems: 'center',
} as React.CSSProperties

const ownBadgeStyle = {
  marginLeft: '10px',
  backgroundColor: '#b50700',
  color: '#ffffff',
  borderRadius: '4px',
  padding: '3px 6px',
  marginBottom: -4,
  fontSize: '.75rem',
  lineHeight: '1rem',
  fontWeight: 500,
  textTransform: 'uppercase',
} as React.CSSProperties

const NewFeatureComponent = (props: {text: string}) => {
  return <div style={NewFeatureComponentStyle}>
    {props.text} <div style={ownBadgeStyle}>New</div>
  </div>
}

export default NewFeatureComponent

type NewFeatureBadgePropsIn = {
  text: React.ReactNode, position: 'left'|'right',
  badgeText?: string, hideBadge?: boolean, feature?: string,
}

const NewFeatureBadgeWithAutoHide = (props: NewFeatureBadgePropsIn&{hideTimeExpired?: boolean}) => {
  const badgeStyle = classNames({
    'ml-2': props.position === 'right',
    'mr-2': props.position === 'left',
  })
  return <div className="flex flex-row">
    {props.position === 'right' && props.text}
    {!props.hideBadge && !props.hideTimeExpired && (
      <span className={`${badgeStyle} uppercase px-2 bg-coral text-xs text-white rounded flex flex-row items-center justify-center`}>
        {props.badgeText || 'NEW'}
      </span>
    )}
    {props.position === 'left' && props.text}
  </div>
}

export const NewFeatureBadge =  withNewFeatureBadgeShown(NewFeatureBadgeWithAutoHide)

const NewFeatureRedDotWithAutoHide = (props: {
  hideTimeExpired?: boolean,
  feature?: string,
  hideRedDot?: boolean, // force hide
  classNames?: string,
  bottom?: string,
  left?: string,
}) => {
  if (props.hideTimeExpired || props.hideRedDot) return null
  return <div style={{width: '.875rem', height: '.875rem'}} className={`rounded-full bg-coral absolute ${props.bottom ? props.bottom : '-bottom-2'} ${props.left ? props.left : 'left-5'} ${props.classNames}`}/>
}
export const NewFeatureRedDot = withNewFeatureBadgeShown(NewFeatureRedDotWithAutoHide)