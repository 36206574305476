import { getReviewFragment, getReviewFragmentName, Review } from './review'
// import { getTimestampsFragment, getTimestampsFragmentName, Timestamps } from './timestamps'

export type ReminderData = {
  id: string,
  at: number,
}

export type Study = {
  id: string,
  userId: string,
  companyId: string,
  courseId: string,
  chapterId: string,
  actionId: string,
  contentType: string,
  viewed?: number,
  answer?: string,
  answeredAt?: number,
  completedAt?: number,
  sharedAt?: number,
  isCorrect?: boolean,
  isAssessmentCorrect?: boolean,
  thumbnail?: string,
  comment?: string,
  secondsSpent?: number,
  reminder?: ReminderData,
  // timestamps: Timestamps,
  // reviewTimestamps: Timestamps,
  likedByMe: boolean,
  commentsLastSeenAt?: number,
  rewardsSeen: string[],
  review: StudyReview,
  lastCommentAt?: number,
  commentsCount: number,
  likesCount: number,
  secondsUsed: number,
  tries: number,
}

export type StudyReview = {
  id: string,
  userId: string,
  companyId: string,
  courseId: string,
  chapterId: string,
  actionId: string,
  assessments: Review[],
  // timestamps: Timestamps,
  createdAt: number,
  updatedAt: number,
}

export const getStudyFragmentName = () => {
  return 'StudyFields'
}

export const getStudyFragment = () => {
  return `
    ${getStudyReviewFragment()}
    fragment ${getStudyFragmentName()} on Study {
      id
      userId
      companyId
      courseId
      chapterId
      actionId
      contentType
      viewed
      answer
      answeredAt
      completedAt
      sharedAt
      isCorrect
      isAssessmentCorrect
      thumbnail
      comment
      secondsSpent
      reminder {
        id
        at
      }
      likedByMe
      commentsLastSeenAt
      rewardsSeen
      review {
        ...${getStudyReviewFragmentName()}
      }
      lastCommentAt
      commentsCount
      likesCount
      secondsUsed
      tries
    }
  `
}


export const getStudyReviewFragmentName = () => {
  return 'StudyReviewFields'
}
export const getStudyReviewFragment = () => {
  return `
    ${getReviewFragment()}
    fragment ${getStudyReviewFragmentName()} on StudyReview {
      id
      userId
      companyId
      courseId
      chapterId
      actionId
      assessments {
        ...${getReviewFragmentName()}
      }
      createdAt
      updatedAt
    }
  `
}
