import * as React from 'react'
import IconType from './iconType'

const HistoryIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 22 19"
      >
      <path d="M12.26 1.00007C7.17 0.860072 3 4.95007 3 10.0001H1.21C0.76 10.0001 0.54 10.5401 0.86 10.8501L3.65 13.6501C3.85 13.8501 4.16 13.8501 4.36 13.6501L7.15 10.8501C7.46 10.5401 7.24 10.0001 6.79 10.0001H5C5 6.10007 8.18 2.95007 12.1 3.00007C15.82 3.05007 18.95 6.18007 19 9.90007C19.05 13.8101 15.9 17.0001 12 17.0001C10.39 17.0001 8.9 16.4501 7.72 15.5201C7.32 15.2101 6.76 15.2401 6.4 15.6001C5.98 16.0201 6.01 16.7301 6.48 17.0901C8 18.2901 9.91 19.0001 12 19.0001C17.05 19.0001 21.14 14.8301 21 9.74007C20.87 5.05007 16.95 1.13007 12.26 1.00007ZM11.75 6.00007C11.34 6.00007 11 6.34007 11 6.75007V10.4301C11 10.7801 11.19 11.1101 11.49 11.2901L14.61 13.1401C14.97 13.3501 15.43 13.2301 15.64 12.8801C15.85 12.5201 15.73 12.0601 15.38 11.8501L12.5 10.1401V6.74007C12.5 6.34007 12.16 6.00007 11.75 6.00007Z"
      />
    </svg>
  )
}

export default HistoryIcon