import React, { forwardRef } from 'react'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'

export type ButtonType = 'primary'|'secondary'|'destructive'|'danger'|'team'|'quote'|'edit'|'chat'|'text'|'default'|'span'
export type ButtonProps = {
  text?: string;
  icon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  type?: ButtonType;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick: (e) => void;
  size?: 'big'|'medium'|'small'
  loading?: boolean,
  onMouseEnter?: (e) => void,
  iconClassName?: string,
  tooltip?: string,
}

export const Button = forwardRef(({ icon, rightIcon, text, type, className, fullWidth, disabled, onClick, size, loading, onMouseEnter, iconClassName, tooltip }: ButtonProps, ref) => {
  const cls = classNames({
    disabled,
    'btn inline-flex items-center justify-center': true,
    'block w-full': fullWidth,
    'border-none': type === 'text' || (type === 'span' && !className?.split(' ').includes('border-solid')),
    [type && type || 'default']: true,
    [size && `btn-${size}` || 'btn-medium']: true,
    [className || '']: className,
  })
  const clsBtnTooltip = classNames({
    visible: tooltip || !!icon && !disabled && !loading,
    'xl:hidden': !tooltip,
  })

  const handleClick = (e) => {
    if (disabled) return null

    return onClick(e)
  }

  if (type === 'span') {
    return (
      <span
        tabIndex={0}
        className={cls}
        // disabled={disabled || loading || false}
        onClick={handleClick}
      >
        {loading
          ? <div className="animate-spin h-5 w-5 rounded-full border-b-2 border-deepgray" />
          : <>
            {icon && React.cloneElement(icon, {className: 'mr-2 w-5 h-5'})}

            {text}

            {rightIcon && React.cloneElement(rightIcon, {className: 'ml-2 w-5 h-5'})}
        </>}
      </span>
    )
  }

  return (
    <Tippy className={clsBtnTooltip} content={<div>{tooltip || text}</div>}>
      <button
        className={cls}
        disabled={disabled || loading || false}
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
      >
        {loading
          ? <div className="animate-spin h-5 w-5 rounded-full border-b-2 border-deepgray" />
          : <>
          {icon && React.cloneElement(icon, {className: `${iconClassName ? iconClassName : 'w-5 h-5'} xl:mr-2`})}

          <span className= {`${icon && 'hidden xl:inline-block'}`}>{text}</span> 

          {rightIcon && React.cloneElement(rightIcon, {className: `${iconClassName ? iconClassName : 'w-5 h-5'} ml-2`})}
        </>}
      </button>
    </Tippy>
  )
})
