import React, { useContext } from 'react'
import { CourseAvailability, CourseBasic, CourseCounts, CourseMeta, CourseTags, CourseTestCompany, CourseType } from '../../models/course'
import { getNewSelection, getRealFilterText } from '../TableToolbar'
import i18n from '../i18n'
import Tooltip from '@material-ui/core/Tooltip'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined'
import { withDefaultRowsPage } from 'components/DefaultRowsPerPage'
import { ExportCoursesRes, ExportCoursesVars, EXPORT_COURSES, GET_COURSES } from 'queries/course'
import ErrorCmp from 'components/Error'
import { handleError } from 'components/utils'
import { addExportId } from 'subscriptions/export'
import { courseDifficulties } from 'lib/course_logic'
import { CourseCategoriesContext } from 'components/context'
import { useLazyQuery, useQuery } from '@apollo/client'
import { openAlert } from 'components/snackbar'
import Table2, { TableOptions } from 'components/Table2'

const TABLE_ID = 'courses'

type CoursesTablePropsIn = {
  onChangeRowsPerPage?: (num: Number) => void,
  categories?: string[],
  difficulties?: string[],
  onlyAvailableToCompanyId?: string,
  onlyAvailableToOrganizationId?: string,
  onlyIds?: string[],
  initialOptions?: TableOptions,
  selected?: {
    onChange: (
      courses: (CourseBasic&CourseTags&CourseMeta&CourseAvailability&CourseCounts&CourseType)[],
      whatSelected: 'row'|'checkbox',
    ) => void,
    selectedIds: Set<string>,
    multiSelectable?: boolean,
  },
  onOptionsChange?: (o: TableOptions) => void,
  getCommands?: (course: CourseBasic&CourseType&CourseTestCompany) => React.ReactNode,
  toolbarCommands?: () => React.ReactNode,
  hideIterator?: boolean,
  contentPartnerIds?: string[],
  showExportButton?: boolean,
}
type CoursesTableProps = CoursesTablePropsIn & {
  loading: boolean,
  options: TableOptions,
  courses: (CourseBasic&CourseTags&CourseMeta&CourseAvailability&CourseCounts&CourseType)[],
  coursesCount: number,
  onOptionsChange: (o: TableOptions) => void,
  onRefresh?: () => void,
}


const CoursesTable = (props: CoursesTableProps) => {
  const courseCategoriesContext = useContext(CourseCategoriesContext)
  const [
    exportCourses,
  ] = useLazyQuery<{exportCourses: ExportCoursesRes}, ExportCoursesVars>(
    EXPORT_COURSES, {
      fetchPolicy: 'no-cache',
      onError: (error) => {
        handleError(error)
      },
      onCompleted: (data) => {
        addExportId(data.exportCourses.exportId)
        openAlert({
          title: i18n.t('exporting'),
          description: i18n.t('export_file_alert_2'),
        })
      },
    },
  )

  const exportFiltered = () => {
    exportCourses({
      variables: {
        searchText: props.options.filterText,
        categories: props.categories,
        difficulties: props.difficulties,
        onlyAvailableToCompanyId: props.onlyAvailableToCompanyId,
        onlyIds: props.onlyIds,
        ...(props.contentPartnerIds && {contentPartnerIds: props.contentPartnerIds}),
      },
    })
  }
  const exportAll = () => {
    exportCourses({
      variables: {
        searchText: '',
        categories: [''].concat(courseCategoriesContext.categories.map(c => c.id)),
        difficulties: [''].concat(courseDifficulties.map(d => d.id)),
        onlyAvailableToCompanyId: props.onlyAvailableToCompanyId,
        onlyIds: props.onlyIds,
        ...(props.contentPartnerIds && {contentPartnerIds: props.contentPartnerIds}),
      },
    })
  }

  const columns = React.useMemo(
    () => [
      {
        accessor: 'title',
        Header: i18n.t('title'),
        disableSortBy: true,
        align: 'start',
        sticky: 'left',
      },
      {
        accessor: 'curator',
        Header: i18n.t('curator'),
        disableSortBy: true,
        align: 'start',
      },
      {
        accessor: 'sessions',
        Header: i18n.t('session_plural'),
        disableSortBy: true,
        align: 'end',
      },
      ...(props.getCommands ? [{
        accessor: 'actions',
        Header: i18n.t('actions'),
        disableSortBy: true,
        align: 'center', sticky: 'right', width: 80,
      }] : []),
    ],
    [],
  )
  const rows = props.courses.map((c, i) => {
    return {
      id: c.id,
      title: <div className="truncate">
        {c.isTemplate ? <Tooltip title="Template">
          <span><InsertDriveFileIcon className="text-medgray" fontSize="small" />&nbsp;</span>
        </Tooltip> : undefined}
        <Tooltip title={c.title}>
            <span style={{lineHeight: '1.4em'}}>
              {c.title}
              <br/>
              <span className="font-normal" style={{color: '#9E9E9E'}}>{(c.tags || []).join(' ')}</span>
            </span>
          </Tooltip>
        </div>,
      curator: <span title={c.curatorName}>{c.curatorName}</span>,
      sessions: c.chaptersCount || 0,
      ...(props.getCommands ? {actions: props.getCommands(c)} : {}),
    }
  })
  return <>
    <Table2
      tableId={TABLE_ID}
      loading={props.loading}
      select={props.selected ? {
        selected: Array.from(props.selected.selectedIds),
        multiSelectable: props.selected.multiSelectable,
        allowRowClick: true,
        onSelect:  (ids, what, checked) => {
          if (!props.selected) return
          props.selected.onChange(
            props.courses.filter(c => ids.includes(c.id)),
            what,
          )
        },
      } : undefined}
      options={props.options}
      onOptionsChange={props.onOptionsChange}
      toolbar={{
        placeholder: i18n.t('courses_filter_text'),
        ...(props.showExportButton && {
          exportData: {
            onExportAll: exportAll,
            onExportFiltered: exportFiltered,
          },
        }),
        onRefresh: () => props.onRefresh && props.onRefresh(),
        getCommands: props.toolbarCommands ? [{
          componentType: 'component',
          type: 'other',
          component: props.toolbarCommands(),
        }] : undefined,
      }}
      columns={columns}
      data={rows}
      count={props.coursesCount}
    />
  </>
}

type QueryRes = {coursesRes: {
  courses: (CourseBasic&CourseTags&CourseMeta&CourseAvailability&CourseCounts&CourseType&CourseTestCompany)[],
  coursesCount: number,
}}

type Variables = {searchText: string; skip: number; limit: number} & Pick<CoursesTablePropsIn, 'categories'|'difficulties'|'onlyAvailableToCompanyId'|'onlyIds'|'contentPartnerIds'>

const Courses = (p: CoursesTablePropsIn & {
  rowsPerPage?: number,
}) => {
  const [options, setOptions] = React.useState<TableOptions>({
    filterText: p.initialOptions && p.initialOptions.filterText || '',
    page: p.initialOptions && p.initialOptions.page || 0,
    rowsPerPage: p.rowsPerPage || (p.initialOptions && p.initialOptions.rowsPerPage || 10),
  })

  const countRef = React.useRef(0)
  const {loading, error, data, variables, refetch} = useQuery<QueryRes, Variables>(
    GET_COURSES(['with_tags', 'with_type', 'with_meta', 'with_availability', 'with_counts', 'with_testcompany']),
    {
      variables: {
        searchText: getRealFilterText(options.filterText),
        skip: options.page * options.rowsPerPage,
        limit: options.rowsPerPage,
        categories: p.categories,
        difficulties: p.difficulties,
        onlyAvailableToCompanyId: p.onlyAvailableToCompanyId || '',
        onlyIds: p.onlyIds,
        contentPartnerIds: p.contentPartnerIds,
      },
      fetchPolicy: 'cache-and-network',
    },
  )

  if (error) return <ErrorCmp error={error} />

  const courses = !loading && data?.coursesRes ? data.coursesRes.courses : []
  const coursesCount = !loading && data?.coursesRes
    ? data.coursesRes.coursesCount
    : countRef.current
  countRef.current = coursesCount

  return <CoursesTable
    {...p}
    options={options}
    onOptionsChange={(o) => {
      setOptions(o)
      p.onOptionsChange && p.onOptionsChange(o)
    }}
    onRefresh={refetch}
    loading={loading}
    courses={courses}
    coursesCount={coursesCount}
  />
}

export default withDefaultRowsPage(Courses, {tableId: TABLE_ID})
