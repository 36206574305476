import React from 'react';
import fetch from 'isomorphic-fetch';
import CLIENT_SETTINGS from '../../lib/client_settings';
import { logoutHandler, getGnowbeApiToken } from '../../lib/auth';
import { Warning } from 'components/notifications/Alerts';
import { Button } from 'components/elements/Button';
import TextField from 'components/TextField';
import TextArea from 'components/TextArea';

type JobRunnerProps = {}
type JobRunnerState = {
  command?: string;
  commandResult?: string;
  processing?: boolean;
}

export class JobRunner extends React.Component<JobRunnerProps, JobRunnerState> {
  constructor(props: JobRunnerProps) {
    super(props)
    this.state = {}
  }

  async executeCommand() {
    if (this.state.processing) return
    this.setState({processing: true})
    try {
      const apiToken = await getGnowbeApiToken()
      const res = await fetch(`${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/admin/jobs`, {
        method: 'POST',
        headers: {
          'authorization': apiToken.tokenType + ' ' + apiToken.token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-gnowbe-source': 'gnowbe-dashboard 1'
        },
        body: JSON.stringify({
          command: this.state.command
        })
      })
      if (res.status === 401) {
        logoutHandler()
      }
      const json = await res.json()
      this.setState({
        processing: false,
        command: '',
        commandResult: `COMMAND\n${this.state.command}\n\nRESULT\n${json.data.result}`
      })
    } catch (err) {
      this.setState({
        processing: false,
        commandResult: 'COMMAND ERROR: ' + err.message
      })
    }
  }

  render() {
    return <div style={{padding: '30px'}}>
      <h1>Job Runner</h1>
      <div className="mt-6" />

      <Warning message={<div>
          <span className="font-bold italic">Example</span><br /><br />
          <code className="font-mono p-2 bg-deepgray rounded mt-2 text-actions-qa">run_job jobName {'{"param1":"value1","param2":"value2"}'}</code>
        </div>}
      />

      <div className="mt-6" />
      <div className="flex flex-row items-center justify-start">
        <div className="flex-1">
          <TextField type="text"
            value={this.state.command || ''}
            onChange={e => this.setState({command: e})}
            // style={{width: '400px', padding: '5px'}}
            // onKeyDown={e => e.keyCode === 13 && this.executeCommand()}
          />
        </div>
        <Button type="primary" className="ml-4" onClick={e => this.executeCommand()} disabled={!!this.state.processing} text="Execute" />
      </div>
      <div className="mt-6" />
      <TextArea placeholder='...' rows={20} readOnly value={this.state.commandResult}></TextArea>
    </div>
  }

}