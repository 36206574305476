
import CheckIcon from '@heroicons/react/24/solid/CheckIcon'
import { Button } from 'components/elements/Button'
import React from 'react'
import { charLimit, colors, idLimit } from './BannerCreation'
import BannerDate from './BannerDate'
import BannerPlatforms from './BannerPlatforms'
import { defaultBannerState } from './BannersTabs'
import CtaSection from './CtaSection'
import PreviewBanner from './PreviewBanner'


export type BannerStateType = {
  bannerId: string,
  type: 'text'|'range5'|'range10',
  bannerText: string,
  bannerColor: string,
  ctaButton: null | {
    url: string,
    label: string,
  },
  showToPlatform: ('ios'|'android'|'web'|'dashboard')[],
  showToProduct: ('FT'| 'CR'| 'UL'| 'XL'| 'VL'| 'CL'| 'GT'| 'OP'| 'IN'| 'DM')[],
  enabled: boolean,
  expirationTime: number,
  minRangeCopy: null | string,
  maxRangeCopy: null | string,
  dismissForDays: number,
  creatorId: string | undefined,
  creatorName: string | undefined,
}

type BannerDetailsProps = {
  state: BannerStateType,
  handleFieldChange: (value: string, e: string) => void,
  setState: (value: BannerStateType) => void,
  checkField: (field: string) => void,
  setCta: (val: boolean) => void,
  cta: boolean,
  selected: number,
  setSelected: (val: number) => void,
  numOfChar: number,
  numOfIdChar: number,
  isDate: boolean,
  setIsDate: (value: boolean) => void,
  tab?: number,
}

const BannerDetails = ({
  state,
  handleFieldChange,
  setState,
  checkField,
  setCta,
  cta,
  numOfChar,
  numOfIdChar,
  selected,
  setSelected,
  isDate,
  setIsDate,
}: BannerDetailsProps) => {

  return (
    <section className="bg-alabastergray">
      <PreviewBanner state={state} cta={cta} />
      <section className="mt-6 p-4 bg-[#F3F4F5] rounded">
        <div className="text-sm text-medgray font-bold">
          Banner ID
        </div>
        <div className={'text-sm text-medgray font-normal'}>
          Banner ID is used for easy identification. Only alphanumeric (A-Z, 0-9), hyphens (-) and underscores (_) characters are allowed.
        </div>
        <input type="text" placeholder="e.g., Creator_tooltips"
          className={`w-1/2 border border-lightgray rounded mt-1 ${location.hash !== '#edit' ? checkField('bannerId') : 'bg-gray-300'}`}
          onChange={e => handleFieldChange('bannerId', e.currentTarget.value)}
          value={state.bannerId}
          disabled={location.hash === '#edit'}
        />
        {numOfIdChar < idLimit && <div className="text-coral">
          Banner ID required.
        </div>}
        <div className="text-sm text-medgray font-bold mt-2">
          Banner text
        </div>
        <div className="text-sm text-medgray font-normal">
          Character limit: 280
        </div>
        <input type="text" placeholder="Insert banner text here"
          className={`w-full border border-lightgray rounded mt-1 ${checkField('bannerText')}`}
          onChange={e => handleFieldChange('bannerText', e.currentTarget.value)}
          value={state.bannerText}
        />
          {numOfChar > charLimit && <div className="text-coral">
            Character limit exceeded.
          </div>}
        <div className="text-sm text-medgray font-bold mt-2">
          Banner color
        </div>
        <div className="text-sm text-medgray font-normal">
          Select from the colors below for your banner.
        </div>
        <div className="flex">
          {colors.map((color, i) => {
            return (
              <div
                key={i}
                className={'w-8 h-8 rounded mt-1 mr-2 flex items-center justify-center'}
                style={{backgroundColor: color}}
                onClick={
                  () => {
                    setSelected(i)
                    handleFieldChange('bannerColor', color)
                  }
                }
              >
                {selected === i && <CheckIcon/>}
              </div>
            )
          })}
        </div>
      </section>
      <CtaSection
        state={state}
        handleFieldChange={handleFieldChange}
        setState={setState}
        checkField={checkField}
        cta={cta}
        setCta={setCta}
      />
      <BannerDate state={state} setState={setState} isDate={isDate} setIsDate={setIsDate}/>
      <BannerPlatforms state={state} setState={setState} />
      <div className="flex items-end justify-end">
        <Button
          disabled={location.hash === '#edit'}
          text="Reset Banner"
          type="destructive"
          onClick={
            () => {
              if (cta) {
                setCta(!cta)
              }
              if (isDate) {
                setIsDate(!isDate)
              }
              setSelected(0)
              setState({
                ...defaultBannerState,
              })
            }
          }
          />
      </div>
    </section>
  )
}

export default BannerDetails
