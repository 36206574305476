import { Switch } from '@headlessui/react'
import classnames from 'classnames'
import React from 'react'

type PropsIn = {
  label?: string,
  hint?: string,
  checked: boolean,
  onChange: (enabled: boolean) => void,
  labelPosition?: 'right'|'left',
  disabled?: boolean,
  value?: string,
}
export default function ({label, checked, onChange, labelPosition, disabled, hint, value}: PropsIn) {
  const switchStyle = `
    ${classnames({
      'bg-lake': checked && !disabled,
      'bg-gray-200': !checked && !disabled,
      'bg-lake-light': checked && disabled,
      'bg-gray-50': !checked && disabled,
      'cursor-not-allowed': disabled,
    })}
    relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2
  `
  return (
    <div>
      <Switch.Group>
        <div className="flex items-center">
          {!!labelPosition && labelPosition === 'left' && <Switch.Label className="mr-2">{label}</Switch.Label>}
          <Switch
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            className={switchStyle}
            value={value}
          >
            <span
              className={`${
                checked ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
            />
          </Switch>
          {!labelPosition || labelPosition === 'right' && <Switch.Label className="ml-2">{label}</Switch.Label>}
        </div>
      </Switch.Group>
    </div>
  )
}
