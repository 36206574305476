import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { client } from 'App'
import { removeToken } from 'lib/auth'
import i18n from './i18n'
import { LISTEN_TO_AUTH_CHANGES } from 'subscriptions/authChanges'
import { Trans } from 'react-i18next'


export default function () {
  const [open, setOpen] = React.useState(false)
  React.useEffect(
    () => {
      const observer = client.subscribe({
        query: LISTEN_TO_AUTH_CHANGES,
      })

      const subscription = observer.subscribe(({ data }) => {
        if (data?.status?.reasonCode === 'inactivity') {
          subscription.unsubscribe()
          removeToken()
          setOpen(true)
        }
      })

      return () => subscription.unsubscribe()
    },
    [],
  )

  const close = () => location.reload()

  return (
  <Transition show={open} as={React.Fragment}>
    <Dialog
      as="div"
      id="modal-inactivity"
      className="fixed inset-0 z-10 overflow-y-auto"
      static
      open={open}
      onClose={() => null}
    >
      <div className="min-h-screen px-4 text-center">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0" />
        </Transition.Child>

        <span
          className="inline-block h-screen align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="span"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {i18n.t('please_login_again')}
            </Dialog.Title>
            <div className="mt-3">
              <p className="text-sm text-gray-500">
                <Trans
                  i18n={i18n}
                  i18nKey="user_logout_reason_inactivity"
                  components={[<br></br>]}
                />
              </p>
            </div>

            <div className="mt-4 w-full flex flex-row justify-end">
              <button
                type="button"
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-lake border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-lake"
                onClick={close}
              >
                {i18n.t('log_in')}
              </button>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
  )
}
