import * as React from 'react'
import IconType from './iconType'

const MlcGnowbeIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 512 512"
      >
      <path d="M0 56C0 42.75 10.75 32 24 32H488C501.3 32 512 42.75 512 56C512 69.25 501.3 80 488 80H24C10.75 80 0 69.25 0 56zM368 160C394.5 160 416 181.5 416 208V304C416 330.5 394.5 352 368 352H144C117.5 352 96 330.5 96 304V208C96 181.5 117.5 160 144 160H368zM368 208H144V304H368V208zM488 432C501.3 432 512 442.7 512 456C512 469.3 501.3 480 488 480H24C10.75 480 0 469.3 0 456C0 442.7 10.75 432 24 432H488z"
      />
    </svg>
  )
}

export default MlcGnowbeIcon
