import React from 'react'
import HelpTooltip from './HelpTooltip'

type PropsIn = {
  label: string,
  helpTooltip?: string,
  bold?: boolean,
}
export default function (props: PropsIn) {
  return <div className={`whitespace-nowrap text-sm w-full text-left text-medgray ${props.bold ? 'font-bold' : ''}`}>
    {props.label} {props.helpTooltip && <HelpTooltip text={<span>{props.helpTooltip}</span>} />}
  </div>
}
