import React from 'react'
import Disclosure from 'components/Disclosure'
import BannerDetails, { BannerStateType } from './BannerDetails'

type BannerDisclosureProps = {
  state: BannerStateType
  handleFieldChange: (value: string, e: string) => void,
  setState: (value: BannerStateType) => void,
  checkField: (field: string) => void,
  setCta: (val: boolean) => void,
  cta: boolean,
  selected: number,
  setSelected: (val: number) => void,
  numOfChar: number,
  numOfIdChar: number,
  isDate: boolean,
  setIsDate: (value: boolean) => void,
}

const BannerDisclosure = ({
  state,
  handleFieldChange,
  setState, checkField,
  setCta,
  cta,
  numOfChar,
  numOfIdChar,
  selected,
  setSelected,
  isDate,
  setIsDate,
}: BannerDisclosureProps) => {

  return (
    <Disclosure
      title={`Banner${state.bannerId.length > 3 ? `: ${state.bannerId}` : ''}`}
      subtitle={' '}
      titleClassName={'font-semibold text-sm text-listenblue py-2 uppercase leading-6'}
      v2={true}
      defaultOpen={true}
      content={
        <BannerDetails
          state={state}
          setState={setState}
          cta={cta} setCta={setCta}
          numOfChar={numOfChar}
          numOfIdChar={numOfIdChar}
          handleFieldChange={handleFieldChange}
          checkField={checkField}
          selected={selected}
          setSelected={setSelected}
          isDate={isDate}
          setIsDate={setIsDate}
          />
      }
    />
  )
}

export default BannerDisclosure
