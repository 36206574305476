
export type EngagementSummary = {
  numOfUsersRegistered: number;
  numOfUsersOpened: number;
  numOfUsersStarted: number;
  numOfUsersGraduated: number;
  avgDiscoverRate: number;
  avgEngagement: number;
  avgCompletion: number;
  avgGraduation: number;
  avgAssessmentsGrade?: number;
  avgAssessmentsGradeReg?: number;
  numOfCompletedChapters: number;
  numOfCompletedActions: number;
  minutesSpent: number;
  avgMinutesSpent: number;
}
export const EngagementSummaryEmpty: EngagementSummary = Object.freeze({
  numOfUsersRegistered: 0,
  numOfUsersOpened: 0,
  numOfUsersStarted: 0,
  numOfUsersGraduated: 0,
  avgDiscoverRate: 0,
  avgEngagement: 0,
  avgCompletion: 0,
  avgGraduation: 0,
  numOfCompletedChapters: 0,
  numOfCompletedActions: 0,
  minutesSpent: 0,
  avgMinutesSpent: 0,
})
export const getEngagementSummaryFragmentName = () => {
  return 'EngagementSummaryFields'
}
export const getEngagementSummaryFragment = () => `
  fragment ${getEngagementSummaryFragmentName()} on EngagementSummary {
    numOfUsersRegistered
    numOfUsersOpened
    numOfUsersStarted
    numOfUsersGraduated
    avgDiscoverRate
    avgEngagement
    avgCompletion
    avgGraduation
    numOfCompletedChapters
    numOfCompletedActions
    minutesSpent
    avgMinutesSpent
  }
`
export type UserEngagementSummary = {
  userId: string,
  registered: number,
  opened: number,
  started: number,
  graduated: number,
  avgDiscoverRate: number,
  avgEngagement: number,
  avgCompletion: number,
  avgGraduation: number,
  avgAssessmentsGrade?: number;
  avgAssessmentsGradeReg?: number;
  numOfCompletedChapters: number,
  numOfCompletedActions: number,
  minutesSpent: number,
  avgMinutesSpent: number,
}

export type UserChapterEngagementSummary = {
  chapterId: string,
  chapterTitle: string,
  chapterOrder: number,
  engagement: number,
  numOfActions: number,
  numOfCompletedActions: number,
  numOfViewedActions: number,
  startedAt?: number,
  isOpened: boolean,
  assessmentsStartedAt: number,
}
export const getUserChapterEngagementSummaryFragmentName = () => 'UserChapterEngagementSummaryFields'
export const getUserChapterEngagementSummaryFragment = () => `
  fragment ${getUserChapterEngagementSummaryFragmentName()} on UserChapterEngagementSummary {
    chapterId
    chapterTitle
    chapterOrder
    engagement
    numOfActions
    numOfCompletedActions
    numOfViewedActions
    startedAt
    isOpened
    assessmentsStartedAt
  }
`

export type UserCourseEngagementSummary = {
  userId: string,
  companyId: string,
  courseId: string,
  isDisabled: boolean,
  opened: boolean,
  started: boolean,
  discoverRate: number,
  engagement: number,
  completion: number,
  numOfCompletedChapters: number,
  numOfCompletedActions: number,
  minutesSpent: number,
  currentDay: number,
  hasAssessments: boolean,
  assessmentsStarted: boolean,
  assessmentsCompleted: boolean,
  assessmentsGrade: number,
  assessmentsPassed: boolean,
  lastVisitAt: number,
  certificate?: string|null,
  completedAt?: number|null,
}
export type UserCourseEngagementSummaryChapters = {
  chapters: UserChapterEngagementSummary[],
}
export type UserCourseEngagementSummaryResponseType = 'with_chapters'
export const getUserCourseEngagementSummaryFragmentName = (types: UserCourseEngagementSummaryResponseType[]) => {
  return `UserCourseEngagementSummaryFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getUserCourseEngagementSummaryFragment = (types: UserCourseEngagementSummaryResponseType[]) => `
  ${types.includes('with_chapters') ? getUserChapterEngagementSummaryFragment() : ''}
  fragment ${getUserCourseEngagementSummaryFragmentName(types)} on UserCourseEngagementSummary {
    userId
    companyId
    isDisabled
    courseId
    currentDay
    opened
    started
    discoverRate
    engagement
    completion
    numOfCompletedChapters
    numOfCompletedActions
    minutesSpent
    hasAssessments
    assessmentsStarted
    assessmentsCompleted
    assessmentsGrade
    assessmentsPassed
    certificate
    lastVisitAt
    completedAt
    ${types.includes('with_chapters') ? `
    chapters {
      ...${getUserChapterEngagementSummaryFragmentName()}
    }
    ` : ''}
  }
`

export type OrganizationEngagementSummary = {
  organizationId: string,
  companyId?: string,
  courseId?: string,
  engagementSummary: EngagementSummary,
  companiesCount: number,
  coursesCount: number,
}
export type GetOrganizationEngagementSummaryVariables = {
  onlyVisibleInAnalytics: boolean,
  courseId?: string,
  companyId?: string,
  organizationId: string,
}
export type UserOrganizationEngagementSummary = {
  userId: string,
  organizationId: string,
  companyId?: string,
  courseId?: string,
  engagementSummary: UserEngagementSummary,
  companiesCount: number,
  coursesCount: number,
}
export type GetUserOrganizationEngagementSummaryVariables = {
  onlyVisibleInAnalytics: boolean,
  organizationId: string,
  companyId?: string,
  courseId?: string,
  userId: string,
}


export const getUserEngagementSummaryFragmentName = () => {
  return 'UserEngagementSummaryFields'
}
export const getUserEngagementSummaryFragment = () => {
  return `
    fragment ${getUserEngagementSummaryFragmentName()} on UserEngagementSummary {
      userId
      registered
      opened
      started
      graduated
      avgDiscoverRate
      avgEngagement
      avgCompletion
      avgGraduation
      avgAssessmentsGrade
      avgAssessmentsGradeReg
      numOfCompletedChapters
      numOfCompletedActions
      minutesSpent
      avgMinutesSpent
    }
  `
}
export const getUserOrganizationEngagementSummaryFragmentName = () => {
  return 'UserOrganizationEngagementSummaryFields'
}
export const getUserOrganizationEngagementSummaryFragment = () => {
  return `
    ${getUserEngagementSummaryFragment()}
    fragment ${getUserOrganizationEngagementSummaryFragmentName()} on UserOrganizationEngagementSummary {
      userId
      organizationId
      companyId
      courseId
      engagementSummary {
        ...${getUserEngagementSummaryFragmentName()}
      }
      companiesCount
      coursesCount
    }
  `
}
