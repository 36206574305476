import CLIENT_SETTINGS from 'lib/client_settings'
import * as logger from 'lib/console_logger'
import { checkHttpStatus } from 'lib/utils'
declare let analytics: any

const NO_IP = '0.0.0.0'

let _fetchedIp: undefined|Promise<string> = undefined

const getIp = async () => {
  if (/localhost/.test(document.location.host)) {
    return NO_IP
  }

  if (_fetchedIp) {
    return _fetchedIp
  }

  _fetchedIp = new Promise(async (fRes) => {
    try {
      const res = await fetch(`${CLIENT_SETTINGS.public.gnowbeApiUrl}/api/v1/admin/my_ip`)
      const response = await checkHttpStatus(res)
      const json = await response.json()

      if (json.error) {
        throw new Error(json.error.message)
      }
      if (!json.data || !json.data.ipMasked) {
        throw new Error('No data returned')
      }

      fRes(json.data.ipMasked)
    }
    catch (err) {
      logger.error('Error while fetching my_ip in track.tsx', { error: err })
      _fetchedIp = undefined
      fRes(NO_IP)
    }
  })
  return _fetchedIp
}

export const initSegment = () => {
  try { // Segment
    const writeKey = CLIENT_SETTINGS.public.segmentWriteKey
    const sourceId = CLIENT_SETTINGS.public.segmentSourceId

    // @ts-ignore
    !function segmentLoad(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src=`https://evs.ping.gnowbe.com/38HuB52nfJ/${sourceId}.min.js`;var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=writeKey;analytics._cdn="https://evs.ping.gnowbe.com";analytics.SNIPPET_VERSION="4.13.2";
      if (key) {
        analytics.load(key);
        page()
      }
    }}();
  } catch (error) {
    logger.error('Segment initialization error', error)
  }
}

export const identifyOnSegment = (userId: string, email: string) => {
  // if (/localhost/.test(document.location.host)) {
  //   return
  // }
  if (typeof analytics === 'undefined') {
    return
  }

  getIp().then(ip =>
    analytics.identify(userId,
      {email},
      {context: {ip}},
    ),
  ).catch(err => {}) // Error should not happen
}

export const resetSegment = () => {
  // if (/localhost/.test(document.location.host)) {
  //   return
  // }
  if (typeof analytics === 'undefined') {
    return
  }
  analytics.reset()
}

export const track = ({ event, variables }: { event: string, variables?: object, }) => {
  // if (/localhost/.test(document.location.host)) {
  //   return
  // }
  if (typeof analytics === 'undefined') {
    return
  }

  getIp().then(ip => {
    if (typeof Cypress === 'undefined') {
      analytics.track(
        event,
        variables || {},
        {context: {ip}},
      )
    }
  }).catch(err => {}) // Error should not happen
}

export const page = () => {
  // if (/localhost/.test(document.location.host)) {
  //   return
  // }
  if (typeof analytics === 'undefined') {
    return
  }

  getIp().then(ip =>
    analytics.page({}, {context: {ip}}),
  ).catch(err => {}) // Error should not happen
}
