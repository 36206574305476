import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from './elements/Button'

export default function () {
  const navigate = useNavigate()
  return <div className="flex flex-col mt-4 items-center justify-center">
    <img src="/images/gnome-crying.png" style={{ height: '100px' }} />
    <div className="mt-2">Page not found!</div>
    <div className="mt-4"><Button onClick={() => navigate('/', {replace: true})} text="Go home" /></div>
  </div>
}
