import * as React from 'react'
import IconType from './iconType'

const BannerIcon = (props: IconType) => {

  return (
    <svg
      className={`${props.className}`}
      height={props.h}
      width={props.w}
      viewBox="0 0 18 18"
      >
      <path d="M0 0V18H18V0H0ZM16 16H2V2H16V16ZM8 4H10V6H8V4ZM4 4H6V6H4V4ZM12 4H14V6H12V4Z"
      />
    </svg>
  )
}

export default BannerIcon