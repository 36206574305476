import Tippy from '@tippyjs/react'
import React from 'react'
import { BannerStateType } from './BannerDetails'

type BannerProductCodeProps = {
  state: BannerStateType
  setState: (value: BannerStateType) => void,
}

const BannerProductCode = ({state, setState}: BannerProductCodeProps) => {

  const orgProducts: any = ['All', 'FT', 'CR', 'UL', 'XL', 'VL', 'CL', 'GT', 'OP', 'IN', 'DM']
  const productsTooltip: any = ['Select All', 'Free Tier', 'Creator', 'Enterprise', 'Extend', 'Extend', 'Learn for Teams', 'Learn for Teams', 'Learn Public', 'Internal', 'Demo']

  const handleProductChange = (field: ('FT'| 'CR'| 'UL'| 'XL'| 'VL'| 'CL'| 'GT'| 'OP'| 'IN'| 'DM')) => {
    if (!state.showToProduct.includes(field)) {
      const array = state.showToProduct.concat(field)
      setState({
        ...state,
        showToProduct: array,
      })
    } else {
      const array = state.showToProduct
      const filteredArray = array.filter(p => p !== field)
      setState({
        ...state,
        showToProduct: filteredArray,
      })
    }
  }

  const handleAllChange = () => {
    if (!orgProducts.slice(1, (orgProducts.length + 1)).every(i => state.showToProduct.includes(i))) {
      setState({
        ...state,
        showToProduct: orgProducts.slice(1, (orgProducts.length + 1)),
      })
    } else {
      setState({
        ...state,
        showToProduct: [],
      })
    }
  }

  return (
    <section className="mt-6 rounded border border-[#E3E4E5]">
      <div className="flex text-sm bg-alabastergray text-medgray font-bold border-b rounded-t border-[#E3E4E5]">
        <div className="p-4 sm:w-1/3 md:w-1/2">
          Banner
        </div>
        <div className="w-1/2 flex">
          {orgProducts.map((product, i) => {
            return (
              <Tippy key={i} content={productsTooltip[i]}>
                <div className="border-l border-[#E3E4E5] h-full w-full flex items-center justify-center">
                  {product}
                </div>
              </Tippy>
            )
          })}
        </div>
      </div>
      <div className="flex text-sm text-medgray font-bold">
        <div className="p-4 sm:w-1/3 md:w-1/2">
          <div>
            {state.bannerId}
            <div className="text-sm text-medgray font-normal">
              {state.bannerText}
            </div>
          </div>
        </div>
        <div className="w-1/2 flex">
          {orgProducts.map((product, i) => {
            if (product === 'All') {
              return (
                <div key={i} className="h-full w-full flex items-center justify-center">
                  <input
                    type="checkbox"
                    className="text-lake focus:ring-0 h-5 w-5 rounded-sm form-checkbox"
                    checked={orgProducts.slice(1, (orgProducts.length + 1)).every(p => state.showToProduct.includes(p))}
                    onChange={handleAllChange}
                  />
                </div>
              )
            }
            return (
              <div key={i} className="h-full w-full flex items-center justify-center">
                <input
                type="checkbox"
                className="text-lake focus:ring-0 h-5 w-5 rounded-sm form-checkbox"
                checked={state.showToProduct.includes(product)}
                onChange={() => handleProductChange(product)}
              />
              </div>
            )
          })}
        </div>
      </div>
    </section>

  )
}

export default BannerProductCode
