import { useQuery } from '@apollo/client'
import i18n from 'components/i18n'
import Table2, { TableOptions } from 'components/Table2'
import { formatDate } from 'lib/utils'
import { UserReferral } from 'models/referral'
import { GET_USER_REFERRALS } from 'queries/referral'
import React from 'react'
import spacetime from 'spacetime'

const ReferralsSuccessfulSignups = (props: {organizationId: string}) => {
  const [options, setOptions] = React.useState<TableOptions>({
    filterText: '',
    page: 0,
    rowsPerPage: 10,
  })
  const countRef = React.useRef(0)
  const {loading, data, error} = useQuery<{referrals: UserReferral[]}, any>(
    GET_USER_REFERRALS, {variables: {organizationId: props.organizationId}},
  )

  const referrals = !loading && data ? data.referrals : []
  const referralsCount = !loading && data ? data.referrals.length : countRef.current
  countRef.current = referralsCount

  const referralsPage = referrals.slice(options.page * options.rowsPerPage, (options.page + 1) * options.rowsPerPage)

  const rows = referralsPage.map((referral) => {
    const validUntil = spacetime(referral.invitationDate).add(30, 'day').toNativeDate()
    return {
      id: referral.id,
      name: referral.inviteeEmail,
      valid_until: formatDate(validUntil.getTime()),
      invitee_promocode_discount: referral.inviteePromoCodeDiscount
      ? `${referral.inviteePromoCodeDiscount}%`
      : '-',
      date_of_registration: formatDate(referral.invitationDate),
      date_of_subscription: referral.inviteeSubscriptionStartDate
        ? formatDate(referral.inviteeSubscriptionStartDate)
        : '-',
    }
  })
  return (
    <Table2
      options={options}
      onOptionsChange={setOptions}
      noDataText={i18n.t('referral_empty_table')}
      loading={loading}
      count={countRef.current}
      columns={[
        { accessor: 'name', Header: i18n.t('name'), disableSortBy: true },
        { accessor: 'valid_until', Header: i18n.t('referral_valid_until'), disableSortBy: true, align: 'center' },
        { accessor: 'invitee_promocode_discount', Header: i18n.t('discount_in_percentage'), disableSortBy: true, align: 'center' },
        { accessor: 'date_of_registration', Header: i18n.t('referral_date_of_reg'), disableSortBy: true, align: 'center' },
        { accessor: 'date_of_subscription', Header: i18n.t('referral_date_of_sub'), disableSortBy: true, align: 'center' },
      ]}
      data={rows}
    />
  )
}
export default ReferralsSuccessfulSignups
