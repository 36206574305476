import { gql } from '@apollo/client'
import { getUserReferralFragment, getUserReferralFragmentName } from 'models/referral'

export const GET_USER_REFERRALS = gql`
  ${getUserReferralFragment()}
  query GetUserReferrals($organizationId: String) {
    referrals: getUserReferrals(organizationId: $organizationId) {
      ...${getUserReferralFragmentName()}
    }
  }
`
export type GetMyReferralLinkVars = {organizationId: string}
export type GetMyReferralLinkRes = {referralLink: string}
export const GET_MY_REFERRAL_LINK = gql`
  query GetMyReferralLink($organizationId: String!) {
    referralLink: getMyReferralLink(organizationId: $organizationId)
  }
`
export const GET_MY_REFERRAL_DISCOUNT_CODE_ID = gql`
  query GetMyReferralDiscountCodeId {
    referralDiscountCodeId: getMyReferralDiscountCodeId
  }
`
export type GetMyReferralBannerDataRes = {
  ref: {
    referrerName: string,
    daysLeftToJoin: number,
    discountPercent: number,
    organizationId: string,
    organizationName: string,
  };
}
export const GET_MY_REFERRAL_BANNER_DATA = gql`
  query GetMyReferralBannerData {
    ref: getMyReferralBannerData {
      referrerName
      daysLeftToJoin
      discountPercent
      organizationId
      organizationName
    }
  }
`
