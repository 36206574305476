export enum AssessmentPassedMode {
  aggregatedScore = 'aggregatedScore',
  percOfPassedEvaluations = 'percOfPassedEvaluations',
}

export enum ReviewerRole {
  endorser = 'endorser',
  assessor = 'assessor',
  assessorTiebreaker = 'assessorTiebreaker',
}

export type Reviewer = {
  id: string;
  role: ReviewerRole;
  email: string;
  name?: string|null;
  emailSentAt?: number;
}
export const getReviewerFragmentName = () => {
  return 'ReviewerFields'
}
export const getReviewerFragment = () => {
  return `
    fragment ${getReviewerFragmentName()} on Reviewer {
      id
      role
      email
      name
      emailSentAt
    }
  `
}

export type Review = {
  id: string,
  reviewerId: string,
  role: ReviewerRole,
  score: number,
  feedback: string,
  reviewedAt: number,
}
export const getReviewFragmentName = () => {
  return 'ReviewFields'
}
export const getReviewFragment = () => {
  return `
    fragment ${getReviewFragmentName()} on Review {
      id
      reviewerId
      role
      score
      feedback
      reviewedAt
    }
  `
}
