import { updateMeta } from 'lib/user_metadata'
import { UserAuthenticated } from 'models/user'
import React from 'react'
import { handleApolloError } from 'utils/graphql'
import ErrorCmp from './Error'
import i18n from 'components/i18n'
import { withAuthUser } from './AuthUserWrapper'
import { useQuery } from '@apollo/client'
import { GET_USER_METADATA } from 'queries/user'


export function withDefaultHiddenFields<TProps>(
  Cmp: React.ComponentType<TProps>,
) {
  const CmpRet = (props: TProps&{
    tableId?: string, hiddenFields?: string[], onFieldHide?: (field: string, hide: boolean) => void,
    userAuthenticated?: UserAuthenticated|null,
  }) => {
    function onFieldHide(fieldsToHide: string[]) {
      const tmp = fieldsToHide.join(',')
      updateMeta(
        `settings/table/${props.tableId}/hiddenFields`,
        tmp,
      )
    }
    const hiddenFieldsTmp = props.hiddenFields || []
    if (!props.tableId) return <Cmp {...props} hiddenFields={hiddenFieldsTmp} />

    const {data, loading, error} = useQuery(GET_USER_METADATA, {
      variables: { userId: props.userAuthenticated?.id },
      skip: !props.userAuthenticated || !props.tableId,
    })

    if (error) {
      handleApolloError(error)
      return <ErrorCmp error={error.message} />
    }

    if (loading) {
      return <div className="bg-transparent">{`${i18n.t('loading')}`}</div>
    }

    if (data?.user?.metadata?.general?.settings?.table) {
      const hiddenFields = data?.user?.metadata?.general?.settings?.table?.[props.tableId]?.hiddenFields
      if (!hiddenFields) {
        return <Cmp {...props} hiddenFields={hiddenFieldsTmp} onFieldHide={onFieldHide} />
      }

      return <Cmp {...props} hiddenFields={[...hiddenFieldsTmp, ...hiddenFields.split(',').filter(Boolean)]}
        onFieldHide={onFieldHide} />
    }

    return <Cmp {...props} hiddenFields={hiddenFieldsTmp} onFieldHide={onFieldHide}/>
  }

  return withAuthUser(CmpRet)
}

