declare var console: any

let logSubscriptions = [] as Function[]
let infoSubscriptions = [] as Function[]
let warnSubscriptions = [] as Function[]
let errorSubscriptions = [] as Function[]
let criticalSubscriptions = [] as Function[]

export function log(message: string, ...params: Array<any>) {
  console.log.apply(console, arguments);
  logSubscriptions.forEach(c => c(message, params))
}

export function info(message: string, ...params: Array<any>) {
  console.info.apply(console, arguments);
  infoSubscriptions.forEach(c => c(message, params))
}

export function warn(message: string, ...params: Array<any>) {
  console.warn.apply(console, arguments);
  warnSubscriptions.forEach(c => c(message, params))
}

export function error(message: string, ...params: Array<any>) {
  console.error.apply(console, arguments);
  errorSubscriptions.forEach(c => c(message, params))
}

export function critical(message: string, ...params: Array<any>) {
  console.error.apply(console, arguments);
  criticalSubscriptions.forEach(c => c(message, params))
}

export function subscribe(logLevel: string, callback: (message: string, ...params: Array<any>) => void) {
  // switch(logLevel) {
  //   case 'log':
  //   case 'debug':
  //     logSubscriptions.push(callback)
  //     break
  //   case 'info':
  //     infoSubscriptions.push(callback)
  //     break
  //   case 'warn':
  //     warnSubscriptions.push(callback)
  //     break
  //   case 'error':
  //     errorSubscriptions.push(callback)
  //     break
  //   case 'critical':
  //     criticalSubscriptions.push(callback)
  //     break
  // }
}