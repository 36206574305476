import { gql } from '@apollo/client'

export const LISTEN_TO_AUTH_CHANGES = gql`
subscription ListenToAuthChanges {
  status: listenToAuthChanges {
    ... on UserLoggedOut {
      userId
      tokenId
      reasonMsg
      reasonCode
    }
    ... on UserAuthNoChange {
      userId
    }
  }
}
`
