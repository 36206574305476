import React, { Fragment, useEffect, useRef, useState } from 'react'
import i18n from '../i18n'
import { openAlert, showSnackbar } from '../snackbar'
import { Button } from 'components/elements/Button'
import CheckIcon from '@heroicons/react/24/solid/CheckIcon'
import PlusIcon from '@heroicons/react/24/solid/PlusIcon'
import SelectorIcon from '@heroicons/react/24/solid/ArrowsUpDownIcon'
import { Combobox, Transition } from '@headlessui/react'
import { getGnowbeApiToken, logoutHandler } from 'lib/auth'
import CLIENT_SETTINGS from 'lib/client_settings'

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

type InviteByEmailProps = {
  onInvite: (email: string, callback: (err, msg?, aborted?) => void) => void;
  disabled?: boolean;
  buttonText?: string;
  className?: string;
}

type SelectedValueType = {value: string, label: string}|null

const InviteByEmail = (props: InviteByEmailProps) => {
  const [processing, setProcessing] = useState(false)
  const [selectedValue, setSelectedValue] = useState<SelectedValueType>(null)
  const [users, setUsers] = useState<SelectedValueType[]>([])
  const [searchText, setSearchText] = useState('')
  const _debounce = useRef<any>(null)
  const _lastRndIdForSuggestions = useRef(0)

  useEffect(
    () => {
      if ((searchText?.length || 0) < 3) return

      _debounce.current = clearTimeout(_debounce.current)
      _debounce.current = setTimeout(
        async () => {
          const rndId = Math.random()
          _lastRndIdForSuggestions.current = rndId

          const apiToken = await getGnowbeApiToken()

          const res = await fetch(CLIENT_SETTINGS.public.gnowbeApiUrl + `/api/v1/users/autocomplete?searchText=${encodeURIComponent(searchText)}`, {
            method: 'GET',
            headers: {
              accept: 'application/json',
              'x-gnowbe-source': 'gnowbe-dashboard 1',
              authorization: `${apiToken.tokenType} ${apiToken.token}`,
            } as any,
          })
          if (res.status === 401) {
            logoutHandler()
            return
          }
          const json = await res.json()
          if (json.error) {
            console.error('Error while getting users autocomplete')
            return
          }

          if (rndId !== _lastRndIdForSuggestions.current) return
          setUsers(json.data.users.map((s) => {
            return {
              value: s.email,
              label: `${s.fullName}`,
            }
          }))
        },
        1000,
      )
    },
    [searchText],
  )

  const onInvite = async (emailObj: {value: string, label: string}|null) => {
    const email = (emailObj?.value || searchText).trim()
    if (!email) return

    if (!emailRegex.test(email)) {
      openAlert({title: i18n.t('invalid_email'), description: i18n.t('please_check_for_typos_and_try_again')})
      setSelectedValue(null)
      return
    }
    setProcessing(true)
    // INVITE WITHOUT NOEMAIL PARAMETER
    props.onInvite(email, (err, msg?, aborted?) => {
      if (aborted) {
        setProcessing(false)
        return
      }
      if (err) {
        showSnackbar(`${i18n.t('an_error_happened')} ${i18n.t('try_again')}`, 3000, 'error')
      } else {
        setSelectedValue(null)
      }
      setProcessing(false)
    })
  }

  const handleChange = (e) => {
    if (props.disabled) return
    const value = e.currentTarget.value
    setSearchText(value)
  }

  const handleEnterClick =  (e) => {
    if (props.disabled) return
    if (e.key === 'Enter') {
      e.preventDefault()
      setTimeout(
        () => {
          onInvite({
            value: selectedValue?.value || '',
            label: selectedValue?.label || '',
          })
        },
        1500,
      )
    }
  }

  return (
    <div className="invite-form md:flex text-left w-full">
      <div className="md:flex sm:col-span-4">
        <Combobox value={selectedValue} onChange={(selected) => {
          setSelectedValue(selected)
        }}>
          <div className="relative mb-5 w-[150px] sm:w-[250px] md:mb-0 md:w-[400px]">
            <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                placeholder={`${i18n.t('invite_by_email')}...`}
                className="w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                displayValue={(user: any) => user?.value || ''}
                onChange={handleChange}
                onKeyDown={handleEnterClick}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Combobox.Options className="absolute z-modal mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {false
                  ? <div className="relative hidden md:block cursor-default select-none py-2 px-4 text-gray-700">
                      Loading...
                    </div>
                  : users.length === 0 && selectedValue?.value !== ''
                    ? <div className="relative hidden md:block cursor-default select-none py-2 px-4 text-gray-700">
                        Nothing found.
                      </div>
                    : users.map(user => (
                      <Combobox.Option
                        key={user!.value}
                        className={({ active }) =>
                          `relative cursor-default select-none py-1.5 pl-10 pr-text-gray-900 v ${
                            active && 'bg-gray-200'
                          }`
                        }
                        value={user}>
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-bold' : 'font-normal'
                              }`}>
                              {user!.label}<br />
                              <span className="text-lightgray italic">{user!.value}</span>
                            </span>

                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active && 'bg-gray-200'
                                }`}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                }
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
        <div className={`flex flex-row items-end sm:ml-0 md:ml-3 submit-button ${props.className}`} >
          <Button
            text={props.buttonText || i18n.t('invite')}
            type="secondary"
            onClick={e => void e.preventDefault() || onInvite({
              value: selectedValue?.value || '',
              label: selectedValue?.label || '',
            })}
            disabled={props.disabled || processing}
            icon={<PlusIcon />}
          />
        </div>
      </div>
    </div >
  )
}

export default InviteByEmail
