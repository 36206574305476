// UserContext so we can consume context inside components instead of having to call GET_AUTHUSER all the time.
import { Organization, OrganizationSsoSettings } from 'models/organization'
import { GetOrganizationLandingPageSettingsContext, GetOrganizationLandingPageSettingsRes } from 'queries/organization'
import React from 'react'

// User context
export type UserContextType = {
  settings: {
    onlyVisibleInAnalytics: boolean
    onChangeVisibleInAnalytics: (visible: boolean) => void,
  },
}
export const UserContext = React.createContext({} as UserContextType)
export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer

// CourseCategories Context
export type CourseCategoriesContextType = {
  categories: {
    id: string,
    title: string,
    order: number,
    description: string,
  }[],
  categoryTitleById: (id: string) => string,
}
export const CourseCategoriesContext = React.createContext({} as CourseCategoriesContextType)
export const CourseCategoriesProvider = CourseCategoriesContext.Provider
export const CourseCategoriesConsumer = CourseCategoriesContext.Consumer

// Organization & PollingInterval
export type OrganizationContextType = {
  organization: Organization | null,
  organizationLodaing: boolean,
  organizationSsoSettings: OrganizationSsoSettings | null,
  organizationLandingPageSettings: GetOrganizationLandingPageSettingsContext | null,
}
export const OrganizationContext = React.createContext({} as OrganizationContextType)
export const OrganizationProvider = OrganizationContext.Provider
export const OrganizationConsumer = OrganizationContext.Consumer
