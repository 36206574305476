import React from 'react'
import clsx from 'clsx'
import HelpTooltip from './HelpTooltip'

export type KeyDownType = 'enter' | 'esc'
type PropsIn = {
  value?: string,
  label?: string,
  placeholder?: string,
  disabled?: boolean,
  isClearable?: boolean,
  onChange?: (value: string) => void,
  error?: string,
  helpTooltip?: string,
  rows?: number,
  readOnly?: boolean,
  isBold?: boolean,
}
export default function (props: PropsIn) {
  const handleTextChange = (e: any) => {
    props.onChange && props.onChange(e.target.value)
  }
  return (
    <>
      {props.label && (
        <div className={`text-sm w-full text-left text-medgray ${props.isBold ? 'font-bold': ''}`}>
          {props.label} {props.helpTooltip && <HelpTooltip text={props.helpTooltip} />}
        </div>
      )}
      <textarea
        readOnly={props.readOnly}
        disabled={props.disabled}
        placeholder={props.placeholder || 'type here...'}
        onChange={handleTextChange}
        value={props.value}
        rows={props.rows || 1}
        className={`${clsx([{'border-coral': props.error}])} px-4 py-2 w-full rounded-lg border border-solid border-gray-200 focus:ring-0 focus:ring-transparent focus:outline-none focus:shadow-outline text-medgray`}
      />
      {props.error && <div style={{minHeight: 20}} className="w-full text-left text-sm text-coral">{props.error || ''}
      </div>}
    </>
  )
}