export type FileImportType = 'org-extusers-csv'|'cmp-users-csv'
export type FileImportStatusType = 'queued'|'inprogress'|'done'
export type FileImport = {
  companyId?: string,
  createdAt: number,
  errors?: string[],
  fileName: string,
  finishedAt?: number,
  id: string,
  organizationId: string,
  processedCount?: number, // How many were successfully processed!
  progress: number,
  status: FileImportStatusType, // 'queued'|'inprogress'|'done'
  type: FileImportType, // 'org-extusers-csv'|'cmp-users-csv'
  updatedAt: number,
  subType: string,
}
export const getFileImportFragmentName = () => {
  return 'FileImportFields'
}
export const getFileImportFragment = () => `
  fragment ${getFileImportFragmentName()} on FileImport {
    id
    companyId
    createdAt
    errors
    fileName
    finishedAt
    organizationId
    processedCount
    progress
    status
    type
    updatedAt
    subType
  }
`
