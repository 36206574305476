import React from 'react'
import { useAuthUser, withAuthUser } from './AuthUserWrapper'
import i18n from './i18n'
import { updateMeta } from '../lib/user_metadata'
import { UserAuthenticated } from '../models/user'
import { handleApolloError } from '../utils/graphql'
import ErrorCmp from './Error'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client'
import { GET_USER_METADATA } from 'queries/user'


type AuthUserProps = {
  userAuthenticated?: UserAuthenticated|null,
}

export function updateMetaRowsPerPage(tableId: string, num: number) {
  updateMeta(
    `settings/table/${tableId}/rowsPerPage`,
    String(num),
  )
}

export type DefaultRowsPerPageDataRes = {rowsPerPage?:number}

export function withDefaultRowsPage<TProps>(
  Cmp: React.ComponentType<TProps>,
  opts?: {
    tableId: string,
    ErrorCmpC?: React.JSXElementConstructor<{error: string}>,
    LoadingCmpC?: React.JSXElementConstructor<any>,
  },
) {
  const CmpRet: React.FunctionComponent<TPropsOut> = (props: TProps&AuthUserProps) => {

    const {data, loading, error} = useQuery(GET_USER_METADATA, {
      variables: { userId: props?.userAuthenticated?.id || '' },
      fetchPolicy: 'cache-first',
      skip: !opts?.tableId || !props.userAuthenticated,
    })

    if (!opts?.tableId) return <Cmp {...props} rowsPerPage={undefined} />

    if (!props.userAuthenticated) {
      if (opts && opts.LoadingCmpC) return <opts.LoadingCmpC />
      return <div className="bg-transparent">{i18n.t('loading')}</div>
    }

    if (error) {
      handleApolloError(error)
      if (opts && opts.ErrorCmpC) return <opts.ErrorCmpC error={error.message} />
      return <ErrorCmp error={error.message} />
    }

    if (loading) {
      if (opts && opts.LoadingCmpC) return <opts.LoadingCmpC />
      return <div className="bg-transparent">{i18n.t('loading')}</div>
    }

    if (data?.user?.metadata?.general?.settings?.table) {
      const rowsPerPage = Number(data.user.metadata.general.settings?.table[opts.tableId]?.rowsPerPage)
      if (!rowsPerPage) {
        return <Cmp {...props} rowsPerPage={undefined} />
      }
      return <Cmp {...props} rowsPerPage={rowsPerPage} />
    }
    return <Cmp {...props} rowsPerPage={undefined}/>
  }

  type TPropsOut = Omit<TProps, 'rowsPerPage'>
  return withAuthUser(CmpRet)
}


type DefaultTableOptionsResType = {
  loading: boolean|null,
  error: ApolloError|null,
  data: DefaultRowsPerPageDataRes|null,
}

export function useDefaultTableOptions(tableId: string): DefaultTableOptionsResType {
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState(null)
  const [error, setError] = React.useState(null)

  if (!tableId) return {loading, error, data}
  const {data: userAuthData, loading: userAuthLoading, error: userAuthError} = useAuthUser()
  const [getMetadata, {data: metaData, loading: metaLoading, error: metaError}] = useLazyQuery(GET_USER_METADATA)

  const onChangeRowsPerPage = (num: number) => {
    updateMeta(
      `settings/table/${tableId}/rowsPerPage`,
      String(num),
    )
  }

  React.useEffect(
    () => {
      if (!!userAuthLoading || !! userAuthError) {
        setLoading(userAuthLoading)
        setError(userAuthError)
        setData(null)
      }
      if (userAuthData) {
        getMetadata({
          variables: { userId: userAuthData.userAuthenticated.id },
        })
      }
    },
    [userAuthData, userAuthLoading, userAuthError],
  )
  React.useEffect(
    () => {
      if (metaLoading || metaError) {
        setLoading(metaLoading)
        setError(metaError)
        setData(null)
      }
      if (metaData && !metaLoading && !metaError) {
        setLoading(false)
        setError(null)
        if (metaData?.user?.metadata?.general?.settings?.table) {
          const rowsPerPage = Number(metaData.user.metadata.general.settings?.table[tableId]?.rowsPerPage)
          if (!rowsPerPage) {
            setData({
              rowsPerPage: 10,
              onChangeRowsPerPage,
            })
          } else {
            setData({
              rowsPerPage,
              onChangeRowsPerPage,
            })
          }
        }
      }
    },
    [metaData, metaLoading, metaError],
  )
  return {loading, error, data}
}
