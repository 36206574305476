import { Popover, Transition } from '@headlessui/react'
import {Popover as PopoverReactTiny, PopoverAlign} from 'react-tiny-popover'
import React from 'react'
import { ButtonProps } from './elements/Button'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'


type PropsIn = {
  content: React.ReactNode|React.ReactElement,
  badge?: string|'dot',
  id?: string,
} & Omit<ButtonProps, 'onClick'>
const PopoverOwn = (props: PropsIn) => {
  const cls = classNames({
    disabled: props.disabled,
    'btn inline-flex items-center justify-center': true,
    'block w-full': props.fullWidth,
    [props.type && props.type || 'default']: true,
    [props.size && `btn-${props.size}` || 'btn-medium']: true,
    [props.className || '']: props.className,
  })
  const clsBtnTooltip = classNames({
    visible: !!props.icon && !props.disabled && !props.loading,
    'xl:hidden': true,
  })

  return (
    <Popover className="relative h-full">
      {({ open }) => (
        <>
          <Tippy className={clsBtnTooltip} content={<span>{props.text}</span>}>
            <Popover.Button
              id={props.id || ''}
              className={`
                ${open ? '' : 'text-opacity-90'}
                ${cls}`}
            >
                {props.icon && React.cloneElement(props.icon, {className: 'w-5 h-5 xl:mr-2'})}
              <span className={`${props.icon && 'hidden xl:inline-block'}`}>{props.text}</span>
              {props.rightIcon && React.cloneElement(props.rightIcon, {className: 'w-5 h-5 ml-2'})}
              {props.badge && Number(props.badge) > 0 && props.badge !== 'dot' ? (
                <span className="absolute top-0 -mt-1 -mr-1 right-0 px-2 py-1 text-xs font-bold leading-none text-medgray transform bg-lake rounded-full">
                  {props.badge}
                </span>
              ) : props.badge && props.badge === 'dot' ? (
                <span className="absolute top-0 -mt-1 -mr-1 right-0 text-xs font-bold leading-none text-medgray transform bg-lake rounded-full w-3 h-3" />
              ) : undefined}
            </Popover.Button>
          </Tippy>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 px-4 mt-3 right-0 sm:px-0 lg:max-w-3xl">
              <div className="bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                {props.content}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default PopoverOwn


export const PopoverTiny = (props: {content: React.ReactNode | React.ReactElement<any, string | React.JSXElementConstructor<any>>, align?: PopoverAlign, divClassName?: string, hide?: boolean; hideThePopoverInTheParent?: (change: boolean) => void; }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return <PopoverReactTiny
  containerClassName="z-modal pr-5 pt-3"
  isOpen={isOpen}
  onClickOutside={() => setIsOpen(false)}
  positions={['right', 'bottom', 'top']}
  align={props.align || 'start'}
  content={() => (
    <Transition
      show={isOpen}
      appear={true}
      enter="transition ease-out duration-100"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95">
      <div className={props.divClassName || 'z-10 w-40 origin-top-right bg-white rounded-md shadow-lg outline-none'}
        onClick={() => {
          setTimeout(
            () => {
              setIsOpen(false)
            },
            50)
        }}>
        {props.content}
      </div>
    </Transition>
  )}>
    <button
      className="focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-yellow-400 rounded-full -mr-1"
      aria-label="Action options"
      onClick={() => setIsOpen(!isOpen)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#101010">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
      </svg>
    </button>
  </PopoverReactTiny>
}
