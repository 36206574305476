import React from 'react'
import { CompanyBasic, CompanyOrganizationSettings, CompanyOrganizationTags, CompanySettings, getPublicFacingCompanyName } from '../../models/company'
import { exportToCsv } from '../../utils/csv'
import ErrorCmp from '../Error'
import { getRealFilterText, isSelectionChanged, ToolbarCommands } from '../TableToolbar'
import i18n from '../i18n'
import GetCompaniesToDistribute from './CourseDistributeTo'
import Typography from '@material-ui/core/Typography'
import RightDrawer, { RightDrawerOptions } from '../RightDrawer'
import { CourseBasic, CourseType } from 'models/course'
import HelpTooltip from 'components/HelpTooltip'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { formatDate } from 'lib/utils'
import { useQuery } from '@apollo/client'
import { GetCompaniesRes, GetCompaniesVars, GET_COMPANIES } from 'queries/company'
import { isAgent, isManager } from 'lib/acl_logic'
import { withAuthUser } from 'components/AuthUserWrapper'
import { UserAuthenticated } from 'models/user'
import clsx from 'clsx'
import { equals } from 'ramda'
import Table2, { TableOptions } from 'components/Table2'


type CompaniesTablePropsIn = {
  onlyHavingCourse?: CourseBasic&CourseType;
  onlyAgentCompanies?: string;
  onlyEditorCompanies?: string;
  onlyIds?: string[];
  skipCompanyTypes?: string[];
  initialOptions?: TableOptions;
  selected?: {
    onChange: (companies: (CompanyBasic&CompanySettings&CompanyOrganizationSettings&CompanyOrganizationTags)[]) => void;
    selectedIds: Set<string>;
    multiSelectable?: boolean;
  };
  onOptionsChange?: (o: TableOptions) => void;
  // tslint:disable-next-line: max-line-length
  getCommands?: (company: CompanyBasic&CompanySettings&CompanyOrganizationSettings&CompanyOrganizationTags) => React.ReactNode;
  showOrganizationName?: boolean;
  showPublicFacingCompanyName?: boolean;
  hideIterator?: boolean;
  toolbarCommands?: () => React.ReactNode;
  userAuthenticated?: UserAuthenticated;
  disableGotoCompany?: boolean,
}
type CompaniesTableProps = CompaniesTablePropsIn & {
  loading: boolean;
  options: TableOptions;
  companies: (CompanyBasic&CompanySettings&CompanyOrganizationSettings&CompanyOrganizationTags)[];
  companiesCount: number;
  onOptionsChange: (o: TableOptions) => void;
}

type CompaniesTableState = {
  awaitNewProps: boolean,
}

// has the table, gets data from OrganizationTableWrapperContainer
class CompaniesTable extends React.Component<CompaniesTableProps, CompaniesTableState> {
  private _interval = null
  private rightDrawer: React.RefObject<RightDrawerOptions>

  constructor(props: CompaniesTableProps) {
    super(props)
    this.state = {
      awaitNewProps: false,
    }
    this.rightDrawer = React.createRef()
  }

  componentWillReceiveProps(newProps: CompaniesTableProps) {
    // If we get refreshed results report refreshed selected item
    const selected = this.props.selected
    if (selected && !newProps.loading) {
      const itemsBefore = this.props.companies
      const itemsNow = newProps.companies
      const selectedCrs = isSelectionChanged(o => o.id, itemsBefore, itemsNow, Array.from(selected.selectedIds))
      if (selectedCrs) {
        selected.onChange(selectedCrs)
      }
    }
    const newCompaniesIds = newProps.companies.map(el => el.id).sort()
    const oldCompaniesIds = this.props.companies.map(el => el.id).sort()
    if (!equals(newCompaniesIds, oldCompaniesIds)) {
      clearInterval(this._interval)
      this._interval = null
      this.setState({
        awaitNewProps: false,
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this._interval)
    this._interval = null
  }

  exportToCsvWrapper = () => {
    exportToCsv(
      [['Id', 'Organization Name', 'Group Name']]
        .concat(this.props.companies.map(c => [c.id, c.organizationName, c.name])),
      'companies.csv',
    )
  }

  onSaveDistribution = () => {
    this.rightDrawer.current?.toggleDrawer(false)
    this.rightDrawer.current?.setContent(null)
    this.setState({awaitNewProps: true}, () => {
      let counter = 0
      this._interval = setInterval(() => {
        this.props.onOptionsChange(this.props.options)
        counter += 1
        if (counter === 5) {
          clearInterval(this._interval)
          this._interval = null
          this.setState({awaitNewProps: false})
        }
      },                           7000)
    })
  }

  showCompaniesDistributeTo = (e: React.SyntheticEvent<any>) => {
    e.preventDefault()
    this.rightDrawer.current?.toggleDrawer(true)
    this.rightDrawer.current?.setContent(<div className="m-4">
      <GetCompaniesToDistribute
        course={this.props.onlyHavingCourse}
        disableDitributionToAll={true}
        onSaveDistribution={() => {
          this.onSaveDistribution()
        }}
      />
    </div>)
  }

  render() {
    const {
      selected,
      options,
      companies,
      companiesCount,
      onOptionsChange,
      getCommands,
      showOrganizationName,
      showPublicFacingCompanyName,
    } = this.props

    const toolbarCommands = [{
      componentType: 'button',
      buttonText: `${i18n.t('add')} / ${i18n.t('remove')}`,
      onClick: this.showCompaniesDistributeTo,
      disabled: this.state.awaitNewProps,
      type: 'secondary',
      icon: <AddRoundedIcon />,
      hidden: !this.props.onlyHavingCourse,
    }, {
      componentType: 'component',
      component: this.props.toolbarCommands ? this.props.toolbarCommands() : <div></div>,
      hidden: !this.props.toolbarCommands,
    }] as ToolbarCommands
    return <div>
      <Table2
        loading={this.props.loading}
        toolbar={{
          placeholder: i18n.t('filter'),
          onRefresh: () => onOptionsChange(options),
          getCommandsLeft: () => {
            if (!(this.props.onlyHavingCourse && this.state.awaitNewProps)) return
            return <Typography
              color="secondary"
              variant="h6"
              style={{fontSize: '.95em'}}
            >
              {i18n.t('alert_changes_visible_soon_wait')}
            </Typography>
          },
          getCommands: toolbarCommands,
          exportData: {
            onExportFiltered: this.exportToCsvWrapper,
          },
        }}
        options={options}
        onOptionsChange={onOptionsChange}
        columns={[
          {accessor: 'name', Header: i18n.t('name'), disableSortBy: true, align: 'start', sticky: 'left', width: 300},
          {accessor: 'orgName', Header: i18n.t('org_name'), disableSortBy: true, align: 'start'},
          {accessor: 'libraryGroup', Header: i18n.t('library_group'), disableSortBy: true, align: 'start'},
          {accessor: 'absoluteDeadline', Header: <span className="flex-nowrap whitespace-nowrap">
            {i18n.t('availableUntil')}&nbsp;<HelpTooltip text={i18n.t('available_until_tooltip')} />
          </span>, disableSortBy: true, align: 'center'},
          {accessor: 'relativeDeadline', Header: <span className="flex-nowrap whitespace-nowrap">
            {i18n.t('availableFor')}&nbsp;<HelpTooltip text={i18n.t('available_for_tooltip')} />
          </span>, disableSortBy: true, align: 'center'},
          {accessor: 'dueDate', Header: <span className="flex-nowrap whitespace-nowrap">
            {i18n.t('dueDate')}&nbsp;<HelpTooltip text={i18n.t('due_date_tooltip')} />
        </span>, disableSortBy: true, align: 'center'},
          ...(this.props.onlyHavingCourse?.manualReviews ? [{
            accessor: 'skipManualEndorsement',
            Header: i18n.t('skip_manual_endorsement'), disableSortBy: true, align: 'center',
          }] : []),
          ...(this.props.onlyHavingCourse?.hasAssessments ? [{
            accessor: 'showHints',
            Header: <div>
              {i18n.t('show_hints')} <HelpTooltip text={i18n.t('show_hints_after_assessments_completed')} />
            </div>, disableSortBy: true, align: 'center',
          }] : []),
          ...(this.props.onlyHavingCourse?.hasAssessments ? [{
            accessor: 'hideAssessments',
            Header: <div>
              {i18n.t('hide_assessment_plural')} <HelpTooltip text={i18n.t('hide_assessments_after_completed')} />
            </div>, disableSortBy: true, align: 'center',
          }] : []),
          ...(getCommands ? [{
            accessor: 'companyActions',
            Header: i18n.t('actions'), disableSortBy: true,
            align: 'center', width: 200, sticky: 'right',
          }] : []),
        ]}
        data={companies.map((c, i) => {
          const subsDistribParamsByCourse = !this.props.onlyHavingCourse ? null : c.subsDistributionParams
            .find(course => course.courseId === this.props.onlyHavingCourse!.id)
          const isManagerOrAgent = isManager(this.props.userAuthenticated, c.organizationId)
            || isAgent(this.props.userAuthenticated, c.id)
          return {
            id: c.id,
            name: <a className={`${clsx([{
              'cursor-pointer text-medgray hover:text-red-500': !this.props.disableGotoCompany,
              'cursor-not-allowed text-lightgray': this.props.disableGotoCompany,
            }])}`}
              onClick={() => {
                if (!this.props.disableGotoCompany) {
                  window.location.assign(`/organization/${c.organizationId}/company/${c.id}`)
                }
              }}
            >
              {showPublicFacingCompanyName ? getPublicFacingCompanyName(c) : c.name}
            </a>,
            orgName: showOrganizationName
              ? c.organizationName || ''
              : undefined,
            libraryGroup: c.groupName || '',
            absoluteDeadline: subsDistribParamsByCourse?.absoluteDeadline
              ? formatDate(subsDistribParamsByCourse.absoluteDeadline)
              : '-',
            relativeDeadline: subsDistribParamsByCourse?.relativeDeadline || '-',
            dueDate: subsDistribParamsByCourse?.dueDate
              ? formatDate(subsDistribParamsByCourse.dueDate)
              : '-',
            ...(this.props.onlyHavingCourse?.manualReviews ? {skipManualEndorsement: <div>
              {c.subsDistributionParams
                .find(course => course.courseId === this.props.onlyHavingCourse!.id)?.manualReviewsSkipEndorsment
                  ? <span className="text-green-800">{i18n.t('yes')}</span>
                  : <span className="text-medgray">{i18n.t('no')}</span>
              }
            </div>} : {}),
            ...(this.props?.onlyHavingCourse?.hasAssessments ? {showHints: <div>
              {c.subsDistributionParams
                .find(course => course.courseId === this.props.onlyHavingCourse!.id)
                ?.showHintsAfterAssessmentsCompleted
                  ? <span className="text-green-800">{i18n.t('yes')}</span>
                  : <span className="text-medgray">{i18n.t('no')}</span>
              }
            </div>} : {}),
            ...(this.props?.onlyHavingCourse?.hasAssessments ? {hideAssessments: <div>
              {c.subsDistributionParams
                .find(course => course.courseId === this.props.onlyHavingCourse!.id)?.hideAssessmentsAfterCompleted
                  ? <span className="text-green-800">{i18n.t('yes')}</span>
                  : <span className="text-medgray">{i18n.t('no')}</span>
              }
            </div>} : {}),
            ...(getCommands ? {
              companyActions: getCommands(c),
            } : {}),
          }
        })}
        select={ selected ? {
          selected: Array.from(selected.selectedIds),
          multiSelectable: selected.multiSelectable,
          onSelect: (ids, what, checked) => {
            selected.onChange(companies.filter(c => ids.includes(c.id)))
          },
        } : undefined}
        count={companiesCount}
      />
      <RightDrawer ref={this.rightDrawer} />
    </div>
  }
}


const CompaniesWrapper = (p: CompaniesTablePropsIn) => {
  const [options, setOptions] = React.useState({
    filterText:  p.initialOptions && p.initialOptions.filterText || '',
    page: p.initialOptions && p.initialOptions.page || 0,
    rowsPerPage: p.initialOptions && p.initialOptions.rowsPerPage || 10,
  })
  const {loading, error, data, refetch, variables} = useQuery<GetCompaniesRes, GetCompaniesVars>(GET_COMPANIES(['with_organization_settings', 'with_settings', 'with_organization_tags']), {
    variables: {
      searchText: getRealFilterText(p.initialOptions && p.initialOptions.filterText || ''),
      skip: (p.initialOptions && p.initialOptions.page || 0)
        * (p.initialOptions && p.initialOptions.rowsPerPage || 10),
      limit: p.initialOptions && p.initialOptions.rowsPerPage || 10,
      onlyHavingCourse: p.onlyHavingCourse?.id,
      onlyAgentCompanies: p.onlyAgentCompanies,
      onlyEditorCompanies: p.onlyEditorCompanies,
      onlyIds: p.onlyIds,
      skipCompanyTypes: p.skipCompanyTypes,
    },
  })
  if (error) {
    return <ErrorCmp error={error} />
  }
  const countRef = React.useRef(0)
  const companiesCount = !loading && !!data ? data.companiesRes.companiesCount : countRef.current
  countRef.current = companiesCount
  return <CompaniesTable
    {...p}
    options={options}
    onOptionsChange={(o) => {
      setOptions(o)
      p.onOptionsChange && p.onOptionsChange(o)
      refetch({
        ...variables,
        searchText: getRealFilterText(o.filterText),
        skip: o.page * o.rowsPerPage,
        limit: o.rowsPerPage,
      })
    }}
    loading={loading}
    companies={data?.companiesRes.companies || []}
    companiesCount={companiesCount}
  />
}

export default withAuthUser(CompaniesWrapper)
