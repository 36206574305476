import { gql } from '@apollo/client'
import { getUserReferralFragment, getUserReferralFragmentName } from 'models/referral'

export const ADD_USER_REFERRAL = gql`
  ${getUserReferralFragment()}
  mutation AddUserReferral(
    $organizationId: String!,
    $inviteeEmail: String!
  ) {
    userReferral: addUserReferral(
      organizationId: $organizationId,
      inviteeEmail: $inviteeEmail,
    ) {
      ...${getUserReferralFragmentName()}
    }
  }
`
