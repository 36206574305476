import { PopoverTiny } from 'components/Popover'
import NoTravelIcon from 'components/utils/Icons/NoTravelIcon'
import React from 'react'
import clsx from 'clsx'
import EditPenIcon from 'components/utils/Icons/EditPenIcon'
import CloudArrowDownIcon from 'components/utils/Icons/CloudArrowDownIcon'
import TrashIcon from 'components/utils/Icons/TrashIcon'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { DISABLE_OR_ENABLE_BANNER } from 'mutations/banners'
import { showSnackbar } from 'components/snackbar'
import i18n from 'components/i18n'
import { omit } from 'ramda'
import { OrganizationProductType } from 'models/organization'
import { Banner } from 'models/banner'
import { formatDate } from 'lib/utils'


const ActiveBanners = ({bannerData, bannerLoading, isOdd, deleteBanner, setState, setIsDate, setCta, updateBannerEnabled}) => {

  const [updateDisable, updateDisableRes] = useMutation(DISABLE_OR_ENABLE_BANNER, {
    onCompleted: () => showSnackbar(i18n.t('success'), 3000, 'success'),
    onError: () => showSnackbar(i18n.t('error'), 3000, 'error'),
  })
  const orgProducts: OrganizationProductType[] = ['FT', 'CR', 'UL', 'XL', 'VL', 'CL', 'GT', 'OP', 'IN', 'DM']
  const btnCn = clsx({
    'w-full px-4 py-2 cursor-pointer hover:bg-alabastergray flex flex-row gap-3 items-center justify-start text-deepgray text-md whitespace-nowrap': true,
  })
  const navigate = useNavigate()

  return (
    <section className="mt-6 rounded border border-[#E3E4E5]">
      <div className="flex text-sm bg-alabastergray text-medgray font-bold border-b rounded-t border-[#E3E4E5]">
        <div className="p-4 w-2/12 border-r-2 border-[#E3E4E5]">
          Date created
        </div>
        <div className="p-4 w-2/12 flex border-r-2 border-[#E3E4E5]">
          Employee
        </div>
        <div className="p-4 w-4/12 flex border-r-2 border-[#E3E4E5]">
          Banner ID
        </div>
        <div className="p-4 w-2/12 flex border-r-2 border-[#E3E4E5]">
          Expiry date
        </div>
        <div className="p-4 w-2/12 flex border-r-2 border-[#E3E4E5]">
          Tiers
        </div>
        <div className="p-4 w-2/12 flex border-r-2 border-[#E3E4E5]">
          Platform(s)
        </div>
        <div className="p-4 w-1/12 flex just">
          Actions
        </div>
      </div>
      {bannerLoading && <div>Loading your banners</div>}
      {bannerData && bannerData.banners.filter(b => b.enabled).map((banner: Banner, i) => {
        const date = new Date(banner.expirationTime)
        return (
          <div key={i} className={`flex text-sm ${isOdd(i) ? 'bg-white' : 'bg-alabastergray' } text-medgray font-bold`}>
            <div className="p-4 flex items-center justify-start w-2/12">
              {formatDate(banner.createdAt)}
            </div>
            <div className="p-4 flex items-center justify-start w-2/12">
              {banner.creatorName}
            </div>
            <div className="px-4 flex flex-col items-start justify-center py-2 w-4/12">
              <div className="flex">
                {banner.bannerId}
              </div>
              <div className="text-sm text-medgray font-normal flex">
                {banner.bannerText}
              </div>
            </div>
            <div className="p-4 w-2/12 flex items-center justify-start">
              {(banner.expirationTime < 2543817720000) ? date.toLocaleDateString('en-US') : '-'}  {/* if expiration date is before 2050 */}
            </div>
            <div className="p-4 w-2/12">
              {orgProducts.every(p => banner.showToProduct.includes(p))
                ? <div className="bg-[#E3E4E5] rounded uppercase text-deepgray px-2 py-0.5 inline-block">
                    All
                  </div>
                : banner.showToProduct.map((product, i) => {
                  return (
                    <div key={i * 4221} className="bg-[#DFF0FF] rounded uppercase text-deepgray px-2 mr-0.5 mt-0.5 inline-block">
                      {product}
                    </div>
                  )
                })}
            </div>
            <div className="p-4 w-2/12 text-center space-y-1">
              {banner.showToPlatform.slice().sort().map(p => <div key={`${banner.bannerId}-active-${p}`} className={`${p === 'dashboard'
                ? 'bg-lake-light'
                : p === 'web'
                  ? 'bg-team' : ''} rounded-lg shadow-sm p-1/2 max-w-[100px]`}>
                {p}
              </div>)}
            </div>
            <div className="p-4 w-1/12 flex items-center justify-center">
              <PopoverTiny  divClassName="z-10 w-52 origin-top-right bg-white rounded-md shadow-lg outline-none" align="center" content={
                <div>
                  <ul className="w-full rounded">
                    <li className="rounded-t">
                      <button className={btnCn}
                        onClick={
                          () => {
                            updateDisable({
                              variables: {
                                bannerId: banner.bannerId,
                                bannerData: {
                                  enabled: false,
                                },
                              },
                            }).then((res) => {
                              if (!res || (res.errors?.length || 0) > 0) return
                              updateBannerEnabled(banner.bannerId, false)
                            })
                          }
                        }>
                        <>
                          <NoTravelIcon className="fill-medgray w-5 h-5" h={20} w={20} />
                          De-activate banner
                        </>
                      </button>
                    </li>
                    <li>
                      <button
                        className={btnCn}
                        onClick={
                          () => {
                            if (banner.ctaButton) {
                              setCta(true)
                            }
                            if (!banner.ctaButton?.url) {
                              setCta(false)
                            }
                            if (banner.expirationTime < 2543817720000) {
                              setIsDate(true)
                            }
                            if (banner.expirationTime > 2543817720000) {
                              setIsDate(false)
                            }
                            navigate('#edit')
                            setState({
                              bannerId: banner.bannerId,
                              type: banner.type,
                              bannerText: banner.bannerText,
                              bannerColor: banner.bannerColor,
                              ctaButton: !banner.ctaButton?.url ? null : omit(['__typename'], banner.ctaButton),
                              showToPlatform: banner.showToPlatform,
                              showToProduct: banner.showToProduct,
                              enabled: banner.enabled,
                              expirationTime: (banner.expirationTime < 2543817720000) && banner.expirationTime,
                              minRangeCopy: banner.minRangeCopy,
                              maxRangeCopy: banner.maxRangeCopy,
                              dismissForDays: banner.dismissForDays,
                            })
                          }
                        }
                        >
                        <>
                          <EditPenIcon className="fill-medgray w-5 h-5" h={20} w={20} />
                          Edit banner
                        </>
                      </button>
                    </li>
                    {/* <li>
                      <button className={btnCn}>
                        <>
                          <CloudArrowDownIcon className="fill-medgray w-5 h-5" h={20} w={20} />
                          Export analytics
                        </>
                      </button>
                    </li> */}
                    <li>
                      <button
                        className="w-full px-4 py-2 cursor-pointer hover:bg-alabastergray flex flex-row gap-3 items-center justify-start text-coral text-md whitespace-nowrap rounded-b"
                        onClick={() => deleteBanner(banner.bannerId)}
                        >
                        <>
                          <TrashIcon className="fill-coral w-5 h-5" h={20} w={20} />
                          Delete
                        </>
                      </button>
                    </li>
                  </ul>
                </div>
              }/>
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default ActiveBanners
