import { useQuery } from '@apollo/client'
import i18n from 'components/i18n'
import { GetMyReferralLinkRes, GetMyReferralLinkVars, GET_MY_REFERRAL_LINK } from 'queries/referral'
import React from 'react'

const DEFAULT_SIGNUP_LINK = i18n.t('not_found')

const ReferralLinkSignup = (props: {organizationId: string}) => {
  const [signupLink, setSignupLink] = React.useState(DEFAULT_SIGNUP_LINK)
  const {data, loading, error} = useQuery<GetMyReferralLinkRes, GetMyReferralLinkVars>(
    GET_MY_REFERRAL_LINK,
    {variables: {organizationId: props.organizationId}})

  React.useEffect(
    () => {
      if (data && !loading && !error) {
        setSignupLink(data.referralLink)
      }
      if (loading) {
        setSignupLink(i18n.t('loading'))
      }
      if (error) {
        setSignupLink(i18n.t('something_went_wrong'))
      }
    },
    [data, loading, error],
  )
  return <a id="signup-referral-url" href={signupLink} target="_blank">{signupLink}</a>
}
export default ReferralLinkSignup
