import { getRewardFragment, getRewardFragmentName, Reward } from './reward'
import { getStudyFragment, getStudyFragmentName, getStudyReviewFragment, getStudyReviewFragmentName, Study, StudyReview } from './study'
// import { getTimestampsFragment, getTimestampsFragmentName } from './timestamps'

export type Action = {
  id: string,
  chapterId: string,
  courseId: string,
  order: number,
  contentType: string,
  userAction: string,
  title: string,
  description?: string,
  placeholderAnswer?: string,
  imageUrl?: string,
  contentUrl?: string,
  contentUrlOrg?: string,
  contentUrlOrgEnabled?: boolean,
  contentUrl_720p?: string,
  contentUrlThumbnail?: string,
  contentUrlAlt?: string,
  audioUrl?: string,
  singleChoice?: boolean,
  interactionTime?: number,
  mcPoolingEnabled?: boolean,
  linkMIMEType?: string,
  invisible: boolean,
  assessmentsNotStarted: boolean,
  // timestamps: Timestamps,
  rewards: Reward[],
}

export type ActionWithStudyContent = {
  userId: string,
  companyId: string,
  courseId: string,
  action: Action,
  study?: Study,
  studyReview?: StudyReview,
}

export type ActionResponseType = 'with_timestamps'|'with_rewards'
export const getActionFragmentName = (types: ActionResponseType[]) => {
  return `ActionFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getActionFragment = (types: ActionResponseType[]) => `
  ${types.includes('with_rewards') ? getRewardFragment([]) : ''}
  fragment ${getActionFragmentName(types)} on Action {
    id
    chapterId
    courseId
    order
    contentType
    userAction
    title
    description
    placeholderAnswer
    imageUrl
    contentUrl
    ${types.includes('with_rewards') ? `
    rewards {
      ...${getRewardFragmentName([])}
    }
    ` : ''}
  }
`



export type ActionWithStudyContentResponseType = 'with_study'|'with_study_review'
export const getActionWithStudyContentFragmentName = (types: ActionWithStudyContentResponseType[]) => {
  return `ActionWithStudyContentFields${types.sort().map(t => `_${t}`).join('')}`
}

export const getActionWithStudyContentFragment = (types: ActionWithStudyContentResponseType[]) => `
  ${getActionFragment([])}
  ${types.includes('with_study') ? getStudyFragment() : ''}
  ${types.includes('with_study_review') ? getStudyReviewFragment() : ''}
  fragment ${getActionWithStudyContentFragmentName(['with_study'])} on ActionWithStudyContent {
    userId
    companyId
    courseId
    action {
      ...${getActionFragmentName([])}
    }
    ${types.includes('with_study') ? `
    study {
      ...${getStudyFragmentName()}
    }
    ` : ''}
    ${types.includes('with_study_review') ? `
    studyReview {
      ...${getStudyReviewFragmentName()}
    }
    ` : ''}
  }
`

export const parseOptions = (options: string) => {
  const opts = options && options.split('\n') || []
  const parsedOpts = opts.filter(o => o).map((o) => {
    const [text, _hint, id] = o.split('|')
    const textNoPlus = text.startsWith('+') ? text.substring(1) : text

    return textNoPlus.trim()
  })
  return parsedOpts.toString()
}

export const isAssessmentCorrect = (placeholderAnswer: string, answer: string) => {
  const correctAnswers = placeholderAnswer.split('\n').filter(o => o.startsWith('+'))
  const userAnswers = answer.split('\n')
  const userAnswerIds = userAnswers.map(a => a.split('|')[2])
  const asdf = correctAnswers.every(ans => userAnswerIds.includes(ans.split('|')[2])) && correctAnswers.length === userAnswers.length
  return asdf
}
