import React from 'react'
import { BannerStateType } from './BannerDetails'
import ReviewAndLaunch from './ReviewAndLaunch'

type ReviewAndLaunchViewProps = {
  state: BannerStateType
  cta: boolean,
  isDate: boolean,
  setState: (value: BannerStateType) => void,
}

const ReviewAndLaunchView = ({ state, cta, setState, isDate }: ReviewAndLaunchViewProps) => {


  return (
      <div className="bg-white px-8 rounded-b-lg mb-52">
        <div className="font-medium text-sm text-listenblue pt-8 uppercase">
          Review and launch
        </div>
          <div className="font-normal text-medgray text-sm mb-4">
            Review your banners and their respective product codes below. Upon clicking “Launch”, your banners will appear immediately on the Gnowbe Web and/or Dashboard screen of the respective product codes.
          </div>
          <ReviewAndLaunch state={state} setState={setState} cta={cta} isDate={isDate} />
      </div>
  )
}

export default ReviewAndLaunchView
