import React from 'react'
import i18n from './i18n'
import FilterIcon from '@heroicons/react/24/solid/FunnelIcon'
import TableFilteMenuItems from './TableFilterMenuItems'
import { clone } from 'ramda'
import Popover from './Popover'
import Tippy from '@tippyjs/react'
import { OrganizationContext } from './context'

export const FILTER_OPERATORS = {
  number: ['lt', 'gt', 'eq', 'lte', 'gte'] as FilterOperator[],
  text: ['eq'] as FilterOperator[], // add 'contains' later
}
export function getDefaultOperator(type: 'text'|'number') {
  switch (type) {
    case 'text': return FILTER_OPERATORS.text[0]
    case 'number': return FILTER_OPERATORS.number[0]
    default: return FILTER_OPERATORS.text[0]
  }
}
export type FilterOperator = 'eq'|'gt'|'gte'|'lt'|'lte'  // add |'contains' later
export type FilterTextContain = {
  field: string;
  name: string;
  type: 'text';
}
export type FilterNumber = {
  field: string;
  name: string;
  type: 'number';
}
export type FilterHeader = FilterTextContain | FilterNumber
export type FilterRow = {
  filter: FilterHeader;
  operator: FilterOperator;
  value: any;
}
type TableFilterProps = {
  header: FilterHeader[],
  values?: FilterRow[],
  onFiltersChange?: (rows: FilterRow[]) => void;
}

type QueryType = {queryList: FilterRow[], queryLastSaved: FilterRow[]}
const FilterContent = (props: {
  query: QueryType,
  onChangeFilter: (key: number, target: 'header'|'operator'|'inputValue', value: any) => void,
  removeQuery: (key: number) => void,
  onAddQuery: () => void,
  onResetQuery: () => void,
  onApplyFilter: () => void,
  header: FilterHeader[],
}) => {
  const orgContext = React.useContext(OrganizationContext)
  const externalUserAttributes = orgContext.organization?.externalUserAttributes || []

  return (
    <div style={{width: 670}} className="px-2 pb-2 bg-white shadow-md flex flex-col justify-between rounded-lg">
      {/* title */}
      <div className="p-3 flex flex-row justify-between items-end">
        <div>
          <h4>{i18n.t('filter')}</h4>
        </div>
        <div></div>
      </div>

      {/* content */}
      <div className="p-3">
      {
        props.query.queryList.length === 0
          ? <span className="text-sm text-lightgray leading-5">
              {i18n.t('add_filter_clicking_on_button')} String search is case sensitive
            </span>
          : props.query.queryList.map((value, i) => {
            return <TableFilteMenuItems
              key={i}
              primaryKey={i}
              header={props.header}
              removeItem={props.removeQuery}
              onChangeFilter={props.onChangeFilter}
              selected={value}
            />
          })
      }
      </div>

      {/* footer */}
      <div className="mt-3 flex flex-row justify-between items-center">
        {/* left */}
        <Tippy content="You can only have one filter at a time." disabled={externalUserAttributes.length > 0 || props.query.queryList.length !== 1}>
          <div>
            <button id="add-new-filter-button" className="px-6 py-2 text-listenblue text-sm border border-solid border-listenblue rounded disabled:opacity-50"
              onClick={props.onAddQuery}
              disabled={!externalUserAttributes.length && props.query.queryList.length === 1}
            >
              {i18n.t('add_new_filter')}
            </button>
          </div>
        </Tippy>
        {/* right */}
        <div className="flex flex-row items-center justify-end">
          <button className="px-6 py-2 text-sm text-deepgray rounded"
            onClick={props.onResetQuery}
          >
            {i18n.t('reset')}
          </button>
          <button className="px-6 py-2 text-sm text-deepgray rounded bg-lake"
            onClick={props.onApplyFilter}
          data-test="apply-filter-btn">
            {i18n.t('apply_filter')}
          </button>
        </div>
      </div>
    </div>
  )
}
export default function (props: TableFilterProps) {
  const [state, setState] = React.useState({
    queryList: props.values || [],
    queryLastSaved: props.values || [],
  })

  const saveQueryList = (field: 'queryList'|'queryLastSaved', value: FilterRow[]) => {
    setState((prev) => {
      return {
        ...prev,
        [field]: value,
      }
    })
  }

  return <Popover
    id="filter-popover"
    text={`${i18n.t('filter')}`}
    icon={<FilterIcon />}
    badge={state.queryList.length.toString()}
    content={
      <FilterContent
        query={state}
        header={props.header}
        removeQuery={(key: number) => {
          saveQueryList('queryList', state.queryList.filter((_, index) => index !== key ))
        }}
        onChangeFilter={(key: number, target: 'header'|'operator'|'inputValue', value: any) => {
          const tmpQueryList = clone(state.queryList)
          switch (target)
          {
            case 'header':
              const filter = props.header.find(h => h.field === value) as FilterHeader
              tmpQueryList[key].filter = filter
              tmpQueryList[key].operator = getDefaultOperator(tmpQueryList[key].filter.type)
              tmpQueryList[key].value = filter.type === 'text' ? '' : 0
              saveQueryList('queryList', tmpQueryList)
              break
            case 'operator':
              tmpQueryList[key].operator = value as FilterOperator
              saveQueryList('queryList', tmpQueryList)
              break
            case 'inputValue':
              tmpQueryList[key].value = tmpQueryList[key].filter.type === 'number'
                ? Number(value)
                : value
              saveQueryList('queryList', tmpQueryList)
              break
            default: break
          }
        }}
        onAddQuery={() => {
          const row = {
            filter: props.header[0],
            operator: getDefaultOperator(props.header[0].type),
            value: props.header[0].type === 'text' ? '' : 0,
          } as FilterRow
          saveQueryList('queryList', state.queryList.concat(row))
        }}
        onResetQuery={() => {
          saveQueryList('queryList', [])
        }}
        onApplyFilter={() => {
          props.onFiltersChange && props.onFiltersChange(state.queryList)
          saveQueryList('queryLastSaved', state.queryList)
        }}
      />
    }
  />
}
