import Chip from '@material-ui/core/Chip'
import React from 'react'
import i18n  from 'components/i18n'
import clsx from 'clsx'

// tslint:disable-next-line: function-name
export function Disabled(props: {clickable?: boolean, disabled?: boolean}) {
  return <Chip size="small" variant="outlined" className={`${clsx([{
    'cursor-pointer': props.clickable,
    'cursor-not-allowed': props.disabled,
  }])} text-red-700 border-red-700`} label={i18n.t('disabled')} />
}

// tslint:disable-next-line: function-name
export function Enabled(props: {clickable?: boolean, disabled?: boolean}) {
  return <Chip size="small" variant="outlined" className={`${clsx([{
    'cursor-pointer': props.clickable,
    'cursor-not-allowed': props.disabled,
  }])} text-green-700 border-green-700`} label={i18n.t('enabled')} />
}

// tslint:disable-next-line: function-name
export function GetStatus(status: boolean) {
  if (status) return <Enabled />
  return <Disabled />
}

export type GnowbeStatus = 'processing'|'approved'|'closed'|'denied'|'cancelled'|'pending'|'queued'|'inprogress'|'done'|'scheduled'|'sent'|'active'|'inactive'|'non-renewing'

const ChipGreen = (label: string) => (
  <Chip size="small" variant="outlined" className="w-26 capitalize text-green-700 border-green-700 border-solid border-2 bg-green-700 bg-opacity-10" label={label} />
)
const ChipRed = (label: string) => (
  <Chip size="small" variant="outlined" className="w-26 capitalize text-coral border-coral border-solid border-2 bg-coral bg-opacity-10" label={label} />
)
const ChipMedGray = (label: string) => (
  <Chip size="small" variant="outlined" className="w-26 capitalize text-medgray border-medgray border-solid border-2 bg-medgray bg-opacity-10" label={label} />
)
const ChipTeak = (label: string) => (
  <Chip size="small" variant="outlined" className="w-26 capitalize text-teak border-teak border-solid border-2 bg-teak bg-opacity-10" label={label} />
)
const ChipYellow = (label: string) => (
  <Chip size="small" variant="outlined" className="w-26 capitalize text-goldenbrown border-actions-video border-solid border-2 bg-actions-video bg-opacity-10" label={label} />
)
// tslint:disable-next-line: function-name
export function GetStatusByString(status: GnowbeStatus) {
  switch (status) {
    case 'approved' : return ChipGreen(status)
    case 'active' : return ChipGreen(status)
    case 'done' : return ChipGreen(status)
    case 'processing' : return ChipYellow(status)
    case 'inprogress' : return ChipYellow('In progress')
    case 'pending' : return ChipTeak(status)
    case 'queued' : return ChipTeak(status)
    case 'closed' : return ChipMedGray(status)
    case 'denied' : return ChipRed(status)
    case 'cancelled' : return ChipRed(status)
    case 'non-renewing' : return ChipRed('Non-renewing')
    case 'scheduled' : return ChipYellow(status)
    case 'sent' : return ChipGreen(status)
    case 'inactive': return ChipRed(status)
    default :  return ChipMedGray(status)
  }
}
