import { gql } from '@apollo/client'
import { getCompanyFragment, getCompanyFragmentName } from '../models/company'
import { getOrganizationFragment, getOrganizationFragmentName, OrganizationBasic, OrganizationSettings } from '../models/organization'

export const GET_COMPANY_WITH_TAGS_SUBSCRIPTIONS_COURSES = gql`
  ${getCompanyFragment(['with_tags', 'with_subscriptions', 'with_courses'])}
  query GetCompanyWithTagsSubscriptionsCourses($companyId: String!) {
    company: getCompany(companyId: $companyId) {
      ...${getCompanyFragmentName(['with_tags', 'with_subscriptions', 'with_courses'])}
    }
  }
`

export type GetOrganizationWithSettingsVars = {organizationId: string}
export type GetOrganizationWithSettingsRes = {organization: OrganizationBasic&OrganizationSettings}
export const GET_ORGANIZATION_WITH_SETTINGS = gql`
  ${getOrganizationFragment(['with_settings'])}
  query GetOrganizationWithSettings($organizationId: String!) {
    organization: getOrganization(organizationId: $organizationId) {
      ...${getOrganizationFragmentName(['with_settings'])}
    }
  }
`
