import fetch from 'isomorphic-fetch'
import { equals, pick } from 'ramda'
import React from 'react'
import { getGnowbeApiToken, logoutHandler } from '../lib/auth'
import CLIENT_SETTINGS from '../lib/client_settings'


export type DataResLoading = {
  resType: 'loading';
}
export type DataResError = {
  resType: 'error';
  status: number;
  errorMsg: string;
}
export type DataResSuccess<TData> = {
  resType: 'success';
  data: TData;

}
export type DataRes<TData> = DataResLoading|DataResError|DataResSuccess<TData>

export type CombinedProps<TPropsIn, K extends keyof TPropsIn, TParamsFromCmp, TDataOut, K2 extends keyof TPropsIn = undefined, TData2Out = {}> = TPropsIn & {
  fData: DataRes<TDataOut>;
  fData2?: DataRes<TData2Out>;
  paramsFromCmp: TParamsFromCmp;
  onParamsChange: (p: TParamsFromCmp, noRefreshIfSame?: boolean) => void;
  fetchData: (props: Pick<TPropsIn, K> & TParamsFromCmp) => Promise<TDataOut>;
  fetchData2: (props: Pick<TPropsIn, K2> & TParamsFromCmp) => Promise<TData2Out>;
}

export function fetchDataApi<TPropsIn, K extends keyof TPropsIn, TParamsFromCmp, TDataOut, K2 extends keyof TPropsIn = undefined, TData2Out = {}>(
  opts: {
    getData: (props: Pick<TPropsIn, K> & TParamsFromCmp) => Promise<TDataOut>;
    getDataItem?: {
      getData?: (props: Pick<TPropsIn, K> & TParamsFromCmp & {dataItemId: any}) => Promise<any>;
      replaceInData: (data: TDataOut, dataItem: any, dataItemId?: any) => TDataOut;
    };
    getDataFields: K[];
    getData2?: (props: Pick<TPropsIn, K2> & TParamsFromCmp) => Promise<TData2Out>;
    getData2Fields?: K2[];
    paramsFromCmpDefault: TParamsFromCmp|((props: TPropsIn) => TParamsFromCmp);
    fetchOnlyFromParamsFromCmpChange?: boolean;
  },
  Component: React.ComponentClass<CombinedProps<TPropsIn, K, TParamsFromCmp, TDataOut>>
    | React.FC<CombinedProps<TPropsIn, K, TParamsFromCmp, TDataOut>>,
) {
  return (
    class A extends React.Component<TPropsIn, {
      loadingData: boolean; loadingDataErrorMsg: string; status: number; data?: TDataOut;
      loadingData2: boolean; loadingData2ErrorMsg: string; data2?: TData2Out
    }> {
      _isUnmounted = false
      _paramsFromCmp: TParamsFromCmp|((props: TPropsIn) => TParamsFromCmp)
      // _id = Math.random().toString()

      _fetchDataState = {
        lastFetchId: '',
        stateDataTimestamp: 0,
        fetching: '',
        fetchingId: {} as {[key: string]: string},
      }

      _fetchData2State = {
        lastFetchId: '',
        stateDataTimestamp: 0,
        fetching: '',
      }

      constructor(props: TPropsIn) {
        super(props)
        this.state = {
          loadingData: true,
          loadingDataErrorMsg: '',
          status: -1,
          loadingData2: !!opts.getData2,
          loadingData2ErrorMsg: '',
        }
        this._paramsFromCmp = typeof opts.paramsFromCmpDefault === 'function'
          ? (opts.paramsFromCmpDefault as any)(props)
          : opts.paramsFromCmpDefault
      }

      componentDidMount() {
        if (opts.fetchOnlyFromParamsFromCmpChange) return
        {
          const dataProps = pick(opts.getDataFields as any, this.props as TPropsIn)
          this.getDataWrapper(dataProps as any)
        }
        if (opts.getData2 && opts.getData2Fields) {
          const data2Props = pick(opts.getData2Fields as any, this.props as TPropsIn)
          this.getData2Wrapper(data2Props as any)
        }
      }

      UNSAFE_componentWillReceiveProps(newProps: TPropsIn) {
        if (opts.fetchOnlyFromParamsFromCmpChange) return
        {
          const oldDataProps = pick(opts.getDataFields as any, this.props as TPropsIn)
          const newDataProps = pick(opts.getDataFields as any, newProps)
          if (!equals(oldDataProps, newDataProps)) {
            this.getDataWrapper(newDataProps as any)
          }
        }
        if (opts.getData2 && opts.getData2Fields) {
          const oldDataProps = pick(opts.getData2Fields as any, this.props as TPropsIn)
          const newDataProps = pick(opts.getData2Fields as any, newProps)
          if (!equals(oldDataProps, newDataProps)) {
            this.getData2Wrapper(newDataProps as any)
          }
        }
      }

      componentWillUnmount() {
        this._isUnmounted = true
      }

      async getDataWrapper(props: Pick<TPropsIn, K>) {
        if (this._isUnmounted) return
        this.setState({loadingData: true, loadingDataErrorMsg: '', data: undefined})
        const r = Math.random().toString(36)
        this._fetchDataState.lastFetchId = r
        try {
          this._fetchDataState.fetching = r
          const data = await opts.getData({...props as any, ...this._paramsFromCmp as any})
          if (this._fetchDataState.fetching === r) {
            this._fetchDataState.fetching = ''
          }
          this._fetchDataState.stateDataTimestamp = Date.now()
          if (this._isUnmounted) return
          if (this._fetchDataState.lastFetchId !== r) return
          this.setState({data, loadingData: false})
        } catch (err) {
          if (this._isUnmounted) return
          if (this._fetchDataState.lastFetchId !== r) return
          this.setState({loadingDataErrorMsg: err.message, loadingData: false, status: err.statusCode})
        }
      }

      async getDataItemWrapper(id: any) {
        if (!opts.getDataItem) throw new Error('You need to specify getDataItem option in order to use getDataItemWrapper')
        try {
          const r = Math.random().toString(36)
          this._fetchDataState.fetchingId[id] = r
          const dataItem = await opts.getDataItem.getData({
            ...this.props as any,
            ...this._paramsFromCmp as any,
            dataItemId: id,
          })
          if (this._fetchDataState.fetchingId[id] === r) {
            this._fetchDataState.fetchingId[id] = ''
          }
          if (this._isUnmounted) return
          const data = opts.getDataItem.replaceInData(this.state.data, dataItem, id)
          this.setState({data})
        } catch (err) {
          // TODO: uross
        }
      }

      async getData2Wrapper(props: Pick<TPropsIn, K2>) {
        if (this._isUnmounted) return
        if (!opts.getData2 || !opts.getData2Fields) return
        this.setState({loadingData2: true, loadingData2ErrorMsg: '', data2: undefined})
        const r = Math.random().toString(36)
        this._fetchData2State.lastFetchId = r
        try {
          this._fetchData2State.fetching = r
          const data2 = await opts.getData2({...props as any, ...this._paramsFromCmp as any})
          if (this._fetchData2State.fetching === r) {
            this._fetchData2State.fetching = ''
          }
          this._fetchData2State.stateDataTimestamp = Date.now()
          if (this._isUnmounted) return
          if (this._fetchData2State.lastFetchId !== r) return
          this.setState({data2, loadingData2: false})
        } catch (err) {
          if (this._isUnmounted) return
          if (this._fetchData2State.lastFetchId !== r) return
          this.setState({loadingData2ErrorMsg: err.message, loadingData2: false, status: err.statusCode})
        }
      }

      render() {
        const data: DataRes<TDataOut> =
          this.state.loadingData ? {resType: 'loading'} :
          this.state.loadingDataErrorMsg ? {
            resType: 'error',
            errorMsg: this.state.loadingDataErrorMsg,
            status: this.state.status} :
          {resType: 'success', data: this.state.data}

        const data2: DataRes<TData2Out> =
          !opts.getData2 ? undefined :
          this.state.loadingData2 ? {resType: 'loading'} :
          this.state.loadingData2ErrorMsg ? {
            resType: 'error',
            errorMsg: this.state.loadingData2ErrorMsg,
            status: this.state.status} :
          {resType: 'success', data: this.state.data2}

        return <Component {...(this.props as any)}
          fData={data}
          fData2={data2}
          paramsFromCmp={this._paramsFromCmp}
          onParamsChange={(p, noRefreshIfSame) => {
            if (noRefreshIfSame && equals(p, this._paramsFromCmp)) {
              return
            }
            this._paramsFromCmp = p
            {
              const dataProps = pick(opts.getDataFields as any, this.props as TPropsIn)
              this.getDataWrapper(dataProps as any)
            }
            if (opts.getData2 && opts.getData2Fields) {
              const data2Props = pick(opts.getData2Fields as any, this.props as TPropsIn)
              this.getData2Wrapper(data2Props as any)
            }
          }}
          fetchData={p => opts.getData(p)}
          fetchData2={p => opts.getData2 && opts.getData2Fields
            ? opts.getData2(p as any)
            : undefined
          }
        />
      }
    }
  )
}

export function fetchDataGApi<TPropsIn, K extends keyof TPropsIn, TParamsFromCmp, TDataOut, K2 extends keyof TPropsIn = undefined, TData2Out = {}>(
  opts: {
    getEndpoint: (props: Pick<TPropsIn, K> & TParamsFromCmp) => string|TDataOut;
    getEndpointItem?: {
      getEndpoint: (props: Pick<TPropsIn, K> & TParamsFromCmp & {dataItemId: any}) => string;
      replaceInData: (data: TDataOut, dataItem: any, dataItemId?: any) => TDataOut;
    };
    getDataFields: K[];
    getEndpoint2?: (props: Pick<TPropsIn, K2> & TParamsFromCmp) => string|TData2Out;
    getData2Fields?: K2[];
    paramsFromCmpDefault: TParamsFromCmp|((props: TPropsIn) => TParamsFromCmp);
    fetchOnlyFromParamsFromCmpChange?: boolean;
  },
  Component: React.ComponentClass<CombinedProps<TPropsIn, K, TParamsFromCmp, TDataOut>>
    | React.FC<CombinedProps<TPropsIn, K, TParamsFromCmp, TDataOut>>,
) {
  const getData = async (props: Pick<TPropsIn, K> & TParamsFromCmp) => {
    try {
      const endpoint = opts.getEndpoint(props)
      if (typeof endpoint !== 'string') {
        return endpoint
      }
      const apiToken = await getGnowbeApiToken()
      const res = await fetch(
        CLIENT_SETTINGS.public.gnowbeApiUrl + endpoint,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'x-gnowbe-source': 'gnowbe-dashboard 1',
            authorization: `${apiToken.tokenType} ${apiToken.token}`,
          } as any,
        },
      )

      if (res.status === 401) {
        logoutHandler()
      }

      const json = await res.json()

      if (json.error) {
        throw {
          message: json.error.message,
          statusCode: json.error.code,
        }
      }

      return json.data as TDataOut
    } catch (err) {
      throw err
    }
  }

  const getDataItem = async (props: Pick<TPropsIn, K> & TParamsFromCmp & {dataItemId: any}) => {
    if (!opts.getEndpointItem) throw new Error('getEndpointItem not set')
    try {
      const apiToken = await getGnowbeApiToken()
      const res = await fetch(
        CLIENT_SETTINGS.public.gnowbeApiUrl + opts.getEndpointItem.getEndpoint(props),
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'x-gnowbe-source': 'gnowbe-dashboard 1',
            authorization: `${apiToken.tokenType} ${apiToken.token}`,
          } as any,
        },
      )
      if (res.status === 401) {
        logoutHandler()
      }
      const json = await res.json()
      if (res.status === 401) {
        return null
      }
      if (res.status >= 400) {
        throw new Error(json.error.message)
      }

      return json.data as any
    } catch (err) {
      throw err
    }
  }

  const getData2 = async (props: Pick<TPropsIn, K2> & TParamsFromCmp) => {
    if (!opts.getEndpoint2) throw new Error('getEndpoint2 not defined')
    try {
      const endpoint = opts.getEndpoint2(props)
      if (typeof endpoint !== 'string') {
        return endpoint
      }
      const apiToken = await getGnowbeApiToken()
      const res = await fetch(
        CLIENT_SETTINGS.public.gnowbeApiUrl + endpoint,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'x-gnowbe-source': 'gnowbe-dashboard 1',
            authorization: `${apiToken.tokenType} ${apiToken.token}`,
          } as any,
        },
      )
      if (res.status === 401) {
        logoutHandler()
      }
      const json = await res.json()
      if (json.error) {
        throw new Error(json.error.message)
      }

      return json.data as TData2Out
    } catch (err) {
      throw err
    }
  }

  return fetchDataApi({
    getData,
    getDataItem: opts.getEndpointItem ? {
      getData: getDataItem,
      replaceInData: opts.getEndpointItem.replaceInData,
    } : undefined,
    getDataFields: opts.getDataFields,
    getData2,
    getData2Fields: opts.getData2Fields,
    paramsFromCmpDefault: opts.paramsFromCmpDefault,
    fetchOnlyFromParamsFromCmpChange: opts.fetchOnlyFromParamsFromCmpChange,
  },                  Component)
}
