export enum RewardChannel {
  inapp,
  email,
  rating,
}

export type Reward = {
  id: string,
  courseId: string
  channel: RewardChannel,
  name: string,
  title: string,
  text: string,
  eventName: string,
  eventData: string,
  imageUrl?: string,
  imageAuthor?: string,
  showMultipleTimes: boolean,
  delay?: string,
  ratingOptionId?: string,
  createdAt: number,
  updatedAt: number,
}


export type RewardResponseType = 'with_reward_channel'
export const getRewardFragmentName = (types: RewardResponseType[]) => {
  return `RewardFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getRewardFragment = (types: RewardResponseType[]) => {
  return `
    fragment ${getRewardFragmentName(types)} on Action {
      id
      courseId
      ${types.includes('with_reward_channel') ? `
      channel 
      ` : ''}
      name
      title
      text
      eventName
      eventData
      imageUrl
      imageAuthor
      showMultipleTimes
      delay
      ratingOptionId
      createdAt
      updatedAt
    }
  `
}
