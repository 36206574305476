import { gql } from '@apollo/client'
import { CompanyBasic, CompanyCourses, CompanyInvitedUsersCount, CompanyOrganizationSettings, CompanyOrganizationTags, CompanyResponseType, CompanySettings, CompanySubscriptions, CompanyTags, getCompanyFragment, getCompanyFragmentName } from 'models/company'
import { CourseDistributionParamsInput, getOrganizationCourseFragment, getOrganizationCourseFragmentName } from 'models/course'
import { EngagementSummary } from 'models/engagement_summary'
import { getOrganizationCompaniesFragment, getOrganizationCompaniesFragmentName } from 'models/organization'

export type GetCompanyRes = {
  company: CompanyBasic&CompanyCourses&CompanySettings&CompanyTags&CompanySubscriptions&CompanyOrganizationSettings,
}
export type GetCompanyVars = {
  companyId: string,
}
export const GET_COMPANY = (types: CompanyResponseType[]) => gql`
  ${getCompanyFragment(types)}
  query GetCompanyWithCourses($companyId: String!) {
    company: getCompany(companyId: $companyId) {
      ...${getCompanyFragmentName(types)}
    }
  }
`
export type GetCompaniesRes = {
  companiesRes: {
    companies: (CompanyBasic&CompanySettings&CompanyOrganizationSettings&CompanyOrganizationTags)[],
    companiesCount: number,
  },
}
export type GetCompaniesVars = {
  searchText: string; skip: number; limit: number;
  onlyHavingCourse?: string; onlyAgentCompanies?: string; onlyEditorCompanies?: string;
  onlyIds?: string[]; skipCompanyTypes?: string[];
}
export const GET_COMPANIES = (types: CompanyResponseType[]) => gql`
  ${getCompanyFragment(types)}
  query GetCompanies($searchText: String!, $skip: Int!, $limit: Int!,
    $onlyHavingCourse: String, $onlyAgentCompanies: String, $onlyEditorCompanies: String,
    $onlyIds: [String!], $skipCompanyTypes: [CompanyType!]
  ) {
    companiesRes: getCompanies(searchText: $searchText, skip: $skip, limit: $limit,
      onlyHavingCourse: $onlyHavingCourse, onlyAgentCompanies: $onlyAgentCompanies, onlyEditorCompanies: $onlyEditorCompanies,
      onlyIds: $onlyIds, skipCompanyTypes: $skipCompanyTypes
    ) {
      companies {
        ...${getCompanyFragmentName(types)}
      }
      companiesCount
    }
  }
`

export type GetOrganizationCompaniesRes = {
  organizationCompanies: {
    organizationId: string;
    companies: {
      company: CompanyBasic&CompanyTags&CompanySubscriptions&CompanyInvitedUsersCount,
      engagementSummary: EngagementSummary,
    }[],
    companiesCount: number,
    companiesNonPublicCount: number,
  },
}
export type GetOrganizationCompaniesVars = {
  organizationId: string,
  // If passed only companies with this course will be returned. And metrics will be count only for this course.
  courseId?:  String,
  onlyVisibleInAnalyticsInMetrics:  boolean,
  searchText: string,
  skip: number,
  limit:  number,
}
export const GET_ORGANIZATION_COMPANIES = () => gql`
${getOrganizationCompaniesFragment()}
  query GetOrganizationCompanies($organizationId: String!,
    $onlyVisibleInAnalyticsInMetrics: Boolean!,
    $courseId: String,
    $searchText: String!,
    $skip: Int!,
    $limit: Int!
  ) {
    organizationCompanies: getOrganizationCompanies(
      organizationId: $organizationId,
      onlyVisibleInAnalyticsInMetrics: $onlyVisibleInAnalyticsInMetrics,
      courseId: $courseId,
      searchText: $searchText,
      skip: $skip, limit: $limit
    ){
      ...${getOrganizationCompaniesFragmentName()}
    }
  }
`

export const UPDATE_COMPANY_COURSES = (types: CompanyResponseType[]) => gql`
  ${getCompanyFragment(types)}
  mutation UpdateCompanyCourses($companyId: String!, $coursesUpdate: CompanyCoursesUpdate!) {
    company: updateCompanyCourses(companyId: $companyId, coursesUpdate: $coursesUpdate) {
      ...${getCompanyFragmentName(types)}
    }
  }
`

export type GetCompaniesAllowedToDistributeCourseToRes = {
  companiesRes: {
    companies: (CompanyBasic&CompanySubscriptions&CompanyOrganizationSettings)[],
    companiesCount: number,
  }}
export type GetCompaniesAllowedToDistributeCourseToVars = {
  searchText: string; skip: number; limit: number;
  userId: string; courseId: string; organizationId?: string;
}
export const GET_COMPANIES_ALLOWED_TO_DISTRIBUTE_COURSE_TO = (types: CompanyResponseType[]) => gql`
  ${getCompanyFragment(types)}
  query GetCompaniesAllowedToDistributeCourseTo($searchText: String!, $skip: Int!, $limit: Int!,
    $userId: String!, $courseId: String!, $organizationId: String
  ) {
    companiesRes: getCompaniesAllowedToDistributeCourseTo(searchText: $searchText, skip: $skip, limit: $limit,
      userId: $userId, courseId: $courseId, organizationId: $organizationId
    ) {
      companies {
        ...${getCompanyFragmentName(types)}
      }
      companiesCount
    }
  }
`
export type GetCompaniesWithCourseDistributionVars = {
  courseId: string; organizationId: string;
}
export type GetCompaniesWithCourseDistributionRes = {
  companies: string[]
}
export const GET_COMPANIES_WITH_COURSE_DISTRIBUTION = () => gql`
  query GetCompaniesWithCourseDistribution($organizationId: String!, $courseId: String!) {
    companies: getCompaniesWithCourseDistribution(organizationId: $organizationId, courseId: $courseId)
  }
`
export const GET_COMPANIES_OF_ORGANIZATION_2 = () => gql`
  query GetUserOrganizationCompanies2 ($organizationId: String!, $userId: String!) {
    organization: getUserOrganizationCompanies2 (organizationId: $organizationId, userId: $userId) {
      id
      companies {
        company {
          id
          name
          companyType
        }
      }
    }
  }
`

export type GetCompaniesAllowedToCopyToRes = {
  companiesRes: {
    companies: (CompanyBasic&CompanySubscriptions&CompanyOrganizationSettings)[],
    companiesCount: number,
  }}
export type GetCompaniesAllowedToCopyToVars = {
  searchText: string; skip: number; limit: number;
}
export const GET_COMPANIES_ALLOWED_TO_COPY_TO = (types: CompanyResponseType[]) => gql`
  ${getCompanyFragment(types)}
  query GetCompaniesAllowedToCopyTo($searchText: String!, $skip: Int!, $limit: Int!) {
    companiesRes: getCompaniesAllowedToCopyTo(searchText: $searchText, skip: $skip, limit: $limit) {
      companies {
        ...${getCompanyFragmentName(types)}
      }
      companiesCount
    }
  }
`

export type ToggleOrgCourseDistSelection = {
  excludeCompanyIds?: string[],
  onlyCompanyIds?: string[],
  searchText?: string,
}
type ToggleOrgCourseDist = {
  addTo?: ToggleOrgCourseDistSelection,
  organizationId: string,
  removeFrom?: ToggleOrgCourseDistSelection,
}
export type ToggleOrgsCourseDistributionVars = {
  courseId: string,
  organizations: ToggleOrgCourseDist[],
  distributionParams: CourseDistributionParamsInput,
  onlyVisibleInAnalyticsInMetrics: boolean,
}
export const TOGGLE_ORGS_COURSE_DISTRIBUTION = gql`
  ${getOrganizationCourseFragment()}
  mutation ToggleOrgsCourseDistribution(
    $courseId: String!,
    $organizations: [ToggleOrgCourseDist!]!,
    $distributionParams: CourseDistributionParamsInput!,
    $onlyVisibleInAnalyticsInMetrics: Boolean!
  ) {
    res: toggleOrgsCourseDistribution(
      courseId: $courseId,
      organizations: $organizations,
      distributionParams: $distributionParams,
      onlyVisibleInAnalyticsInMetrics: $onlyVisibleInAnalyticsInMetrics
    ) {
      ...${getOrganizationCourseFragmentName()}
    }
  }
`

export type GetCompanyLandingPageSettings = {
  companyId: string,
  organizationId: string,
  landingPageEnabled: boolean,
  settings: GetCompanyLandingPage,
}
export type GetCompanyLandingPage = {
  logoImage?: string,
  bannerImage?: string,
  primaryColor?: string,
  secondaryColor?: string,
  backgroundColor?: string,
  primaryColorInherited?: boolean,
  secondaryColorInherited?: boolean,
  backgroundColorInherited?: boolean,
  logoInherited?: boolean,
  bannerInherited?: boolean,
  name?: string,
  description?: string,
  isPublic: boolean,
  template?: 'default'|'sky'|'luna'|'bubble',
  showGroupMembers?: boolean,
  showAgents?: boolean,
  showPrograms?: boolean,
  showAnnouncements?: boolean,
  showGroupDescription?: boolean,
  blurElementsOnLoginScreen?: boolean,
  allowMembersToMessageGroupAgents?: boolean,
  allowMembersToMessageOtherMembers?: boolean,
  allowMembersToAutoJoin?: boolean
}
export type GetCompanyLandingPageSettingsVars = { companyId: string }
export type GetCompanyLandingPageSettingsRes = {
  res: {
    companyId: string,
    organizationId: string,
    landingPageEnabled: boolean,
    settings: GetCompanyLandingPage,
  },
}
export const GET_COMPANY_LANDING_PAGE_SETTINGS = gql`
  query GetCompanyLandingPageSettings ($companyId: String!) {
    res: getCompanySettings (companyId: $companyId) {
      companyId
      organizationId
      landingPageEnabled
      settings {
        logoImage
        bannerImage
        primaryColor
        secondaryColor
        backgroundColor
        logoInherited
        bannerInherited
        primaryColorInherited
        secondaryColorInherited
        backgroundColorInherited
        name
        description
        isPublic
        template
        showGroupMembers
        showAgents
        showPrograms
        showAnnouncements
        showGroupDescription
        blurElementsOnLoginScreen
        allowMembersToMessageGroupAgents
        allowMembersToMessageOtherMembers
        allowMembersToAutoJoin
      }
    }
  }
`
export const SET_COMPANY_LANDING_PAGE_SETTINGS = gql`
  mutation UpsertCompanySettings($companyId: String!, $organizationId: String!, $landingPageEnabled: Boolean!, $settings: CompanySettingsInput!) {
    res: upsertCompanySettings(companyId: $companyId, organizationId: $organizationId, landingPageEnabled: $landingPageEnabled, settings: $settings) {
      companyId
      organizationId
      landingPageEnabled
      settings {
        logoImage
        bannerImage
        primaryColor
        secondaryColor
        backgroundColor
        logoInherited
        bannerInherited
        primaryColorInherited
        secondaryColorInherited
        backgroundColorInherited
        name
        description
        isPublic
        template
        showGroupMembers
        showAgents
        showPrograms
        showAnnouncements
        showGroupDescription
        blurElementsOnLoginScreen
        allowMembersToMessageGroupAgents
        allowMembersToMessageOtherMembers
        allowMembersToAutoJoin
      }
    }
  }
`