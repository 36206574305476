import { gql } from '@apollo/client'
import i18n from 'components/i18n'

export const getGnowbeUpdates = () => {
  const translations = i18n.getResourceBundle('en', 'translations')

  /** Structure example:
   *  {
   *    updates_15122021: {
   *      updates_15122021_title: {key: 'updates_15122021_title', timestamp: 1639547651},
   *      updates_15122021_description: {key: 'updates_15122021_description', timestamp: 1639547651},
   *    }
   *  }
   */
  const updates = Object.keys(translations)
  .filter(k => k.startsWith('updates_'))
  .reduce((obj, item) => {
    const test = (item.split('_')[1]).match(/.{1,2}/g)
    const day = test ? Number(test[0]) : 0
    const month = test ? Number(test[1]) : 0
    const year = test ? Number(String(test[2]) + String(test[3])) : 0
    obj[`updates_${item.split('_')[1]}`] = {
      [item]: {
        key: item,
        timestamp: Date.parse(`${month} ${day} ${year}`),
      },
      ...obj[`updates_${item.split('_')[1]}`],
    }
    return obj
  }, {})
  return updates
}
export const GET_GNOWBE_UPDATES = gql`
  query GetGnowbeUpdates {
    gnowbeUpdates @client
  }
`
