import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import fetch from 'isomorphic-fetch'
import React from 'react'
import { getGnowbeApiToken, logoutHandler } from '../../lib/auth'
import CLIENT_SETTINGS from '../../lib/client_settings'
import { CompanyBasic } from '../../models/company'
import { CourseBasic } from '../../models/course'
import CompaniesTable from '../company/CompaniesTable'
import CoursesTable from '../course/CoursesTable'
import i18n from '../i18n'
import { openAlert, showModal, showSnackbar } from '../snackbar'
import { copyToClipboard } from '../utils'
import Paper from '@material-ui/core/Paper'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { ApolloClient, ApolloConsumer, ApolloProvider } from '@apollo/client'
import { Button } from 'components/elements/Button'
import TrashIcon from '@heroicons/react/24/solid/TrashIcon'

type State = {
  courses: CourseBasic[];
  companies: CompanyBasic[];
  reference: string;
}

export class BundleExtRefView extends React.Component<{}, State> {

  constructor(props) {
    super(props)
    this.state = {
      courses: [],
      companies: [],
      reference: '',
    }
  }

  addCourse = (crs: CourseBasic) => {
    this.setState(s => ({
      courses: s.courses.concat(crs).slice().sort((a, b) => a.title <= b.title ? -1 : 1),
      reference: '',
    }))
  }
  removeCourse = (crsId: string) => {
    this.setState(s => ({
      courses: s.courses.filter(c => c.id !== crsId),
      reference: '',
    }))
  }

  addCompany = (cmp: CompanyBasic) => {
    this.setState(s => ({
      companies: s.companies.concat(cmp).slice().sort((a, b) => a.name <= b.name ? -1 : 1),
      reference: '',
    }))
  }
  removeCompany = (cmpId: string) => {
    this.setState(s => ({
      companies: s.companies.filter(c => c.id !== cmpId),
      reference: '',
    }))
  }

  showCoursesModal = (client: ApolloClient<any>) => (e: React.SyntheticEvent<any>) => {
    e.preventDefault()
    const selectedCourses = new Set(this.state.courses.map(c => c.id))
    const modalHandler = showModal({
      title: i18n.t('program', {count: 0}),
      content: [{
        element: <div style={{ overflowY: 'auto', overflowX: 'hidden', padding: '10px' }}>
          <ApolloProvider client={client}>
            <CoursesTable
              getCommands={crs =>
                <Button size="small" disabled={selectedCourses.has(crs.id)}
                  onClick={(e) => {
                    e.preventDefault()
                    modalHandler.close()
                    this.addCourse(crs)
                  }}
                  text={selectedCourses.has(crs.id) ? i18n.t('added') : i18n.t('add')}
                />
              }
            />
          </ApolloProvider>
        </div>,
      }],
    })
  }

  showCompaniesModal = (client: ApolloClient<any>) => (e: React.SyntheticEvent<any>) => {
    e.preventDefault()
    const selectedCompanies = new Set(this.state.companies.map(c => c.id))
    const modalHandler = showModal({
      title: i18n.t('group', {count: 0}),
      content: [{
        element: <div style={{ overflowY: 'auto', overflowX: 'hidden', padding: '10px' }}>
          <ApolloProvider client={client}>
            <CompaniesTable
              showOrganizationName={true}
              skipCompanyTypes={['test', 'public']}
              getCommands={cmp =>
                <Button size="small" disabled={selectedCompanies.has(cmp.id)}
                  onClick={(e) => {
                    e.preventDefault()
                    modalHandler.close()
                    this.addCompany(cmp)
                  }}
                  text={selectedCompanies.has(cmp.id) ? i18n.t('added') : i18n.t('add')}
                />
              }
            />
          </ApolloProvider>
        </div>,
      }],
    })
  }

  generateReference = async (e) => {
    e.preventDefault()

    try {
      const body = {
        courseIds: this.state.courses.map(c => c.id),
        companyIds: this.state.companies.map(c => c.id),
        partnersShares: [],
      }
      const apiToken = await getGnowbeApiToken()
      const res = await fetch(
        CLIENT_SETTINGS.public.gnowbeApiUrl +
        `/api/v1/integrations/2checkout/ext_ref`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'x-gnowbe-source': 'gnowbe-dashboard 1',
            authorization: apiToken.tokenType + ' ' + apiToken.token,
          } as any,
          body: JSON.stringify(body),
        },
      )
      if (res.status === 401) {
        logoutHandler()
      }

      const json = await res.json()
      if (json.error) {
        throw new Error(json.error.message)
      }

      const bodyNow = {
        courseIds: this.state.courses.map(c => c.id),
        companyIds: this.state.companies.map(c => c.id),
        partnersShares: [],
      }
      if (JSON.stringify(bodyNow) !== JSON.stringify(body)) {
        return
      }

      this.setState({reference: json.data.extRef})
    } catch (err) {
      openAlert({title: 'Something went wrong', description: 'Reference was not generated: \n\n' + err.message})
    }
  }

  render() {

    const {courses, companies, reference} = this.state

    return <>

      <header>
        <h2 className="text-gray-800">External reference generator</h2>
      </header>

      <h4>{i18n.t('program', {count: 0})}</h4>
      <div style={{width: '100%'}}>
        <div className="flex-1 text-right mb-2">
          <ApolloConsumer>{client =>
            <Button type="secondary"
              onClick={this.showCoursesModal(client)}
              icon={<AddRoundedIcon />}
              text={`${i18n.t('add')} ${i18n.t('program', {count: 1})}`}
            />
          }</ApolloConsumer>
        </div>
        <CoursesTable onlyIds={courses.map(c => c.id)}
          getCommands={crs =>
            <Button size="small"
              onClick={(e) => {
                e.preventDefault()
                this.removeCourse(crs.id)
              }}
              text={i18n.t('remove')}
              icon={<TrashIcon />}
            />
          }
        />
      </div>

      <br/>

      <h4>{i18n.t('group', {count: 1})}</h4>
      <div style={{width: '100%'}}>
        <div className="flex-1 text-right mb-2">
          <ApolloConsumer>{client =>
            <Button type="secondary"
              onClick={this.showCompaniesModal(client)}
              text={`${i18n.t('add')} ${i18n.t('group')}`}
              icon={<AddRoundedIcon fontSize="small" />}
            />
          }</ApolloConsumer>
        </div>
        <CompaniesTable onlyIds={companies.map(c => c.id)}
          showOrganizationName={true}
          getCommands={cmp =>
            <Button size="small"
              onClick={(e) => {
                e.preventDefault()
                this.removeCompany(cmp.id)
              }}
              icon={<TrashIcon />}
              text={i18n.t('remove')}
            />
          }
        />
      </div>

      <h4 className="mt-8">External reference:</h4>

      <Paper className="p-4 mt-4">
        <Button type="secondary" text="Generate" onClick={this.generateReference}/>&nbsp;&nbsp;
        <Button type="destructive" text="Clear"
          onClick={() => this.setState({courses: [], companies: [], reference: ''})} />
        <br/>
        <Input
          value={reference}
          fullWidth={true}
          disabled={true}
          endAdornment={
            <InputAdornment position="end">
              <span style={{cursor: 'pointer'}} onClick={e => {
                const res = copyToClipboard(reference)
                if (res) showSnackbar(i18n.t('copied_to_clipboard'), 3000, 'success')
                else showSnackbar(i18n.t('copy_to_clipboard_not_supported'), undefined, 'error')
              }}>🔗</span>
            </InputAdornment>
          }
        />
      </Paper>
    </>
  }
}
