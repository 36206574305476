import { gql } from '@apollo/client';
import { Banner } from 'models/banner';

export const GET_ALL_BANNERS = gql`
   query GetAllNotificationBanners {
    banners: getAllNotificationBanners {
      bannerId
      type
      bannerText
      bannerColor
      ctaButton {
        url
        label
      }
      showToPlatform
      showToProduct
      expirationTime
      minRangeCopy
      maxRangeCopy
      dismissForDays
      showToProduct
      enabled
      creatorId
      creatorName
      changeHistory {
        timestamp
        bannerText
        updatedById
        updatedByName
        showToProduct
        showToPlatform
        updatedAt
      }
      deleted
      createdAt
    }
  }
`

export type GET_BANNER_RES = {
  banners: Banner[];
}

export const GET_BANNER = gql`
  query GetUserNotificationBanners {
    banners: getUserNotificationBanners {
      bannerId
      type
      bannerText
      bannerColor
      ctaButton {
        url
        label
      }
      showToPlatform
      showToProduct
      expirationTime
      minRangeCopy
      maxRangeCopy
      dismissForDays
    }
  }
`
