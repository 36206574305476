import { BillingCycle } from 'models/bundle'

export const ALLOWED_PLANS = [
  // 'creator-organization-v5-developing-annual',
  // 'creator-organization-v5-developing-quarterly',
  // 'creator-pro-v5-developing-annual',
  // 'creator-pro-v5-developed-quarterly',
  // 'creator-pro-plus-v5-developing-annual',
  // 'creator-pro-v5-developing-quarterly',
  // 'creator-starter-v5-developing-annual',
  // 'creator-starter-v5-developing-monthly',
  // 'cu-sta-0101-monthly-dm',
  'cu-sta-0101-qtr-dm',
  'cu-sta-0101-annual-dm',
  'cu-pro-0101-qtr-dm',
  'cu-pro-0101-annual-dm',
  // 'cu-ent-0101-qtr-dm',
  'cu-ent-0101-annual-dm',
]
export const REFFERAL_ALLOWED_PLANS = [
  'creator-pro-v5-developing-annual',
  'creator-pro-v5-developed-quarterly',
  'creator-pro-plus-v5-developing-annual',
  'creator-pro-v5-developing-quarterly',
  'creator-starter-v5-developing-annual',
  'creator-starter-v5-developing-monthly',
  'cu-sta-0101-monthly-dm',
  'cu-sta-0101-qtr-dm',
  'cu-sta-0101-annual-dm',
  'cu-pro-0101-qtr-dm',
  'cu-pro-0101-annual-dm',
  'cu-ent-0101-annual-dm',
]

export const ALLOWED_PERIODS: BillingCycle[] = ['quarterly', 'yearly']

export const planMapping = {
  'creator-starter-v5-developing-monthly': 'cu-sta-0101-monthly-dm',
  'creator-starter-v5-developing-annual': 'cu-sta-0101-annual-dm',
  'creator-pro-v5-developing-quarterly': 'cu-pro-0101-qtr-dm',
  'creator-pro-plus-v5-developing-annual': 'cu-pro-0101-annual-dm',
  'creator-pro-v5-developed-quarterly': 'cu-pro-0101-qtr-dm',
  'creator-pro-v5-developing-annual': 'cu-pro-0101-annual-dm',
  'creator-organization-v5-developing-quarterly': 'cu-ent-0101-qtr-dm',
  'creator-organization-v5-developing-annual': 'cu-ent-0101-annual-dm',
}