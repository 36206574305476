import { gql, useQuery } from '@apollo/client'
import { showModal } from 'components/snackbar'
import { GET_ALL_BANNERS } from 'queries/banners'
import React from 'react'
import ActiveBanners from './ActiveBanners'
import { BannerStateType } from './BannerDetails'
import DeleteModal from './DeleteModal'
import InactiveBanners from './InactiveBanners'

type BannerHistoryViewProps = {
  setState: (value: BannerStateType) => void,
  setIsDate: (value: boolean) => void,
  setCta: (value: boolean) => void,
}

const BannerHistoryView = ({setState, setIsDate, setCta}: BannerHistoryViewProps) => {

  const { data, loading, variables, client } = useQuery(GET_ALL_BANNERS)
  const isOdd = (num: number) => (num % 2) !== 1
  const deleteBanner = async (bannerId) => {
    const modal = showModal({
      title: 'Delete Banner',
      hideElements: ['actions'],
      content: [{
        element: (
          <DeleteModal
            bannerId={bannerId}
            onClose={() => {
              modal.close()
              const queryData: any = client.readQuery({ query: GET_ALL_BANNERS, variables })
              if (!queryData) return
              client.writeQuery({
                query: GET_ALL_BANNERS,
                variables,
                data: {
                  ...queryData,
                  banners: queryData.banners.filter((banner) => {
                    return banner.bannerId !== bannerId
                  }),
                },
              })
            }}
          />
        ),
      }],
    })
  }

  const updateBannerEnabled = (bannerId: string, enabled: boolean) => {
    const queryData: any = client.readQuery({ query: GET_ALL_BANNERS, variables })
    if (!queryData) return
    client.writeQuery({
      query: GET_ALL_BANNERS,
      variables,
      data: {
        ...queryData,
        banners: queryData.banners.map((banner) => {
          if (banner.bannerId === bannerId) {
            return {
              ...banner,
              enabled,
            }
          }
          return banner
        }),
      },
    })
  }

  return (
    <div className="bg-white px-8 rounded-b-lg mb-52">
      <div className="font-medium text-sm text-listenblue pt-8 uppercase">
        Active banners
      </div>
      <ActiveBanners
        bannerData={data}
        bannerLoading={loading}
        isOdd={isOdd}
        deleteBanner={deleteBanner}
        setState={setState}
        setIsDate={setIsDate}
        setCta={setCta}
        updateBannerEnabled={updateBannerEnabled}
      />
      <div className="font-medium text-sm text-listenblue pt-12 uppercase">
        Inactive banners
      </div>
      <InactiveBanners
        bannerData={data}
        bannerLoading={loading}
        isOdd={isOdd}
        deleteBanner={deleteBanner}
        setState={setState}
        setIsDate={setIsDate}
        setCta={setCta}
        updateBannerEnabled={updateBannerEnabled}
      />
    </div>
  )
}

export default BannerHistoryView
